import { useTranslation } from 'react-i18next';

// Apollo
import { useQuery } from '@apollo/client';
import { REFLEXIVITY_AXES } from 'views/authenticated/management/reflexivity-axes/gql-reflexivity-axes';

// Services
import { IReflexivityAxis } from 'services/interfaces';
import { EStepType } from 'services/enums';

// Components
import Select, { ISelectProps } from 'components/react-select';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ SELECT REFLEXIVITY AXIS --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ISelectReflexivityAxisProps extends ISelectProps {
    stepType?: EStepType;
}

export default function SelectReflexivityAxis(props: ISelectReflexivityAxisProps) {

    const { t } = useTranslation();

    const { loading, data } = useQuery<{ reflexivityAxes: IReflexivityAxis[] }>(REFLEXIVITY_AXES, {
        variables: { stepType: props.stepType },
    });

    return (
        <Select
            {...props}
            placeholder={t('reflexivity_axes')}
            options={data?.reflexivityAxes ? data.reflexivityAxes.map(ra => ({ value: ra.id, label: ra.name })) : []}
            isLoading={loading}
        />
    );
}
