import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InputActionMeta, ActionMeta } from 'react-select';

// Lodash
import { debounce } from 'lodash';

// Apollo
import { useQuery } from '@apollo/client';
import { COMPANIES } from 'views/authenticated/companies/gql-companies';

// Services
import { ICompany, ISelectOption } from 'services/interfaces';

// Components
import Select, { ISelectProps } from 'components/react-select';
import { getSelectedOptionCompany } from '../index';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SELECT COMPANY -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ISelectCompanyProps extends ISelectProps {
    onChange: (newValue: any) => void;
    filterIds?: string[];
}

export default function SelectCompany(props: ISelectCompanyProps) {

    const { filterIds } = props;
    
    const { t } = useTranslation();
    const [options, setOptions] = useState<ISelectOption[]>([]);
    const [searchValue, setSearchValue] = useState('');

    const { loading, data } = useQuery<{ companies: { data: ICompany[] }}>(COMPANIES, {
        variables: {
            page: 1,
            first: 10,
            search: '%' + searchValue + '%',
        },
    });

    const delayedOnSearch = useRef(debounce(value => setSearchValue(value), 500)).current;

    const handleDelayedOnSearch = (value: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change') {
            delayedOnSearch(value);
        }
    };

    const handleOnChange = (value: any, actionMeta: ActionMeta<any>) => {
        if (actionMeta.action === 'clear') {
            setSearchValue('');
        }
        props.onChange(value);
    };

    useEffect(() => {
        if (data?.companies) {

            let companies = data.companies?.data;

            if (filterIds && filterIds.length > 0) {
                companies = companies.filter(c => !filterIds.includes(c.id));
            }

            setOptions(companies.map(c => getSelectedOptionCompany(c)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, filterIds]);

    return (
        <Select
            {...props}
            placeholder={t('search_company')}
            onChange={(value, actionMeta) => handleOnChange(value, actionMeta)}
            onInputChange={(value, actionMeta) => handleDelayedOnSearch(value, actionMeta)}
            options={options}
            emptyMessage={t('none.2')}
            isLoading={loading}
        />
    );
}
