import { lazy } from 'react';
import { Routes, Route, useLocation, Navigate, Outlet, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Services
import { getToken, getStoredUser } from 'services/storage';
import { getBackUrl } from 'services/utils';
import { useDocumentTitle } from 'services/hooks';

// Components
import PageNotFound from 'components/page-not-found';

// Views
import AuthenticatedLayout from 'views/authenticated';
import ArticleRoutes from './articles-routes';
import RepositoryRoutes from './repository-routes';
import CertificationRoutes from './certification-routes';
import UserRoutes from './user-routes';
import CompanyRoutes from './company-routes';
import PedagogicalToolRoutes from './pedagogical-tool-routes';
import ModelRoutes from './model-routes';
import ManagementRoutes from './management-routes';

/* * *
* Lazy Imports
*/

const Dashboard = lazy(() => import('../../views/authenticated/dashboard'));
const Profile = lazy(() => import('../../views/authenticated/profile'));

/* * *
* Require Authentication
*/

const RequireAuth = ({ children } : { children: JSX.Element }) => {
    
    const token = getToken();
    const location = useLocation();

    if (token) {
        return children;
    }
    return <Navigate to="/" state={{ from: location }} replace />;
};

/* * *
* Learner Restriction
*/

const LearnerRestriction = ({ children } : { children: JSX.Element }) => {
    
    const storedUser = getStoredUser();

    if (storedUser?.role?.id && Number(storedUser.role.id) < 5) {
        return children;
    }
    return <Navigate to="/app" replace />;
};

/* * *
* Require Permission
*/

export const RequirePerm = ({ children, perm } : { children: JSX.Element, perm: boolean }) => {

    const location = useLocation();

    if (perm) {
        return children;
    }
    return <Navigate to={getBackUrl(location.pathname)} replace />;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ PRIVATE ROUTES ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function PrivateRoutes() {
    return (
        <Routes>
            <Route element={<RequireAuth><AuthenticatedLayout /></RequireAuth>}>
                <Route index element={<Dashboard />} />
                <Route path="profile" element={<Profile />} />
                <Route path="articles/*" element={<ArticleRoutes />} />
                <Route path="repositories/*" element={<RepositoryRoutes />} />
                <Route path="certifications/*" element={<CertificationRoutes />} />
                <Route path="users/*" element={<UserRoutes />} />
                <Route path="companies/*" element={<CompanyRoutes />} />
                <Route path="pedagogical-tools/*" element={<PedagogicalToolRoutes />} />
                <Route path="models/*" element={<ModelRoutes />} />
                <Route path="management/*" element={<LearnerRestriction><ManagementRoutes /></LearnerRestriction>} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- DOCUMENT TITLE ROUTE --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function DocumentTitleRoute({ title, id } : { title: string, id: string }) {

    const { t } = useTranslation();
    const params = useParams();

    useDocumentTitle(`${t(title)} #${params[id]}`);

    return <Outlet />;
}
