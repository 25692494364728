import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

// MUI
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Grid } from '@mui/material';

// Apollo
import { useMutation } from '@apollo/client';
import { SET_COMPANY_STORAGE_LIMIT } from './gql-set-company-storage-limit';

// Services
import { getErrorExtensions } from 'services/utils';
import { forbiddenKeysNumberInput } from 'services/constants';

// Components
import Title from 'components/title';
import MobileTextField from 'components/mobile-text-field';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ SET COMPANY STORAGE LIMIT ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ISetCompanyStorageLimitProps {
    storageLimit: string;
    onClose: VoidFunction;
    onRefetch: VoidFunction;
}

export default function SetCompanyStorageLimit(props: ISetCompanyStorageLimitProps) {
    
    const { t } = useTranslation();
    const { id } = useParams();
    const [storageLimit, setStorageLimit] = useState(props.storageLimit || '');
    const [setCompanyStorageLimit, { loading, error, data }] = useMutation(SET_COMPANY_STORAGE_LIMIT);

    const onSetStorageLimit = () => {
        setCompanyStorageLimit({
            variables: {
                id: id,
                storageLimit: Number(storageLimit),
            },
        });
    };

    useEffect(() => {
        if (data?.setCompanyStorageLimit) {
            enqueueSnackbar(t('updated_successfully'), { variant: 'success' });
            props.onRefetch();
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line
    }, [data, error]);

    return (
        <Dialog
            open
            onClose={props.onClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogContent>
                <Title>
                    {t('storage_limit')} (Gigaoctet)
                </Title>
                <Grid
                    container
                    alignItems="center"
                >                    
                    <MobileTextField
                        placeholder={t('storage_limit')}
                        name="storageLimit"
                        value={storageLimit}
                        onKeyDown={e => forbiddenKeysNumberInput.some(key => key === e.key) ? e.preventDefault() : undefined}
                        onChange={e => setStorageLimit(e.target.value)}
                        type="number"
                        sx={{ maxWidth: 100, marginRight: 1 }}
                    />
                    <span>
                        Go
                    </span>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={props.onClose}
                    color="inherit"
                >
                    {t('close')}
                </Button>
                <Button
                    onClick={onSetStorageLimit}
                    disabled={loading || storageLimit === '' || isNaN(Number(storageLimit))}
                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                >
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
