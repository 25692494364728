import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

// MUI
import { Grid, Breadcrumbs, Typography } from '@mui/material';

// Services
import { IBreadcrumbItem } from 'services/interfaces';
import { primaryLight } from 'services/constants';
import { truncateLabel } from 'services/utils';

// CSS
import styles from './Breadcrumb.module.css';

interface IBreadcrumbProps extends PropsWithChildren {
    breadcrumbItems: IBreadcrumbItem[];
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- BREADCRUMB --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Breadcrumb(props: IBreadcrumbProps) {

    const { children, breadcrumbItems } = props;

    return (
        <div className={styles.breadcrumb}>
            <Grid
                container
                alignItems="center"
                spacing={1}
                minHeight={44.5}
            >
                <Grid item xs>
                    <Breadcrumbs>
                        {breadcrumbItems.map((item, index) => {
                            if (index + 1 === breadcrumbItems.length) {
                                return (
                                    <Typography
                                        key={index}
                                        style={{ color: primaryLight }}
                                    >
                                        {item?.label ? truncateLabel(item.label, 40) : '...'}
                                    </Typography>
                                );
                            }
                            return (
                                <Link
                                    key={index}
                                    to={item.route || ''}
                                    className={styles.link}
                                >
                                    {item?.label ? truncateLabel(item.label, 30) : '...'}
                                </Link>
                            );
                        })}
                    </Breadcrumbs>
                </Grid>
                {children}
            </Grid>
        </div>
    );
}
