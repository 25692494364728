import { useTranslation } from 'react-i18next';

// MUI
import { Dialog, DialogContent, DialogActions, Divider, Button, List, ListItemButton, Checkbox } from '@mui/material';

// Services
import { IActivity, IRepositoryItem, IObservableElement, IRepositoryItems } from 'services/interfaces';
import { useMediaQueries } from 'services/hooks';
import { primaryMain } from 'services/constants';

// Components
import Title from 'components/title';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- SUCCESS INDICATORS ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const ObservableElements = ({
    activity,
    onClose,
    repositoryItems,
    setRepositoryItems,
} : {
    activity: IActivity,
    onClose: VoidFunction,
    repositoryItems: IRepositoryItem[],
    setRepositoryItems: (repositoryItems: IRepositoryItems) => void,
}) => {

    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const observableElementChecked = (observableElementId: string) => {
        let observableElements = repositoryItems.find(i => i.id === activity.id)?.observableElements;
        if (observableElements?.includes(observableElementId)) {
            return true;
        }
        return false;
    };

    const onCheckObservableElement = (observableElement: IObservableElement) => {

        let updatedItems = [...repositoryItems];
        let index = updatedItems.findIndex(i => i.id === activity.id);
        let updatedObservableElements = updatedItems[index]?.observableElements || [];

        let indexOE = updatedObservableElements.findIndex(id => id === observableElement.id);
        if (indexOE === -1) {
            updatedObservableElements.push(observableElement.id);
        }
        else {
            updatedObservableElements.splice(indexOE, 1);
        }

        updatedItems[index].observableElements = updatedObservableElements;
        setRepositoryItems({ activities: updatedItems });
    };

    return (
        <Dialog
            open
            onClose={onClose}
            fullWidth
            fullScreen={!mediaMD}
        >
            <DialogContent>
                <div className="dialog-title">
                    {activity?.position}. {activity?.name}
                </div>
                <div>
                    {t('specify_observable_elements_to_work_on')} :
                </div>
                {activity.tasks.map(task => (
                    (
                        task.observableElements
                        && task.observableElements.length > 0
                    ) && (
                        <div
                            key={task.id}
                            className="framed-block"
                            style={{ padding: 0, marginTop: 24, borderColor: primaryMain }}
                        >
                            <div style={{ padding: '12px 12px 0px 12px' }}>
                                <Title>
                                    {activity.position}.{task.position} {task.name}
                                </Title>
                            </div>
                            <List>
                                {task.observableElements.map(observableElement => (
                                    <ListItemButton
                                        key={observableElement.id}
                                        onClick={() => onCheckObservableElement(observableElement)}
                                    >
                                        <Checkbox
                                            checked={observableElementChecked(observableElement.id)}
                                            style={{ padding: 0, marginRight: 6 }}
                                        />
                                        <span>
                                            {`${activity.position}.${task.position}.${observableElement.position} ${observableElement.name}`}
                                        </span>
                                    </ListItemButton>
                                ))}
                            </List>
                        </div>
                    )
                ))}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ObservableElements;
