import { useContext } from 'react';

// MUI
import { Checkbox } from '@mui/material';

// Services
import { ILinkItem } from 'services/interfaces';
import { ERepositoryItemType, EResultType } from 'services/enums';

// Components
import ResultTypeIcon from 'components/result-type';

// Repository Items
import { DescriptorBlockContext, IDescriptorBlockContext } from '../index';
import { getRepositoryItemLabel } from '../../../utils';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- LIST ITEM DESCRIPTOR --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ListItemLinkItem({ linkItem, resultType } : { linkItem: ILinkItem, resultType?: EResultType }) {

    const {
        listItemType,
        listLinkItemChecked,
    } = useContext(DescriptorBlockContext) as IDescriptorBlockContext;

    const AtypicalItemName = () => (
        <span style={{ display: 'block', marginLeft: 6 }}>
            {getRepositoryItemLabel(ERepositoryItemType.ATYPICAL_ITEM, linkItem.atypicalItem)}
        </span>
    );

    if (listItemType === 'checkbox') {
        return (
            <>
                <Checkbox
                    checked={listLinkItemChecked ? listLinkItemChecked(linkItem) : undefined}
                    style={{ padding: 0 }}
                />
                <AtypicalItemName />
                {resultType && (
                    <ResultTypeIcon
                        resultType={resultType}
                        enabledNone
                    />
                )}
            </>
        );
    }

    if (resultType) {
        return (
            <>
                <ResultTypeIcon
                    resultType={resultType}
                    enabledNone
                />
                <AtypicalItemName />
            </>
        );
    }

    return (
        <>
            <span>-</span>
            <AtypicalItemName />
        </>
    );
}
