import React from 'react';
import { useTranslation } from 'react-i18next';

// Dayjs
import dayjs from 'dayjs';

// MUI
import { Grid, Checkbox, FormControlLabel } from '@mui/material';

// Services
import { ISelectOption, ISelectOptionWithStepModel } from 'services/interfaces';
import { EPlaceType, ESchedule, EStepType, EWorkSituationType } from 'services/enums';
import { compareTimes, transformMinuteToTime } from 'services/utils';
import { useMediaQueries } from 'services/hooks';
import { successMain } from 'services/constants';

// Components
import DatePicker from 'components/date-picker';
import Select from 'components/react-select';
import Title from 'components/title';
import TimePicker from 'components/time-picker';
import PelletCard from 'components/pellet-card';
import MobileTextField from 'components/mobile-text-field';

// Selects
import SelectCompany from 'selects/select-company';
import SelectCustomRepository from 'selects/select-custom-repository';
import SelectStepModel from 'selects/select-step-model';
import SelectOrCreateUser from 'selects/select-or-create-user';
import SelectPreparatorySequenceModality from 'selects/select-preparatory-sequence-modality';

// Repository Items
import { getAtypicalRepositoryIdFromStepObject, getRepositoryTypeFromStepObject } from 'repository-items/utils';

// Views
import { IState } from '../../create-work-situation-from-roadmap';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- 01 WORK SITUATION FIELDS ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const WorkSituationFields = ({
    state,
    setState,
} : {
    state: IState,
    setState: React.Dispatch<React.SetStateAction<IState>>,
}) => {

    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const scheduleOptions = Object.values(ESchedule).map(v => ({ value: v, label: t(v.toLowerCase() + (v === ESchedule.DAY ? '.2' : '')) }));

    const onChangeCompany = (company: ISelectOption | null) => {
        if (state.source === 'ROADMAP') {
            setState(prevState => ({
                ...prevState,
                company,
            }));
        }
        else {
            setState(prevState => ({
                ...prevState,
                company,
                customRepository: null,
                stepModel: null,
                descriptors: [],
                tasks: [],
                atypicalItems: [],
            }));
        }
    };

    const onChangeCustomRepository = (customRepository: ISelectOption | null) => {
        setState(prevState => ({
            ...prevState,
            customRepository,
            stepModel: null,
            descriptors: [],
            tasks: [],
            atypicalItems: [],
        }));
    };

    const onSelectStepModel = (stepModel: ISelectOptionWithStepModel | null) => {
        if (stepModel && stepModel.value !== state.stepModel?.value) {
            setState(prevState => ({
                ...prevState,
                stepModel,
                duration: stepModel.duration,
                comment: (prevState.comment === '' && stepModel.context) ? stepModel.context : prevState.comment,
                descriptors: stepModel.descriptors ? stepModel.descriptors.map(s => ({ id: s.id })) : [],
                tasks: stepModel.tasks ? stepModel.tasks.map(a => ({ id: a.id })) : [],
                atypicalItems: stepModel.atypicalItems ? stepModel.atypicalItems.map(i => ({ id: i.id })) : [],
                linkItems: stepModel.linkItems ? stepModel.linkItems.map(li => ({ id: li.id })) : [],
                defaultRepositoryType: getRepositoryTypeFromStepObject(stepModel),
                defaultAtypicalRepositoryId: getAtypicalRepositoryIdFromStepObject(stepModel),
            }));
        }
        if (stepModel == null) {
            setState(prevState => ({
                ...prevState,
                stepModel,
                descriptors: [],
                tasks: [],
                atypicalItems: [],
                linkItems: [],
                defaultRepositoryType: undefined,
                defaultAtypicalRepositoryId: undefined,
            }));
        }
    };

    const getDuration = () => {

        let morningDuration = (state.startMorning && state.endMorning && state.schedule.value !== ESchedule.AFTERNOON)
            ? (state.endMorning.hour() * 60 + state.endMorning.minute()) - (state.startMorning.hour() * 60 + state.startMorning.minute())
            : 0;

        let afternoonDuration = (state.startAfternoon && state.endAfternoon && state.schedule.value !== ESchedule.MORNING)
            ? (state.endAfternoon.hour() * 60 + state.endAfternoon.minute()) - (state.startAfternoon.hour() * 60 + state.startAfternoon.minute())
            : 0;

        let totalDuration = morningDuration + afternoonDuration;
        return dayjs(new Date(0 ,0, 0, Math.trunc(totalDuration / 60), totalDuration % 60)).format('H[h]mm');
    };

    return (
        <PelletCard
            paddingTop={0}
            pelletColor={successMain}
            pelletContent={1}
            summary={(
                <Grid item xs>
                    {state.workSituationType === EWorkSituationType.PREPARATORY_SEQUENCE
                        ? t('preparatory_sequence_details')
                        : t('work_situation_details')
                    }
                </Grid> 
            )}
        >
            <Grid
                container
                spacing={3}
            >
                <Grid item xs={12} md={6} xl={4}>
                    {/* -------------------- COMPANY -------------------- */}
                    {state.source !== 'COMPANY' && (
                        <Grid>
                            <Title required>
                                {t('company')}
                            </Title>
                            <SelectCompany
                                value={state.company}
                                onChange={value => onChangeCompany(value)}
                            />
                        </Grid>
                    )}
                    {/* -------------------- CUSTOM REPOSITORY -------------------- */}
                    {state.source !== 'ROADMAP' && (
                        <Grid marginTop={state.source === 'COMPANY' ? undefined : 3}>
                            <Title required>
                                {t('custom_repository')}
                            </Title>
                            <SelectCustomRepository
                                value={state.customRepository}
                                onChange={value => onChangeCustomRepository(value)}
                                companyId={state.company?.value}
                            />
                        </Grid>
                    )}
                    {/* -------------------- STEP MODEL -------------------- */}
                    <Grid marginTop={3}>
                        <Title>
                            {t('model')}
                        </Title>
                        <SelectStepModel
                            value={state.stepModel}
                            onChange={(stepModel: ISelectOptionWithStepModel | null) => onSelectStepModel(stepModel)}
                            customRepositoryId={state.customRepository?.value}
                            stepType={state.workSituationType as EStepType}
                        />
                    </Grid>
                    {/* -------------------- TRAINING DATE -------------------- */}
                    <Grid marginTop={3}>
                        <Title required>
                            {t('training_date')}
                        </Title>
                        <DatePicker
                            value={state.trainingDate}
                            onChange={trainingDate => setState(prevState => ({ ...prevState, trainingDate }))}
                            minDate={state.roadmap ? dayjs(state.roadmap.startDate) : undefined}
                        />
                    </Grid>
                    {/* -------------------- SCHEDULE -------------------- */}
                    <Grid marginTop={3}>
                        <Title required>
                            {t('schedule.1')}
                        </Title>
                        <Select
                            placeholder={t('schedule.1')}
                            value={state.schedule}
                            onChange={(schedule: ISelectOption) => setState(prevState => ({ ...prevState, schedule }))}
                            options={scheduleOptions}
                            isClearable={false}
                        />
                    </Grid>
                    {/* -------------------- START & END MORNING -------------------- */}
                    {(state.schedule?.value !== ESchedule.AFTERNOON) && (
                        <Grid
                            container 
                            alignItems="center"
                            marginTop={2}
                        >
                            <Grid
                                item xs={6}
                                style={{ paddingRight: 3 }}
                            >
                                <TimePicker
                                    label={t('start_morning')}
                                    value={state.startMorning}
                                    onChange={startMorning => setState(prevState => ({ ...prevState, startMorning }))}
                                />
                            </Grid>
                            <Grid
                                item xs={6}
                                style={{ paddingLeft: 3 }}
                            >
                                <TimePicker
                                    label={t('end_morning')}
                                    value={state.endMorning}
                                    onChange={endMorning => setState(prevState => ({ ...prevState, endMorning }))}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: compareTimes(state.startMorning, state.endMorning, '>'),
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {/* -------------------- START & END AFTERNOON -------------------- */}
                    {(state.schedule?.value !== ESchedule.MORNING) && (
                        <Grid
                            container
                            alignItems="center"
                            marginTop={2}
                        >
                            <Grid
                                item xs={6}
                                style={{ paddingRight: 3 }}
                            >
                                <TimePicker
                                    label={t('start_afternoon')}
                                    value={state.startAfternoon}
                                    onChange={startAfternoon => setState(prevState => ({ ...prevState, startAfternoon }))}
                                />
                            </Grid>
                            <Grid
                                item xs={6}
                                style={{ paddingLeft: 3 }}
                            >
                                <TimePicker
                                    label={t('end_afternoon')}
                                    value={state.endAfternoon}
                                    onChange={endAfternoon => setState(prevState => ({ ...prevState, endAfternoon }))}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: compareTimes(state.startAfternoon, state.endAfternoon, '>'),
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {/* -------------------- DURATION -------------------- */}
                    <Grid marginTop={1}>
                        <span className="subtitle">
                            {t('duration')} : {getDuration()}
                        </span>
                        {state.duration && (
                            <span
                                className="subtitle"
                                style={{ marginLeft: 8 }}
                            >
                                ({t('planned')} : {transformMinuteToTime(state.duration)})
                            </span>
                        )}
                    </Grid>
                    {/* -------------------- PREPARATORY SEQUENCE MODALITIES -------------------- */}
                    {state.workSituationType === EWorkSituationType.PREPARATORY_SEQUENCE && (
                        <Grid marginTop={3}>
                            <Title required>
                                {t('modalities')}
                            </Title>
                            <SelectPreparatorySequenceModality
                                value={state.preparatorySequenceModalities}
                                onChange={(preparatorySequenceModalities: ISelectOption[]) => setState(prevState => ({ ...prevState, preparatorySequenceModalities }))}
                                isMulti
                            />
                        </Grid>
                    )}
                    {/* -------------------- TRAINERS -------------------- */}
                    <SelectOrCreateUser
                        value={state.trainers}
                        onChange={trainers => setState(prevState => ({ ...prevState, trainers }))}
                        label={t('trainers')}
                        dialogTitle={t('add_trainer')}
                        roleIds={['1', '2', '3', '4']}
                        color="success"
                        isMulti
                        containerProps={{ marginTop: 24 }}
                    />
                    {/* -------------------- LEARNERS -------------------- */}
                    {(state.source === 'USER' || state.source === 'COMPANY') && (
                        <SelectOrCreateUser
                            value={state.learners}
                            onChange={learners => setState(prevState => ({ ...prevState, learners }))}
                            label={t('learners')}
                            dialogTitle={t('add_learner')}
                            roleIds={['5']}
                            color="success"
                            isMulti
                            containerProps={{ marginTop: 24 }}
                        />
                    )}
                    {/* -------------------- PLACE TYPE -------------------- */}
                    <Grid marginTop={3}>
                        <Title required>
                            {t('place_type')}
                        </Title>
                        <Select
                            value={state.placeType}
                            onChange={(placeType: ISelectOption) => setState(prevState => ({ ...prevState, placeType }))}
                            options={Object.values(EPlaceType).map(v => ({ value: v, label: t(v.toLowerCase()) }))}
                            isClearable={false}
                        />
                    </Grid>
                    {/* -------------------- PLACE DETAILS -------------------- */}
                    <Grid marginTop={3}>
                        <Title>
                            {t('place_details')}
                        </Title>
                        <MobileTextField
                            name="placeDetails"
                            value={state.placeDetails}
                            onChange={e => setState(prevState => ({ ...prevState, placeDetails: e.target.value }))}
                            placeholder={t('place_details')}
                            multiline
                            rows={3}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    {/* -------------------- SIGNATURES -------------------- */}
                    <Grid>
                        <Title>
                            {t('signatures')}
                        </Title>
                        <FormControlLabel
                            label={state.workSituationType === EWorkSituationType.PREPARATORY_SEQUENCE
                                ? t('disable_signatures_for_this_preparatory_sequence')
                                : t('disable_signatures_for_this_work_situation')
                            }
                            control={(
                                <Checkbox
                                    checked={state.signatureDisabled}
                                    onChange={(e, checked) => setState(prevState => ({ ...prevState, signatureDisabled: checked }))}
                                    color="success"
                                />
                            )}
                        />
                    </Grid>
                    {/* -------------------- COMMENT -------------------- */}
                    <Grid marginTop={3}>
                        <Title required={state.atypicalItems.length > 0}>
                            {t('context')}
                        </Title>
                        <MobileTextField
                            name="comment"
                            value={state.comment}
                            onChange={e => setState(prevState => ({ ...prevState, comment: e.target.value }))}
                            placeholder={t('context')}
                            multiline
                            rows={mediaMD ? 12 : 3}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </PelletCard>
    );
};

export default WorkSituationFields;
