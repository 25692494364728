import { useContext } from 'react';

// MUI
import { Checkbox, Grid } from '@mui/material';

// Services
import { IDescriptor } from 'services/interfaces';
import { EResultType } from 'services/enums';

// Components
import ResultTypeIcon from 'components/result-type';

// Repository Items
import { DescriptorBlockContext, IDescriptorBlockContext } from '../index';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- LIST ITEM DESCRIPTOR --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ListItemDescriptor({ descriptor, resultType } : { descriptor: IDescriptor, resultType?: EResultType }) {

    const DescriptorName = () => (
        <Grid
            flex={1}
            marginLeft={1}
        >
            {descriptor.name}
        </Grid>
    );

    const {
        listItemType,
        descriptorChecked,
    } = useContext(DescriptorBlockContext) as IDescriptorBlockContext;

    if (listItemType === 'checkbox') {
        return (
            <>
                <Checkbox
                    checked={descriptorChecked ? descriptorChecked(descriptor) : undefined}
                    style={{ padding: 0 }}
                />
                <DescriptorName />
                {resultType && (
                    <Grid marginLeft={1}>
                        <ResultTypeIcon resultType={resultType} />
                    </Grid>
                )}
            </>
        );
    }

    if (resultType) {
        return (
            <>
                <ResultTypeIcon
                    resultType={resultType}
                    enabledNone
                />
                <DescriptorName />
            </>
        );
    }

    return (
        <>
            <span>-</span>
            <DescriptorName />
        </>
    );
}
