import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

// Dayjs
import dayjs from 'dayjs';

// Notistack
import { useSnackbar } from 'notistack';

// Apollo
import { useQuery, useMutation } from '@apollo/client';
import { WORK_SITUATION, UPDATE_WORK_SITUATION } from './gql-update-work-situation';

// Services
import { IWorkSituation, IBreadcrumbItem } from 'services/interfaces';
import { EPlaceType, ESchedule, EWorkSituationType } from 'services/enums';
import {
    getErrorExtensions,
    transformTimeToDate,
    connectOrDisconnectStepModel,
    getBreadcrumbItems
} from 'services/utils';

// Repository Items
import { getAtypicalRepositoryIdFromStepObject, getRepositoryTypeFromStepObject } from 'repository-items/utils';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Selects
import { getSelectedOptionCompany, getSelectedOptionStepModel } from 'selects';

// Views
import WorkSituationForm from '../work-situation-form';
import {
    getLearnerWorkSituationsToCreate, getTrainerWorkSituationToDelete,
    getTrainerWorkSituationToCreate, getLearnerWorkSituationsToDelete,
    getLearnerWorkSituationsToUpdate,
} from '../utils';
import { IState } from '../create-work-situation-from-roadmap';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- FETCH WORK SITUATION ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const FetchWorkSituation = ({
    source,
    defaultBreadcrumbItems,
    setBreadcrumbItems,
} : {
    source: 'USER' | 'ROADMAP' | 'COMPANY',
    defaultBreadcrumbItems: IBreadcrumbItem[],
    setBreadcrumbItems: React.Dispatch<React.SetStateAction<IBreadcrumbItem[]>>,
}) => {

    const { t } = useTranslation();
    const { id, roadmapId, workSituationId } = useParams();

    const { loading, error, data } = useQuery<{ workSituation: IWorkSituation }>(WORK_SITUATION, {
        variables: { id: workSituationId },
    });

    useEffect(() => {
        if (data?.workSituation) {
            const { items } = getBreadcrumbItems({mainId: id, workSituation: data.workSituation, source, items: defaultBreadcrumbItems, roadmapId, action: t('edit')});

            setBreadcrumbItems(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <UpdateWorkSituation
            source={source}
            workSituation={data?.workSituation}
        />
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- UPDATE WORK SITUATION --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const UpdateWorkSituation = ({
    source,
    workSituation,
} : {
    source: 'USER' | 'ROADMAP' | 'COMPANY',
    workSituation: IWorkSituation | undefined,
 }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { id, roadmapId, workSituationId } = useParams();
    const [updateWorkSituation, { loading, error, data }] = useMutation(UPDATE_WORK_SITUATION);

    function getSchedule() {
        return workSituation?.startMorning && workSituation?.startAfternoon
            ? ({ value: ESchedule.DAY, label: t(ESchedule.DAY.toLowerCase() + '.2') })
            : (workSituation?.startAfternoon
                ? ({ value: ESchedule.AFTERNOON, label: t(ESchedule.AFTERNOON.toLowerCase()) })
                : ({ value: ESchedule.MORNING, label: t(ESchedule.MORNING.toLowerCase()) })
            )
    }

    const [state, setState] = useState<IState>({
        source: source,
        actionType: 'UPDATE',
        roadmap: source === 'ROADMAP' ? workSituation?.learnerWorkSituations.find(s => s.learner?.id === id)?.roadmap : undefined,
        workSituationType: workSituation?.workSituationType || EWorkSituationType.WORK_SITUATION,
        trainingDate: workSituation?.trainingDate ? dayjs(workSituation.trainingDate) : null,
        duration: workSituation?.stepModel?.duration || null,
        schedule: getSchedule(),
        startMorning: transformTimeToDate(workSituation?.startMorning),
        endMorning: transformTimeToDate(workSituation?.endMorning),
        startAfternoon: transformTimeToDate(workSituation?.startAfternoon),
        endAfternoon: transformTimeToDate(workSituation?.endAfternoon),
        comment: workSituation?.comment || '',
        placeType: workSituation?.placeType ? ({ value: workSituation.placeType, label: t(workSituation.placeType.toLowerCase()) }) : ({ value: EPlaceType.INTERNAL, label: t(EPlaceType.INTERNAL.toLowerCase()) }),
        placeDetails: workSituation?.placeDetails || '',
        customRepository: workSituation?.customRepository ? ({ value: workSituation.customRepository?.id, label: workSituation.customRepository?.name }) : null,
        stepModel: workSituation?.stepModel ? getSelectedOptionStepModel(workSituation.stepModel) : null,
        descriptors: workSituation?.descriptors ? workSituation.descriptors.map(d => ({ id: d.id })) : [],
        tasks: workSituation?.tasks ? workSituation.tasks.map(t => ({ id: t.id })) : [],
        atypicalItems: workSituation?.atypicalItems ? workSituation.atypicalItems.map(i => ({ id: i.id })) : [],
        company: workSituation?.company ? getSelectedOptionCompany(workSituation.company) : null,
        learners: workSituation?.learnerWorkSituations ? workSituation.learnerWorkSituations.map(s => ({ value: s.learner?.id, label: s.learner?.fullName, result: s.result, workSituationId: s.id })) : [],
        trainers: workSituation?.trainerWorkSituations ? workSituation.trainerWorkSituations.map(s => ({ value: s.trainer?.id, label: s.trainer?.fullName })) : [],
        preparatorySequenceModalities: (workSituation?.workSituationType === EWorkSituationType.PREPARATORY_SEQUENCE && workSituation?.preparatorySequenceModalities) ? workSituation.preparatorySequenceModalities.map(m => ({ value: m, label: t(m.toLowerCase()) })) : [],
        signatureDisabled: workSituation?.signatureDisabled || false,
        defaultRepositoryType: workSituation ? getRepositoryTypeFromStepObject(workSituation) : undefined,
        defaultAtypicalRepositoryId: workSituation ? getAtypicalRepositoryIdFromStepObject(workSituation) : undefined,
        linkItems: workSituation?.linkItems ? workSituation.linkItems.map(i => ({ id: i.id })) : [],
    });
    
    const onUpdateWorkSituation = () => {
        updateWorkSituation({
            variables: {
                id: workSituation?.id,
                workSituationType: state.workSituationType,
                trainingDate: dayjs(state.trainingDate).format('YYYY-MM-DD'),
                comment: state.comment,
                placeType: state.placeType?.value,
                placeDetails: state.placeDetails,
                customRepository: { connect: state.customRepository?.value },
                stepModel: connectOrDisconnectStepModel(state.stepModel, workSituation?.stepModel),
                company: { connect: state.company?.value },
                descriptors: { sync: state.descriptors.map(d => d.id) },
                tasks: { sync: state.tasks.map(t => t.id) },
                atypicalItems: { sync: state.atypicalItems.map(i => i.id) },
                startMorning: state.schedule?.value !== ESchedule.AFTERNOON ? dayjs(state.startMorning).format('HH:mm:00') : null,
                endMorning: state.schedule?.value !== ESchedule.AFTERNOON ? dayjs(state.endMorning).format('HH:mm:00') : null,
                startAfternoon: state.schedule?.value !== ESchedule.MORNING ? dayjs(state.startAfternoon).format('HH:mm:00') : null,
                endAfternoon: state.schedule?.value !== ESchedule.MORNING ? dayjs(state.endAfternoon).format('HH:mm:00') : null,
                learnerWorkSituations: {
                    create: getLearnerWorkSituationsToCreate(state.learners, workSituation?.learnerWorkSituations),
                    update: getLearnerWorkSituationsToUpdate(state.learners, workSituation?.learnerWorkSituations),
                    delete: getLearnerWorkSituationsToDelete(state.learners, workSituation?.learnerWorkSituations),
                },
                trainerWorkSituations: {
                    create: getTrainerWorkSituationToCreate(state.trainers, workSituation?.trainerWorkSituations),
                    delete: getTrainerWorkSituationToDelete(state.trainers, workSituation?.trainerWorkSituations),
                },
                preparatorySequenceModalities: state.preparatorySequenceModalities ? state.preparatorySequenceModalities.map(m => m.value) : null,
                signatureDisabled: state.signatureDisabled,
                linkItems: { sync: state.linkItems.map(i => i.id) },
            },
        });
    };

    useEffect(() => {
        if (data?.updateWorkSituation) {

            enqueueSnackbar(t('updated_successfully'), { variant: 'success' });

            switch (source) {
                
                case 'ROADMAP':
                    navigate('/app/users/' + id + '/roadmaps/' + roadmapId);
                    break;

                case 'COMPANY':
                case 'USER':
                    navigate('/app/' + (source === 'USER' ? 'users' : 'companies') + '/' + id + '/work-situations/' + workSituationId);
                    break;
            }
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <WorkSituationForm
            state={state}
            setState={setState}
            onSubmit={onUpdateWorkSituation}
            loading={loading}
        />
    );
};

export default FetchWorkSituation;
