import { ToggleButtonGroup, ToggleButton } from '@mui/material';

// Services
import { EWeather } from 'services/enums';

// Assets
import { ReactComponent as StormyIcon } from 'assets/images/weather/stormy.svg';
import { ReactComponent as RainyIcon } from 'assets/images/weather/rainy.svg';
import { ReactComponent as CloudyIcon } from 'assets/images/weather/cloudy.svg';
import { ReactComponent as SunnyIcon } from 'assets/images/weather/sunny.svg';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- TOGGLE WEATHER -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const ToggleWeather = ({
    value,
    onChange,
} : {
    value: EWeather | null,
    onChange: (value: EWeather | null) => void,
}) => {
    return (
        <ToggleButtonGroup
            value={value}
            onChange={(e, value) => onChange(value)}
            exclusive
        >
            <ToggleButton
                value={EWeather.SUNNY}
                style={{ padding: 4 }}
            >
                <SunnyIcon width={64} height={64} />
            </ToggleButton>
            <ToggleButton
                value={EWeather.CLOUDY}
                style={{ padding: 4 }}
            >
                <CloudyIcon width={64} height={64} />
            </ToggleButton>
            <ToggleButton
                value={EWeather.RAINY}
                style={{ padding: 4 }}
            >
                <RainyIcon width={64} height={64} />
            </ToggleButton>
            <ToggleButton
                value={EWeather.STORMY}
                style={{ padding: 4 }}
            >
                <StormyIcon width={64} height={64} />
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default ToggleWeather;
