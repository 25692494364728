import dayjs from 'dayjs';
import i18next from 'i18next';

// Services
import { ILearnerWorkSituation, ISelectOptionWithUserAttributes, ITrainerWorkSituation } from 'services/interfaces';
import { ESchedule } from 'services/enums';
import { connectOrCreateUser } from 'services/utils';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ LEARNER WORK SITUATIONS --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

/* * *
*  CREATE
*/
export const getLearnerWorkSituationsToCreate = (
    learners: ISelectOptionWithUserAttributes[],
    learnerWorkSituations?: ILearnerWorkSituation[],
) => {

    let learnersToCreate = [...learners];

    if (learnerWorkSituations) {
        learnersToCreate = learnersToCreate.filter(learner => learnerWorkSituations?.every(s => s?.learner?.id !== learner?.value))
    }

    return learnersToCreate.map(l => ({
        result: l.result,
        roadmap: l.roadmapId ? { connect: l.roadmapId } : null,
        learner: connectOrCreateUser(l),
    }));
};

/* * *
*  UPDATE
*/
export const getLearnerWorkSituationsToUpdate = (
    learners: ISelectOptionWithUserAttributes[],
    learnerWorkSituations: ILearnerWorkSituation[] | undefined,
) => {
    return learners
        .filter(l => learnerWorkSituations?.some(s => s?.learner?.id === l?.value))
        .map(l => ({ id: l.workSituationId, result: l.result }));
};

/* * *
*  DELETE
*/
export const getLearnerWorkSituationsToDelete = (
    learners: ISelectOptionWithUserAttributes[],
    learnerWorkSituations: ILearnerWorkSituation[] | undefined,
) => {
    return learnerWorkSituations
        ?.filter(s => learners.every(t => t?.value !== s?.learner?.id))
        ?.map(s => s?.id);
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ TRAINER WORK SITUATIONS --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

/* * *
*  CREATE
*/
export const getTrainerWorkSituationToCreate = (
    trainers: ISelectOptionWithUserAttributes[],
    trainerWorkSituations: ITrainerWorkSituation[] | undefined,
) => {
    return trainers
        .filter(t => trainerWorkSituations?.every(s => s?.trainer?.id !== t?.value))
        .map(t => ({ trainer: connectOrCreateUser(t) }));
};

/* * *
*  DELETE
*/
export const getTrainerWorkSituationToDelete = (
    trainers: ISelectOptionWithUserAttributes[],
    trainerWorkSituations: ITrainerWorkSituation[] | undefined,
) => {
    return trainerWorkSituations
        ?.filter(s => trainers.every(t => t?.value !== s?.trainer?.id))
        ?.map(s => s?.id);
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- WORK SITUATION TIMES ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function getDefaultWorkSituationTimes(duration: number | undefined) {

    let schedule = ({ value: ESchedule.MORNING, label: i18next.t(ESchedule.MORNING.toLowerCase()) });
    let startMorning = dayjs(new Date(0, 0, 0, 8));
    let endMorning = dayjs(new Date(0, 0, 0, 12));
    let startAfternoon = dayjs(new Date(0, 0, 0, 13));
    let endAfternoon = dayjs(new Date(0, 0, 0, 17));

    if (duration && duration <= 240) {
        endMorning = dayjs(new Date(0, 0, 0, (8 + Math.floor(duration / 60)), (duration % 60)));
    }

    if (duration && duration > 240) {
        schedule = ({ value: ESchedule.DAY, label: i18next.t(ESchedule.DAY.toLowerCase() + '.2') });
        endAfternoon = dayjs(new Date(0, 0, 0, (13 + Math.floor((duration - 240) / 60)), (duration % 60)));
    }

    return ({
        schedule,
        startMorning,
        endMorning,
        startAfternoon,
        endAfternoon,
    });
}
