import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, IconButton, Tooltip } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

// Services
import { IAtypicalItem, IAtypicalRepository, IStepObject } from 'services/interfaces';
import { ERepositoryItemType } from 'services/enums';

// Repository Items
import {
    getRepositoryItemLabel,
    getRepositoryItemTypeLabelFromStepObject,
    getSortedRepositoryItemsFromStepObject
} from '../../utils';

// Components
import Title from 'components/title';

// Icons
import MasteryIcon from 'icons/mastery-icon';
import SelfMasteryIcon from 'icons/self-mastery-icon';
import SubItemsDialog from './sub-items-dialog';

interface IState {
    openSubItems: boolean;
    selectedAtypicalItem: IAtypicalItem | undefined;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- REPOSITORY ITEMS TABLE --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function RepositoryItemsTable({
    stepObject,
    withMastery = false,
    withSelfMastery = false,
    disableTitle = false,
    containerProps,
    atypicalItemsRelatedToActivity = [],
} : {
    stepObject: IStepObject,
    withMastery?: boolean,
    withSelfMastery?: boolean,
    disableTitle?: boolean,
    containerProps?: React.CSSProperties,
    atypicalItemsRelatedToActivity?: IAtypicalRepository[],
}) {

    const { repositoryItemType, sortedItems } = getSortedRepositoryItemsFromStepObject(stepObject);

    const { t } = useTranslation();

    const atypicalRepositories = useMemo(() => {

        let linkItems = stepObject.linkItems;
        let atypicalRepositories: IAtypicalRepository[] = [];

        linkItems?.forEach(linkItem => {
            if (! atypicalRepositories.some(ar => ar.id === linkItem.atypicalItem.atypicalRepository.id)) {
                atypicalRepositories.push(linkItem.atypicalItem.atypicalRepository);
            }
        });

        return atypicalRepositories;
    }, [stepObject.linkItems]);

    const [state, setState] = useState<IState>({
        openSubItems: false,
        selectedAtypicalItem: undefined,
    });

    const onOpenSubItems = (atypicalItem: IAtypicalItem | undefined) => {
        setState(prevState => ({
            ...prevState,
            openSubItems: true,
            selectedAtypicalItem: atypicalItem,
        }));
    };

    return (
        <Grid
            container
            style={containerProps}
        >
            {sortedItems.length > 0 && (
                <Grid
                    container
                    flexDirection="column"
                >
                    {/* -------------------- TITLE -------------------- */}
                    {!disableTitle && (
                        <Title>
                            {getRepositoryItemTypeLabelFromStepObject(stepObject)}
                        </Title>
                    )}
                    <table style={{ width: 'fit-content' }}>
                        <tbody>
                            {sortedItems.map(item => {

                                let origin = repositoryItemType === ERepositoryItemType.DESCRIPTOR
                                    ? item.skill?.name
                                    : (
                                        repositoryItemType === ERepositoryItemType.TASK
                                            ? item.activity?.name
                                            : null
                                    );

                                return (
                                    <tr key={item.id}>
                                        {/* -------------------- LABEL -------------------- */}
                                        <td
                                            className="table-td"
                                            style={{ width: '100%' }}
                                        >
                                            {getRepositoryItemLabel(repositoryItemType, item, false, origin)}
                                        </td>
                                        {/* -------------------- MASTERY -------------------- */}
                                        {withMastery && (
                                            <td
                                                className="table-td"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <MasteryIcon
                                                    mastery={item.pivot?.mastery}
                                                />
                                            </td>
                                        )}
                                        {/* -------------------- SELF MASTERY -------------------- */}
                                        {withSelfMastery && (
                                            <td
                                                className="table-td"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <SelfMasteryIcon
                                                    selfMastery={item.pivot?.selfMastery}
                                                />
                                            </td>
                                        )}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Grid>
            )}
            {/* -------------------- LINK ITEMS -------------------- */}
            {atypicalRepositories.length > 0 && (
                atypicalRepositories.map((atypicalRepository, index) => (
                    <Grid
                        key={'ar' + atypicalRepository.id}
                        container
                        flexDirection="column"
                        marginTop={(sortedItems.length > 0 || index > 0) ? 3 : undefined}
                    >
                        {/* -------------------- TITLE -------------------- */}
                        {!disableTitle && (
                            <Title>
                                {atypicalRepository.name}
                            </Title>
                        )}
                        <table style={{ width: 'fit-content' }}>
                            <tbody>
                                {stepObject.linkItems
                                    ?.filter(linkItem => linkItem.atypicalItem.atypicalRepository.id === atypicalRepository.id)
                                    .map(item => (
                                        <tr key={'li' + item.id}>
                                            {/* -------------------- LABEL -------------------- */}
                                            <td
                                                className="table-td"
                                                style={{ width: '100%' }}
                                            >
                                                {getRepositoryItemLabel(ERepositoryItemType.ATYPICAL_ITEM, item.atypicalItem, false, item.skillName)}
                                            </td>
                                            {/* -------------------- MASTERY -------------------- */}
                                            {withMastery && (
                                                <td
                                                    className="table-td"
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    <MasteryIcon
                                                        mastery={item.pivot?.mastery}
                                                    />
                                                </td>
                                            )}
                                            {/* -------------------- SELF MASTERY -------------------- */}
                                            {withSelfMastery && (
                                                <td
                                                    className="table-td"
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    <SelfMasteryIcon
                                                        selfMastery={item.pivot?.selfMastery}
                                                    />
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </Grid>
                ))
            )}
            {/* -------------------- ATYPICAL ITEMS RELATED TO ACTIVITY -------------------- */}
            {(
                atypicalItemsRelatedToActivity
                && atypicalItemsRelatedToActivity.length > 0
            ) && (
                atypicalItemsRelatedToActivity.map((atypicalRepository, index) => (
                    <Grid
                        key={'arRelated' + atypicalRepository.id}
                        container
                        flexDirection="column"
                        marginTop={(sortedItems.length > 0 || index > 0) ? 3 : undefined}
                    >
                        {!disableTitle && (
                            <Title>
                                {atypicalRepository.name}
                            </Title>
                        )}
                        <table style={{ width: 'fit-content' }}>
                            <tbody>
                                {atypicalRepository.atypicalItems.map(item => (
                                    <tr key={'ai' + item.id}>
                                        <td
                                            className="table-td"
                                            style={{width: '100%'}}
                                        >
                                            {getRepositoryItemLabel(ERepositoryItemType.ATYPICAL_ITEM, item, false )}
                                            <Tooltip title={t('item')}>
                                                <IconButton
                                                    onClick={() => onOpenSubItems(item)}
                                                    style={{ padding: 6 }}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {(state.openSubItems && state.selectedAtypicalItem) && (
                            <SubItemsDialog
                                atypicalItem={state.selectedAtypicalItem}
                                onClose={() => setState(prevState => ({ ...prevState, openSubItems: false }))}
                            />
                        )}
                    </Grid>
                ))
            )}
        </Grid>
    );
}
