import { gql } from '@apollo/client';

export const SIMPLIFIED_ROADMAP = gql`
    query Roadmap($id: ID!) {
        roadmap(id: $id) {
            id
            name
            startDate
            endDate
            learner {
                id
                fullName
            }
            company {
                id
                name
                postalCode
                city
            }
            customRepository {
                id
                name
            }
        }
    }
`

export const ROADMAP = gql`
    query Roadmap($id: ID!) {
        roadmap(id: $id) {
            id
            name
            startDate
            endDate
            duration
            durationUnit
            externalCompany
            status
            modalities
            individualTrainingProtocolId
            hasAccessThroughInternship
            company {
                id
                name
                postalCode
                city
                footer
            }
            customRepository {
                id
                name
            }
            roadmapModel {
                id
                name
            }
            learner {
                id
                fullName
                email
                phoneNumber
                phoneNumberPrefix
            }
            trainer {
                id
                fullName
                email
                phoneNumber
                phoneNumberPrefix
            }
            plannedSteps {
                id
                fromDate
                toDate
                duration
                stepType
                supportType
                modality
                context
                scheduledDate
                scheduledTime
                isAlreadyMastered
                goalProgress {
                    covered
                    validated
                }
                reflexivityAxis {
                    id
                    name
                }
                reflexivityQuestions {
                    id
                    position
                    description
                }
                stepModel {
                    id
                    name
                }
                customSkills {
                    id
                    customBlock {
                        id
                        position
                    }
                    skill {
                        id
                        name
                        position
                    }
                }
                descriptors {
                    id
                    name
                    descriptorType
                    skill {
                        id
                        name
                    }
                }
                activities {
                    id
                    name
                    position
                }
                tasks {
                    id
                    name
                    position
                    activity {
                        id
                        name
                        position
                    }
                }
                atypicalItems {
                    id
                    name
                    parentItem {
                        id
                        name
                    }
                    atypicalRepository {
                        id
                        name
                    }
                }
                linkItems {
                    id
                    skillName
                    atypicalItem {
                        id
                        name
                        parentItem {
                            id
                            name
                        }
                        atypicalRepository {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`
