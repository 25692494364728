import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { ActionMeta, Props, createFilter } from 'react-select';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ REACT SELECT -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export interface ISelectProps extends Omit<Props, 'onChange'> {
    hasFilterOption?: boolean;
    emptyMessage?: string;
    onChange?: (newValue: any, action: ActionMeta<any>) => void;
}

const ReactSelect = forwardRef(function ReactSelect(props: ISelectProps, ref: any) {

    const { t } = useTranslation();

    return (
        <Select
            {...props}
            ref={ref}
            placeholder={props?.placeholder || t('select')}
            loadingMessage={() => `${t('loading')}...`}
            noOptionsMessage={() => (props?.emptyMessage || t('none.1'))}
            maxMenuHeight={props?.maxMenuHeight || 200}
            menuPlacement={props?.menuPlacement || 'auto'}
            isClearable={typeof props.isClearable === 'boolean' ? props.isClearable : true}
            filterOption={createFilter({
                ignoreCase: true,
                ignoreAccents: true,
                matchFrom: 'any',
                stringify: (option: any) => `${option.label} ${props?.hasFilterOption ? option.data?.filter : ''}`,
                trim: true,
            })}
            styles={{
                control: base => ({ ...base, minHeight: 40 }),
                container: base => ({ ...base, width: '100%' }),
                multiValue: base => ({ ...base, maxWidth: 200 }),
                placeholder: base => ({ ...base, whiteSpace: 'nowrap' }),
                menu: base => ({ ...base, zIndex: 9999 }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
        />
    );
});

export default ReactSelect;
