import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';
import { Clear as FailIcon, Check as ValidIcon, VisibilityOff as NotObservableIcon } from '@mui/icons-material';

// Apollo
import { useQuery } from '@apollo/client';
import { SELF_PLACEMENT_RESULTS } from 'views/authenticated/self-placements/self-placement/gql-self-placement';
import { SELF_EVALUATION_RESULTS } from 'views/authenticated/self-evaluations/self-evaluation/gql-self-evaluation';

// Services
import { ICustomRepository, ISelfEvaluationResult, ISelfPlacementResult } from 'services/interfaces';
import { ERepositoryItemType, EStepType } from 'services/enums';
import { secondaryMain } from 'services/constants';

// Components
import SelfMasteryLegend from 'components/self-mastery-legend';
import PelletCard from 'components/pellet-card';

// Views
import RatingCustomSkills from './custom-skills';
import RatingDescriptors from './descriptors';
import RatingActivities from './activities';
import RatingTasks from './tasks';
import RatingAtypicalItems from './atypical-items';
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../index';
import { getRepositoryItemTypeTranslationKey } from '../../utils';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- RATING REPOSITORY ITEM -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function RatingRepositoryItem({
    position,
    customRepository,
} : {
    position: number,
    customRepository: ICustomRepository | undefined,
}) {

    const {
        stepType,
        atypicalRepositorySelectOption,
        repositoryItemType,
        roadmap,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const titleTranslationKey = repositoryItemType ? getRepositoryItemTypeTranslationKey(repositoryItemType) : '';

    const { t } = useTranslation();
    const [selfResults, setSelfResults] = useState<ISelfPlacementResult[] | ISelfEvaluationResult[]>([]);

    const { data: dataSPR } = useQuery<{ selfPlacementResults: ISelfPlacementResult[] }>(SELF_PLACEMENT_RESULTS, {
        variables: {
            roadmapId: roadmap?.id,
            atypicalRepositoryId: atypicalRepositorySelectOption?.value,
        },
        skip: ! (roadmap && stepType === EStepType.PLACEMENT),
    });

    const { data: dataSER } = useQuery<{ selfEvaluationResults: ISelfEvaluationResult[] }>(SELF_EVALUATION_RESULTS, {
        variables: {
            roadmapId: roadmap?.id,
            atypicalRepositoryId: atypicalRepositorySelectOption?.value,
        },
        skip: ! (roadmap && stepType === EStepType.EVALUATION),
    });

    useEffect(() => {
        if (dataSPR?.selfPlacementResults) {
            setSelfResults(dataSPR.selfPlacementResults);
        }
        if (dataSER?.selfEvaluationResults) {
            setSelfResults(dataSER.selfEvaluationResults);
        }
    }, [dataSPR, dataSER]);

    return (
        <PelletCard
            paddingTop={0}
            pelletColor={secondaryMain}
            pelletContent={position}
            summary={(
                <Grid item xs>
                    {t(`evaluate_${titleTranslationKey}`)}
                </Grid>
            )}
        >
            {/* -------------------- LEGENDS -------------------- */}
            {(
                stepType === EStepType.SELF_PLACEMENT
                || stepType === EStepType.SELF_EVALUATION
            ) ? (
                <SelfMasteryLegend
                    containerProps={{ marginBottom: 24 }}
                />
            ) : (
                <Grid marginBottom={3}>
                    <Grid
                        container
                        alignItems="center"
                    >
                        <ValidIcon color="success" />
                        <Grid
                            item xs
                            marginLeft={1}
                        >
                            {t('validated')}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                    >
                        <FailIcon color="error" />
                        <Grid
                            item xs
                            marginLeft={1}
                        >
                            {t('not_validated')}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                    >
                        <NotObservableIcon color="primary" />
                        <Grid
                            item xs
                            marginLeft={1}
                        >
                            {t('not_observable.2')}
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12}> 
                {/* -------------------- CUSTOM SKILL -------------------- */}
                {repositoryItemType === ERepositoryItemType.CUSTOM_SKILL && (
                    <RatingCustomSkills
                        customRepository={customRepository}
                        selfPlacementResults={selfResults.filter(r => r.itemType === ERepositoryItemType.CUSTOM_SKILL)}
                    />
                )}
                {/* -------------------- DESCRIPTOR -------------------- */}
                {repositoryItemType === ERepositoryItemType.DESCRIPTOR && (
                    <RatingDescriptors
                        customRepository={customRepository}
                        selfEvaluationResults={selfResults.filter(r => r.itemType === ERepositoryItemType.DESCRIPTOR || r.itemType === ERepositoryItemType.LINK_ITEM)}
                    />
                )}
                {/* -------------------- ACTIVITY -------------------- */}
                {repositoryItemType === ERepositoryItemType.ACTIVITY && (
                    <RatingActivities
                        customRepository={customRepository}
                        selfPlacementResults={selfResults.filter(r => r.itemType === ERepositoryItemType.ACTIVITY)}
                    />
                )}
                {/* -------------------- TASK -------------------- */}
                {repositoryItemType === ERepositoryItemType.TASK && (
                    <RatingTasks
                        customRepository={customRepository}
                        selfEvaluationResults={selfResults.filter(r => r.itemType === ERepositoryItemType.TASK)}
                    />
                )}
                {/* -------------------- ATYPICAL ITEM -------------------- */}
                {repositoryItemType === ERepositoryItemType.ATYPICAL_ITEM && (
                    <RatingAtypicalItems
                        selfPlacementResults={selfResults.filter(r => r.itemType === ERepositoryItemType.ATYPICAL_ITEM)}
                    />
                )}
            </Grid>
        </PelletCard>
    );
}
