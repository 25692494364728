import React from 'react';

// Services
import { EStepType, EWorkSituationType } from 'services/enums';

// Assets
import { ReactComponent as SelfPlacementIcon } from 'assets/images/step-types/self-placement.svg';
import { ReactComponent as PlacementIcon } from 'assets/images/step-types/placement.svg';
import { ReactComponent as PreparatorySequenceIcon } from 'assets/images/step-types/preparatory-sequence.svg';
import { ReactComponent as WorkSituationIcon } from 'assets/images/step-types/work-situation.svg';
import { ReactComponent as ReflectionPhaseIcon } from 'assets/images/step-types/reflection-phase.svg';
import { ReactComponent as EvaluationIcon } from 'assets/images/step-types/evaluation.svg';
import { ReactComponent as SupportIcon } from 'assets/images/step-types/support.svg';
import { ReactComponent as OtherTrainingIcon } from 'assets/images/step-types/other-training.svg';
import { ReactComponent as InternshipIcon } from 'assets/images/step-types/internship.svg'

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- STEP TYPE ICON -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function StepTypeIcon({
    stepType,
    width = 100,
    height = 100,
    color,
    iconProps,
} : {
    stepType: EStepType | EWorkSituationType | undefined,
    width?: number,
    height?: number,
    color?: string,
    iconProps?: React.CSSProperties,
}) {

    switch (stepType) {

        case EStepType.SELF_PLACEMENT:
        case EStepType.SELF_EVALUATION:
            return <SelfPlacementIcon width={width} height={height} color={color} style={iconProps} />;

        case EStepType.PLACEMENT:
            return <PlacementIcon width={width} height={height} color={color} style={iconProps} />;

        case EStepType.PREPARATORY_SEQUENCE:
            return <PreparatorySequenceIcon width={width} height={height} color={color} style={iconProps} />;

        case EStepType.WORK_SITUATION:
        case EStepType.PROFESSIONAL_PRACTICE:
            return <WorkSituationIcon width={width} height={height} color={color} style={iconProps} />;

        case EStepType.REFLECTION_PHASE:
        case EStepType.REFLEXIVE_RESULT:
            return <ReflectionPhaseIcon width={width} height={height} color={color} style={iconProps} />;

        case EStepType.EVALUATION:
        case EStepType.FINAL_EVALUATION:
            return <EvaluationIcon width={width} height={height} color={color} style={iconProps} />;

        case EStepType.SUPPORT:
            return <SupportIcon width={width} height={height} color={color} style={iconProps} />;

        case EStepType.OTHER_TRAINING:
            return <OtherTrainingIcon width={width} height={height} color={color} style={iconProps} />;

        case EStepType.INTERNSHIP:
            return <InternshipIcon width={width} height={height} color={color} style={iconProps} />;

        default:
            return <></>;
    }
}
