import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Apollo
import { useQuery } from '@apollo/client';
import { REFLECTION_PHASE } from './gql-reflection-phase';

// Services
import { IBreadcrumbItem, IReflectionPhase } from 'services/interfaces';
import { getBreadcrumbItems } from 'services/utils';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import ReflectionPhaseCard from './01-reflection-phase-card';
import ReflectionPhaseDetails from './02-reflection-phase-details';
import ReflectionPhaseUsers from './03-reflection-phase-users';
import ReflectionPhaseRepositoryItems from './04-reflection-phase-repository-items';
import ReflectionPhaseReflexivityQuestions from './05-reflexivity-questions';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- REFLECTION PHASE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

const ReflectionPhase = (
{
    source,
    defaultBreadcrumbItems,
    setBreadcrumbItems,
} : {
    source: 'USER' | 'ROADMAP' | 'COMPANY',
    defaultBreadcrumbItems: IBreadcrumbItem[],
    setBreadcrumbItems: React.Dispatch<React.SetStateAction<IBreadcrumbItem[]>>,
}) => {
    
    const { t } = useTranslation();
    const { id, roadmapId, reflectionPhaseId } = useParams();
    const [workSituationLink, setWorkSituationLink] = useState('');

    const { loading, error, data } = useQuery<{ reflectionPhase: IReflectionPhase }>(REFLECTION_PHASE, {
        variables: { id: reflectionPhaseId },
    });

    useEffect(() => {
        if (data?.reflectionPhase) {

            const workSituation = data.reflectionPhase?.learnerWorkSituation?.workSituation;

            const { items, workSituationLink } = getBreadcrumbItems({
                mainId: id,
                workSituation,
                source,
                items: defaultBreadcrumbItems,
                roadmapId,
                reflectionPhaseId,
            });

            setBreadcrumbItems(items);
            setWorkSituationLink(workSituationLink);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (loading) return <Spinner />;
    if (error) return <Error />;
    if ((data || error) && !data?.reflectionPhase && !error) return <Error error={`${t('reflection_phase')} ${t('not_found').toLowerCase()}`} />

    return (
        <>
            {/* -------------------- 01 CARD -------------------- */}
            <ReflectionPhaseCard
                reflectionPhase={data?.reflectionPhase}
                workSituationLink={workSituationLink}
            />
            {/* -------------------- 02 DETAILS -------------------- */}
            <ReflectionPhaseDetails
                reflectionPhase={data?.reflectionPhase}
                workSituationLink={workSituationLink}
            />
            {/* -------------------- 03 USERS -------------------- */}
            <ReflectionPhaseUsers
                reflectionPhase={data?.reflectionPhase}
            />
            {/* -------------------- 04 REPOSITORY ITEMS -------------------- */}
            <ReflectionPhaseRepositoryItems
                reflectionPhase={data?.reflectionPhase}
            />
            {/* -------------------- 05 REFLEXIVITY AXIS -------------------- */}
            {data?.reflectionPhase?.reflexivityAxis && (
                <ReflectionPhaseReflexivityQuestions
                    reflectionPhase={data?.reflectionPhase}
                />
            )}
        </>
    );
}

export default ReflectionPhase;