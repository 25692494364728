
// Services
import { EStepType } from 'services/enums';

// Components
import ReflexivityQuestionsForm from 'components/reflexivity-questions-form';

// Views
import { IState } from '../../create-reflection-phase';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- REFLECTION PHASE QUESTIONS ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ReflectionPhaseQuestions({
    state,
    setState,
} : {
    state: IState,
    setState: React.Dispatch<React.SetStateAction<IState>>,
}) {
    return (
        <ReflexivityQuestionsForm
            startPosition={3}
            reflexivityAxis={state.reflexivityAxis}
            setReflexivityAxis={reflexivityAxis => setState(prevState => ({ ...prevState, reflexivityAxis, reflexivityQuestionItems: [] }))}
            reflexivityQuestionItems={state.reflexivityQuestionItems}
            setReflexivityQuestionItems={reflexivityQuestionItems => setState(prevState => ({ ...prevState, reflexivityQuestionItems }))}
            stepType={EStepType.REFLECTION_PHASE}
            withAnswers
        />
    );
}
