import React, { useContext, useEffect, useState } from 'react';

// MUI
import { Divider, Grid } from '@mui/material';

// Services
import { IAtypicalItem, IAtypicalLevel } from 'services/interfaces';

// Views
import AtypicalItems from '../atypical-items';
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../../index';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- ATYPICAL LEVEL ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const AtypicalLevel = ({
    atypicalLevel,
    parentItemId,
    containerProps,
} : {
    atypicalLevel: IAtypicalLevel,
    parentItemId?: string,
    containerProps?: React.CSSProperties,
}) => {

    const { atypicalRepository } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;
    const [selectedItem, setSelectedItem] = useState<IAtypicalItem | null>(null);
    const [nextLevel, setNextLevel] = useState<IAtypicalLevel | undefined>(undefined);

    useEffect(() => {
        if (parentItemId) {
            setSelectedItem(null);
        }
    }, [parentItemId]);

    useEffect(() => {
        if (selectedItem && atypicalLevel?.childLevel) {
            let nextLevel = atypicalRepository?.atypicalLevels.find(l => l.id === atypicalLevel.childLevel?.id);
            setNextLevel(nextLevel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem]);

    return (
        <>
            <Grid
                borderRadius={2}
                border="1px solid black"
                width="100%"
                style={containerProps}
            >
                <Grid padding={1}>
                    {atypicalLevel.level}. {atypicalLevel.name}
                </Grid>
                <Divider />
                <Grid padding={3}>
                    <AtypicalItems
                        atypicalLevel={atypicalLevel}
                        atypicalItems={parentItemId ? atypicalLevel?.atypicalItems.filter(i => i.parentItem?.id === parentItemId) : atypicalLevel.atypicalItems}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                    />
                </Grid>
            </Grid>
            {/* -------------------- CHILD ATYPICAL LEVEL -------------------- */}
            {(selectedItem && nextLevel) && (
                <AtypicalLevel
                    atypicalLevel={nextLevel}
                    parentItemId={selectedItem.id}
                    containerProps={{ marginTop: 24 }}
                />
            )}
        </>
    );
};

export default AtypicalLevel;
