import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { ISelectOptionWithUserAttributes } from 'services/interfaces';
import { primaryMain } from 'services/constants';

// Components
import MobileTextField from 'components/mobile-text-field';
import PelletCard from 'components/pellet-card';

// Views
import { IState } from '../../create-work-situation-from-roadmap';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------- 05 WORK SITUATION LEARNER RESULTS --------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function WorkSituationLearnerResults({
    state,
    setState,
} : {
    state: IState,
    setState: React.Dispatch<React.SetStateAction<IState>>,
}) {

    const { t } = useTranslation();

    const learner = useMemo(() => {
        return state.learners.find(l => l?.value === state.roadmap?.learner?.id);
        // eslint-disable-next-line
    }, [state.roadmap, state.source]);

    const learnerIndex = useMemo(() => {
        return state.learners.findIndex(l => l?.value === state.roadmap?.learner?.id);
        // eslint-disable-next-line
    }, [state.roadmap, state.source]);

    const onChangeLearnerResult = (value: string, index: number) => {
        let updatedLearners = [...state.learners];
        updatedLearners[index].result = value;
        setState(prevState => ({ ...prevState, learners: updatedLearners }));
    };

    return (
        <PelletCard
            paddingTop={0}
            pelletColor={primaryMain}
            pelletContent={5}
            summary={(
                <Grid item xs>
                    {state.source === 'ROADMAP' ? t('learner_result') : t('learner_results')}
                </Grid>
            )}
        >
            <Grid
                container
                spacing={3}
            >
                {(state.source === 'ROADMAP' && learner) ? (
                    <LearnerResultItem
                        learner={learner}
                        setLearnerResult={(value: string) => onChangeLearnerResult(value, learnerIndex)}
                    />
                ) : (
                    state.learners.map((learner, index) => (
                        <LearnerResultItem
                            key={index}
                            learner={learner}
                            setLearnerResult={(value: string) => onChangeLearnerResult(value, index)}
                        />
                    ))
                )}
            </Grid>
        </PelletCard>
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- LEARNER RESULT ITEM ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const LearnerResultItem = ({
    learner,
    setLearnerResult,
} : {
    learner: ISelectOptionWithUserAttributes,
    setLearnerResult: (value: string) => void,
}) => {

    const { t } = useTranslation();

    return (
        <Grid item xs={12} sm={6} xl={4}>
            <span>
                {learner?.label}
            </span>
            <Grid marginTop={1}>
                <MobileTextField
                    name="result"
                    value={learner.result || ''}
                    onChange={e => setLearnerResult(e.target.value)}
                    placeholder={t('result.2')}
                    multiline
                    rows={3}
                />
            </Grid>
        </Grid>
    );
}
