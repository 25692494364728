import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { CircularProgress, Grid } from '@mui/material';

// Apollo
import { useQuery } from '@apollo/client';
import { ATYPICAL_ITEMS_RELATED_TO_ACTIVITY } from 'views/authenticated/repositories/activity-repositories/activity-repository/activities/gql-activities';

// Services
import { IAtypicalItem, IAtypicalRepository } from 'services/interfaces';
import { getStepTypeActionLabel } from 'services/utils';
import { magentaMain, yellowMain } from 'services/constants';

// Components
import PelletCard from 'components/pellet-card';
import { Block, BlockContainer, BlockIconButton, EmptyBlock } from 'components/block';
import { ResultTypeBadge } from 'components/result-type';

// Icons
import { SelectedRepositoryItemIcon } from 'icons';

// Repository Items
import AtypicalLevel from './atypical-level';
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../index';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- ATYPICAL LEVELS ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

const AtypicalLevels = ({ position } : { position: number }) => {

    const { t } = useTranslation();
    const [itemsRelatedToActivity, setItemsRelatedToActivity] = useState<IAtypicalItem[]>([]);

    const {
        setRepositoryItems,
        stepType,
        atypicalRepository,
        atypicalRepositoryResults,
        atypicalItems,
        filterItemsRelatedToActivities,
        filteredActivityIds,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const { loading, data } = useQuery<{ atypicalItemsRelatedToActivity: IAtypicalRepository[] }>(ATYPICAL_ITEMS_RELATED_TO_ACTIVITY, {
        variables: {
            atypicalRepositoryId: atypicalRepository?.id,
            activityIds: filteredActivityIds,
        },
        skip: filterItemsRelatedToActivities === '0',
    });

    const onClickToAtypicalItem = (atypicalItemId: string) => {

        let items = [...atypicalItems];
        let index = items.findIndex(i => i.id === atypicalItemId);

        if (index === -1) {
            items.push({ id: atypicalItemId });
        }
        else {
            items.splice(index, 1);
        }

        setRepositoryItems({ atypicalItems: items });
    };

    useEffect(() => {
        if (data?.atypicalItemsRelatedToActivity) {

            let items = data.atypicalItemsRelatedToActivity.length > 0
                ? data.atypicalItemsRelatedToActivity[0].atypicalItems
                : [];

            setRepositoryItems({ atypicalItems: [...atypicalItems].filter(ri => items.some(i => i.id === ri.id)) });
            setItemsRelatedToActivity(items);
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <PelletCard
            paddingTop={0}
            pelletColor={yellowMain}
            pelletContent={position}
            summary={(
                <>
                    <Grid item xs>
                        {t('select_atypical_items')} {getStepTypeActionLabel(stepType)} ({atypicalItems.length})
                    </Grid>
                    {loading && (
                        <Grid item xs="auto">
                            <CircularProgress size={20} color="inherit" />
                        </Grid>
                    )}
                </>
            )}
        >
            {filterItemsRelatedToActivities === '1' ? (
                <Grid
                    container
                    spacing={3}
                >
                    {itemsRelatedToActivity.length > 0 ? (
                        itemsRelatedToActivity.map(atypicalItem => {

                            let selected = atypicalItems?.some(i => i.id === atypicalItem.id);
                            let resultType = atypicalRepositoryResults.find(r => r.simpleResultId === atypicalItem.id)?.resultType;

                            return (
                                <BlockContainer key={atypicalItem.id}>
                                    <Block
                                        onClick={() => onClickToAtypicalItem(atypicalItem.id)}
                                        selected={selected}
                                        color="magenta"
                                        badge={<ResultTypeBadge resultType={resultType} />}
                                        icons={(
                                            selected && (
                                                <BlockIconButton tooltip={t('selected_item')}>
                                                    <SelectedRepositoryItemIcon />
                                                </BlockIconButton>
                                            )
                                        )}
                                    >
                                        {atypicalItem.name}
                                    </Block>
                                </BlockContainer>
                            );
                        })
                    ) : (
                        <BlockContainer>
                            <EmptyBlock color={magentaMain}>
                                {t('none.1')}
                            </EmptyBlock>
                        </BlockContainer>
                    )}
                </Grid>
            ) : (
                (
                    atypicalRepository?.atypicalLevels
                    && atypicalRepository.atypicalLevels.length > 0
                ) ? (
                    <AtypicalLevel
                        atypicalLevel={atypicalRepository.atypicalLevels[0]}
                    />
                ) : (
                    <BlockContainer>
                        <EmptyBlock color={magentaMain}>
                            {t('none.1')}
                        </EmptyBlock>
                    </BlockContainer>
                )
            )}
        </PelletCard>
    );
};

export default AtypicalLevels;
