import { gql } from '@apollo/client';

export const REORDER_ACTIVITIES = gql`
    mutation ReorderActivities(
        $id: ID!
        $activities: UpdateActivitiesInActivityRepository!
    ) {
        updateActivityRepository(input: {
            id: $id
            activities: $activities
        }) {
            id
        }
    }
`

export const ATYPICAL_ITEMS_RELATED_TO_ACTIVITY = gql`
    query AtypicalItemsRelatedToActivity (
        $atypicalRepositoryId: ID
        $activityIds: [ID]
        $taskIds: [ID]
    ) {
        atypicalItemsRelatedToActivity(
            atypicalRepositoryId: $atypicalRepositoryId
            activityIds: $activityIds
            taskIds: $taskIds
        ) {
            id
            name
            atypicalItems {
                id
                name
                parentItem {
                    id
                    name
                }
                subItems {
                    id
                    name
                    atypicalLevel {
                        name
                    }
                }
            }
        }
    }
`
