import { gql } from '@apollo/client';

export const ROLES = gql`
    query Roles($onlyAccessible: Boolean) {
        roles(onlyAccessible: $onlyAccessible) {
            id
            name
        }
    }
`;
