
// Services
import { useBreadcrumbFromUser } from 'views/authenticated/users/user';
import { useBreadcrumbFromCompany } from 'views/authenticated/companies/company';

// Views
import WorkSituation from '../work-situation';
import UpdateWorkSituation from '../update-work-situation';
import SignWorkSituation from '../sign-work-situation';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------- SIGN REFLECTION PHASE FROM  ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const ActionWorkSituationFrom = (
    {
        source,
        action,
    } : {
        source: "USER" | "COMPANY" | "ROADMAP",
        action: "VIEW" | "UPDATE" | "SIGN",
    }) => {
    const { defaultBreadcrumbItems: defaultUserBreadcrumbItems, setBreadcrumbItems: setUserBreadcrumbItems } = useBreadcrumbFromUser();

    const { defaultBreadcrumbItems: defaultCompanyBreadcrumbItems, setBreadcrumbItems: setCompanyBreadcrumbItems } = useBreadcrumbFromCompany();

    const defaultBreadcrumbItems = source === "COMPANY" ? defaultCompanyBreadcrumbItems : defaultUserBreadcrumbItems;

    const setBreadcrumbItems = source === "COMPANY" ? setCompanyBreadcrumbItems : setUserBreadcrumbItems;

    return (
        <>
            {action === "VIEW" && (
                <WorkSituation
                    source={source}
                    defaultBreadcrumbItems={defaultBreadcrumbItems}
                    setBreadcrumbItems={setBreadcrumbItems}
                />
            )}
            {action === "UPDATE" && (
                <UpdateWorkSituation
                    source={source}
                    defaultBreadcrumbItems={defaultBreadcrumbItems}
                    setBreadcrumbItems={setBreadcrumbItems}
                />
            )}
            {action === "SIGN" && (
                <SignWorkSituation
                    source={source}
                    defaultBreadcrumbItems={defaultBreadcrumbItems}
                    setBreadcrumbItems={setBreadcrumbItems}
                />
            )}
        </>
    );
};

export default ActionWorkSituationFrom;
