import { useState } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Button, Fab, Tooltip } from '@mui/material';
import { Edit as SignIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

// Services
import { IReflectionPhase } from 'services/interfaces';
import { EStepType } from 'services/enums';
import { yellowMain } from 'services/constants';

// Permissions
import { canUpdateReflectionPhase, canDeleteReflectionPhase, canSignReflectionPhase } from 'permissions';

// Components
import Title from 'components/title';

// Icons
import StepTypeIcon from 'icons/step-type-icon';

// Views
import DeleteReflectionPhase from '../../delete-reflection-phase';
import PelletCard from 'components/pellet-card';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ 01 REFLECTION PHASE CARD -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ReflectionPhaseCard({ reflectionPhase, workSituationLink } : { reflectionPhase: IReflectionPhase | undefined, workSituationLink: string }) {

    const roadmap = reflectionPhase?.learnerWorkSituation?.roadmap;
    const workSituation = reflectionPhase?.learnerWorkSituation?.workSituation;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { reflectionPhaseId } = useParams();
    const { pathname } = useLocation();
    const [openDelete, setOpenDelete] = useState(false);

    return (
        <PelletCard
            startIcon={(
                <StepTypeIcon
                    stepType={EStepType.REFLECTION_PHASE}
                    width={40}
                    height={40}
                    color={yellowMain}
                />
            )}
            summary={(
                <>
                    <Grid item xs>
                        <Title>
                            {t('reflection_phase')}
                        </Title>
                        <br />
                        <span>
                            {workSituation?.customRepository?.name} {t('at.1')} {workSituation?.company?.name}
                        </span>
                    </Grid>
                    {canUpdateReflectionPhase({ workSituation: workSituation, roadmap }) && (
                        <Grid item xs="auto">
                            <Tooltip title={t('edit')}>
                                <Fab
                                    onClick={() => navigate(pathname + '/update')}
                                    color="yellow"
                                >
                                    <EditIcon />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    )}
                    {canDeleteReflectionPhase({ workSituation: workSituation, roadmap }) && (
                        <Grid item xs="auto">
                            <Tooltip title={t('delete')}>
                                <Fab
                                    onClick={() => setOpenDelete(true)}
                                    color="yellow"
                                >
                                    <DeleteIcon />
                                </Fab>
                            </Tooltip>
                            {openDelete && (
                                <DeleteReflectionPhase
                                    reflectionPhaseId={reflectionPhaseId}
                                    onClose={() => setOpenDelete(false)}
                                    refetch={() => navigate(workSituationLink)}
                                />
                            )}
                        </Grid>
                    )}
                </>
            )}
        >
            {canSignReflectionPhase(reflectionPhase) && (
                <Button
                    component={Link}
                    to={pathname + '/signatures'}
                    endIcon={<SignIcon />}
                    color="yellow"
                >
                    {t('sign_reflection_phase')}
                </Button>
            )}
        </PelletCard>
    );
}
