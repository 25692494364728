import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Permissions
import { DocumentTitleRoute, RequirePerm } from '../index';
import {
    canViewAnySkillRepository, canViewSkillRepository, canCreateSkillRepository, canUpdateSkillRepository,
    canViewAnyCustomRepository, canViewCustomRepository, canCreateCustomRepository, canUpdateCustomRepository,
    canViewAnyActivityRepository, canViewActivityRepository, canCreateActivityRepository, canUpdateActivityRepository,
    canViewAnyAtypicalRepository, canViewAtypicalRepository, canCreateAtypicalRepository, canUpdateAtypicalRepository,
} from 'permissions';

/* * *
* Lazy Imports
*/

const Repositories = lazy(() => import('../../../views/authenticated/repositories'));

// Skill Repositories
const SkillRepositories = lazy(() => import('../../../views/authenticated/repositories/skill-repositories'));
const SkillRepository = lazy(() => import('../../../views/authenticated/repositories/skill-repositories/skill-repository'));
const CreateSkillRepository = lazy(() => import('../../../views/authenticated/repositories/skill-repositories/create-skill-repository'));
const UpdateSkillRepository = lazy(() => import('../../../views/authenticated/repositories/skill-repositories/update-skill-repository'));

// Custom Repositories
const CustomRepositories = lazy(() => import('../../../views/authenticated/repositories/custom-repositories'));
const CustomRepository = lazy(() => import('../../../views/authenticated/repositories/custom-repositories/custom-repository'));
const CreateCustomRepository = lazy(() => import('../../../views/authenticated/repositories/custom-repositories/create-custom-repository'));
const UpdateCustomRepository = lazy(() => import('../../../views/authenticated/repositories/custom-repositories/update-custom-repository'));
const MissingInCustomRepository = lazy(() => import('../../../views/authenticated/repositories/custom-repositories/custom-repository/missing-in-custom-repository'));
const CreateCustomBlocks = lazy(() => import('../../../views/authenticated/repositories/custom-repositories/custom-repository/custom-blocks/create-custom-blocks'));
const UpdateCustomBlock = lazy(() => import('../../../views/authenticated/repositories/custom-repositories/custom-repository/custom-blocks/update-custom-block'));

// Activity Repositories
const ActivityRepositories = lazy(() => import('../../../views/authenticated/repositories/activity-repositories'));
const ActivityRepository = lazy(() => import('../../../views/authenticated/repositories/activity-repositories/activity-repository'));
const CreateActivityRepository = lazy(() => import('../../../views/authenticated/repositories/activity-repositories/create-activity-repository'));
const CreateActivity = lazy(() => import('../../../views/authenticated/repositories/activity-repositories/activity-repository/activities/create-activity'));
const UpdateActivity = lazy(() => import('../../../views/authenticated/repositories/activity-repositories/activity-repository/activities/update-activity'));
const NotCoveredInActivityRepository = lazy(() => import('../../../views/authenticated/repositories/activity-repositories/activity-repository/not-covered-in-activity-repository'));

// Activity Repositories
const AtypicalRepositories = lazy(() => import('../../../views/authenticated/repositories/atypical-repositories'));
const AtypicalRepository = lazy(() => import('../../../views/authenticated/repositories/atypical-repositories/atypical-repository'));
const CreateAtypicalRepository = lazy(() => import('../../../views/authenticated/repositories/atypical-repositories/create-atypical-repository'));
const UpdateAtypicalRepository = lazy(() => import('../../../views/authenticated/repositories/atypical-repositories/update-atypical-repository'));

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- REPOSITORY ROUTES ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function RepositoryRoutes() {
    return (
        <Routes>
            <Route index element={<Repositories />} />
            {/* -------------------- SKILL REPOSITORIES -------------------- */}
            <Route path="skill-repositories">
                <Route index element={<RequirePerm perm={canViewAnySkillRepository()}><SkillRepositories /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={canCreateSkillRepository()}><CreateSkillRepository /></RequirePerm>} />
                <Route path=":id" element={<DocumentTitleRoute title="skill_repository" id="id" />}>
                    <Route index element={<RequirePerm perm={canViewSkillRepository()}><SkillRepository /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={canUpdateSkillRepository()}><UpdateSkillRepository /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- CUSTOM REPOSITORIES -------------------- */}
            <Route path="custom-repositories">
                <Route index element={<RequirePerm perm={canViewAnyCustomRepository()}><CustomRepositories /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={canCreateCustomRepository()}><CreateCustomRepository /></RequirePerm>} />
                <Route path=":id" element={<DocumentTitleRoute title="custom_repository" id="id" />}>
                    <Route index element={<RequirePerm perm={canViewCustomRepository()}><CustomRepository /></RequirePerm>} />
                    <Route path="missing-in-custom-repository" element={<MissingInCustomRepository/>} />
                    <Route path="update" element={<RequirePerm perm={canUpdateCustomRepository()}><UpdateCustomRepository /></RequirePerm>} />
                    <Route path="create-custom-blocks" element={<RequirePerm perm={canUpdateCustomRepository()}><CreateCustomBlocks /></RequirePerm>} />
                    <Route path="update-custom-block/:customBlockId" element={<RequirePerm perm={canUpdateCustomRepository()}><UpdateCustomBlock /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- ACTIVITY REPOSITORIES -------------------- */}
            <Route path="activity-repositories">
                <Route index element={<RequirePerm perm={canViewAnyActivityRepository()}><ActivityRepositories /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={canCreateActivityRepository()}><CreateActivityRepository /></RequirePerm>} />
                <Route path=":id" element={<DocumentTitleRoute title="activity_repository" id="id" />}>
                    <Route index element={<RequirePerm perm={canViewActivityRepository()}><ActivityRepository /></RequirePerm>} />
                    <Route path="not-covered-in-activity-repository" element={<NotCoveredInActivityRepository />} />
                    <Route path="create-activity" element={<RequirePerm perm={canUpdateActivityRepository()}><CreateActivity /></RequirePerm>} />
                    <Route path="update-activity/:activityId" element={<RequirePerm perm={canUpdateActivityRepository()}><UpdateActivity /></RequirePerm>} />
                </Route>
            </Route>
            {/* -------------------- ATYPICAL REPOSITORIES -------------------- */}
            <Route path="atypical-repositories">
                <Route index element={<RequirePerm perm={canViewAnyAtypicalRepository()}><AtypicalRepositories /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={canCreateAtypicalRepository()}><CreateAtypicalRepository /></RequirePerm>} />
                <Route path=":id" element={<DocumentTitleRoute title="atypical_repository" id="id" />}>
                    <Route index element={<RequirePerm perm={canViewAtypicalRepository()}><AtypicalRepository /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={canUpdateAtypicalRepository()}><UpdateAtypicalRepository /></RequirePerm>} />
                </Route>
            </Route>
        </Routes>
    );
}
