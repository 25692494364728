import { useTranslation } from 'react-i18next';

// MUI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid, Fab } from '@mui/material';
import { Visibility as VisibilityIcon, Add as AddIcon } from '@mui/icons-material';

// Services
import { IWorkSituation } from 'services/interfaces';

// Permissions
import { canUpdateWorkSituation } from 'permissions';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ROADMAP DIALOG ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const RoadmapDialog = ({
    workSituation,
    onClose,
    onNavigateToRoadmap,
    onOpenUpdateLearnerWorkSituation,
} : {
    workSituation: IWorkSituation | undefined,
    onClose: VoidFunction,
    onNavigateToRoadmap: VoidFunction,
    onOpenUpdateLearnerWorkSituation: VoidFunction,
}) => {

    const { t } = useTranslation();

    return (
        <Dialog
            open
            onClose={onClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogContent>
                {/* -------------------- VIEW ROADMAP -------------------- */}
                <Grid
                    container
                    alignItems="center"
                >
                    <Fab
                        onClick={onNavigateToRoadmap}
                        color="secondary"
                    >
                        <VisibilityIcon />
                    </Fab>
                    <Grid
                        item xs
                        marginLeft={1}
                    >
                        {t('access_to_roadmap')}
                    </Grid>
                </Grid>
                {/* -------------------- UPDATE LEARNER WORK SITUATION -------------------- */}
                {canUpdateWorkSituation({ workSituation }) && (
                    <Grid
                        container
                        alignItems="center"
                        marginTop={2}
                    >
                        <Fab
                            onClick={onOpenUpdateLearnerWorkSituation}
                            color="secondary"
                        >
                            <AddIcon />
                        </Fab>
                        <Grid
                            item xs
                            marginLeft={1}
                        >
                            {t('associate_roadmap')}
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RoadmapDialog;
