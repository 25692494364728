import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InputActionMeta, ActionMeta } from 'react-select';

// Lodash
import { debounce } from 'lodash';

// Apollo
import { useQuery } from '@apollo/client';
import { CUSTOM_REPOSITORIES } from 'views/authenticated/repositories/custom-repositories/gql-custom-repositories';

// Services
import { ICustomRepository } from 'services/interfaces';

// Components
import Select, { ISelectProps } from 'components/react-select';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ SELECT CUSTOM REPOSITORY -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ISelectCustomRepositoryProps extends ISelectProps {
    onChange: (newValue: any) => void;
    companyId?: string;
}

export default function SelectCustomRepository(props: ISelectCustomRepositoryProps) {

    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');

    const { loading, data } = useQuery<{ customRepositories: { data: ICustomRepository[] }}>(CUSTOM_REPOSITORIES, {
        variables: {
            page: 1,
            first: 10,
            name: '%' + searchValue + '%',
            status: 'AVAILABLE',
            companyId: props.companyId,
        },
    });

    const delayedOnSearch = useRef(debounce(value => setSearchValue(value), 500)).current;

    const handleDelayedOnSearch = (value: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change') {
            delayedOnSearch(value);
        }
    };

    const handleOnChange = (value: any, actionMeta: ActionMeta<any>) => {
        if (actionMeta.action === 'clear') {
            setSearchValue('');
        }
        props.onChange(value);
    };

    return (
        <Select
            {...props}
            placeholder={t('search_custom_repository')}
            onChange={(value, actionMeta) => handleOnChange(value, actionMeta)}
            onInputChange={(value, actionMeta) => handleDelayedOnSearch(value, actionMeta)}
            options={data?.customRepositories?.data ? data.customRepositories.data.map(cr => ({ value: cr.id, label: cr.name })) : []}
            isLoading={loading}
        />
    );
}
