import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Dialog, DialogContent, DialogActions, Button, CircularProgress, Divider } from '@mui/material';
import { WarningRounded as WarningIcon } from '@mui/icons-material'; 

// Apollo
import { useMutation } from '@apollo/client';
import { DELETE_REFLECTION_PHASE } from './gql-delete-reflection-phase';

// Services
import { getErrorExtensions } from 'services/utils';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- DELETE REFLECTION PHASE ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const DeleteReflectionPhase = ({
    reflectionPhaseId,
    onClose,
    refetch,
} : {
    reflectionPhaseId: string | undefined,
    onClose: VoidFunction,
    refetch: any,
}) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [deleteReflectionPhase, { loading, error, data }] = useMutation(DELETE_REFLECTION_PHASE);

    useEffect(() => {
        if (data?.deleteReflectionPhase) {
            enqueueSnackbar(t('deleted_successfully'), { variant: 'success' });
            refetch();
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <Dialog
            open
            onClose={loading ? undefined : onClose}
        >
            <DialogContent>
                {t('confirm_delete_reflection_phase')}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    disabled={loading}
                    color="inherit"
                >
                    {t('no')}
                </Button>
                <Button
                    onClick={() => deleteReflectionPhase({ variables: { id: reflectionPhaseId }})}
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <WarningIcon fontSize="small" />}
                    color="warning"
                >
                    {t('yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteReflectionPhase;
