// React
import { useTranslation } from 'react-i18next';

// MUI
import { Button, Dialog, DialogActions, DialogContent, Divider } from '@mui/material';

// Services
import { IAtypicalItem } from 'services/interfaces';
import { useMediaQueries } from 'services/hooks';

export default function SubItemsDialog({
    atypicalItem,
    onClose,
} : {
    atypicalItem: IAtypicalItem,
    onClose: VoidFunction,
}) {
    const { mediaMD } = useMediaQueries();

    const { t } = useTranslation();

    return (
        <Dialog
            open
            onClose={onClose}
            fullWidth
            fullScreen={!mediaMD}
        >
            <DialogContent>
                {(atypicalItem.subItems && atypicalItem.subItems.length > 0) && (
                    <div className="dialog-title">
                        {atypicalItem.subItems[0].atypicalLevel?.name}
                    </div>
                )}
                {(atypicalItem.subItems && atypicalItem.subItems.length > 0) && (
                    <table style={{width: 'fit-content'}}>
                        <tbody>
                        {atypicalItem.subItems.map(item => (
                            <tr key={'si' + item.id}>
                                <td
                                    className="table-td"
                                    style={{width: '100%'}}
                                >
                                    {item.name}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                >
                {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}