import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Accordion, AccordionSummary, AccordionDetails, Divider, Tooltip, Icon, Badge } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

// Services
import { API_URL } from 'services/apollo-client';

// Components
import Title from 'components/title';

// CSS
import styles from './Subheader.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- SUBHEADER --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const Subheader = ({
    title,
    icon,
    iconProps,
    titleIcons,
    imageUrl,
    imageUpload,
    subtitle,
    description,
    descriptionTitle,
    status,
    children,
} : {
    title: string | undefined,
    icon: React.ReactNode,
    iconProps?: React.CSSProperties,
    titleIcons?: React.ReactNode,
    imageUrl?: string,
    imageUpload?: React.ReactNode,
    subtitle?: string,
    description?: string,
    descriptionTitle?: string,
    status?: React.ReactNode,
    children?: React.ReactNode,
}) => {

    const { t } = useTranslation();
    const [descriptionExpanded, setDescriptionExpanded] = useState(false);

    return (
        <div className={styles.backgroundColor}>
            <Grid
                padding={1}
                container
                alignItems="center"
            >
                {/* -------------------- AVATAR -------------------- */}
                <Grid
                    item xs={12} md="auto"
                    padding={1}
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <Badge
                        badgeContent={imageUpload}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        slotProps={{ badge: { style: { bottom: '12px', right: '12px' }}}}
                    >
                        {imageUrl ? (
                            <div
                                className={styles.image}
                                style={{ backgroundImage: `url(${API_URL + imageUrl})` }}
                            />
                        ) : (
                            <div
                                className={styles.icon}
                                style={iconProps}
                            >
                                {icon}
                            </div>
                        )}
                    </Badge>
                </Grid>
                <Grid item xs={12} sm>
                    <Grid padding={1}>
                        <Grid
                            container
                            alignItems="center"
                            spacing={1}
                        >
                            {/* -------------------- TITLE -------------------- */}
                            <Grid item xs>
                                <span className={styles.title}>
                                    {title}{titleIcons}
                                </span>
                                {subtitle && (
                                    <>
                                        <br/>
                                        <span className="subtitle">
                                            {subtitle}
                                        </span>
                                    </>
                                )}
                            </Grid>
                            {/* -------------------- STATUS -------------------- */}
                            {status && (
                                <Grid item xs={12} md="auto">
                                    {status}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {/* -------------------- CHILDREN -------------------- */}
                    {children && (
                        <Grid container>
                            {children}
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- DESCRIPTION -------------------- */}
            {description && (
                <>
                    <Divider />
                    <Accordion
                        expanded={descriptionExpanded}
                        onChange={(e, expanded) => setDescriptionExpanded(expanded)}
                        className={styles.accordion}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Grid container alignItems="center">
                                <Title>
                                    {descriptionTitle || t('description')}
                                </Title>
                                <Grid
                                    item xs
                                    className="subtitle"
                                    marginLeft={1}
                                >
                                    ({descriptionExpanded ? t('click_to_hide') : t('click_to_view') })
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails className="break-word back-to-line">
                            {description}
                        </AccordionDetails>
                    </Accordion>
                </>
            )}
        </div>
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- SUBHEADER TITLE ICONS --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ISubheaderTitleIconsProps {
    tooltip: React.ReactNode;
    icon: string;
    iconColor?: string;
}

export const SubheaderTitleIcons = (props: ISubheaderTitleIconsProps) => {
    return (
        <Tooltip title={props.tooltip}>
            <Icon sx={{ color: props.iconColor || 'gray', marginLeft: 1 }}>
                {props.icon}
            </Icon>
        </Tooltip>
    );
};

export default Subheader;
