import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Permissions
import { canViewAnyCertification, canViewCertification, canCreateCertification, canUpdateCertification } from 'permissions';
import { DocumentTitleRoute, RequirePerm } from '../index';

/* * *
* Lazy Imports
*/

const Certifications = lazy(() => import('../../../views/authenticated/certifications'));
const Certification = lazy(() => import('../../../views/authenticated/certifications/certification'));
const CreateCertification = lazy(() => import('../../../views/authenticated/certifications/create-certification'));
const UpdateCertification = lazy(() => import('../../../views/authenticated/certifications/update-certification'));

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- CERTIFICATION ROUTES ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function CertificationRoutes() {
    return (
        <Routes>
            <Route index element={<RequirePerm perm={canViewAnyCertification()}><Certifications /></RequirePerm>} />
            <Route path="create" element={<RequirePerm perm={canCreateCertification()}><CreateCertification /></RequirePerm>} />
            <Route path=":certificationId" element={<DocumentTitleRoute title="certification" id="certificationId" />}>
                <Route index element={<RequirePerm perm={canViewCertification()}><Certification /></RequirePerm>} />
                <Route path="update" element={<RequirePerm perm={canUpdateCertification()}><UpdateCertification /></RequirePerm>} />
            </Route>
        </Routes>
    );
}
