import { gql } from '@apollo/client';

export const UPLOAD_REFLECTION_PHASE_LEARNER_SIGNATURE = gql`
    mutation UploadReflectionPhaseLearnerSignature(
        $id: ID!
        $file: Upload!
    ) {
        uploadReflectionPhaseLearnerSignature(
            id: $id,
            file: $file,
        ) {
            id
        }
    }
`
