import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { ToggleButtonGroup, ToggleButton, ButtonGroup, Button, Dialog, DialogContent, Divider, DialogActions } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Services
import { IRepositoryItems, IRepositoryItem, ISelfPlacementResult, ISelfEvaluationResult } from 'services/interfaces';
import { EMastery, ERepositoryItemType, ESelfMastery } from 'services/enums';
import { useMediaQueries } from 'services/hooks';

// Components
import ToggleSelfMastery from 'components/toggle-self-mastery';
import SelfMasteryLegend from 'components/self-mastery-legend';
import Title from 'components/title';

// Repository Items
import { getRepositoryItemTypeKey } from 'repository-items/utils';

// Icons
import MasteryIcon from 'icons/mastery-icon';
import SelfMasteryIcon from 'icons/self-mastery-icon';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- RESPONSIVE TABLE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const ResponsiveTable = ({
    index,
    children1,
    children2,
    children3,
    children4,
} : {
    index: number,
    children1: React.ReactNode,
    children2: React.ReactNode,
    children3?: React.ReactNode,
    children4?: React.ReactNode,
}) => {

    const { mediaSM } = useMediaQueries();

    return (
        <>
            {mediaSM ? (
                <tr>
                    <td className="table-td">
                        {children1}
                    </td>
                    <td className="table-td">
                        {children2}
                    </td>
                    {children3 && (
                        <td className="table-td">
                            {children3}
                        </td>
                    )}
                    {children4 && (
                        <td className="table-td">
                            {children4}
                        </td>
                    )}
                </tr>
            ) : (
                <>
                    {index !== 0 && (
                        <tr>
                            <td style={{ height: 12 }} />
                        </tr>
                    )}
                    <tr>
                        <td className="table-td">
                            {children1}
                        </td>
                    </tr>
                    <tr>
                        <td className="table-td">
                            {children2}
                        </td>
                    </tr>
                    {children3 && (
                        <tr>
                            <td className="table-td">
                                {children3}
                            </td>
                        </tr>
                    )}
                    {children4 && (
                        <tr>
                            <td className="table-td">
                                {children4}
                            </td>
                        </tr>
                    )}
                </>
            )}
        </>
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- RATING GLOBAL BUTTON -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const RatingGlobalButton = ({
    repositoryItemType,
    repositoryItems,
    linkItems,
    setRepositoryItems,
} : {
    repositoryItemType: ERepositoryItemType,
    repositoryItems: IRepositoryItem[],
    linkItems?: IRepositoryItem[],
    setRepositoryItems: (repositoryItems: IRepositoryItems) => void,
}) => {

    const onChangeMastery = (value: EMastery) => {
        setRepositoryItems({
            [getRepositoryItemTypeKey(repositoryItemType)]: repositoryItems.map(i => ({
                ...i,
                mastery: value,
            }))
        });
        if (linkItems && linkItems.length > 0) {
            setRepositoryItems({
                linkItems: linkItems.map(i => ({
                    ...i,
                    mastery: value,
                }))
            });
        }
    };

    return (
        <ButtonGroup>
            <Button
                onClick={() => onChangeMastery(EMastery.NOT_VALIDATED)}
                color="error"
                variant="text"
                style={{ padding: 11 }}
            >
                <MasteryIcon
                    mastery={EMastery.NOT_VALIDATED}
                />
            </Button>
            <Button
                onClick={() => onChangeMastery(EMastery.VALIDATED)}
                color="success"
                variant="text"
                style={{ padding: 11 }}
            >
                <MasteryIcon
                    mastery={EMastery.VALIDATED}
                />
            </Button>
            <Button
                onClick={() => onChangeMastery(EMastery.NOT_OBSERVABLE)}
                variant="text"
                style={{ padding: 11 }}
            >
                <MasteryIcon
                    mastery={EMastery.NOT_OBSERVABLE}
                />
            </Button>
        </ButtonGroup>
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- RATING BUTTON ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const RatingButton = ({
    itemId,
    mastery,
    repositoryItemType,
    repositoryItems,
    setRepositoryItems,
} : {
    itemId: string,
    mastery: EMastery | undefined,
    repositoryItemType: ERepositoryItemType,
    repositoryItems: IRepositoryItem[],
    setRepositoryItems: (repositoryItems: IRepositoryItems) => void,
}) => {

    const theme = createTheme({
        components: {
            MuiToggleButton: {
                styleOverrides: {
                    root: ({ ownerState }) => ({
                        ...(ownerState.color === 'success' && {
                            '&.Mui-selected': {
                                backgroundColor: '#4caf50',
                            },
                        }),
                        ...(ownerState.color === 'error' && {
                            '&.Mui-selected': {
                                backgroundColor: '#ef5350',
                            },
                        }),
                        ...(ownerState.color === 'primary' && {
                            '&.Mui-selected': {
                                backgroundColor: '#03a9f4',
                            },
                        }),
                    }),
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: ({ ownerState }) => ({
                        ...(ownerState.color === 'success' && mastery === EMastery.VALIDATED) && {
                            color: 'white',
                        },
                        ...(ownerState.color === 'error' && mastery === EMastery.NOT_VALIDATED) && {
                            color: 'white',
                        },
                        ...(ownerState.color === 'primary' && mastery === EMastery.NOT_OBSERVABLE) && {
                            color: 'white',
                        },
                    }),
                },
            },
        },
    });

    const onChangeMastery = (value: any) => {
        let updatedItems = [...repositoryItems];
        let index = updatedItems.findIndex(i => i.id === itemId);

        if (index !== -1) {
            updatedItems[index].mastery = value;
        }
        setRepositoryItems({ [getRepositoryItemTypeKey(repositoryItemType)]: updatedItems });
    };

    return (
        <ThemeProvider theme={theme}>
            <ToggleButtonGroup
                value={mastery}
                onChange={(e, value) => value ? onChangeMastery(value) : undefined}
                exclusive
            >
                <ToggleButton
                    value={EMastery.NOT_VALIDATED}
                    color="error"
                >
                    <MasteryIcon
                        mastery={EMastery.NOT_VALIDATED}
                    />
                </ToggleButton>
                <ToggleButton
                    value={EMastery.VALIDATED}
                    color="success"
                >
                    <MasteryIcon
                        mastery={EMastery.VALIDATED}
                    />
                </ToggleButton>
                <ToggleButton
                    value={EMastery.NOT_OBSERVABLE}
                    color="primary"
                >
                    <MasteryIcon
                        mastery={EMastery.NOT_OBSERVABLE}
                    />
                </ToggleButton>
            </ToggleButtonGroup>
        </ThemeProvider>
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- RATING SELF MASTERY BUTTON --------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const RatingSelfMasteryButton = ({
    itemId,
    selfMastery,
    repositoryItemType,
    repositoryItems,
    setRepositoryItems,
} : {
    itemId: string,
    selfMastery: ESelfMastery | undefined,
    repositoryItemType: ERepositoryItemType,
    repositoryItems: IRepositoryItem[],
    setRepositoryItems: (repositoryItems: IRepositoryItems) => void,
}) => {

    const onChangeSelfMastery = (value: any) => {
        let updatedItems = [...repositoryItems];
        let index = updatedItems.findIndex(i => i.id === itemId);

        if (index !== -1) {
            updatedItems[index].selfMastery = value;
        }
        setRepositoryItems({ [getRepositoryItemTypeKey(repositoryItemType)]: updatedItems });
    };

    return (
        <ToggleSelfMastery
            value={selfMastery}
            onChange={value => onChangeSelfMastery(value)}
        />
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SELF PLACEMENT RESULT ICON -------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getSelfResultIcon = (
    modelId: string,
    itemType: ERepositoryItemType,
    selfResults: ISelfPlacementResult[] | ISelfEvaluationResult[],
) => {

    let selfResult = selfResults.find(i => i.itemId === modelId && i.itemType === itemType);

    switch (selfResult?.selfMastery) {
        case ESelfMastery.KNOW:
            return <SelfMasteryIcon selfMastery={ESelfMastery.KNOW} />

        case ESelfMastery.KNOW_PARTIALLY:
            return <SelfMasteryIcon selfMastery={ESelfMastery.KNOW_PARTIALLY} />

        case ESelfMastery.DO_NOT_KNOW:
            return <SelfMasteryIcon selfMastery={ESelfMastery.DO_NOT_KNOW} />

        case ESelfMastery.DO_NOT_UNDERSTAND:
            return <SelfMasteryIcon selfMastery={ESelfMastery.DO_NOT_UNDERSTAND} />

        default:
            return <></>;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- SELF RESULT INFOS ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export function SelfResultInfos({ title, onClose } : { title: string, onClose: VoidFunction }) {
    
    const { t } = useTranslation();

    return (
        <Dialog
            open
            fullWidth
            onClose={onClose}
        >
            <DialogContent>
                <div className="dialog-title">
                    {title}
                </div>
                <div
                    className="subtitle"
                    style={{ marginBottom: 24 }}
                >
                    {t('self_results_description')}
                </div>
                <Title>
                    {t('legend')}
                </Title>
                <SelfMasteryLegend
                    containerProps={{ marginTop: 8 }}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
