import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// MUI
import { Avatar, IconButton, Tooltip } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

// Services
import { getStoredUser } from 'services/storage';
import { useMediaQueries } from 'services/hooks';
import { API_URL } from 'services/apollo-client';

// Assets
import ZeroBarrierWhiteLogo from 'assets/images/zerobarrier-white.png';

// Components
import SideMenu from '../side-menu';

// CSS
import styles from './AppBar.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- APP BAR --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const AppBar = () => {

    const { t } = useTranslation();
    const { mediaSM, mediaLG } = useMediaQueries();
    const [openMenu, setOpenMenu] = useState(false);
    const [photoUrl, setPhotoUrl] = useState(getStoredUser()?.photoUrl);

    useEffect(() => {

        const onUpdateAvatar = () => {
            setPhotoUrl(getStoredUser()?.photoUrl);
        };
        document.addEventListener('updateAvatar', onUpdateAvatar);

        return () => {
            document.removeEventListener('updateAvatar', onUpdateAvatar);
        };
    }, []);

    return (
        <>
            <div className={styles.appBar}>
                <div className={styles.leftContainer}>
                    {!mediaLG && (
                        <IconButton
                            onClick={() => setOpenMenu(true)}
                            size="small"
                            style={{ marginRight: mediaSM ? 24 : 12 }}
                        >
                            <MenuIcon style={{ color: 'white' }} />
                        </IconButton>
                    )}
                    <Link to="/app">
                        <img
                            alt="Logo de ZERO BARRIER"
                            src={ZeroBarrierWhiteLogo}
                            width={222}
                            height={30}
                            loading="eager"
                            className={styles.logo}
                        />
                    </Link>
                </div>
                <Tooltip
                    title={t('profile')}
                    placement="bottom"
                >
                    <IconButton
                        component={Link}
                        to="/app/profile"
                        size="small"
                    >
                        <Avatar
                            className={styles.avatar}
                            src={photoUrl ? (API_URL + photoUrl) : undefined}
                        />
                    </IconButton>
                </Tooltip>
            </div>
            {/* -------------------- MENU -------------------- */}
            <SideMenu
                open={openMenu}
                onClose={() => setOpenMenu(false)}
            />
        </>
    );
};

export default AppBar;
