import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Apollo
import { useQuery } from '@apollo/client';
import { WORK_SITUATION } from './gql-work-situation';

// Services
import { IBreadcrumbItem, IWorkSituation } from 'services/interfaces';
import { getBreadcrumbItems } from 'services/utils';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import WorkSituationCard from './01-work-situation-card';
import WorkSituationDetails from './02-work-situation-details';
import WorkSituationUsers from './03-work-situation-users';
import WorkSituationRepositoryItems from './04-work-situation-repository-items';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- WORK SITUATION ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function WorkSituation({
    source,
    defaultBreadcrumbItems,
    setBreadcrumbItems,
} : {
    source: 'USER' | 'COMPANY' | 'ROADMAP',
    defaultBreadcrumbItems: IBreadcrumbItem[],
    setBreadcrumbItems: (breadcrumbItems: IBreadcrumbItem[]) => void,
}) {

    const { id, workSituationId, roadmapId } = useParams();
    const { t } = useTranslation();

    const { loading, error, data, refetch } = useQuery<{ workSituation: IWorkSituation }>(WORK_SITUATION, {
        variables: { id: workSituationId },
    });

    useEffect(() => {
        if (data?.workSituation) {
            const { items } = getBreadcrumbItems({mainId: id, workSituation: data.workSituation, source, items: defaultBreadcrumbItems, roadmapId});
            
            setBreadcrumbItems(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (loading) return <Spinner />;
    if (error) return <Error />;
    if ((data || error) && !data?.workSituation && !error) return <Error error={`${t('work_situation')} ${t('not_found').toLowerCase()}`} />

    return (
        <>
            {/* -------------------- 01 CARD -------------------- */}
            <WorkSituationCard
                workSituation={data?.workSituation}
                source={source}
            />
            {/* -------------------- 02 DETAILS -------------------- */}
            <WorkSituationDetails
                workSituation={data?.workSituation}
            />
            {/* -------------------- 03 USERS -------------------- */}
            <WorkSituationUsers
                workSituation={data?.workSituation}
                source={source}
                refetch={refetch}
            />
            {/* -------------------- 04 REPOSITORY ITEMS -------------------- */}
            <WorkSituationRepositoryItems
                workSituation={data?.workSituation}
            />
        </>
    );
}
