import { gql } from '@apollo/client';

export const CUSTOM_REPOSITORY = gql`
    query CustomRepository($id: ID!) {
        customRepository(id: $id) {
            id
            name
            repositoryLinks {
                id
                linkItems {
                    id
                    linkable_id
                    linkableType
                    atypicalItem {
                        id
                        name
                        parentItem {
                            id
                            name
                        }
                    }
                }
                atypicalRepository {
                    id
                    name
                    atypicalLevels {
                        id
                        isBlock
                        isLinkable
                        isTrainable
                        isEvaluable
                        isCertifiable
                    }
                }
            }
            activityRepository {
                id
                activities {
                    id
                    name
                    position
                    tasks {
                        id
                        name
                        position
                        activity {
                            id
                            position
                        }
                        observableElements {
                            id
                            name
                            position
                        }
                        successIndicators {
                            id
                            name
                            position
                        }
                    }
                }
            }
            customBlocks {
                id
                position
                skillBlock {
                    id
                    name
                }
                missingSkills {
                    id
                    name
                    position
                    descriptors {
                        id
                        name
                        descriptorType
                    }
                }
                customSkills {
                    id
                    customBlock {
                        id
                        position
                    }
                    skill {
                        id
                        name
                        position
                    }
                    descriptors {
                        id
                        name
                        descriptorType
                    }
                    missingDescriptors {
                        id
                        name
                        descriptorType
                    }
                    linkItems {
                        id
                        atypicalItem {
                            id
                            name
                            parentItem {
                                id
                                name
                            }
                            atypicalRepository {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`
