import React from 'react';

// Services
import { getWorkSituationTitle } from 'services/utils';

// Components
import Title from 'components/title';

// Views
import WorkSituationDetails from './01-details';
import WorkSituationRepositoryItems from './02-repository-items';
import WorkSituationLearnerResults from './05-learner-results';
import ValidateWorkSituation from './06-validate';
import { IState } from '../create-work-situation-from-roadmap';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- WORK SITUATION FORM ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const WorkSituationForm = ({
    state,
    setState,
    onSubmit,
    loading,
} : {
    state: IState,
    setState: React.Dispatch<React.SetStateAction<IState>>,
    onSubmit: () => void,
    loading: boolean,
}) => {
    return (
        <>
            {/* -------------------- FORM TITLE -------------------- */}
            <div className="container-padding">
                <Title style={{ fontSize: 18 }}>
                    {getWorkSituationTitle(state.workSituationType, state.actionType)}
                </Title>
            </div>
            {/* -------------------- 01 DETAILS -------------------- */}
            <WorkSituationDetails
                state={state}
                setState={setState}
            />
            {/* -------------------- 02 REPOSITORY ITEMS -------------------- */}
            <WorkSituationRepositoryItems
                state={state}
                setState={setState}
            />
            {(
                state.customRepository &&
                (
                    state.descriptors.length > 0
                    || state.tasks.length > 0
                    || state.atypicalItems.length > 0
                    || state.linkItems.length > 0
                )
            ) && (
                <>
                    {/* -------------------- 05 LEARNER RESULTS -------------------- */}
                    {state.learners.length > 0 && (
                        <WorkSituationLearnerResults
                            state={state}
                            setState={setState}
                        />
                    )}
                    {/* -------------------- 06 VALIDATE -------------------- */}
                    <ValidateWorkSituation
                        state={state}
                        onSubmit={onSubmit}
                        loading={loading}
                    />
                </>
            )}
        </>
    );
};

export default WorkSituationForm;
