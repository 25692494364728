
// Services
import { useBreadcrumbFromUser } from 'views/authenticated/users/user';
import { useBreadcrumbFromCompany } from 'views/authenticated/companies/company';

// Views
import ReflectionPhase from '../reflection-phase';
import CreateReflectionPhase from '../create-reflection-phase';
import UpdateReflectionPhase from '../update-reflection-phase';
import SignReflectionPhase from '../sign-reflection-phase';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------- SIGN REFLECTION PHASE FROM  ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const ActionReflectionPhaseFrom = ({
    source,
    action,
} : {
    source: "USER" | "COMPANY" | "ROADMAP",
    action: "VIEW" | "CREATE" | "UPDATE" | "SIGN",
}) => {

    const { defaultBreadcrumbItems: defaultUserBreadcrumbItems, setBreadcrumbItems: setUserBreadcrumbItems } = useBreadcrumbFromUser();

    const { defaultBreadcrumbItems: defaultCompanyBreadcrumbItems, setBreadcrumbItems: setCompanyBreadcrumbItems } = useBreadcrumbFromCompany();

    const defaultBreadcrumbItems = source === 'COMPANY' ? defaultCompanyBreadcrumbItems : defaultUserBreadcrumbItems;

    const setBreadcrumbItems = source === 'COMPANY' ? setCompanyBreadcrumbItems : setUserBreadcrumbItems;

    return (
        <>
            {action === 'VIEW' && (
                <ReflectionPhase
                    source={source}
                    defaultBreadcrumbItems={defaultBreadcrumbItems}
                    setBreadcrumbItems={setBreadcrumbItems}
                />
            )}
            {action === 'CREATE' && (
                <CreateReflectionPhase
                    source={source}
                    defaultBreadcrumbItems={defaultBreadcrumbItems}
                    setBreadcrumbItems={setBreadcrumbItems}
                />
            )}
            {action === 'UPDATE' && (
                <UpdateReflectionPhase
                    source={source}
                    defaultBreadcrumbItems={defaultBreadcrumbItems}
                    setBreadcrumbItems={setBreadcrumbItems}
                />
            )}
            {action === 'SIGN' && (
                <SignReflectionPhase
                    source={source}
                    defaultBreadcrumbItems={defaultBreadcrumbItems}
                    setBreadcrumbItems={setBreadcrumbItems}
                />
            )}
        </>
    );
};

export default ActionReflectionPhaseFrom;
