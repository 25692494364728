import { useTranslation } from 'react-i18next';

// MUI
import { CircularProgress, Grid, Box } from '@mui/material';

// Services
import { ISelectOption } from 'services/interfaces';
import { EStepType } from 'services/enums';
import { yellowMain } from 'services/constants';

// Components
import PelletCard from 'components/pellet-card';

// Selects
import SelectReflexivityAxis from 'selects/select-reflexivity-axis';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- 01 REFLEXIVITY AXIS --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IReflexivityAxisProps {
    loading: boolean;
    position: number;
    stepType?: EStepType;
    reflexivityAxis: ISelectOption | null;
    setReflexivityAxis: (reflexivityAxis: ISelectOption | null) => void;
}

export default function ReflexivityAxis(props: IReflexivityAxisProps) {

    const { t } = useTranslation();

    return (
        <PelletCard
            paddingTop={0}
            pelletColor={yellowMain}
            pelletContent={props.position}
            summary={(
                <Grid item xs>
                    {t('select_reflexivity_axis')} <em>({t('optional')})</em>
                </Grid>
            )}
        >
            <Grid
                container
                alignItems="center"
                spacing={1}
            >
                <Grid item xs md={6} xl={4}>
                    <SelectReflexivityAxis
                        value={props.reflexivityAxis}
                        onChange={value => (value !== props.reflexivityAxis) ? props.setReflexivityAxis(value) : undefined}
                        stepType={props.stepType}
                        isClearable
                    />
                </Grid>
                {props.loading && (
                    <Grid item xs="auto">
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress size={24} color="inherit" />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </PelletCard>
    );
}
