import { gql } from '@apollo/client';

export const WORK_SITUATION = gql`
    query WorkSituation($id: ID!) {
        workSituation(id: $id) {
            id
            workSituationType
            trainingDate
            startMorning
            endMorning
            startAfternoon
            endAfternoon
            comment
            placeType
            placeDetails
            signOffSheetUrl
            isSigned
            signatureDisabled
            preparatorySequenceModalities
            company {
                id
                name
                postalCode
                city
            }
            stepModel {
                id
                name
                stepTypes
            }
            customRepository {
                id
                name
            }
            descriptors {
                id
                name
            }
            tasks {
                id
                name
                position
            }
            atypicalItems {
                id
                atypicalRepository {
                    id
                }
            }
            linkItems {
                id
            }
            trainerWorkSituations {
                id
                weather
                signatureUrl
                trainer {
                    id
                    fullName
                }
            }
            learnerWorkSituations {
                id
                result
                personalResult
                weather
                signatureUrl
                learner {
                    id
                    fullName
                }
                roadmap {
                    id
                    name
                    startDate
                    endDate
                    company {
                        id
                        name
                    }
                    learner {
                        id
                    }
                    customRepository {
                        id
                        name
                        activityRepository {
                            id
                        }
                    }
                }
            }
        }
    }
`

export const UPDATE_WORK_SITUATION = gql`
    mutation UpdateWorkSituation(
        $id: ID!
        $workSituationType: WorkSituationType
        $trainingDate: Date!
        $startMorning: String
        $endMorning: String
        $startAfternoon: String
        $endAfternoon: String
        $comment: String
        $placeType: PlaceType!
        $placeDetails: String
        $descriptors: SyncDescriptorsBelongsToMany
        $atypicalItems: SyncAtypicalItemsBelongsToMany
        $tasks: SyncTasksBelongsToMany
        $learnerWorkSituations: UpdateLearnerWorkSituationsHasMany!
        $trainerWorkSituations: CreateTrainerWorkSituationsHasMany!
        $customRepository: ConnectCustomRepositoryBelongsTo!
        $company: ConnectCompanyBelongsTo!
        $stepModel: ConnectStepModelBelongsTo
        $signatureDisabled: Boolean
        $preparatorySequenceModalities: [PreparatorySequenceModality]
        $linkItems: SyncLinkItemsBelongsToMany
    ) {
        updateWorkSituation(input: {
            id: $id
            attributes: {
                workSituationType: $workSituationType
                trainingDate: $trainingDate
                startMorning: $startMorning
                endMorning: $endMorning
                startAfternoon: $startAfternoon
                endAfternoon: $endAfternoon
                comment: $comment
                placeType: $placeType
                placeDetails: $placeDetails
                descriptors: $descriptors
                atypicalItems: $atypicalItems
                tasks: $tasks
                learnerWorkSituations: $learnerWorkSituations
                trainerWorkSituations: $trainerWorkSituations
                customRepository: $customRepository
                company: $company
                stepModel: $stepModel
                signatureDisabled: $signatureDisabled
                preparatorySequenceModalities: $preparatorySequenceModalities
                linkItems: $linkItems
            }
        }) {
            id
        }
    }
`
