import React from 'react';
import { useTranslation } from 'react-i18next';

// Dayjs
import dayjs from 'dayjs';

// MUI
import { Grid } from '@mui/material';

// Services
import { useMediaQueries } from 'services/hooks';
import { yellowMain } from 'services/constants';
import { compareTimes } from 'services/utils';

// Components
import DatePicker from 'components/date-picker';
import Title from 'components/title';
import TimePicker from 'components/time-picker';
import PelletCard from 'components/pellet-card';
import MobileTextField from 'components/mobile-text-field';

// Selects
import SelectOrCreateUser from 'selects/select-or-create-user';

// Views
import { IState } from '../../create-reflection-phase';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- 01 REFLECTION PHASE DETAILS ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ReflectionPhaseDetails({
    state,
    setState,
} : {
    state: IState,
    setState: React.Dispatch<React.SetStateAction<IState>>,
}) {

    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    return (
        <PelletCard
            paddingTop={0}
            pelletColor={yellowMain}
            pelletContent={1}
            summary={(
                <Grid item xs>
                    {t('reflection_phase_details')}
                </Grid>
            )}
        >
            <Grid
                container
                spacing={3}
            >
                <Grid item xs={12} sm={6} xl={4}>
                    {/* -------------------- TRAINING DATE -------------------- */}
                    <Title required>
                        {t('training_date')}
                    </Title>
                    <DatePicker
                        value={state.trainingDate}
                        onChange={trainingDate => setState(prevState => ({ ...prevState, trainingDate }))}
                        minDate={state.learnerWorkSituationDate ? dayjs(state.learnerWorkSituationDate) : undefined}
                    />
                    {/* -------------------- START & END TIMES -------------------- */}
                    <Grid
                        container
                        alignItems="center"
                        marginTop={3}
                    >
                        <Grid
                            item xs={6}
                            style={{ paddingRight: 3 }}
                        >
                            <Title required>
                                {t('start_time')}
                            </Title>
                            <TimePicker
                                value={state.startTime}
                                onChange={startTime => setState(prevState => ({ ...prevState, startTime }))}
                            />
                        </Grid>
                        <Grid
                            item xs={6}
                            style={{ paddingLeft: 3 }}
                        >
                            <Title required>
                                {t('end_time')}
                            </Title>
                            <TimePicker
                                value={state.endTime}
                                onChange={endTime => setState(prevState => ({ ...prevState, endTime }))}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: compareTimes(state.startTime, state.endTime, '>'),
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- TRAINERS -------------------- */}
                    <SelectOrCreateUser
                        value={state.trainers}
                        onChange={trainers => setState(prevState => ({ ...prevState, trainers }))}
                        label={t('trainers')}
                        color="yellow"
                        dialogTitle={t('add_trainer')}
                        roleIds={['1', '2', '3', '4']}
                        containerProps={{ marginTop: 24 }}
                        isMulti={true}
                    />
                </Grid>
                {state.reflexivityAxis == null && (
                    <Grid item xs={12} sm={6} xl={4}>
                        {/* -------------------- RESULT -------------------- */}
                        <Grid>
                            <Title>
                                {t('result.2')}
                            </Title>
                            <MobileTextField
                                name="result"
                                value={state.result}
                                onChange={e => setState(prevState => ({ ...prevState, result: e.target.value }))}
                                placeholder={t('result.2')}
                                multiline
                                rows={mediaMD ? 8 : 3}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </PelletCard>
    );
}
