import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

// MUI
import {
    Event as PlannedIcon, Remove as NoneIcon, DynamicFeed as ContainIcon, Rule as PartiallyIcon,
    Done as ValidatedIcon, Star as CertifiedIcon, Build as TrainingIcon,
} from '@mui/icons-material';

// Services
import { EResultType } from 'services/enums';

// Assets
import { ReactComponent as TrainingBadge } from 'assets/images/result-types/training-badge.svg';
import { ReactComponent as ValidatedBadge } from 'assets/images/result-types/validated-badge.svg';
import { ReactComponent as CertificatedBadge } from 'assets/images/result-types/certified-badge.svg';

// CSS
import styles from './ResultType.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- RESULT TYPE ICON ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

const ResultTypeIcon = ({ resultType, enabledNone } : { resultType: EResultType | undefined, enabledNone?: boolean }) => {

    switch (resultType) {

        case EResultType.TRAINING:
            return <TrainingIcon htmlColor="black" />;

        case EResultType.VALIDATED:
            return <ValidatedIcon htmlColor="black" />;

        case EResultType.CERTIFIED:
            return <CertifiedIcon htmlColor="black" />;

        case EResultType.CONTAINS:
            return <ContainIcon htmlColor="black" />;

        case EResultType.PLANNED:
            return <PlannedIcon htmlColor="black" />;

        case EResultType.PARTIALLY:
            return <PartiallyIcon htmlColor="black" />;

        case EResultType.NONE:
            return enabledNone ? <NoneIcon htmlColor="black" /> : <></>;

        default:
            return <></>;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- RESULT TYPE BADGE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const ResultTypeBadge = ({ resultType } : { resultType: EResultType | undefined }) => {

    switch (resultType) {
        
        case EResultType.TRAINING:
            return <TrainingBadge width={46} height={46} />;

        case EResultType.VALIDATED:
            return <ValidatedBadge width={46} height={46} />;

        case EResultType.CERTIFIED:
            return <CertificatedBadge width={46} height={46} />;

        default:
            return <></>;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- RESULT TYPE LEGEND ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const ResultTypeLegend = () => {
    
    const { t } = useTranslation();

    return (
        <div className={styles.legendGrid}>
            {/* -------------------- TRAINING -------------------- */}
            <div>
                <ResultTypeBadge resultType={EResultType.TRAINING} />
                <span>
                    {t('training_items')}
                </span>
            </div>
            {/* -------------------- VALIDATE -------------------- */}
            <div>
                <ResultTypeBadge resultType={EResultType.VALIDATED} />
                <span>
                    {t('validated_items')}
                </span>
            </div>
            {/* -------------------- CERTIFICATED -------------------- */}
            <div>
                <ResultTypeBadge resultType={EResultType.CERTIFIED} />
                <span>
                    {t('certified_items')}
                </span>
            </div>
            {/* -------------------- CONTAINS -------------------- */}
            <div>
                <div>
                    <ResultTypeIcon resultType={EResultType.CONTAINS} />
                </div>
                <span>
                    {t('contains_items')}
                </span>
            </div>
            {/* -------------------- PLANNED -------------------- */}
            <div>
                <div>
                    <ResultTypeIcon resultType={EResultType.PLANNED} />
                </div>
                <span>
                    {t('planned_items')}
                </span>
            </div>
            {/* -------------------- PARTIALLY -------------------- */}
            <div>
                <div>
                    <ResultTypeIcon resultType={EResultType.PARTIALLY} />
                </div>
                <span>
                    {t('partially_validated_items')}
                </span>
            </div>
        </div>
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- GET RESULT TYPE LABEL --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getResultTypeLabel = (resultType: EResultType | undefined) => {

    switch (resultType) {

        case EResultType.CONTAINS:
            return i18next.t('contains_items');

        case EResultType.PLANNED:
            return i18next.t('planned_item');

        case EResultType.TRAINING:
            return i18next.t('training_item');

        case EResultType.PARTIALLY:
            return i18next.t('partially_validated_item');

        case EResultType.VALIDATED:
            return i18next.t('validated_item');

        case EResultType.CERTIFIED:
            return i18next.t('certified_item');

        default:
            return "";
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- IS RESULT TYPE ICON --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const isResultTypeIcon = (resultType: EResultType | undefined) => {
    return resultType ? [EResultType.CONTAINS, EResultType.PLANNED, EResultType.PARTIALLY].includes(resultType) : false;
};

export default ResultTypeIcon;
