import { useTranslation } from 'react-i18next';

// Services
import { EWeather } from 'services/enums';

// Assets
import { ReactComponent as StormyIcon } from 'assets/images/weather/stormy.svg';
import { ReactComponent as RainyIcon } from 'assets/images/weather/rainy.svg';
import { ReactComponent as CloudyIcon } from 'assets/images/weather/cloudy.svg';
import { ReactComponent as SunnyIcon } from 'assets/images/weather/sunny.svg';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- WEATHER ICON --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function WeatherIcon({ weather } : { weather: EWeather | undefined }) {

    const { t } = useTranslation();

    switch (weather) {

        case EWeather.STORMY:
            return <StormyIcon width={64} height={64} className="vaM" />;

        case EWeather.RAINY:
            return <RainyIcon width={64} height={64} className="vaM" />;

        case EWeather.CLOUDY:
            return <CloudyIcon width={64} height={64} className="vaM" />;

        case EWeather.SUNNY:
            return <SunnyIcon width={64} height={64} className="vaM" />;

        default:
            return t('not_specified.2');
    }
}
