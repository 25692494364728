import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, ToggleButtonGroup, ToggleButton } from '@mui/material';

// Services
import { EGender } from 'services/enums';
import { isValidEmail, getLocales, getEmailHelperText } from 'services/utils';
import { ISelectOption } from 'services/interfaces';

// Components
import FieldsRequired from 'components/fields-required';
import DatePicker from 'components/date-picker';
import Title from 'components/title';
import Select from 'components/react-select';
import MobileTextField from 'components/mobile-text-field';
import ReactInternationalPhone, { isValidPhoneNumber } from 'components/react-international-phone';

// Selects
import SelectRole from 'selects/select-role';
import SelectCompany from 'selects/select-company';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- USER FORM --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const UserForm = ({
    state,
    setState,
    disabledCompany = false,
    fullFields = true,
} : {
    state: any,
    setState: React.Dispatch<React.SetStateAction<any>>,
    disabledCompany?: boolean,
    fullFields?: boolean,
}) => {

    const localeOptions = getLocales();
    const { t } = useTranslation();

    return (
        <>
            <FieldsRequired />
            {/* -------------------- LAST NAME -------------------- */}
            <Grid marginTop={3}>
                <Title required>
                    {t('last_name')}
                </Title>
                <MobileTextField
                    name="lastName"
                    value={state.lastName}
                    onChange={e => setState((prevState: any) => ({ ...prevState, lastName: e.target.value }))}
                    placeholder={t('last_name')}
                    autoComplete="family-name"
                />
            </Grid>
            {/* -------------------- FIRST NAME -------------------- */}
            <Grid marginTop={3}>
                <Title required>
                    {t('first_name')}
                </Title>
                <MobileTextField
                    name="firstName"
                    value={state.firstName}
                    onChange={e => setState((prevState: any) => ({ ...prevState, firstName: e.target.value }))}
                    placeholder={t('first_name')}
                    autoComplete="given-name"
                />
            </Grid>
            {/* -------------------- EMAIL -------------------- */}
            <Grid marginTop={3}>
                <Title required={!isValidPhoneNumber(state.phoneNumber)}>
                    {t('email')}
                </Title>
                <MobileTextField
                    name="email"
                    value={state.email}
                    onChange={e => setState((prevState: any) => ({ ...prevState, email: e.target.value }))}
                    placeholder={t('email')}
                    autoComplete="email"
                    error={(state.email !== '' && !isValidEmail(state.email))}
                    helperText={getEmailHelperText(state.email, state.phoneNumber)}
                />
            </Grid>
            {/* -------------------- PHONE NUMBER -------------------- */}
            <Grid marginTop={3}>
                <Title>
                    {t('mobile_phone')}
                </Title>
                <ReactInternationalPhone
                    value={state.phoneNumber}
                    onChange={(phoneNumber, isInvalid) => setState((prevState: any) => ({ ...prevState, phoneNumber, phoneNumberInvalid: isInvalid }))}
                    defaultPhoneNumberPrefix={state.phoneNumberPrefix}
                />
            </Grid>
            {/* -------------------- ROLE -------------------- */}
            {fullFields && (
                <Grid marginTop={3}>
                    <Title required>
                        {t('role')}
                    </Title>
                    <SelectRole
                        value={state.role}
                        onChange={(role: any) => setState((prevState: any) => ({ ...prevState, role }))}
                        onlyAccessible
                    />
                </Grid>
            )}
            {/* -------------------- COMPANY -------------------- */}
            {(
                fullFields
                && !disabledCompany
            ) && (
                <Grid marginTop={3}>
                    <Title>
                        {t('company')}
                    </Title>
                    <SelectCompany
                        value={state.company}
                        onChange={(company: ISelectOption | null) => setState((prevState: any) => ({ ...prevState, company }))}
                        filterIds={state.companies.map((c: ISelectOption) => c.value)}
                    />
                </Grid>
            )}
            {/* -------------------- COMPANIES -------------------- */}
            {(
                fullFields
                && state.role?.value
                && (state.role.value > 1 && state.role.value < 5)
            ) && (
                <Grid marginTop={3}>
                    <Title>
                        {t('accessible_companies')}
                    </Title>
                    <SelectCompany
                        value={state.companies}
                        onChange={(companies: ISelectOption[]) => setState((prevState: any) => ({ ...prevState, companies }))}
                        filterIds={state.company ? [state.company.value] : undefined}
                        isMulti
                    />
                </Grid>
            )}
            {/* -------------------- BIRTH DATE -------------------- */}
            <Grid marginTop={3}>
                <Title>
                    {t('birthdate')}
                </Title>
                <DatePicker
                    value={state.birthdate}
                    onChange={birthdate => setState((prevState: any) => ({ ...prevState, birthdate }))}
                />
            </Grid>
            {/* -------------------- GENDER -------------------- */}
            <Grid marginTop={3}>
                <Title>
                    {t('gender')}
                </Title>
                <ToggleButtonGroup
                    value={state.gender}
                    onChange={(e, gender) => gender ? setState((prevState: any) => ({ ...prevState, gender })) : undefined}
                    exclusive
                    fullWidth
                    size="small"
                    style={{ backgroundColor: 'white' }}
                >
                    {Object.keys(EGender).map(key => (
                        <ToggleButton key={key} value={key}>
                            {t(key.toLocaleLowerCase())}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Grid>
            {/* -------------------- LOCALE -------------------- */}
            {fullFields && (
                <Grid marginTop={3}>
                    <Title>
                        {t('language')}
                    </Title>
                    <Select
                        placeholder={t('language')}
                        value={state.locale}
                        onChange={(locale: ISelectOption) => setState((prevState: any) => ({ ...prevState, locale }))}
                        options={localeOptions}
                    />
                </Grid>
            )}
        </>
    );
};

export default UserForm;
