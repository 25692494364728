import React from 'react';

// MUI
import { Grid, Icon, ListItemButton, Tooltip, CircularProgress } from '@mui/material';

// Services
import { primaryMain } from 'services/constants';

// Components
import Title from '../title';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SUBHEADER ITEM ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SubheaderItem({
    children,
    tooltip,
    onClick,
    href,
    icon,
    title,
    loading = false,
} : {
    children: React.ReactNode,
    tooltip?: string,
    onClick?: VoidFunction,
    href?: string,
    icon?: string,
    title?: string,
    loading?: boolean,
}) {
    return (
        <Grid
            item xs={12} sm="auto"
            style={{ maxWidth: '100%' }}
        >
            <SubheaderItemTooltip title={tooltip}> 
                <SubheaderItemButton 
                    onClick={onClick}
                    href={href}
                >
                    <>
                        <Grid container alignItems="center">  
                            {icon && (
                                <Icon
                                    fontSize="small"
                                    color="primary"
                                    style={{ marginRight: 4 }}
                                >
                                    {icon}
                                </Icon>
                            )}
                            {title && (
                                <Title>
                                    {title}
                                </Title>
                            )}
                        </Grid>
                        <SubheaderItemLabel
                            button={!!(onClick || href)}
                            loading={loading}
                        >
                            {children}
                        </SubheaderItemLabel>
                    </>
                </SubheaderItemButton>
            </SubheaderItemTooltip>
        </Grid>
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- SUBHEADER ITEM TOOLTIP --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function SubheaderItemTooltip({
    children,
    title,
} : {
    children: React.ReactNode,
    title: string | undefined,
}) {
    return (
        <>
            {title ? (
                <Tooltip
                    title={title}
                    placement="bottom"
                >
                    <span>{children}</span>
                </Tooltip>
            ) : (
                children
            )}
        </>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- SUBHEADER ITEM BUTTON ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function SubheaderItemButton({
    children,
    onClick,
    href,
} : {
    children: React.ReactNode,
    onClick: VoidFunction | undefined,
    href: string | undefined,
}) {
    return (
        <>
            {(onClick || href) ? (
                <ListItemButton
                    onClick={onClick}
                    href={href || ''}
                    target={href ? '_blank' : undefined}
                    style={{ flexDirection: 'column', alignItems: 'start' }}
                >
                    {children}
                </ListItemButton>
            ) : (
                <Grid
                    paddingX={2}
                    paddingY={1}
                >
                    {children}
                </Grid>
            )}
        </>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- SUBHEADER ITEM LABEL ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function SubheaderItemLabel({
    children,
    button,
    loading,
} : {
    children: React.ReactNode,
    button: boolean,
    loading: boolean,
}) {
    return (
        <Grid
            position="relative"
            color={button ? primaryMain : undefined}
            fontWeight={button ? 500 : 400}
            fontSize={button ? 15 : 16}
        >
            {children}
            {loading &&
                <CircularProgress
                    size={20}
                    color="inherit"
                    style={{ position: 'absolute', right: -28, top: -2 }}
                />
            }
        </Grid>
    );
}
