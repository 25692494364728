import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Dialog, DialogContent, DialogActions, Button, Divider } from '@mui/material';

// Apollo
import { useMutation } from '@apollo/client';
import client from 'services/apollo-client';
import { LOGOUT } from './gql-logout';

// Services
import { clearStorage } from 'services/storage';

// Components
import Backdrop from 'components/backdrop';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- LOGOUT ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const Logout  = ({ onClose } : { onClose: VoidFunction }) => {
    
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [logout, { loading, error, data }] = useMutation(LOGOUT);

    const onLogout = () => {
        clearStorage();
        client.clearStore();
        navigate('/');
    };

    useEffect(() => {
        if (data?.logout) {
            enqueueSnackbar(data.logout, { variant: 'success' });
            onLogout();
        }
        if (error) {
            onLogout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <>
            <Dialog
                open
                onClose={loading ? undefined : onClose}
            >
                <DialogContent>
                    {t('logout_confirm')}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color="inherit"
                        disabled={loading}
                    >
                        {t('no')}
                    </Button>
                    <Button
                        onClick={() => logout()}
                        disabled={loading}
                    >
                        {t('yes')}
                    </Button>
                </DialogActions>
                {/* -------------------- BACKDROP -------------------- */}
                {loading && (
                    <Backdrop />
                )}
            </Dialog>
        </>
    );
};

export default Logout;
