import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { ICustomBlock, ICustomSkill, ISimpleResult } from 'services/interfaces';
import { magentaMain } from 'services/constants';

// Components
import { Block, BlockContainer, BlockIconButton, EmptyBlock } from 'components/block';
import ResultTypeIcon, { getResultTypeLabel, isResultTypeIcon, ResultTypeBadge } from 'components/result-type';

// Icons
import { SelectedRepositoryItemIcon } from 'icons';

// Repository Items
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../../../index';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SELECTABLE SKILLS ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SelectableSkills({
    customBlock,
    customSkillResults,
} : {
    customBlock: ICustomBlock,
    customSkillResults?: ISimpleResult[],
}) {

    const { t } = useTranslation();
    const [customSkills, setCustomSkills] = useState<ICustomSkill[]>([]);

    const {
        setRepositoryItems,
        customSkills: selectedSkills,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const onCheckCustomSkill = (customSkill: ICustomSkill) => {
        let index = selectedSkills.findIndex(i => i.id === customSkill.id);

        if (index === -1) {
            selectedSkills.push({ id: customSkill.id });
        }
        else {
            selectedSkills.splice(index, 1);
        }
        
        setRepositoryItems({ customSkills: selectedSkills });
    };

    useEffect(() => {
        if (customBlock.customSkills) {
            let sortedSkills = [...customBlock.customSkills];
            sortedSkills.sort((a, b) => a.skill.position - b.skill.position);
            setCustomSkills(sortedSkills);
        }
    }, [customBlock]);

    return (
        <Grid
            container
            spacing={3}
        >
            {(
                customSkills
                && customSkills.length > 0
            ) ? (
                customSkills.map(customSkill => {

                    let selected = selectedSkills.some(s => s.id === customSkill.id);
                    let resultType = customSkillResults?.find(r => r.simpleResultId === customSkill.id)?.resultType;

                    return (
                        <BlockContainer key={customSkill.id}>
                            <Block
                                onClick={() => onCheckCustomSkill(customSkill)}
                                selected={selected}
                                color="magenta"
                                badge={<ResultTypeBadge resultType={resultType} />}
                                icons={(
                                    <>
                                        {isResultTypeIcon(resultType) && (
                                            <BlockIconButton tooltip={getResultTypeLabel(resultType)}>
                                                <ResultTypeIcon resultType={resultType} />
                                            </BlockIconButton>
                                        )}
                                        {selected && (
                                            <BlockIconButton tooltip={t('selected_item')}>
                                                <SelectedRepositoryItemIcon />
                                            </BlockIconButton>
                                        )}
                                    </>
                                )}
                            >
                                {customBlock.position}.{customSkill.skill?.position} {customSkill.skill?.name}
                            </Block>
                        </BlockContainer>
                    );
                })
            ) : (
                <BlockContainer>
                    <EmptyBlock color={magentaMain}>
                        {t('none.2')}
                    </EmptyBlock>
                </BlockContainer>
            )}
        </Grid>
    );
}
