import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Fab } from '@mui/material';
import { People as UserIcon, Visibility as VisibilityIcon } from '@mui/icons-material';

// Services
import { IReflectionPhase } from 'services/interfaces';

// Components
import Title from 'components/title';
import WeatherIcon from 'icons/weather-icon';
import SignatureDialog from 'components/signature-dialog';
import PelletCard from 'components/pellet-card';

interface IState {
    openSignature: boolean;
    username: string;
    signatureUrl: string;
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- REFLECTION PHASE USERS ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ReflectionPhaseUsers({ reflectionPhase } : { reflectionPhase: IReflectionPhase | undefined }) {

    const { t } = useTranslation();
    const resultEnabled = reflectionPhase?.result !== '';
    const personalResultEnabled = reflectionPhase?.personalResult != null;

    const [state, setState] = useState<IState>({
        openSignature: false,
        username: '',
        signatureUrl: '',
    });

    const onOpenSignature = (name: string, url: string) => {
        setState(prevState => ({
            ...prevState,
            openSignature: true,
            username: name,
            signatureUrl: url,
        }));
    };

    return (
        <>
            <PelletCard
                displayBlock
                paddingTop={0}
                startIcon={<UserIcon color="secondary" />}
                summary={(
                    <Grid item xs>
                        {t('users')}
                    </Grid>
                )}
            >
                {/* -------------------- TRAINERS -------------------- */}
                <div style={{ overflow: 'auto' }}>
                    <table>
                        <thead>
                            <tr>
                                <td
                                    className="table-td"
                                    style={{ minWidth: 200 }}
                                >
                                    <Title>
                                        {t('trainers')}
                                    </Title>
                                </td>
                                <td
                                    className="table-td taC"
                                    style={{ minWidth: 140 }}
                                >
                                    <Title>
                                        {t('weather')}
                                    </Title>
                                </td>
                                {!reflectionPhase?.signOffSheetUrl && (
                                    <td
                                        className="table-td"
                                        style={{ minWidth: 200 }}
                                    >
                                        <Title>
                                            {t('signature')}
                                        </Title>
                                    </td>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {(
                                reflectionPhase?.reflectionPhaseTrainers
                                && reflectionPhase.reflectionPhaseTrainers.length > 0
                            ) && (
                                reflectionPhase.reflectionPhaseTrainers?.map(rpt => (
                                    <tr key={rpt.id}>
                                        <td className="table-td">
                                            {rpt.trainer?.fullName}
                                        </td>
                                        <td className="table-td taC">
                                            <WeatherIcon weather={rpt.weather} />
                                        </td>
                                        {!reflectionPhase?.signOffSheetUrl && (
                                            <td className="table-td">
                                                {rpt.signatureUrl ? (
                                                    <>
                                                        <Fab
                                                            onClick={() => onOpenSignature(rpt.trainer?.fullName, rpt.signatureUrl)}
                                                            color="secondary"
                                                        >
                                                            <VisibilityIcon />
                                                        </Fab>
                                                        <span style={{ marginLeft: 8 }}>
                                                            {t('signed')}
                                                        </span>
                                                    </>
                                                ) : (
                                                    t('waiting_signature')
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
                {/* -------------------- LEARNER -------------------- */}
                <div style={{ overflow: 'auto', marginTop: 24 }}>
                    <table>
                        <thead>
                            <tr>
                                <td
                                    className="table-td"
                                    style={{ minWidth: 200 }}
                                >
                                    <Title>
                                        {t('learner')}
                                    </Title>
                                </td>
                                {resultEnabled && (
                                    <td
                                        className="table-td"
                                        style={{ minWidth: 200 }}
                                    >
                                        <Title>
                                            {t('result.2')}
                                        </Title>
                                    </td>
                                )}
                                {personalResultEnabled && (
                                    <td
                                        className="table-td"
                                        style={{ minWidth: 200 }}
                                    >
                                        <Title>
                                            {t('personal_result')}
                                        </Title>
                                    </td>
                                )}
                                <td
                                    className="table-td taC"
                                    style={{ minWidth: 140 }}
                                >
                                    <Title>
                                        {t('weather')}
                                    </Title>
                                </td>
                                {!reflectionPhase?.signOffSheetUrl && (
                                    <td
                                        className="table-td"
                                        style={{ minWidth: 200 }}
                                    >
                                        <Title>
                                            {t('signature')}
                                        </Title>
                                    </td>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="table-td">
                                    {reflectionPhase?.learnerWorkSituation?.learner?.fullName}
                                </td>
                                {resultEnabled && (
                                    <td className="table-td">
                                        {reflectionPhase?.result}
                                    </td>
                                )}
                                {personalResultEnabled && (
                                    <td className="table-td">
                                        {reflectionPhase?.personalResult}
                                    </td>
                                )}
                                <td className="table-td taC">
                                    <WeatherIcon weather={reflectionPhase?.weather} />
                                </td>
                                {!reflectionPhase?.signOffSheetUrl && (
                                    <td className="table-td">
                                        {reflectionPhase?.learnerSignatureUrl ? (
                                            <>
                                                <Fab
                                                    onClick={() => onOpenSignature(reflectionPhase?.learnerWorkSituation?.learner?.fullName, reflectionPhase?.learnerSignatureUrl)}
                                                    color="secondary"
                                                >
                                                    <VisibilityIcon />
                                                </Fab>
                                                <span style={{ marginLeft: 8 }}>
                                                    {t('signed')}
                                                </span>
                                            </>
                                        ) : (
                                            t('waiting_signature')
                                        )}
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </PelletCard>
            {/* -------------------- SIGNATURE DIALOG -------------------- */}
            {state.openSignature && (
                <SignatureDialog
                    username={state.username}
                    signatureUrl={state.signatureUrl}
                    onClose={() => setState(prevState => ({ ...prevState, openSignature: false }))}
                />
            )}
        </>
    );
}
