import { gql } from '@apollo/client';

export const UPDATE_REFLECTION_PHASE = gql`
    mutation UpdateReflectionPhase(
        $id: ID!
        $trainingDate: Date!
        $startTime: String!
        $endTime: String!
        $result: String
        $reflectionPhaseTrainers: CreateReflectionPhaseTrainersHasMany
        $reflexivityAxis: ConnectOrDisconnectReflexivityAxisBelongsTo
        $reflexivityQuestions: SyncReflexivityQuestionsWithPivotBelongsToMany
    ) {
        updateReflectionPhase(input: {
            id: $id
            trainingDate: $trainingDate
            startTime: $startTime
            endTime: $endTime
            result: $result
            reflectionPhaseTrainers: $reflectionPhaseTrainers
            reflexivityAxis: $reflexivityAxis
            reflexivityQuestions: $reflexivityQuestions
        }) {
            id
        }
    }
`
