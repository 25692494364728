import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Dialog, DialogContent, DialogActions, Button, CircularProgress, Divider } from '@mui/material';

// Apollo
import { useMutation } from '@apollo/client';
import { REMOVE_FROM_COMPANY } from './gql-remove-user';

// Services
import { IUser } from 'services/interfaces';
import { getErrorExtensions } from 'services/utils';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- REMOVE USER FROM COMPANY ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const RemoveUserFromCompany = ({
    user,
    open,
    onClose,
    onRefetch,
} : {
    user: IUser | undefined,
    open: boolean,
    onClose: VoidFunction,
    onRefetch: VoidFunction,
}) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [removeUserFromCompany, { loading, error, data }] = useMutation(REMOVE_FROM_COMPANY);

    const onRemoveUser = () => {
        removeUserFromCompany({
            variables: {
                id: user?.id,
            },
        });
    };

    useEffect(() => {
        if (data?.removeFromCompany) {
            enqueueSnackbar(t('user_removed_successfully'), { variant: 'success' });
            onRefetch();
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <Dialog
            open={open}
            onClose={loading ? undefined : onClose}
        >
            <DialogContent>
                <div className="dialog-title">
                    {user?.fullName}
                </div>
                {t('remove_from_company_confirm')}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    disabled={loading}
                    variant="outlined"
                >
                    {t('no')}
                </Button>
                <Button
                    onClick={onRemoveUser}
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={16} color="inherit" /> : <></>}
                >
                    {t('yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RemoveUserFromCompany;
