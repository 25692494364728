import { Suspense, useEffect, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

// MUI
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Language as LocaleIcon } from '@mui/icons-material';

// Services
import { handleLocaleChange, getLocales } from 'services/utils';
import { useMediaQueries } from 'services/hooks';

// Components
import Spinner from 'components/spinner';

// CSS
import styles from './Unauthenticated.module.css';

// Assets
import ZeroBarrierWhiteLogo from 'assets/images/zerobarrier-white.png';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ UNAUTHENTICATED LAYOUT ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function UnauthenticatedLayout() {

    const locale = localStorage.getItem('locale');
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    useEffect(() => {
        if (locale) {
            handleLocaleChange(locale);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className={styles.container}>
            {mediaMD ? (
                <>
                    <div className={styles.paper}>
                        <div className={styles.paperContent}>
                            {/* -------------------- LOGO -------------------- */}
                            <img
                                alt="Logo de Zero Barrier"
                                src={ZeroBarrierWhiteLogo}
                                width={222}
                                height={30}
                                loading="eager"
                                className={styles.paperContentLogo}
                            />
                            {/* -------------------- TITLE -------------------- */}
                            <div className={styles.paperContentTitle}>
                                {t('login_title')}
                            </div>
                            {/* -------------------- FILL -------------------- */}
                            <div
                                className={styles.paperContentFillVoid}
                            />
                            {/* -------------------- LINK -------------------- */}
                            <div className={styles.paperContentLink}>
                                <a
                                    href="https://discovia.fr"
                                    target="_blank"
                                    rel="noreferrer"
                                    className={styles.paperContentLinkItem}
                                >
                                    Discovia
                                </a>
                                <a
                                    href="https://guide.discovia.fr?applicationId=1"
                                    target="_blank"
                                    rel="noreferrer"
                                    className={styles.paperContentLinkItem}
                                    style={{ marginLeft: 8 }}
                                >
                                    {t('user_guide')}
                                </a>
                            </div>
                        </div>
                        <div className={styles.children}>
                            <Locale />
                            {/* -------------------- CHILDREN -------------------- */}
                            <Suspense fallback={<Spinner />}>
                                <Outlet />
                            </Suspense>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.mobileHeader}>
                        {/* -------------------- LOGO -------------------- */}
                        <img
                            alt="Logo de Zero Barrier"
                            src={ZeroBarrierWhiteLogo}
                            width={148}
                            height={20}
                            loading="eager"
                        />
                        <Locale />
                    </div>
                    {/* -------------------- CHILDREN -------------------- */}
                    <div className={styles.mobileContent}>
                        <Suspense fallback={<Spinner />}>
                            <Outlet />
                        </Suspense>
                    </div>
                </>
            )}
            {/* -------------------- FOOTER -------------------- */}
            <div className={styles.footer}>
                <span>
                    Copyright © 2022-{new Date().getFullYear()} Discovia. {t('all_rights_reserved')}
                </span>
                <Link to="/legal-notice">
                    {t('legal_notice')}.
                </Link>
            </div>
        </div>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- LOCALE ------------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

function Locale() {

    const locales = getLocales().filter(l => !l.value.includes('-'));
    const { mediaMD } = useMediaQueries();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const onChangeLocale = (locale: string) => {
        handleLocaleChange(locale);
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={e => setAnchorEl(e.currentTarget)}
                className={styles.locale}
                sx={{ color: mediaMD ? undefined : 'white' }}
            >
                <LocaleIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {locales.map(locale => (
                    <MenuItem
                        key={locale.value}
                        onClick={() => onChangeLocale(locale.value)}
                        selected={i18next.language === locale.value}
                    >
                        {locale.notTranslated}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}