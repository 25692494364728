import { SvgIconProps } from '@mui/material';
import { Check as ValidIcon, Clear as NotValidIcon, VisibilityOff as NotObservableIcon } from '@mui/icons-material';

// Services
import { EMastery } from 'services/enums';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ MASTERY ICON -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function MasteryIcon({ mastery, iconProps } : { mastery: EMastery | undefined, iconProps?: SvgIconProps }) {

    switch (mastery) {

        case EMastery.VALIDATED:
            return <ValidIcon {...iconProps} color={iconProps?.color || 'success'} />;

        case EMastery.NOT_VALIDATED:
            return <NotValidIcon {...iconProps} color={iconProps?.color || 'error'} />;

        case EMastery.NOT_OBSERVABLE:
            return <NotObservableIcon {...iconProps} color={iconProps?.color || 'primary'} />;

        default:
            return <></>;
    }
}
