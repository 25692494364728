import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Apollo
import { useQuery } from '@apollo/client';
import { ATYPICAL_ITEMS_RELATED_TO_ACTIVITY } from 'views/authenticated/repositories/activity-repositories/activity-repository/activities/gql-activities';

// Services
import { IAtypicalRepository, IStepObject } from 'services/interfaces';
import { magentaMain, primaryMain } from 'services/constants';

// Components
import PelletCard from 'components/pellet-card';

// Repository Items
import RepositoryItemsTable from 'repository-items/repository-items-display/repository-items-table';

// Views
import { IState } from '../../create-reflection-phase';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- 02 SELECTED ITEMS ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SelectedItems({ state } : { state: IState }) {

    const { t } = useTranslation();

    const { data } = useQuery<{ atypicalItemsRelatedToActivity: IAtypicalRepository[] }>(ATYPICAL_ITEMS_RELATED_TO_ACTIVITY, {
        variables: { taskIds: state.tasks.map(task => task.id) },
    });

    return (
        <PelletCard
            paddingTop={0}
            pelletColor={state.tasks.length > 0 ? magentaMain : primaryMain}
            pelletContent={2}
            summary={(
                <Grid item xs>
                    {(state.descriptors.length > 0 || state.linkItems.length > 0) ? (
                        t('selected_descriptors')
                    ) : state.tasks.length > 0 ? (
                        t('selected_tasks')
                    ) : state.atypicalItems.length > 0 ? (
                        t('selected_atypical_items')
                    ) : (
                        t('undefined')
                    )}
                </Grid>
            )}
        >
            <RepositoryItemsTable
                stepObject={state as IStepObject}
                atypicalItemsRelatedToActivity={data?.atypicalItemsRelatedToActivity}
            />
        </PelletCard>
    );
}
