
// MUI
import { Grid, List, ListItem } from '@mui/material';

// Services
import { ITask } from 'services/interfaces';
import { primaryMain } from 'services/constants';
import { useTranslation } from 'react-i18next';

// Components
import Title from 'components/title';

/* * *
* CSS
*/

const blockProps : React.CSSProperties = {
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: primaryMain,
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- DASHED TASK ITEMS BLOCK ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function DashedTaskItemsBlock({ task } : { task: ITask }) {
    return (
        <Grid
            container spacing={3}
            style={{ padding: 24 }}
        >
            {(
                task.observableElements
                && task.observableElements.length > 0
            ) && (
                <TaskItemList
                    items={task.observableElements}
                    itemType='observable_elements'
                />
            )}
            {(
                task.successIndicators
                && task.successIndicators.length > 0
            ) && (
                <TaskItemList
                    items={task.successIndicators}
                    itemType='success_indicators'
                />
            )}
        </Grid>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ TASK ITEM LIST ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

interface ITaskItem {
    id: string;
    name: string;
}

function TaskItemList({
    items,
    itemType,
} : {
    items: ITaskItem[],
    itemType: 'observable_elements' | 'success_indicators',
}) {

    const { t } = useTranslation();
    
    return (
        <Grid item xs={12} xl={6}>
            <div style={blockProps}>
                <List>
                    <ListItem>
                        <Title style={{ color: primaryMain }}>
                            {t(itemType)}
                        </Title>
                    </ListItem>
                    {items.map(item => (
                        <ListItem
                            key={item.id}
                            style={{ alignItems: 'start' }}
                        >
                            <span>-</span>
                            <span style={{ marginLeft: 6 }}>
                                {item.name}
                            </span>
                        </ListItem>
                    ))}
                </List>
            </div>
        </Grid>
    );
}
