import { useContext } from 'react';

// Services
import { ICustomRepository, ICustomRepositoryResults } from 'services/interfaces';
import { ERepositoryType } from 'services/enums';

// Repository Items
import CustomBlocks from './custom-blocks';
import Activities from './activities';
import AtypicalLevels from './atypical-levels';
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../index';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ SELECT REPOSITORY ITEMS --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SelectRepositoryItems({
    position,
    customRepository,
    customRepositoryResults,
} : {
    position: number,
    customRepository: ICustomRepository | undefined,
    customRepositoryResults: ICustomRepositoryResults | undefined,
}) {

    const {
        repositoryType,
        atypicalRepository,
        atypicalRepositorySelectOption,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    return (
        <>
            {/* ---------------------- CUSTOM BLOCKS ---------------------- */}
            {repositoryType === ERepositoryType.CUSTOM && (
                <CustomBlocks
                    position={position}
                    customBlocks={customRepository?.customBlocks}
                    customRepositoryResults={customRepositoryResults}
                />
            )}
            {/* ---------------------- ACTIVITIES ---------------------- */}
            {repositoryType === ERepositoryType.ACTIVITY && (
                <Activities
                    position={position}
                    activityRepository={customRepository?.activityRepository}
                    customRepositoryResults={customRepositoryResults}
                />
            )}
            {/* ---------------------- ATYPICAL LEVELS ---------------------- */}
            {(
                repositoryType === ERepositoryType.ATYPICAL
                && atypicalRepositorySelectOption
                && atypicalRepository
            ) && (
                <AtypicalLevels position={position} />
            )}
        </>
    );
}
