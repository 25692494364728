import { useEffect } from 'react';

// MUI
import { Grid } from '@mui/material';

// CSS
import styles from './SignCard.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- SIGN CARD ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const SignCard = () => {

    useEffect(() => {
        
        let dialog = document.getElementById('dialog') as HTMLElement;
        let canvas = document.getElementById('signCanvas') as HTMLCanvasElement;

        if (canvas) {

            const resizeCanvas = () => {
                canvas.width = 0;
                canvas.height = 0;

                let container = document.getElementById('signCanvas-container') as HTMLElement;
                canvas.width = container.clientWidth - 8;
                canvas.height = container.clientHeight - 8;
            };

            resizeCanvas();
            let ctx = canvas.getContext('2d');
            let pos = { x: 0, y: 0 };

            const start = (event: any) => {
                dialog.addEventListener('mousemove', draw);
                dialog.addEventListener('touchmove', draw);
                setPosition(event);
            };

            const setPosition = (event: any) => {
                pos.x = (event.type === 'touchmove' ? event.touches[0].clientX : event.clientX) - canvas.offsetLeft;
                pos.y = (event.type === 'touchmove' ? event.touches[0].clientY : event.clientY) - canvas.offsetTop;
            };

            const stop = () => {
                dialog.removeEventListener('mousemove', draw);
                dialog.removeEventListener('touchmove', draw);
            };

            const draw = (e: any) => {

                if (ctx) {

                    ctx.beginPath();

                    ctx.lineWidth = 5;
                    ctx.lineCap = 'round';
                    ctx.strokeStyle = '#1E1E1E';

                    ctx.moveTo(pos.x, pos.y);
                    setPosition(e);
                    ctx.lineTo(pos.x, pos.y);

                    ctx.stroke();
                }
            };

            dialog.addEventListener('mousedown', start);
            dialog.addEventListener('mouseup', stop);
            dialog.addEventListener('touchstart', start);
            dialog.addEventListener('touchend', stop);
            window.addEventListener('resize', resizeCanvas);

            return () => {
                dialog.removeEventListener('mousedown', start);
                dialog.removeEventListener('mouseup', stop);
                dialog.removeEventListener('touchstart', start);
                dialog.removeEventListener('touchend', stop);
                window.removeEventListener('resize', resizeCanvas);
            };
        }
    }, []);

    return (
        <Grid
            id="signCanvas-container"
            flex={1}
        >
            <canvas
                id="signCanvas"
                className={styles.canvas}
            />
        </Grid>
    );
};

export default SignCard;
