import { gql } from '@apollo/client';

export const SELF_EVALUATION = gql`
    query SelfEvaluation($id: ID!) {
        selfEvaluation(id: $id) {
            id
            evaluationDate
            result
            proofItems {
                id
                name
                fileUrl
                fileSize
                externalLink
                mastery
                comment
                createdAt
                updatedAt
                creator {
                    id
                    fullName
                }
                lastModifier {
                    id
                    fullName
                }
            }
            company {
                id
                name
                postalCode
                city
                footer
            }
            learner {
                id
                fullName
            }
            stepModel {
                id
                name
                stepTypes
            }
            roadmap {
                id
                name
                learner {
                    id
                }
                company {
                    id
                }
            }
            customRepository {
                id
                name
            }
            descriptors {
                id
                name
                descriptorType
                skill {
                    id
                    name
                }
                pivot {
                    selfMastery
                }
            }
            tasks {
                id
                name
                position
                pivot {
                    selfMastery
                }
            }
            atypicalItems {
                id
                name
                parentItem {
                    id
                    name
                }
                atypicalRepository {
                    id
                    name
                }
                pivot {
                    selfMastery
                }
            }
            linkItems {
                id
                skillName
                atypicalItem {
                    id
                    name
                    parentItem {
                        id
                        name
                    }
                    atypicalRepository {
                        id
                        name
                    }
                }
                pivot {
                    selfMastery
                }
            }
        }
    }
`

export const SELF_EVALUATION_RESULTS = gql`
    query SelfEvaluationResults(
        $roadmapId: ID!
        $atypicalRepositoryId: ID
    ) {
        selfEvaluationResults(
            roadmapId: $roadmapId
            atypicalRepositoryId: $atypicalRepositoryId
        ) {
            itemId
            itemType
            selfMastery
        }
    }
`;
