import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Dialog, DialogActions, DialogContent, Divider, Button, CircularProgress, Grid, Fab, Tooltip } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

// Apollo
import { useMutation } from '@apollo/client';
import { UPDATE_LEARNER_WORK_SITUATION_WEATHER, UPDATE_LEARNER_WORK_SITUATION_ROADMAP } from './gql-update-learner-work-situation';

// Services
import { ICustomRepository, ILearnerWorkSituation, ISelectOption } from 'services/interfaces';
import { useMediaQueries } from 'services/hooks';
import { getErrorExtensions } from 'services/utils';
import { EWeather } from 'services/enums';

// Components
import Title from 'components/title';
import ToggleWeather from 'components/toggle-weather';
import MobileTextField from 'components/mobile-text-field';

// Selects
import SelectRoadmap from 'selects/select-roadmap';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------- UPDATE LEARNER WORK SITUATION ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const UpdateLearnerWorkSituation = ({
    learnerWorkSituation,
    actionType,
    onClose,
    refetch,
    customRepository,
} : {
    learnerWorkSituation: ILearnerWorkSituation | undefined,
    actionType: 'WEATHER' | 'ROADMAP',
    onClose: () => void,
    refetch: any,
    customRepository?: ICustomRepository,
}) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { id, workSituationId } = useParams();
    const { mediaSM } = useMediaQueries();
    const { pathname, state: locationState } = useLocation();

    const [weather, setWeather] = useState<EWeather | null>(null);
    const [roadmap, setRoadmap] = useState<ISelectOption | null>(null);
    const [personalResult, setPersonalResult] = useState('');

    const [updateLearnerWorkSituationWeather, {
        loading: loadingWeather,
        error: errorWeather,
        data: dataWeather
    }] = useMutation(UPDATE_LEARNER_WORK_SITUATION_WEATHER);

    const [updateLearnerWorkSituationRoadmap, {
        loading: loadingRoadmap,
        error: errorRoadmap,
        data: dataRoadmap
    }] = useMutation(UPDATE_LEARNER_WORK_SITUATION_ROADMAP);

    const onNavigateToCreateRoadmap = () => {

        let url = '/app/'
            + (pathname.includes('/app/companies') ? 'companies' : 'users')
            + '/' + id + '/create-roadmap';

        navigate(url, {
            state: ({
                action: 'linkRoadmapToWorkSituation',
                workSituationId: workSituationId,
                learner: { id: learnerWorkSituation?.learner?.id, fullName: learnerWorkSituation?.learner?.fullName },
                customRepository: { id: customRepository?.id, name: customRepository?.name },
            }),
            replace: true,
        });
    };

    const submitDisabled = () => {
        if (actionType === 'WEATHER' && (loadingWeather || weather == null)) {
            return true;
        }
        if (actionType === 'ROADMAP' && (loadingRoadmap || roadmap == null)) {
            return true;
        }
        return false;
    };

    const onUpdateLearnerWorkSituation = () => {

        switch (actionType) {

            case 'WEATHER':
                updateLearnerWorkSituationWeather({
                    variables: {
                        id: learnerWorkSituation?.id,
                        weather: weather,
                        personalResult: personalResult,
                    },
                });
                break;

            case 'ROADMAP':
                updateLearnerWorkSituationRoadmap({
                    variables: {
                        id: learnerWorkSituation?.id,
                        roadmap: { connect: roadmap?.value },
                    },
                });
                break;
        }
    };

    useEffect(() => {
        if (dataWeather?.updateLearnerWorkSituation || dataRoadmap?.updateLearnerWorkSituation) {
            enqueueSnackbar(t('results_saved_successfully'), { variant: 'success' });
            refetch();
        }
        if (errorWeather || errorRoadmap) {
            enqueueSnackbar(getErrorExtensions(errorWeather || errorRoadmap), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataWeather, dataRoadmap, errorWeather, errorRoadmap]);

    return (
        <Dialog
            open
            onClose={(loadingWeather || loadingRoadmap) ? undefined : onClose}
            fullWidth
            PaperProps={{ style: { margin: mediaSM ? undefined : 0 } }}
        >
            <DialogContent>
                {/* ------------------ WEATHER ------------------ */}
                {actionType === 'WEATHER' && (
                    <>
                        <Title>
                            {t('weather')}
                        </Title>
                        <Grid marginBottom={1}>
                            {t('how_did_you_feel_during_this_work_situation')}
                        </Grid>
                        <ToggleWeather
                            value={weather}
                            onChange={value => setWeather(value)}
                        />
                        <Grid marginTop={3}>
                            <Title>
                                {t('personal_result')}
                            </Title>
                            <MobileTextField
                                name="personalResult"
                                value={personalResult}
                                onChange={e => setPersonalResult(e.target.value)}
                                placeholder={t('personal_result')}
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </>
                )}
                {/* ------------------ ROADMAP ------------------ */}
                {actionType === 'ROADMAP' && (
                    <>
                        <div className="dialog-title">
                            {learnerWorkSituation?.learner?.fullName}
                        </div>
                        <Title>
                            {t('roadmap')}
                        </Title>
                        <Grid
                            container
                            alignItems="center"
                        >
                            <Grid item xs>
                                <SelectRoadmap
                                    value={roadmap}
                                    onChange={(value: ISelectOption | null) => setRoadmap(value)}
                                    learnerId={learnerWorkSituation?.learner?.id}
                                    customRepositoryId={customRepository?.id}
                                    defaultValue={locationState?.roadmapId}
                                    menuPortalTarget={document.body}
                                />
                            </Grid>
                            <Grid
                                item xs="auto"
                                marginLeft={1}
                            >
                                <Tooltip title={t('add_roadmap')}>
                                    <Fab
                                        onClick={onNavigateToCreateRoadmap}
                                        color="secondary"
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </>
                )}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                >
                    {t('close')}
                </Button>
                <Button
                    onClick={onUpdateLearnerWorkSituation}
                    disabled={submitDisabled()}
                    endIcon={(loadingWeather || loadingRoadmap) ? <CircularProgress size={20} color="inherit" /> : <></>}
                    color={actionType === 'WEATHER' ? 'yellow' : 'secondary'}
                >
                    {t('validate')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateLearnerWorkSituation;
