import { useMemo, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, RadioGroup, Radio, FormControlLabel } from '@mui/material';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { ATYPICAL_REPOSITORY } from 'views/authenticated/repositories/atypical-repositories/atypical-repository/gql-atypical-repository';
import { ATYPICAL_REPOSITORY_RESULTS } from 'views/authenticated/repositories/atypical-repositories/atypical-repository/gql-atypical-repository';

// Services
import { ICustomRepository, ISelectOptionWithLinkItems, IAtypicalRepository, ISimpleResult } from 'services/interfaces';
import { getAtypicalItemsFromAtypicalRepository } from 'services/utils';

// Components
import Title from 'components/title';
import Select from 'components/react-select';
import { Block, BlockContainer, BlockIconButton } from 'components/block';

// Icons
import { SelectedRepositoryItemIcon } from 'icons';

// Views
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../index';
import { ResultTypeBadge } from 'components/result-type';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- SELECT ATYPICAL REPOSITORY ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SelectAtypicalRepository({
    customRepository,
    activityResults,
} : {
    customRepository: ICustomRepository | undefined,
    activityResults: ISimpleResult[] | undefined,
}) {

    const { t } = useTranslation();

    const {
        setContext,
        setRepositoryItems,
        atypicalRepositorySelectOption,
        roadmap,
        filterItemsRelatedToActivities,
        filteredActivityIds,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const [atypicalRepository, { loading, data }] = useLazyQuery<{ atypicalRepository: IAtypicalRepository }>(ATYPICAL_REPOSITORY);
    const [atypicalRepositoryResults, { data: dataResults }] = useLazyQuery<{ atypicalRepositoryResults: ISimpleResult[] }>(ATYPICAL_REPOSITORY_RESULTS);

    const atypicalRepositoryOptions = useMemo(() => {
        if (customRepository?.repositoryLinks) {
            return customRepository.repositoryLinks.map(l => ({
                value: l.atypicalRepository?.id,
                label: l.atypicalRepository?.name,
                linkItems: l.linkItems,
            }));
        }
        return [];
    }, [customRepository]);

    const onChangeAtypicalRepository = (value: ISelectOptionWithLinkItems | null) => {
        setRepositoryItems({ atypicalItems: [] });
        setContext(prevContext => ({ ...prevContext, atypicalRepositorySelectOption: value, atypicalRepository: null, atypicalRepositoryResults: [] }));
    };

    const onClickToActivity = (activityId: string) => {

        let activityIds = [...filteredActivityIds];
        let index = activityIds.findIndex(id => id === activityId);

        if (index === -1) {
            activityIds.push(activityId);
        }
        else {
            activityIds.splice(index, 1);
        }

        setContext(prevContext => ({ ...prevContext, filteredActivityIds: activityIds }));
    };

    useEffect(() => {
        if (atypicalRepositorySelectOption) {

            atypicalRepository({ variables: { id: atypicalRepositorySelectOption.value }});

            if (roadmap) {
                atypicalRepositoryResults({ variables: {
                    learnerId: roadmap.learnerId,
                    atypicalRepositoryId: atypicalRepositorySelectOption.value,
                    customRepositoryId: customRepository?.id,
                    roadmapId: roadmap.id,
                }});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [atypicalRepositorySelectOption]);

    useEffect(() => {
        if (data?.atypicalRepository) {
            setContext(prevContext => ({
                ...prevContext,
                atypicalRepository: { ...data.atypicalRepository, atypicalItems: getAtypicalItemsFromAtypicalRepository(data.atypicalRepository) },
            }));
        }
        if (dataResults?.atypicalRepositoryResults) {
            setContext(prevContext => ({
                ...prevContext,
                atypicalRepositoryResults: dataResults.atypicalRepositoryResults,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dataResults]);

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={6} xl={4}>
                    <Title>
                        {t('atypical_repository')}
                    </Title>
                    <Select
                        placeholder={t('atypical_repositories')}
                        value={atypicalRepositorySelectOption}
                        onChange={(value: ISelectOptionWithLinkItems | null) => onChangeAtypicalRepository(value)}
                        options={atypicalRepositoryOptions}
                        isLoading={loading}
                    />
                    {atypicalRepositoryOptions.length === 0 && (
                        <div
                            className="subtitle"
                            style={{ marginTop: 4 }}
                        >
                            {t('no_atypical_repository_linked_to_custom_repository')}
                        </div>
                    )}
                </Grid>
            </Grid>
            {(
                customRepository?.activityRepository?.activities
                && customRepository.activityRepository.activities.length > 0
            ) && (
                <>
                    <Grid
                        marginTop={3}
                        container
                        flexDirection="column"
                    >
                        <Title>
                            {t('selection_type')}
                        </Title>
                        <RadioGroup
                            value={filterItemsRelatedToActivities}
                            onChange={(e, value) => { setRepositoryItems({ atypicalItems: [] }); setContext(prevContext => ({ ...prevContext, filterItemsRelatedToActivities: value })); }}
                        >
                            <FormControlLabel
                                label={t('manual.2')}
                                value="0"
                                control={<Radio />}
                            />
                            <FormControlLabel
                                label={t('through_activity_repository')}
                                value="1"
                                control={<Radio />}
                            />
                        </RadioGroup>
                    </Grid>
                    {filterItemsRelatedToActivities === '1' && (
                        <Grid
                            marginTop={2}
                            container
                        >
                            <Grid>
                                <Title>
                                    {t('activities')}
                                </Title>
                            </Grid>
                            <Grid
                                container
                                marginTop={0}
                                spacing={3}
                            >
                                {customRepository.activityRepository.activities.map(activity => {

                                    let selected = filteredActivityIds.includes(activity.id);
                                    let resultType = activityResults?.find(r => r.simpleResultId === activity.id)?.resultType;

                                    return (
                                        <BlockContainer key={activity.id}>
                                            <Block
                                                onClick={() => onClickToActivity(activity.id)}
                                                selected={selected}
                                                color="yellow"
                                                badge={<ResultTypeBadge resultType={resultType} />}
                                                icons={selected && (
                                                    <BlockIconButton tooltip={t('selected_item')}>
                                                        <SelectedRepositoryItemIcon />
                                                    </BlockIconButton>
                                                )}
                                            >
                                                {activity.position}. {activity.name}
                                            </Block>
                                        </BlockContainer>   
                                    );
                                })}
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </>
    );
}
