import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Button, CircularProgress } from '@mui/material';

// Services
import { ESchedule, EWorkSituationType } from 'services/enums';
import { compareTimes } from 'services/utils';
import { successMain } from 'services/constants';

// Components
import PelletCard, { PelletCardMissingFields } from 'components/pellet-card';

// Views
import { IState } from '../../create-work-situation-from-roadmap';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- VALIDATE WORK SITUATION -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ValidateWorkSituation({
    state,
    onSubmit,
    loading,
} : {
    state: IState,
    onSubmit: VoidFunction,
    loading: boolean,
}) {

    const { t } = useTranslation();

    const submitDisabled = (
        state.trainingDate == null
        || state.company == null
        || (state.atypicalItems.length > 0 && state.comment === '')
        || (
            state.workSituationType === EWorkSituationType.PREPARATORY_SEQUENCE
            && state.preparatorySequenceModalities.length === 0
        )
        || (state.schedule?.value === ESchedule.MORNING
            && (
                state.startMorning == null
                || state.endMorning == null
                || compareTimes(state.startMorning, state.endMorning, '>')
            )
        )
        || (state.schedule?.value === ESchedule.AFTERNOON
            && (
                state.startAfternoon == null
                || state.endAfternoon == null
                || compareTimes(state.startAfternoon, state.endAfternoon, '>')
            )
        )
        || (state.schedule?.value === ESchedule.DAY
            && (
                state.startMorning == null
                || state.endMorning == null
                || state.startAfternoon == null
                || state.endAfternoon == null
                || compareTimes(state.startMorning, state.endMorning, '>')
                || compareTimes(state.startAfternoon, state.endAfternoon, '>')
                || compareTimes(state.endMorning, state.startAfternoon, '>')
            )
        )
    );

    const missingFields = () => {
        let array = [];

        if (state.trainingDate == null) {
            array.push(t('training_date'));
        }
        if (state.company == null) {
            array.push(t('company'));
        }
        if (
            state.workSituationType === EWorkSituationType.PREPARATORY_SEQUENCE
            && state.preparatorySequenceModalities.length === 0
        ) {
            array.push(t('modalities'));
        }
        if (state.atypicalItems.length > 0 && state.comment === '') {
            array.push(t('context'));
        }
        if (
            (
                state.schedule?.value === ESchedule.MORNING
                && (
                    state.startMorning == null
                    || state.endMorning == null
                    || compareTimes(state.startMorning, state.endMorning, '>')
                )
            )
            || (
                state.schedule?.value === ESchedule.AFTERNOON
                && (
                    state.startAfternoon == null
                    || state.endAfternoon == null
                    || compareTimes(state.startAfternoon, state.endAfternoon, '>')
                )
            )
            || (
                state.schedule?.value === ESchedule.DAY
                && (
                    state.startMorning == null
                    || state.endMorning == null
                    || state.startAfternoon == null
                    || state.endAfternoon == null
                    || compareTimes(state.startMorning, state.endMorning, '>')
                    || compareTimes(state.startAfternoon, state.endAfternoon, '>')
                    || compareTimes(state.endMorning, state.startAfternoon, '>')
                )
            )
        ) {
            array.push(t('schedule.1'));
        }

        return `${t('you_have_not_completed_following_fields')} : ${array.join(', ')}.`;
    };

    return (
        <PelletCard
            paddingTop={0}
            pelletColor={successMain}
            pelletContent={state.learners.length > 0 ? 6 : 5}
            summary={(
                <>
                    <Grid item xs>
                        {state.workSituationType === EWorkSituationType.PREPARATORY_SEQUENCE ? t('validate_preparatory_sequence') : t('validate_work_situation')}
                    </Grid>
                    <Grid item xs="auto"> 
                        <Button
                            onClick={onSubmit}
                            disabled={loading || submitDisabled}
                            endIcon={loading ? <CircularProgress color="inherit" size={20} /> : <></>}
                            color="success"
                        >
                            {t('validate')}
                        </Button>
                    </Grid>
                </>
            )}
        >
            {submitDisabled && (
                <PelletCardMissingFields
                    fields={missingFields()}
                />
            )}
        </PelletCard>
    );
}
