import { PropsWithChildren } from 'react';

// MUI
import { Grid, Divider } from '@mui/material';
import { WarningRounded as WarningIcon } from '@mui/icons-material';

// Services
import { bgContentColor, warningMain } from 'services/constants';
import { useMediaQueries } from 'services/hooks';

// CSS
import styles from './PelletCard.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- PELLET CARD -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IPelletCardProps extends PropsWithChildren {
    pelletColor?: string;
    pelletSize?: string | number;
    pelletContent?: React.ReactNode;
    startIcon?: React.ReactNode;
    summary?: React.ReactNode;
    paddingTop?: number;
    paddingBottom?: number;
    paddingContent?: number;
    displayBlock?: boolean;
    disabledSummary?: boolean;
}

export default function PelletCard(props: IPelletCardProps) {

    const { mediaSM } = useMediaQueries();

    return (
        <Grid
            padding={mediaSM ? 3 : 1}
            paddingTop={props.paddingTop}
            paddingBottom={props.paddingBottom}
        >
            <Grid
                bgcolor={bgContentColor}
                borderRadius={1}
            >
                {!props.disabledSummary && (
                    <Grid padding={1}>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center"
                        >
                            {(props.startIcon || props.pelletContent) && (
                                <Grid
                                    item xs="auto"
                                    className={styles.startIcon}
                                >
                                    {props.startIcon ? (
                                        props.startIcon
                                    ) : (
                                        <Pellet
                                            color={props.pelletColor}
                                            size={props.pelletSize}
                                        >
                                            {props.pelletContent}
                                        </Pellet>
                                    )}
                                </Grid>
                            )}
                            {props.summary}
                        </Grid>
                    </Grid>
                )}
                {props.children && (
                    <>
                        {!props.disabledSummary && (
                            <Divider />
                        )}
                        <Grid
                            container={!props.displayBlock}
                            padding={props.paddingContent != null ? props.paddingContent : 3}
                        >
                            {props.children}
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- PELLET ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IPelletProps extends PropsWithChildren  {
    color?: string;
    size?: string | number;
}

export function Pellet(props: IPelletProps) {
    return (
        <div
            className={styles.pellet}
            style={{ backgroundColor: props.color || 'gray', width: props.size, height: props.size }}
        >
            {props.children}
        </div>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- PELLET CARD MISSING FIELDS ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function PelletCardMissingFields({ fields } : { fields: string }) {
    return (
        <Grid
            container
            alignItems="center"
        >
            <WarningIcon color="warning" />
            <Grid
                item xs
                marginLeft={1}
                color={warningMain}
            >
                {fields}
            </Grid>
        </Grid>
    );
}
