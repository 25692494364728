import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { IReflectionPhase } from 'services/interfaces';

// Components
import PelletCard from 'components/pellet-card';
import Title from 'components/title';

// Icons
import { ReflexivityAxisIcon } from 'icons';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- REFLEXIVITY QUESTIONS ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ReflexivityQuestions({ reflectionPhase } : { reflectionPhase: IReflectionPhase | undefined }) {

    const { t } = useTranslation();

    return (
        <PelletCard
            paddingTop={0}
            startIcon={<ReflexivityAxisIcon color="primary" />}
            summary={(
                <Grid item xs>
                    {t('reflexivity_axis')} : {reflectionPhase?.reflexivityAxis?.name}
                </Grid>
            )}
        >
            {(
                reflectionPhase?.reflexivityQuestions
                && reflectionPhase.reflexivityQuestions.length > 0
            ) && (
                <>
                    <Title>
                        {t('reflexivity_questions')}
                    </Title>
                    <table width="100%">
                        <tbody>
                            {reflectionPhase.reflexivityQuestions.map(rq => (
                                <Fragment key={rq.id}>
                                    <tr>
                                        <td className="table-td fw500">
                                            {rq.position}. {rq.description}
                                        </td>
                                    </tr>
                                    {rq.pivot?.answer && (
                                        <tr>
                                            <td className="table-td">
                                                {rq.pivot.answer}
                                            </td>
                                        </tr>
                                    )}
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </PelletCard>
    );
}
