import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';
import { People as UserIcon } from '@mui/icons-material';

// Services
import { IWorkSituation } from 'services/interfaces';

// Components
import SignatureDialog from 'components/signature-dialog';
import PelletCard from 'components/pellet-card';

// Views
import Trainers from './trainers';
import Learners from './learners';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- WORK SITUATION USERS -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IState {
    openSignature: boolean;
    username: string;
    signatureUrl: string;
}

const WorkSituationUsers = ({
    workSituation,
    source,
    refetch,
} : {
    workSituation: IWorkSituation | undefined,
    source: 'USER' | 'COMPANY' | 'ROADMAP',
    refetch: any,
}) => {

    const { t } = useTranslation();

    const [state, setState] = useState<IState>({
        openSignature: false,
        username: '',
        signatureUrl: '',
    });

    const onOpenSignature = (name: string, url: string) => {
        setState(prevState => ({
            ...prevState,
            openSignature: true,
            username: name,
            signatureUrl: url,
        }));
    };

    return (
        <>
            <PelletCard
                displayBlock
                paddingTop={0}
                startIcon={<UserIcon color="secondary" />}
                summary={(
                    <Grid item xs>
                        {t('users')}
                    </Grid>
                )}
            >
                {/* -------------------- TRAINERS -------------------- */}
                <Trainers
                    workSituation={workSituation}
                    onOpenSignature={onOpenSignature}
                />
                {/* -------------------- LEARNERS -------------------- */}
                <Learners
                    source={source}
                    refetch={refetch}
                    workSituation={workSituation}
                    onOpenSignature={onOpenSignature}
                />
            </PelletCard>
            {/* -------------------- SIGNATURE DIALOG -------------------- */}
            {state.openSignature && (
                <SignatureDialog
                    username={state.username}
                    signatureUrl={state.signatureUrl}
                    onClose={() => setState(prevState => ({ ...prevState, openSignature: false }))}
                />
            )}
        </>
    );
};

export default WorkSituationUsers;
