import React from 'react';

// MUI
import { Button, ButtonProps } from '@mui/material';

// Services
import { useMediaQueries } from 'services/hooks';

interface IResponsiveButtonProps extends ButtonProps {
    icon: React.ReactNode;
    placement: 'start' | 'end',
    to?: string;
    state?: any;
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- RESPONSIVE BUTTON ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const ResponsiveButton = (props: IResponsiveButtonProps) => {

    const { mediaMD } = useMediaQueries();

    return (
        <Button
            {...props}
            startIcon={(mediaMD && props?.placement === 'start') ? props.icon : undefined}
            endIcon={(mediaMD && props?.placement === 'end') ? props.icon : undefined}
            style={{ ...props.style, minWidth: 0, padding: mediaMD ? undefined : '6px 10px' }}
        >
            {mediaMD ? props.children : props.icon}
        </Button>
    );
};

export default ResponsiveButton;
