import { ToggleButtonGroup, ToggleButton } from '@mui/material';

// Services
import { ESelfMastery } from 'services/enums';

// Icons
import SelfMasteryIcon from 'icons/self-mastery-icon';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- TOGGLE SELF MASTERY ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const ToggleSelfMastery = ({
    value,
    onChange,
} : {
    value: ESelfMastery | undefined,
    onChange: (value: ESelfMastery | null) => void,
}) => {
    return (
        <ToggleButtonGroup
            value={value}
            onChange={(e, value) => onChange(value)}
            exclusive
        >
            <ToggleButton
                value={ESelfMastery.KNOW}
                style={{ padding: 4 }}
            >
                <SelfMasteryIcon
                    selfMastery={ESelfMastery.KNOW}
                    unselected={(value && value !== ESelfMastery.KNOW)}
                />
            </ToggleButton>
            <ToggleButton
                value={ESelfMastery.KNOW_PARTIALLY}
                style={{ padding: 4 }}
            >
                <SelfMasteryIcon
                    selfMastery={ESelfMastery.KNOW_PARTIALLY}
                    unselected={(value && value !== ESelfMastery.KNOW_PARTIALLY)}
                />
            </ToggleButton>
            <ToggleButton
                value={ESelfMastery.DO_NOT_KNOW}
                style={{ padding: 4 }}
            >
                <SelfMasteryIcon
                    selfMastery={ESelfMastery.DO_NOT_KNOW}
                    unselected={(value && value !== ESelfMastery.DO_NOT_KNOW)}
                />
            </ToggleButton>
            <ToggleButton
                value={ESelfMastery.DO_NOT_UNDERSTAND}
                style={{ padding: 4 }}
            >
                <SelfMasteryIcon
                    selfMastery={ESelfMastery.DO_NOT_UNDERSTAND}
                    unselected={(value && value !== ESelfMastery.DO_NOT_UNDERSTAND)}
                />
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default ToggleSelfMastery;
