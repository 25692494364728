import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useOutletContext, Outlet, useMatch, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

// Apollo
import { useQuery, ApolloError } from '@apollo/client';
import { COMPANY } from './gql-company';

// Permissions
import { canUpdateCompany } from 'permissions';

// Services
import { ICompany, IBreadcrumbItem } from 'services/interfaces';
import { useDocumentTitle } from 'services/hooks';

// Components
import Breadcrumb from 'components/breadcrumb';
import Spinner from 'components/spinner';
import Error from 'components/error';
import ResponsiveButton from 'components/responsive-button';

// Views
import CompanyCard from './company-card';

type ContextType = {
    company: ICompany,
    refetchCompany: any,
    defaultBreadcrumbItems: IBreadcrumbItem[],
    setBreadcrumbItems: React.Dispatch<React.SetStateAction<IBreadcrumbItem[]>>,
};

export function useBreadcrumbFromCompany() {
    return useOutletContext<ContextType>();
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ FETCH COMPANY ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const FetchCompany = () => {
    
    const { t } = useTranslation();
    const { id } = useParams();
    const { pathname } = useLocation();
    const match = useMatch('/app/companies/:id');

    const { loading, error, data, refetch } = useQuery<{ company: ICompany }>(COMPANY, {
        variables: { id: id },
    });
    
    useDocumentTitle(data?.company?.name || `${t('company')} #${id}`);

    const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumbItem[]>([
        {
            label: t('companies'),
            route: '/app/companies',
        },
        {
            label: '...',
        },
    ]);

    useEffect(() => {
        if (data?.company && match) {
            setBreadcrumbItems([
                {
                    label: t('companies'),
                    route: '/app/companies',
                },
                {
                    label: data.company?.name || `${t('company')} #${id}`,
                },
            ]);  
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, pathname]);

    return (
        <>
            {/* ------------------ BREADCRUMB ------------------ */}
            <Breadcrumb
                breadcrumbItems={breadcrumbItems}
            >
                {match && canUpdateCompany() && (
                    <Grid item xs="auto">
                        <ResponsiveButton
                            component={Link}
                            to={pathname + '/update'}
                            icon={<EditIcon />}
                            placement="end"
                        >
                            {t('edit_company')}
                        </ResponsiveButton>
                    </Grid>
                )}
            </Breadcrumb>
            {/* ------------------ COMPANY ------------------ */}
            <Company
                loading={loading}
                error={error}
                data={data}
                refetch={refetch}
                setBreadcrumbItems={setBreadcrumbItems}
            />
        </>
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- COMPANY ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const Company = ({
    loading,
    error,
    data,
    refetch,
    setBreadcrumbItems,
} : {
    loading: boolean,
    error: ApolloError | undefined,
    data: { company: ICompany } | undefined,
    refetch: any,
    setBreadcrumbItems: React.Dispatch<React.SetStateAction<IBreadcrumbItem[]>>,
}) => {

    const { t } = useTranslation();

    if (loading) return <Spinner />;
    if (error) return <Error />;
    if ((data || error) && !data?.company && !error) return <Error error={`${t('company')} ${t('not_found').toLowerCase()}`} />

    return (
        <>
            <CompanyCard
                company={data?.company}
                refetch={refetch}
            />
            <Outlet
                context={{
                    company: data?.company,
                    refetchCompany: refetch,
                    defaultBreadcrumbItems: [
                        {
                            label: t('companies'),
                            route: '/app/companies',
                        },
                        {
                            label: data?.company?.name,
                            route: '/app/companies/' + data?.company?.id,
                        },
                    ],
                    setBreadcrumbItems,
                }}
            />
        </>
    );
};

export default FetchCompany;
