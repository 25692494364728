import { gql } from '@apollo/client';

export const COMPANY = gql`
    query Company($id: ID) {
        company(id: $id) {
            id
            name
            address
            postalCode
            city
            description
            logoUrl
            footer
            leader
            storageLimit
            storageSize
            creator {
                id
                fullName
            }
            lastModifier {
                id
                fullName
            }
            applications {
                id
                name
                pivot {
                    expiration
                }
            }
            country {
                id
                name
            }
            parentCompany {
                id
                name
                postalCode
                city
            }
            subCompanies {
                id
                name
                address
                postalCode
                city
                logoUrl
            }
            users {
                id
                fullName
                photoUrl
                role {
                    id
                    name
                }
            }
        }
    }
`;
