import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Fab, Grid, Tooltip } from '@mui/material';
import { CheckBox as CheckedIcon, CheckBoxOutlineBlank as UncheckIcon } from '@mui/icons-material';

// Services
import { ICustomBlock, IDescriptor, ILinkItem, IRepositoryLink, ISimpleResult } from 'services/interfaces';
import { magentaMain } from 'services/constants';
import { EDescriptorType, ELinkableType } from 'services/enums';

// Components
import { Block, BlockIconButton, EmptyBlock } from 'components/block';
import SeparatedContainer from 'components/separated-container';
import ResultTypeIcon, { ResultTypeBadge, getResultTypeLabel, isResultTypeIcon } from 'components/result-type';

// Repository Items
import DashedDescriptorsBlock from 'repository-items/repository-items-display/dashed-descriptors-block';
import { IRepositoryItemsFormContext, RepositoryItemsFormContext } from '../../../../index';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- SKILLS WITH DESCRIPTORS ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ICombinedSkill {
    id: string;
    type: string;
    customSkillId: string;
    name: string;
    position: number;
    descriptors: IDescriptor[];
    isMissing: boolean;
}

export default function SkillsWithDescriptors({
    customBlock,
    customSkillResults,
    descriptorResults,
    linkItemResults,
} : {
    customBlock: ICustomBlock,
    customSkillResults?: ISimpleResult[],
    descriptorResults?: ISimpleResult[],
    linkItemResults?: ISimpleResult[],
}) {

    const { t } = useTranslation();
    const [selectedSkill, setSelectedSkill] = useState<ICombinedSkill | null>(null);

    const {
        setRepositoryItems,
        descriptors: selectedDescriptors,
        linkItems: selectedLinkItems,
        enabledSkillRepositoryItems,
        repositoryLinks,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const combinedSkills : ICombinedSkill[] = useMemo(() => {

        let combinedSkills = customBlock.customSkills.map(cs => ({
            id: cs.skill?.id,
            type: 'CUSTOM_SKILL',
            customSkillId: cs.id,
            name: cs.skill?.name,
            position: cs.skill?.position,
            descriptors: enabledSkillRepositoryItems ? cs.descriptors.concat(cs.missingDescriptors.map(md => ({ ...md, isMissing: true }))) : cs.descriptors,
            isMissing: false,
        }));

        if (enabledSkillRepositoryItems) {

            combinedSkills = combinedSkills.concat(customBlock.missingSkills.map(ms => ({
                id: ms.id,
                type: 'SKILL',
                customSkillId: '',
                name: ms.name,
                position: ms.position,
                descriptors: ms.descriptors.map(d => ({ ...d, isMissing: true })),  
                isMissing: true,
            })));
        }

        combinedSkills.sort((a, b) => a.position - b.position);

        return combinedSkills;

    }, [customBlock, enabledSkillRepositoryItems]);

    const getDescriptorCount = (d: IDescriptor[]) => {
        return d.filter(d => selectedDescriptors.some(i => i.id === d.id)).length;
    };

    const getLinkItemCount = (csId: string) => {

        let csLinkItems: ILinkItem[] = [];

        repositoryLinks.forEach(repositoryLink => {
            csLinkItems = csLinkItems.concat(repositoryLink.linkItems.filter(linkItem => linkItem.linkable_id === csId && linkItem.linkableType === ELinkableType.CUSTOM_SKILL));
        });
        
        return csLinkItems.filter(linkItem => selectedLinkItems.some(i => i.id === linkItem.id)).length;
    };

    const onCheckDescriptor = (descriptor: IDescriptor) => {

        let updatedDescriptors = [...selectedDescriptors] || [];
        let index = updatedDescriptors.findIndex(i => i.id === descriptor.id);

        if (index === -1) {
            updatedDescriptors.push({ id: descriptor.id });
        }
        else {
            updatedDescriptors.splice(index, 1);
        }

        setRepositoryItems({ descriptors: updatedDescriptors });
    };

    const toggleAllDescriptors = (descriptorType: EDescriptorType) => {

        let updatedDescriptors = [...selectedDescriptors] || [];

        let descriptors = selectedSkill!.descriptors.filter(d => d.descriptorType === descriptorType);

        if (descriptors.every(d => updatedDescriptors.some(i => i.id === d.id))) {
            updatedDescriptors = updatedDescriptors.filter(i => !descriptors.some(d => d.id === i.id));
        }
        else {
            updatedDescriptors = updatedDescriptors.concat(descriptors.filter(d => updatedDescriptors.every(i => i.id !== d.id)));
        }

        setRepositoryItems({ descriptors: updatedDescriptors });
    };

    const onCheckLinkItem = (linkItem: ILinkItem) => {

        let updatedLinkItems = [...selectedLinkItems] || [];
        let index = updatedLinkItems.findIndex(i => i.id === linkItem.id);

        if (index === -1) {
            updatedLinkItems.push({ id: linkItem.id });
        }
        else {
            updatedLinkItems.splice(index, 1);
        }

        setRepositoryItems({ linkItems: updatedLinkItems });
    };

    const toggleAllLinkItems = (repositoryLink: IRepositoryLink) => {

        let updatedLinkItems = [...selectedLinkItems] || [];

        let linkItems = repositoryLink.linkItems.filter(li => li.linkable_id === selectedSkill!.customSkillId && li.linkableType === ELinkableType.CUSTOM_SKILL);

        if (linkItems.every(li => updatedLinkItems.some(i => i.id === li.id))) {
            updatedLinkItems = updatedLinkItems.filter(i => !linkItems.some(li => li.id === i.id));
        }
        else {
            updatedLinkItems = updatedLinkItems.concat(linkItems.filter(li => updatedLinkItems.every(i => i.id !== li.id)));
        }

        setRepositoryItems({ linkItems: updatedLinkItems });
    };

    useEffect(() => {
        if (selectedSkill) {
            setSelectedSkill(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customBlock]);

    return (
        <SeparatedContainer
            leftChildren={(
                <>
                    {(
                        combinedSkills
                        && combinedSkills.length > 0
                    ) ? (
                        <Grid container flexDirection="column">
                            {combinedSkills.map((skill, index) => {

                                let descriptorCount = getDescriptorCount(skill.descriptors);
                                let resultType = customSkillResults?.find(r => r.simpleResultId === skill.customSkillId)?.resultType;
                                let linkItemCount = skill.type === "CUSTOM_SKILL" ? getLinkItemCount(skill.customSkillId) : 0;

                                return (
                                    <Block
                                        key={skill.id}
                                        onClick={() => setSelectedSkill(selectedSkill?.id === skill.id ? null : skill)}
                                        selected={selectedSkill?.id === skill.id}
                                        deselected={(selectedSkill != null && selectedSkill.id !== skill.id)}
                                        color="magenta"
                                        buttonProps={{ style: { opacity: skill.isMissing ? 0.5 : 1 } }}
                                        gridProps={{ marginTop: index > 0 ? 3 : undefined }}
                                        badge={<ResultTypeBadge resultType={resultType} />}
                                        icons={(
                                            <>
                                                {isResultTypeIcon(resultType) && (
                                                    <BlockIconButton tooltip={getResultTypeLabel(resultType)}>
                                                        <ResultTypeIcon resultType={resultType} />
                                                    </BlockIconButton>
                                                )}
                                                <BlockIconButton>
                                                    {descriptorCount + linkItemCount}
                                                </BlockIconButton>
                                            </>
                                        )}
                                    >
                                        {customBlock.position}.{skill.position} {skill.name}
                                    </Block>
                                )
                            })}
                        </Grid>
                    ) : (
                        <EmptyBlock color={magentaMain}>
                            {t('none.2')}
                        </EmptyBlock>
                    )}
                </>
            )}
            rightChildren={(
                <>
                    {selectedSkill && (
                        <DashedDescriptorsBlock
                            descriptors={selectedSkill.descriptors}
                            descriptorResults={descriptorResults}
                            listItemType="checkbox"
                            descriptorOnClick={descriptor => onCheckDescriptor(descriptor)}
                            descriptorChecked={descriptor => selectedDescriptors.some(i => i.id === descriptor.id)}
                            descriptorTitleAction={descriptorType => (
                                <Tooltip title={t('select_unselect_all')}> 
                                    <Fab
                                        onClick={() => toggleAllDescriptors(descriptorType)}
                                        color="primary"
                                    >
                                        {(selectedSkill.descriptors
                                            .filter(d => d.descriptorType === descriptorType)
                                            .every(d => selectedDescriptors.some(i => i.id === d.id))
                                        ) ? (
                                            <CheckedIcon />
                                        ) : (
                                            <UncheckIcon />
                                        )}
                                    </Fab>
                                </Tooltip>
                            )}
                            linkableType={ELinkableType.CUSTOM_SKILL}
                            linkableId={selectedSkill.customSkillId}
                            linkItemResults={linkItemResults}
                            repositoryLinks={repositoryLinks}
                            linkItemOnClick={linkItem => onCheckLinkItem(linkItem)}
                            listLinkItemChecked={linkItem => selectedLinkItems.some(i => i.id === linkItem.id)}
                            linkItemTitleAction={repositoryLink => (
                                <Tooltip title={t('select_unselect_all')}> 
                                    <Fab
                                        onClick={() => toggleAllLinkItems(repositoryLink)}
                                        color="primary"
                                    >
                                        {(repositoryLink.linkItems
                                            .filter(li => li.linkable_id === selectedSkill.customSkillId && li.linkableType === ELinkableType.CUSTOM_SKILL)
                                            .every(li => selectedLinkItems.some(i => i.id === li.id)) 
                                        ) ? (
                                            <CheckedIcon />
                                        ) : (
                                            <UncheckIcon />
                                        )}
                                    </Fab>
                                </Tooltip>
                            )}
                        />
                    )}
                </>
            )}
            leftContainerProps={{ padding: 24 }}
        />
    );
}
