import i18next from 'i18next';
import { IStepObject } from 'services/interfaces';
import { EDescriptorType, EEvaluationType, ERepositoryItemType, ERepositoryType, EStepType } from 'services/enums';

// MUI
import { ArrowRightAlt } from '@mui/icons-material';
import { Grid, Tooltip } from "@mui/material";

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- GET REPOSITORY ITEM TYPE ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRepositoryItemType = (stepType: EStepType | EEvaluationType, repositoryType: ERepositoryType) => {

    switch(stepType) {

        case EStepType.SELF_PLACEMENT:
        case EStepType.PLACEMENT:
        case EStepType.FINAL_EVALUATION:
            switch(repositoryType) {
                case ERepositoryType.CUSTOM:
                    return ERepositoryItemType.CUSTOM_SKILL;

                case ERepositoryType.ACTIVITY:
                    return ERepositoryItemType.ACTIVITY;

                case ERepositoryType.ATYPICAL:
                    return ERepositoryItemType.ATYPICAL_ITEM;

                default:
                    return undefined;
            }

        case EStepType.SELF_EVALUATION:
        case EStepType.PREPARATORY_SEQUENCE:
        case EStepType.WORK_SITUATION:
        case EStepType.REFLECTION_PHASE:
        case EStepType.EVALUATION:
        case EStepType.OTHER_TRAINING:
        case EStepType.PROFESSIONAL_PRACTICE:
        case EStepType.INTERNSHIP:
            switch(repositoryType) {
                case ERepositoryType.CUSTOM:
                    return ERepositoryItemType.DESCRIPTOR;

                case ERepositoryType.ACTIVITY:
                    return ERepositoryItemType.TASK;

                case ERepositoryType.ATYPICAL:
                    return ERepositoryItemType.ATYPICAL_ITEM;

                default:
                    return undefined;
            }

        case EStepType.SUPPORT:
            return ERepositoryItemType.ATYPICAL_ITEM;

        default:
            return undefined;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------- GET REPOSITORY ITEM TYPE KEY ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRepositoryItemTypeKey = (repositoryItemType: ERepositoryItemType) => {

    switch (repositoryItemType) {

        case ERepositoryItemType.CUSTOM_SKILL:
            return 'customSkills';

        case ERepositoryItemType.DESCRIPTOR:
            return 'descriptors';

        case ERepositoryItemType.ACTIVITY:
            return 'activities';

        case ERepositoryItemType.TASK:
            return 'tasks';

        case ERepositoryItemType.ATYPICAL_ITEM:
            return 'atypicalItems';

        case ERepositoryItemType.LINK_ITEM:
            return 'linkItems';
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------- GET ATYPICAL LEVEL TYPE FROM STEP TYPE -------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getAtypicalLevelTypeFromStepType = (stepType: EStepType | EEvaluationType | undefined) => {

    switch(stepType) {

        case EStepType.SELF_PLACEMENT:
        case EStepType.PLACEMENT:
        case EStepType.FINAL_EVALUATION:
            return 'isEvaluable';

        case EStepType.SELF_EVALUATION:
        case EStepType.PREPARATORY_SEQUENCE:
        case EStepType.WORK_SITUATION:
        case EStepType.REFLECTION_PHASE:
        case EStepType.EVALUATION:
        case EStepType.OTHER_TRAINING:
        case EStepType.PROFESSIONAL_PRACTICE:
        case EStepType.INTERNSHIP:
            return 'isTrainable';

        default:
            return 'isEvaluable';
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------- GET ATYPICAL REPOSITORY ID FROM STEP OBJECT ---------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getAtypicalRepositoryIdFromStepObject = (stepObject: IStepObject) => {
    if (stepObject.atypicalItems && stepObject.atypicalItems.length > 0) {
        return stepObject.atypicalItems[0].atypicalRepository?.id;
    }
    return undefined;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------ GET REPOSITORY TYPE FROM STEP OBJECT -------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRepositoryTypeFromStepObject = (stepObject: IStepObject) => {

    if (
        (stepObject?.customSkills && stepObject.customSkills.length > 0)
        || (stepObject?.descriptors && stepObject.descriptors.length > 0)
        || (stepObject?.linkItems && stepObject.linkItems.length > 0)
    ) {
        return ERepositoryType.CUSTOM;
    }

    if (
        (stepObject?.activities && stepObject.activities.length > 0)
        || (stepObject?.tasks && stepObject.tasks.length > 0)
    ) {
        return ERepositoryType.ACTIVITY;
    }
    
    if (
        stepObject?.atypicalItems
        && stepObject.atypicalItems.length > 0
    ) {
        return ERepositoryType.ATYPICAL;
    }

    return undefined;
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------- GET REPOSITORY ITEM TYPE FROM STEP OBJECT ----------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRepositoryItemTypeFromStepObject = (stepObject: IStepObject) => {

    if (stepObject?.customSkills && stepObject.customSkills.length > 0) {
        return ERepositoryItemType.CUSTOM_SKILL;
    }
    if (
        (stepObject?.descriptors && stepObject.descriptors.length > 0)
        || (stepObject?.linkItems && stepObject.linkItems.length > 0)
    ) {
        return ERepositoryItemType.DESCRIPTOR;
    }
    if (stepObject?.activities && stepObject.activities.length > 0) {
        return ERepositoryItemType.ACTIVITY;
    }
    if (stepObject?.tasks && stepObject.tasks.length > 0) {
        return ERepositoryItemType.TASK;
    }
    if (stepObject?.atypicalItems && stepObject.atypicalItems.length > 0) {
        return ERepositoryItemType.ATYPICAL_ITEM;
    }
    return undefined;
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------- GET REPOSITORY ITEM TYPE LABEL ---------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRepositoryItemTypeLabel = (repositoryItemType: ERepositoryItemType) => {

    switch (repositoryItemType) {

        case ERepositoryItemType.CUSTOM_SKILL:
            return i18next.t('custom_skills');

        case ERepositoryItemType.DESCRIPTOR:
            return i18next.t('descriptors');

        case ERepositoryItemType.ACTIVITY:
            return i18next.t('activities');

        case ERepositoryItemType.TASK:
            return i18next.t('tasks');

        default:
            return i18next.t('items');
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------- GET REPOSITORY ITEM TYPE TRANSLATION KEY ----------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRepositoryItemTypeTranslationKey = (repositoryItemType: ERepositoryItemType) => {

    switch (repositoryItemType) {

        case ERepositoryItemType.CUSTOM_SKILL:
            return 'custom_skills';

        case ERepositoryItemType.DESCRIPTOR:
            return 'descriptors';

        case ERepositoryItemType.ACTIVITY:
            return 'activities';

        case ERepositoryItemType.TASK:
            return 'tasks';

        default:
            return 'items';
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------- GET REPOSITORY ITEM TYPE LABEL FROM STEP OBJECT -------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRepositoryItemTypeLabelFromStepObject = (stepObject: IStepObject) => {

    if (stepObject?.customSkills && stepObject.customSkills.length > 0) {
        return i18next.t('custom_skills');
    }
    if (stepObject?.descriptors && stepObject.descriptors.length > 0) {
        return i18next.t('descriptors');
    }
    if (stepObject?.activities && stepObject.activities.length > 0) {
        return i18next.t('activities');
    }
    if (stepObject?.tasks && stepObject.tasks.length > 0) {
        return i18next.t('tasks');
    }
    if (stepObject?.atypicalItems && stepObject.atypicalItems.length > 0) {
        return `${stepObject.atypicalItems[0].atypicalRepository?.name}`;
    }
    return '';
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------- GET SORTED REPOSITORY ITEMS FROM STEP OBJECT ---------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

/* * *
* Args : step object (like an evaluation, a work situation...)
* Return : repository item type (corresponding to the enum) and a sorted array of repostiory items
*/

export const getSortedRepositoryItemsFromStepObject = (stepObject: IStepObject) => {

    // Repository Item Type
    let repositoryItemType : ERepositoryItemType | undefined = undefined;

    // Sorted Items
    let sortedItems : any[] = [];

    /* * *
    * Repository Items
    */

    // Custom Skills
    if (
        stepObject?.customSkills
        && stepObject.customSkills.length > 0
    ) {

        sortedItems = [...stepObject.customSkills];

        sortedItems.sort((a, b) =>
            a.customBlock?.position - b.customBlock?.position
            || a.skill?.position - b.skill?.position
        );

        repositoryItemType = ERepositoryItemType.CUSTOM_SKILL;
    }

    // Descriptors
    else if (
        stepObject?.descriptors
        && stepObject.descriptors.length > 0
    ) {

        let order = Object.values(EDescriptorType).map(v => v);

        sortedItems = [...stepObject.descriptors];
        sortedItems.sort((a, b) => (a.descriptorType && b.descriptorType)
            ? order.indexOf(a.descriptorType) - order.indexOf(b.descriptorType)
            : 0
        );

        repositoryItemType = ERepositoryItemType.DESCRIPTOR;
    }

    // Activities
    else if (
        stepObject?.activities
        && stepObject.activities.length > 0
    ) {

        sortedItems = [...stepObject.activities];
        sortedItems.sort((a, b) => a.position - b.position);

        repositoryItemType = ERepositoryItemType.ACTIVITY;
    }

    // Tasks
    else if (
        stepObject?.tasks
        && stepObject.tasks.length > 0
    ) {

        sortedItems = [...stepObject.tasks];

        sortedItems.sort((a, b) =>
            a.activity?.position - b.activity?.position
            || a.position - b.position
        );

        repositoryItemType = ERepositoryItemType.TASK;
    }

    // Atypical Items
    else if (
        stepObject?.atypicalItems
        && stepObject.atypicalItems.length > 0
    ) {

        sortedItems = [...stepObject.atypicalItems];

        repositoryItemType = ERepositoryItemType.ATYPICAL_ITEM;
    }

    return ({ repositoryItemType, sortedItems });
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ GET REPOSITORY ITEM LABEL ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

/* * *
* Return : repository item label (in string or JSX Element depending stringify boolean arg)
*/

export const getRepositoryItemLabel = (
    repositoryItemType: ERepositoryItemType | undefined,
    item: any,
    stringify = false,
    origin?: string
) => {

    switch (repositoryItemType) {

        case ERepositoryItemType.CUSTOM_SKILL:
            if (item.customBlock) {
                return `${item.customBlock.position}.${item.skill?.position} ${item.skill?.name}`;
            }
            return `${item.skill?.name}`;

        case ERepositoryItemType.DESCRIPTOR:
            if (item.descriptorType) {

                let descriptorType = i18next.t(`${item.descriptorType.toLowerCase()}`);

                return stringify ? (
                    `${descriptorType}\r\n${item.name}` + (origin ? `\r\n\r\n--> ${origin}` : '')
                ) : (
                    <>
                        <span className="subtitle">
                            {descriptorType}
                        </span>
                        <br />
                        <span>
                            {item.name}
                        </span>
                        {
                            origin && getOriginSpan(origin)
                        }
                    </>
                );
            }
            return `${item.name}`;

        case ERepositoryItemType.ACTIVITY:
            return `${item.position}. ${item.name}`;

        case ERepositoryItemType.TASK:
            if (item.activity) {
                return `${item.activity.position}.${item.position} ${item.name}`;
            }
            return `${item.name}`;

        case ERepositoryItemType.ATYPICAL_ITEM:
            if (item.parentItem) {
                return stringify ? (
                    `${item.parentItem.name}\r\n${item.name}` + (origin ? `\r\n\r\n--> ${origin}` : '')
                ) : (
                    <>
                        <span className="subtitle">
                            {item.parentItem.name}
                        </span>
                        <br />
                        <span>
                            {item.name}
                        </span>
                        {origin && getOriginSpan(origin)}
                    </>
                );
            }
            return `${item.name}`;

        default:
            return stringify ? '' : <></>;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- GET ORIGIN -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getOriginSpan = (origin: string) => {
    return (
        <Grid
            display="flex"
            alignItems="center"
            marginTop={1}
            className="subtitle"
        >
            <Tooltip title={i18next.t('corresponding_skill')}>
                <ArrowRightAlt />
            </Tooltip>
            {`${origin}`}
        </Grid>
    );
};
