import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Services
import { EPreparatorySequenceModality } from 'services/enums';

// Components
import Select, { ISelectProps } from 'components/react-select';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------- SELECT PREPARATORY SEQUENCE MODALITY ------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SelectPreparatorySequenceModality(props: ISelectProps) {

    const { t } = useTranslation();

    const modalities = useMemo(() => {
        return Object.values(EPreparatorySequenceModality).map(modality => ({
            value: modality,
            label: t(modality.toLowerCase()),
        }));
    }, [t]);

    return (
        <Select
            {...props}
            placeholder={t('modalities')}
            options={modalities}
            emptyMessage={t('none.2')}
        />
    );
}
