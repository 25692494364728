import { forwardRef } from 'react';

// Dayjs
import { Dayjs } from 'dayjs';

// MUI
import { LocalizationProvider, TimePicker, TimePickerProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Services
import { useMediaQueries } from 'services/hooks';
import { getAdapterLocale, getLocaleText } from 'services/utils';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ TIME PICKER --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const LocaleTimePicker = forwardRef(function LocaleTimePicker(props: TimePickerProps<Dayjs>, ref: React.Ref<HTMLInputElement>) {

    const { mediaDesktop } = useMediaQueries();

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={getAdapterLocale()}
            localeText={getLocaleText()}
        >
            <TimePicker
                {...props}
                inputRef={ref}
                format="HH:mm"
                slotProps={{
                    ...props?.slotProps,
                    actionBar: { actions: mediaDesktop ? ['clear'] : ['clear', 'accept'] },
                    textField: { ...props?.slotProps?.textField, fullWidth: true },
                }}
            />
        </LocalizationProvider>
    );
});

export default LocaleTimePicker;
