import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { IReflectionPhase } from 'services/interfaces';
import { EStepType } from 'services/enums';
import { getFormattedDate } from 'services/utils';
import { yellowMain } from 'services/constants';

// Icons
import StepTypeIcon from 'icons/step-type-icon';

// Components
import Title from 'components/title';
import PelletCard from 'components/pellet-card';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- SIGN REFLECTION PHASE CARD ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SignReflectionPhaseCard({ reflectionPhase } : { reflectionPhase: IReflectionPhase | undefined }) {

    const workSituation = reflectionPhase?.learnerWorkSituation?.workSituation;
    const { t } = useTranslation();

    return (
        <PelletCard
            startIcon={(
                <StepTypeIcon
                    stepType={EStepType.REFLECTION_PHASE}
                    width={40}
                    height={40}
                    color={yellowMain}
                />
            )}
            summary={(
                <Grid item xs>
                    <Title>
                        {t('reflection_phase')} {t('at.2')} {getFormattedDate(reflectionPhase?.trainingDate)}
                    </Title>
                    <br />
                    <span>
                        {workSituation?.customRepository?.name} {t('at.1')} {workSituation?.company?.name}
                    </span>
                </Grid>
            )}
        />
    );
}
