import { useTranslation } from 'react-i18next';

// MUI
import { Dialog, DialogContent, DialogActions, Divider, Button, List, ListItem } from '@mui/material';

// Services
import { useMediaQueries } from 'services/hooks';
import { ITask } from 'services/interfaces';
import { primaryMain } from 'services/constants';

// Components
import Title from 'components/title';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- SUCCESS INDICATORS ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const SuccessIndicators = ({
    task,
    onClose,
} : {
    task: ITask,
    onClose: VoidFunction,
}) => {

    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    return (
        <Dialog
            open
            onClose={onClose}
            fullWidth
            fullScreen={!mediaMD}
        >
            <DialogContent>
                <div className="dialog-title">
                    {task.activity?.position}.{task.position} {task.name}
                </div>
                <div
                    className="framed-block"
                    style={{ borderColor: primaryMain, padding: 0 }}
                >
                    <div style={{ padding: '12px 12px 0px 12px' }}>
                        <Title style={{ color: primaryMain }}>
                            {t('success_indicators')}
                        </Title>
                    </div>
                    <List style={{ alignItems: 'start' }}>
                        {task.successIndicators.map(successIndicator => (
                            <ListItem key={successIndicator.id}>
                                <span>-</span>
                                <span style={{ marginLeft: 6 }}>
                                    {successIndicator.name}
                                </span>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SuccessIndicators;
