import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@mui/material';

// Services
import { useMediaQueries } from 'services/hooks';
import { ISelectOptionWithUserAttributes } from 'services/interfaces';
import { EGender } from 'services/enums';
import { isValidEmail } from 'services/utils';

// Components
import { isValidPhoneNumber, getPhoneNumber, getPhoneNumberPrefix } from 'components/react-international-phone';

// Views
import UserForm from 'views/authenticated/users/user-form';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ADD USER DIALOG ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IState {
    [key: string]: any;
    lastName: string;
    firstName: string;
    email: string;
    phoneNumber: string;
    phoneNumberPrefix: string;
    phoneNumberInvalid: boolean;
    gender: EGender;
    birthdate: Date | null;
}

const AddUserDialog = ({
    title,
    onClose,
    onSubmit,
    color = 'primary',
} : {
    title: string,
    onClose: VoidFunction,
    onSubmit: (user: ISelectOptionWithUserAttributes) => void,
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'magenta' | 'yellow',
}) => {

    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState<IState>({
        lastName: '',
        firstName: '',
        email: '',
        phoneNumber: '',
        phoneNumberPrefix: '33',
        phoneNumberInvalid: false,
        gender: EGender.UNKNOWN,
        birthdate: null,
    });

    const submitDisabled = (
        state.lastName === ''
        || state.firstName === ''
        || (state.email !== '' && !isValidEmail(state.email))
        || state.phoneNumberInvalid
        || (!isValidEmail(state.email) && !isValidPhoneNumber(state.phoneNumber))
    );

    const onAddUser = () => {
        onSubmit({
            value: undefined,
            label: state.firstName + ' ' + state.lastName,
            lastName: state.lastName,
            firstName: state.firstName,
            email: state.email,
            phoneNumber: isValidPhoneNumber(state.phoneNumber) ? getPhoneNumber(state.phoneNumber) : undefined,
            phoneNumberPrefix: isValidPhoneNumber(state.phoneNumber) ? getPhoneNumberPrefix(state.phoneNumber) : undefined,
            gender: state.gender,
            birthdate: state.birthdate,
        });
    };

    return (
        <Dialog
            open
            onClose={onClose}
            fullWidth
            fullScreen={!mediaMD}
        >
            <DialogContent>
                <div className="dialog-title">
                    {title}
                </div>
                <UserForm
                    state={state}
                    setState={setState}
                    fullFields={false}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                >
                    {t('cancel')}
                </Button>
                <Button
                    onClick={onAddUser}
                    disabled={submitDisabled}
                    color={color}
                >
                    {t('add')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddUserDialog;
