import { gql } from '@apollo/client';

export const UPLOAD_REFLECTION_PHASE_TRAINER_SIGNATURE = gql`
    mutation UploadReflectionPhaseTrainerSignature(
        $id: ID!
        $file: Upload!
    ) {
        uploadReflectionPhaseTrainerSignature(
            id: $id,
            file: $file,
        ) {
            id
        }
    }
`
