import { gql } from '@apollo/client';

export const CUSTOM_REPOSITORIES = gql`
    query CustomRepositories(
        $first: Int
        $page: Int
        $name: String
        $status: RepositoryStatus
        $companyId: ID
    ) {
        customRepositories(
            first: $first
            page: $page
            name: $name
            status: $status
            companyId: $companyId
        ) {
            paginatorInfo {
                total
            }
            data {
                id
                name
                jobsConcerned
                status
                company {
                    id
                    name
                }
                activityRepository {
                    id
                }
            }
        }
    }
`
