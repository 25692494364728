import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// MUI
import { Person as UserIcon } from '@mui/icons-material';

// Services
import { IUser, ICreatorAndLastModifier } from 'services/interfaces';
import { EGender } from 'services/enums';
import { getFormattedDate, getRoleLabel, truncateLabel, getCreatorAndModifierNames, isValidEmail } from 'services/utils';

// Permissions
import { canUploadUserPhoto } from 'permissions';

// Components
import Subheader, { SubheaderTitleIcons } from 'components/subheader';
import SubheaderItem from 'components/subheader/subheader-item';
import { getFormattedPhoneNumber, isValidPhoneNumber } from 'components/react-international-phone';

// Views
import UploadUserPhoto from '../../upload-user-photo';
import SendConfirmEmail from '../../send-confirm-email';
import SendConfirmPhoneNumber from '../../send-confirm-phone-number';

// CSS
import './user-card.css';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- USER CARD --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const UserCard = ({ user, refetch } : { user: IUser | undefined, refetch: any }) => {
    
    const navigate = useNavigate();
    const { t } = useTranslation();

    const getGenderIcon = (gender: EGender) => {

        switch (gender) {

            case EGender.MALE:
                return 'man';

            case EGender.FEMALE:
                return 'woman';

            default:
                return undefined;
        }
    };

    return (
        <Subheader
            title={user?.fullName}
            titleIcons={<SubheaderTitleIcons tooltip={getCreatorAndModifierNames(user as ICreatorAndLastModifier)} icon="info" />}
            icon={<UserIcon />}
            imageUrl={user?.photoUrl}
            imageUpload={(
                canUploadUserPhoto(user) && (
                    <UploadUserPhoto
                        userId={user?.id}
                        refetch={refetch}
                    />
                )
            )}
        >
            {/* -------------------- COMPANY -------------------- */}
            {user?.company && (
                <SubheaderItem
                    onClick={() => navigate('/app/companies/' + user.company.id)}
                    title={t('company')}
                    icon="business"
                    tooltip={user.company.name}
                >
                    {truncateLabel(user.company.name, 30)}
                </SubheaderItem>
            )}
            {/* -------------------- ROLE -------------------- */}
            {user?.role && (
                <SubheaderItem
                    title={t('role')}
                    icon="assignment_ind"
                >
                    {getRoleLabel(user.role?.id)}
                </SubheaderItem>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {user?.email && (
                <SubheaderItem
                    title={t('email')}
                    icon="email"
                >
                    <>
                        {user.email}
                        {(
                            !user?.hasVerifiedEmail
                            && isValidEmail(user.email)
                        ) && (
                            <SendConfirmEmail
                                userId={user.id}
                            />
                        )}
                    </>
                </SubheaderItem>
            )}
            {/* -------------------- PHONE -------------------- */}
            {(
                user?.phoneNumber
                && user?.phoneNumberPrefix
            ) && (
                <SubheaderItem
                    title={t('phone_number')}
                    icon="phone"
                >
                    <>
                        {getFormattedPhoneNumber(user.phoneNumber, user.phoneNumberPrefix)}
                        {(
                            !user?.hasVerifiedPhoneNumber
                            && isValidPhoneNumber(`+${user.phoneNumberPrefix}${user.phoneNumber}`)
                        ) && (
                            <SendConfirmPhoneNumber
                                userId={user.id}
                            />
                        )}
                    </>
                </SubheaderItem>
            )}
            {/* -------------------- BIRTHDATE -------------------- */}
            {user?.birthdate && (
                <SubheaderItem
                    title={t('birthdate')}
                    icon="cake"
                >
                    {getFormattedDate(user.birthdate)}
                </SubheaderItem>
            )}
            {/* -------------------- GENDER -------------------- */}
            {(user?.gender && user.gender !== EGender.UNKNOWN) && (
                <SubheaderItem
                    title={t('gender')}
                    icon={getGenderIcon(user.gender)}
                >
                    {t(user.gender?.toLowerCase())}
                </SubheaderItem>
            )}
        </Subheader>
    );
};

export default UserCard;
