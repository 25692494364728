import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { IWorkSituation } from 'services/interfaces';
import { getFormattedDate, getWorkSituationTitle } from 'services/utils';
import { yellowMain } from 'services/constants';

// Components
import Title from 'components/title';
import PelletCard from 'components/pellet-card';

// Icons
import StepTypeIcon from 'icons/step-type-icon';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ 01 SIGN WORK SITUATION CARD ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SignWorkSituationCard({ workSituation } : { workSituation: IWorkSituation | undefined }) {

    const { t } = useTranslation();

    return (
        <PelletCard
            startIcon={(
                <StepTypeIcon
                    stepType={workSituation?.workSituationType}
                    width={40}
                    height={40}
                    color={yellowMain}
                />
            )}
            summary={(
                <Grid item xs>
                    <Title>
                        {getWorkSituationTitle(workSituation?.workSituationType)} {t('at.2')} {getFormattedDate(workSituation?.trainingDate)}
                    </Title>
                    <br />
                    <span>
                        {workSituation?.customRepository?.name} {t('at.1')} {workSituation?.company?.name}
                    </span>
                </Grid>
            )}
        />
    );
}
