import { gql } from '@apollo/client';

export const WORK_SITUATION = gql`
    query WorkSituation($id: ID!) {
        workSituation(id: $id) {
            id
            workSituationType
            trainingDate
            signOffSheetUrl
            customRepository {
                id
                name
            }
            company {
                id
                name
            }
            trainerWorkSituations {
                id
                signatureUrl
                trainer {
                    id
                    fullName
                }
            }
            learnerWorkSituations {
                id
                signatureUrl
                learner {
                    id
                    fullName
                }
            }
        }
    }
`
