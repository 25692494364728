import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Radio } from '@mui/material';

// Services
import { IActivity, ITask, ISimpleResult } from 'services/interfaces';
import { getStepTypeActionLabel } from 'services/utils';
import { magentaMain } from 'services/constants';

// Components
import SeparatedContainer from 'components/separated-container';
import PelletCard from 'components/pellet-card';
import { Block, BlockIconButton } from 'components/block';

// Repository Items
import DashedTaskItemsBlock from 'repository-items/repository-items-display/dashed-task-items-block';

// Icons
import ResultTypeIcon, { ResultTypeBadge, isResultTypeIcon } from 'components/result-type';
import { SelectedRepositoryItemIcon } from 'icons';

// Repository Items
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../../../index';

interface IState {
    openObservableElements: boolean;
    selectedTask: ITask | null;
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- TASKS ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Tasks({
    position,
    activity,
    taskResults,
} : {
    position: number,
    activity: IActivity,
    taskResults?: ISimpleResult[],
}) {

    const { t } = useTranslation();

    const {
        setRepositoryItems,
        stepType,
        tasks,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const [state, setState] = useState<IState>({
        openObservableElements: false,
        selectedTask: null,
    });

    const onCheckTask = (task: ITask) => {
        let index = tasks.findIndex(i => i.id === task.id);
        if (index === -1) {
            tasks.push({ id: task.id });
        }
        else {
            tasks.splice(index, 1);
        }
        setRepositoryItems({ tasks });
    };

    useEffect(() => {
        if (activity.tasks && activity.tasks.length > 0) {
            setState(prevState => ({ ...prevState, selectedTask: activity.tasks[0] }));
        }
        else {
            setState(prevState => ({ ...prevState, selectedTask: null }));
        }
    }, [activity]);

    return (
        <PelletCard
            paddingTop={0}
            paddingContent={0}
            pelletColor={magentaMain}
            pelletContent={position}
            summary={(
                <Grid item xs>
                    {t('select_tasks')} {getStepTypeActionLabel(stepType)} ({tasks.length})
                </Grid>
            )}
        >
            <SeparatedContainer
                leftChildren={
                    (
                        activity.tasks
                        && activity.tasks.length > 0
                    ) && (
                        activity.tasks.map((task, index) => {

                            let selected = tasks.some(i => i.id === task.id);
                            let resultType = taskResults?.find(r => r.simpleResultId === task.id)?.resultType;
    
                            return (
                                <Grid
                                    key={task.id}
                                    className='inline-flex'
                                    marginTop={index > 0 ? 3 : undefined}
                                >
                                    <Block
                                        onClick={() => onCheckTask(task)}
                                        selected={selected}
                                        color="magenta"
                                        gridProps={{ flex: 1 }}
                                        badge={<ResultTypeBadge resultType={resultType} />}
                                        icons={(
                                            <>
                                                {isResultTypeIcon(resultType) && (
                                                    <BlockIconButton>
                                                        <ResultTypeIcon resultType={resultType} />
                                                    </BlockIconButton>
                                                )}
                                                {selected && (
                                                    <BlockIconButton tooltip={t('selected_item')}>
                                                        <SelectedRepositoryItemIcon />
                                                    </BlockIconButton>
                                                )}
                                            </>
                                        )}
                                    >
                                        {activity.position}.{task.position} {task.name}
                                    </Block>
                                    <Radio
                                        checked={state.selectedTask?.id === task.id}
                                        onChange={() => setState(prevState => ({ ...prevState, selectedTask: task }))}
                                    />
                                </Grid>
                            );
                        })
                    )
                }
                leftContainerProps={{ padding: '24px 0px 24px 24px' }}
                rightChildren={(
                    state.selectedTask && (
                        <DashedTaskItemsBlock
                            task={state.selectedTask}
                        />
                    )
                )}
            />
        </PelletCard>
    );
}
