import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// File Saver
import { saveAs } from 'file-saver';

// MUI
import { Grid, Button } from '@mui/material';
import { Assignment as DetailIcon, ArrowRightAlt as ArrowIcon } from '@mui/icons-material';

// Services
import { IWorkSituation } from 'services/interfaces';
import { EWorkSituationType } from 'services/enums';
import { getFormattedDate, getFormattedTime, getFileExtension } from 'services/utils';
import { API_URL } from 'services/apollo-client';

// Components
import Title from 'components/title';
import FramedStatus from 'components/framed-status';
import PelletCard from 'components/pellet-card';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- WORK SITUATION DETAILS --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const WorkSituationDetails = ({ workSituation } : { workSituation: IWorkSituation | undefined }) => {

    const { t } = useTranslation();

    const onSaveSignOffSheet = () => {
        let fileName = t('work_situation').toUpperCase() + '-' + getFormattedDate(workSituation?.trainingDate);
        saveAs(
            API_URL + workSituation?.signOffSheetUrl,
            fileName + '.' + getFileExtension(workSituation?.signOffSheetUrl),
        );
    };

    return (
        <PelletCard
            paddingTop={0}
            startIcon={<DetailIcon color="success" />}
            summary={(
                <Grid item xs>
                    {workSituation?.workSituationType === EWorkSituationType.PREPARATORY_SEQUENCE
                        ? t('preparatory_sequence_details')
                        : t('work_situation_details')
                    }
                </Grid>
            )}
        >
            <Grid
                container
                spacing={3}
            >
                {/* ------------------ STATUS ------------------ */}
                {! workSituation?.signatureDisabled && (
                    <Grid item xs={12}>
                        <FramedStatus
                            status={workSituation?.isSigned ? 'SIGNED' : 'WAITING'}
                        />
                    </Grid>
                )}
                {/* ------------------ IS SIGNED ------------------ */}
                {! workSituation?.isSigned && (
                    <Grid item xs={12} sm="auto">
                        <Title>
                            {t('status')}
                        </Title>
                        <br />
                        <span>
                            {workSituation?.signatureDisabled ? (
                                t('signatures_disabled')
                            ) : (
                                t('waiting_signature')
                            )}
                        </span>
                    </Grid>
                )}
                {/* ------------------ TRAINING DATE ------------------ */}
                <Grid item xs={12} sm="auto">
                    <Title>
                        {t('training_date')}
                    </Title>
                    <br />
                    <span>
                        {getFormattedDate(workSituation?.trainingDate)}
                    </span>
                </Grid>
                {/* ------------------ STEP MODEL ------------------ */}
                {workSituation?.stepModel && (
                    <Grid item xs={12} sm="auto">
                        <Title>
                            {t('model')}
                        </Title>
                        <br />
                        <span>
                            {workSituation.stepModel?.name}
                        </span>
                    </Grid>
                )}
                {/* -------------------- START & END MORNING / AFTERNOON -------------------- */}
                <Grid item xs={12} sm="auto">
                    <Title>
                        {t('schedule.1')}
                    </Title>
                    {workSituation?.startMorning && (
                        <div className="inline-flex">
                            <span>
                                {getFormattedTime(workSituation?.startMorning)}
                            </span>
                            <ArrowIcon />
                            <span>
                                {getFormattedTime(workSituation?.endMorning)}
                            </span>
                        </div>
                    )}
                    {workSituation?.startAfternoon && (
                        <div className="inline-flex">
                            <span>
                                {getFormattedTime(workSituation?.startAfternoon)}
                            </span>
                            <ArrowIcon />
                            <span>
                                {getFormattedTime(workSituation?.endAfternoon)}
                            </span>
                        </div>
                    )}
                </Grid>
                {/* ------------------ PLACE ------------------ */}
                {workSituation?.placeType && (
                    <Grid item xs={12} sm="auto">
                        <Title>
                            {t('place_type')}
                        </Title>
                        <div className="back-to-line break-word">
                            {t(workSituation.placeType.toLowerCase())}
                            {workSituation?.placeDetails && (
                                <>
                                    <br />
                                    {workSituation.placeDetails}
                                </>
                            )}
                        </div>
                    </Grid>
                )}
                {/* ------------------ COMPANY ------------------ */}
                {workSituation?.company && (
                    <Grid item xs={12} sm="auto">
                        <Title>
                            {t('company')}
                        </Title>
                        <br />
                        <Button
                            component={Link}
                            to={'/app/companies/' + workSituation.company?.id}
                            variant="text"
                            style={{ padding: 0 }}
                        >
                            {workSituation.company?.name}
                        </Button>
                    </Grid>
                )}
                {/* ------------------ SIGN OFF SHEET ------------------ */}
                {workSituation?.signOffSheetUrl && (
                    <Grid item xs={12} sm="auto">
                        <Title>
                            {t('sign_off_sheet')}
                        </Title>
                        <div>
                            <Button
                                onClick={onSaveSignOffSheet}
                                variant="text"
                                style={{ padding: 0 }}
                            >
                                {t('download')}
                            </Button>
                        </div>
                    </Grid>
                )}
                {/* ------------------ COMMENT ------------------ */}
                {workSituation?.comment && (
                    <Grid item xs={12}>
                        <Title>
                            {t('comment')}
                        </Title>
                        <br />
                        <span className="back-to-line">
                            {workSituation.comment}
                        </span>
                    </Grid>
                )}
            </Grid>
        </PelletCard>
    );
};

export default WorkSituationDetails;
