import React, { createContext, useContext, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, List, ListItem, ListItemButton } from '@mui/material';

// Services
import { IDescriptor, ILinkItem, IRepositoryLink, ISimpleResult } from 'services/interfaces';
import { EDescriptorType, ELinkableType, EResultType } from 'services/enums';
import { primaryMain } from 'services/constants';

// Components
import Title from 'components/title';

// Views
import ListItemDescriptor from './list-item-descriptor';
import ListItemLinkItem from './list-item-link-item';

// CSS
import styles from './DashedDescriptorsBlock.module.css';
import { getStoredUser } from 'services/storage';

/* * *
* CONTEXT
*/

export interface IDescriptorBlockContext {
    descriptorResults: ISimpleResult[];
    listItemType: 'default' | 'button' | 'checkbox';
    descriptorTitleAction: ((descriptorType: EDescriptorType) => React.ReactNode) | undefined;
    descriptorOnClick: ((descriptor: IDescriptor) => void) | undefined;
    descriptorChecked: ((descriptor: IDescriptor) => boolean) | undefined;
    linkItemResults: ISimpleResult[];
    linkItemOnClick: ((linkItem: ILinkItem) => void) | undefined;
    listLinkItemChecked: ((linkItem: ILinkItem) => boolean) | undefined;
    linkItemTitleAction: ((repositoryLink: IRepositoryLink) => React.ReactNode) | undefined;
    linkItemAction: ((linkItem: ILinkItem) => React.ReactNode) | undefined;
}

export const DescriptorBlockContext = createContext<IDescriptorBlockContext | null>(null);

/* * *
* DESCRIPTORS FIELDS REQUIRED : id, name, descriptorType
*/

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- DASHED DESCRIPTORS BLOCK ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function DashedDescriptorsBlock({
    descriptors,
    descriptorResults = [],
    listItemType = 'default',
    descriptorTitleAction,
    descriptorOnClick,
    descriptorChecked,
    emptyVisible,
    linkableId,
    linkableType,
    repositoryLinks,
    linkItems,
    linkItemResults = [],
    linkItemTitleAction,
    linkItemOnClick,
    listLinkItemChecked,
    linkItemAction,
    linkItemEmptyVisible,
} : {
    descriptors: IDescriptor[],
    descriptorResults?: ISimpleResult[],
    listItemType?: 'default' | 'button' | 'checkbox',
    descriptorTitleAction?: (descriptorType: EDescriptorType) => React.ReactNode,
    descriptorOnClick?: (descriptor: IDescriptor) => void,
    descriptorChecked?: (descriptor: IDescriptor) => boolean,
    emptyVisible?: boolean,
    linkableId?: string,
    linkableType?: ELinkableType,
    repositoryLinks?: IRepositoryLink[],
    linkItems?: ILinkItem[],
    linkItemResults?: ISimpleResult[],
    linkItemTitleAction?: (repositoryLink: IRepositoryLink) => React.ReactNode,
    linkItemOnClick?: (linkItem: ILinkItem) => void,
    listLinkItemChecked?: (linkItem: ILinkItem) => boolean,
    linkItemAction?: (repositoryLink: ILinkItem) => React.ReactNode,
    linkItemEmptyVisible?: boolean,
}) {

    const knowHows = descriptors.filter(d => d.descriptorType === EDescriptorType.KNOW_HOW);
    const knowledges = descriptors.filter(d => d.descriptorType === EDescriptorType.KNOWLEDGE);
    const aptitudes = descriptors.filter(d => d.descriptorType === EDescriptorType.APTITUDE);

    const filteredRepositoryLinks = useMemo(() => {
        if (repositoryLinks) {
            return repositoryLinks.filter(
                repositoryLink => repositoryLink.atypicalRepository.atypicalLevels.filter(
                    atypicalLevel => { return linkableType === ELinkableType.CUSTOM_REPOSITORY ? atypicalLevel.isBlock : atypicalLevel.isLinkable; }
                ).length > 0
                && (
                    linkItems == null
                    || linkItems.length === 0
                    || repositoryLink.linkItems.some(li => linkItems.find(linkItem => linkItem.id === li.id))
                )
            );
        }
        return [];
    }, [repositoryLinks, linkItems, linkableType]);

    return (
        <DescriptorBlockContext.Provider
            value={{
                descriptorResults: descriptorResults,
                listItemType: listItemType,
                descriptorTitleAction: descriptorTitleAction,
                descriptorOnClick: descriptorOnClick,
                descriptorChecked: descriptorChecked,
                linkItemResults: linkItemResults,
                linkItemTitleAction: linkItemTitleAction,
                linkItemOnClick: linkItemOnClick,
                listLinkItemChecked: listLinkItemChecked,
                linkItemAction: linkItemAction,
            }}
        >
            <Grid padding={3}>
                <Grid
                    container
                    spacing={3}
                >
                    {/* -------------------- KNOW HOW -------------------- */}
                    {(knowHows.length > 0 || emptyVisible) && (
                        <DescriptorList
                            descriptors={knowHows}
                            descriptorType={EDescriptorType.KNOW_HOW}
                        />
                    )}
                    {/* -------------------- KNOWLEDGES -------------------- */}
                    {(knowledges.length > 0 || emptyVisible) && (
                        <DescriptorList
                            descriptors={knowledges}
                            descriptorType={EDescriptorType.KNOWLEDGE}
                        />
                    )}
                    {/* -------------------- APTITUDES -------------------- */}
                    {(aptitudes.length > 0 || emptyVisible) && (
                        <DescriptorList
                            descriptors={aptitudes}
                            descriptorType={EDescriptorType.APTITUDE}
                        />
                    )}
                    {/* -------------------- LINK ITEMS ------------------- */}
                    {filteredRepositoryLinks.map(repositoryLink => (
                        <LinkItemList
                            key={repositoryLink.id}
                            linkableId={linkableId}
                            linkableType={linkableType}
                            repositoryLink={repositoryLink}
                            linkItems={linkItems}
                            linkItemEmptyVisible={linkItemEmptyVisible}
                        />
                    ))}
                </Grid>
            </Grid>
        </DescriptorBlockContext.Provider>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ DESCRIPTOR LIST ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function DescriptorList({
    descriptors,
    descriptorType,
} : {
    descriptors: IDescriptor[],
    descriptorType: EDescriptorType,
}) {

    const { t } = useTranslation();
    const storedUser = getStoredUser();
    const isAdmin = !!(storedUser?.role?.id && Number(storedUser.role.id) === 1);

    const {
        descriptorResults,
        listItemType,
        descriptorTitleAction,
        descriptorOnClick,
    } = useContext(DescriptorBlockContext) as IDescriptorBlockContext;

    const getDescriptorTypeLabel = (descriptorType: EDescriptorType) => {
    
        switch (descriptorType) {
    
            case EDescriptorType.KNOW_HOW:
                return t('know_how');
    
            case EDescriptorType.KNOWLEDGE:
                return t('knowledges');
    
            case EDescriptorType.APTITUDE:
                return t('aptitudes');
        }
    };

    return (
        <Grid item xs={12} xl={4}>
            <div className={styles.borderBlock}>
                <List>
                    {/* -------------------- DESCRIPTOR TYPE -------------------- */}
                    <ListItem>
                        <Grid
                            container
                            alignItems="center"
                        >
                            <Grid item xs>
                                <Title style={{ color: primaryMain }}>
                                    {getDescriptorTypeLabel(descriptorType)} {isAdmin ? `(${descriptors.length})` : ''}
                                </Title>
                            </Grid>
                            {descriptorTitleAction && (
                                <Grid
                                    item xs="auto"
                                    marginLeft={1}
                                >
                                    {descriptorTitleAction(descriptorType)}
                                </Grid>
                            )}
                        </Grid>
                    </ListItem>
                    {/* -------------------- DESCRIPTOR NAME -------------------- */}
                    {descriptors.map(descriptor => {

                        let resultType = descriptorResults?.find(r => r.simpleResultId === descriptor.id)?.resultType;

                        return (
                            <Fragment key={descriptor.id}>
                                {(
                                    listItemType === 'button'
                                    || listItemType === 'checkbox'
                                ) ? (
                                    <ListItemButton
                                        onClick={descriptorOnClick ? () => descriptorOnClick(descriptor) : undefined}
                                        style={{
                                            alignItems: listItemType === 'checkbox' ? 'center' : 'start',
                                            color: descriptor.isMissing ? 'lightslategray' : undefined,
                                            backgroundColor: (resultType && resultType !== EResultType.NONE) ? 'rgba(25, 118, 210, 0.08)' : undefined,
                                        }}
                                    >
                                        <ListItemDescriptor
                                            descriptor={descriptor}
                                            resultType={resultType}
                                        />
                                    </ListItemButton>
                                ) : (
                                    <ListItem
                                        style={{
                                            alignItems: 'start',
                                            backgroundColor: (resultType && resultType !== EResultType.NONE) ? 'rgba(25, 118, 210, 0.08)' : undefined,
                                        }}
                                    >
                                        <ListItemDescriptor
                                            descriptor={descriptor}
                                            resultType={resultType}
                                        />
                                    </ListItem>
                                )}
                            </Fragment>
                        );
                    })}
                </List>
            </div>
        </Grid>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ LINK ITEM LIST ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function LinkItemList({
    linkableId,
    linkableType,
    repositoryLink,
    linkItems,
    linkItemEmptyVisible,
} : {
    linkableId?: string,
    linkableType?: ELinkableType,
    repositoryLink: IRepositoryLink,
    linkItems?: ILinkItem[],
    linkItemEmptyVisible?: boolean,
}) {

    const {
        linkItemResults,
        listItemType,
        linkItemTitleAction,
        linkItemOnClick,
        linkItemAction,
    } = useContext(DescriptorBlockContext) as IDescriptorBlockContext;

    const filteredLinkItems = useMemo(() => {
        return (
            linkItems
            || repositoryLink.linkItems.filter(linkItem => linkItem.linkable_id === linkableId && linkItem.linkableType === linkableType)
        );
    }, [repositoryLink.linkItems, linkItems, linkableId, linkableType]);

    if (filteredLinkItems.length > 0 || linkItemEmptyVisible) {
        return (
            <Grid item xs={12} xl={4}>
                <div className={styles.borderBlock}>
                    <List>
                        {/* -------------------- ATYPICAL REPOSITORY NAME -------------------- */}
                        <ListItem>
                            <Grid
                                container
                                alignItems="center"
                            >
                                <Grid item xs>
                                    <Title style={{ color: primaryMain }}>
                                        {repositoryLink.atypicalRepository?.name}
                                    </Title>
                                </Grid>
                                {linkItemTitleAction && (
                                    <Grid
                                        item xs="auto"
                                        marginLeft={1}
                                    >
                                        {linkItemTitleAction(repositoryLink)}
                                    </Grid>
                                )}
                            </Grid>
                        </ListItem>
                        {/* -------------------- ATYPICAL ITEM NAME -------------------- */}
                        {(
                            repositoryLink.linkItems
                            && filteredLinkItems.length > 0
                        ) && (

                            filteredLinkItems.map(linkItem => {

                                let resultType = linkItemResults?.find(r => r.simpleResultId === linkItem.id)?.resultType;

                                return (
                                    <Fragment key={linkItem.id}>
                                        {listItemType === 'checkbox' ? (
                                            <ListItemButton
                                                onClick={linkItemOnClick ? () => linkItemOnClick(linkItem) : undefined}
                                                style={{ alignItems: 'center' }}
                                            >
                                                <ListItemLinkItem linkItem={linkItem} resultType={resultType} />
                                            </ListItemButton>
                                        ) : (
                                            <ListItem
                                                secondaryAction={linkItemAction ? linkItemAction(linkItem) : undefined}
                                                style={{
                                                    alignItems: 'start',
                                                    backgroundColor: (resultType && resultType !== EResultType.NONE) ? 'rgba(25, 118, 210, 0.08)' : undefined,
                                                }}
                                            >
                                                <ListItemLinkItem
                                                    linkItem={linkItem}
                                                    resultType={resultType}
                                                />
                                            </ListItem>
                                        )}
                                    </Fragment>
                                );
                            }))}
                    </List>
                </div>
            </Grid>
        );
    }

    return;
}
