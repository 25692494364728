import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Permissions
import { canViewAnyArticle, canViewArticle, canCreateArticle, canUpdateArticle } from 'permissions';
import { RequirePerm, DocumentTitleRoute } from '../index';

/* * *
* Lazy Imports
*/

const Articles = lazy(() => import('../../../views/authenticated/articles'));
const Article = lazy(() => import('../../../views/authenticated/articles/article'));
const CreateArticle = lazy(() => import('../../../views/authenticated/articles/create-article'));
const UpdateArticle = lazy(() => import('../../../views/authenticated/articles/update-article'));

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- ARTICLE ROUTES ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ArticleRoutes() {
    return (
        <Routes>
            <Route index element={<RequirePerm perm={canViewAnyArticle()}><Articles /></RequirePerm>} />
            <Route path="create" element={<RequirePerm perm={canCreateArticle()}><CreateArticle /></RequirePerm>} />
            <Route path=":articleId" element={<DocumentTitleRoute title="news.1" id="articleId" />}>
                <Route index element={<RequirePerm perm={canViewArticle()}><Article /></RequirePerm>} />
                <Route path="update" element={<RequirePerm perm={canUpdateArticle()}><UpdateArticle /></RequirePerm>} />
            </Route>
        </Routes>
    );
}
