import { gql } from '@apollo/client';

export const REFLEXIVITY_AXES = gql`
    query ReflexivityAxes(
        $companyId: ID
        $stepType: StepType
    ) {
        reflexivityAxes(
            companyId: $companyId
            stepType: $stepType
        ) {
            id
            name
            stepType
            companies {
                id
                name
                postalCode
                city
            }
        }
    }
`;
