import React from 'react';

// MUI
import { Grid } from '@mui/material';

// Services
import { useMediaQueries } from 'services/hooks';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- SEPARATED CONTAINER ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SeparatedContainer({
    leftChildren,
    rightChildren,
    leftContainerProps,
    rightContainerProps,
} : {
    leftChildren: React.ReactNode,
    rightChildren: React.ReactNode,
    leftContainerProps?: React.CSSProperties,
    rightContainerProps?: React.CSSProperties,
}) {

    const { mediaMD } = useMediaQueries();

    return (
        <Grid container>
            <Grid
                item xs={12} md={6} lg={4} xl={3}
                style={{
                    ...leftContainerProps,
                    borderRight: mediaMD ? '1px solid rgba(0, 0, 0, 0.12)' : undefined,
                    borderBottom: mediaMD ? undefined : '1px solid rgba(0, 0, 0, 0.12)',
                }}
            >
                {leftChildren}
            </Grid>
            <Grid
                item xs={12} md={6} lg={8} xl={9}
                style={rightContainerProps}
            >
                {rightChildren}
            </Grid>
        </Grid>
    );
}
