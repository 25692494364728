import { useState, useEffect } from 'react';

// MUI
import { Drawer, Grid, Button, Divider, IconButton } from '@mui/material';
import { Clear as CloseIcon, ChevronLeft as LeftArrowIcon, ChevronRight as RightArrowIcon } from '@mui/icons-material';

// Services
import { useMediaQueries } from 'services/hooks';

// Components
import MenuList from './menu-list';

// Views
import Logout from 'views/authenticated/logout';

// CSS
import './side-menu.css';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- MENU ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const Menu = ({
    open,
    onClose,
} : {
    open: boolean, 
    onClose: VoidFunction,
}) => {

    const reducedWidth = getComputedStyle(document.documentElement).getPropertyValue('--menu-reduced-width');
    const expandedWidth = getComputedStyle(document.documentElement).getPropertyValue('--menu-expanded-width');

    const { mediaSM, mediaLG } = useMediaQueries();
    const [openLogout, setOpenLogout] = useState(false);
    const [menuState, setMenuState] = useState<'expanded' | 'reduced'>('expanded');

    const handleMenuState = () => {

        let sideMenu = document.getElementById('SideMenu') as HTMLElement;
        let authenticatedLayout = document.getElementById('AuthenticatedLayout') as HTMLElement;

        if (menuState === 'reduced') {
            sideMenu.style.width = expandedWidth;
            authenticatedLayout.style.marginLeft = expandedWidth;
            localStorage.setItem('menuState', 'expanded');
            setMenuState(value => value === 'reduced' ? 'expanded' : 'reduced');
        }
        else {
            sideMenu.style.width = reducedWidth;
            authenticatedLayout.style.marginLeft = reducedWidth;
            localStorage.setItem('menuState', 'reduced');
            setTimeout(() => setMenuState(value => value === 'reduced' ? 'expanded' : 'reduced'), 600);
        }
    };

    useEffect(() => {

        if (mediaLG) {

            let storedMenuState = localStorage.getItem('menuState');
            let sideMenu = document.getElementById('SideMenu') as HTMLElement;
            let authenticatedLayout = document.getElementById('AuthenticatedLayout') as HTMLElement;
    
            if (storedMenuState === 'reduced') {
                sideMenu.style.width = reducedWidth;
                authenticatedLayout.style.marginLeft = reducedWidth;
                setMenuState('reduced');
            }
            else {
                sideMenu.style.width = expandedWidth;
                authenticatedLayout.style.marginLeft = expandedWidth;
                setMenuState('expanded');
            }
        }
        else {

            let authenticatedLayout = document.getElementById('AuthenticatedLayout') as HTMLElement;
            authenticatedLayout.style.marginLeft = '0px';
        }

    }, [mediaLG, reducedWidth, expandedWidth]);

    return (
        <>
            {mediaLG ? (
                <div
                    id="SideMenu"
                    className="SideMenu"
                >
                    <Grid
                        container
                        justifyContent={menuState === 'reduced' ? 'center' : 'end'}
                        sx={{ padding: '6px' }}
                    >
                        <IconButton onClick={handleMenuState}>
                            {menuState === 'reduced' ? (
                                <RightArrowIcon />
                            ) : (
                                <LeftArrowIcon />
                            )}
                        </IconButton>
                    </Grid>
                    <Divider />
                    <MenuList
                        onClose={onClose}
                        onOpenLogout={() => setOpenLogout(true)}
                    />
                </div>
            ) : (
                <Drawer
                    open={open}
                    onClose={onClose}
                    anchor="left"
                    PaperProps={{ style: { width: mediaSM ? undefined : '100%' }}}
                    ModalProps={{ style: { zIndex: 1400 } }}
                >
                    <Grid
                        padding={2}
                        container
                        justifyContent="end"
                    >
                        <Button
                            onClick={onClose}
                            variant="text"
                            color="inherit"
                            size="small"
                        >
                            <CloseIcon />
                        </Button>
                    </Grid>
                    <Divider />
                    <MenuList
                        onClose={onClose}
                        onOpenLogout={() => { onClose(); setOpenLogout(true); }}
                    />
                </Drawer>
            )}
            {/* -------------------- LOGOUT -------------------- */}
            {openLogout && (
                <Logout
                    onClose={() => setOpenLogout(false)}
                />
            )}
        </>
    );
};

export default Menu;
