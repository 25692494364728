import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Dialog, DialogActions, DialogContent, Divider, Button, List, ListItemButton, Checkbox, ListItem } from '@mui/material';

// Services
import {
    IDescriptor,
    IRepositoryItems,
    IRepositoryItem,
    ICustomSkill,
    IAtypicalRepository,
    ILinkItem
} from 'services/interfaces';
import { EDescriptorType } from 'services/enums';
import { useMediaQueries } from 'services/hooks';
import { primaryMain } from 'services/constants';

// Components
import Title from 'components/title';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- DESCRIPTORS NOT MASTERED ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function DescriptorsNotMastered({
    customSkill,
    actionType,
    onClose,
    repositoryItems,
    setRepositoryItems,
} : {
    customSkill: ICustomSkill,
    actionType: 'READ' | 'SELECT',
    onClose: VoidFunction,
    repositoryItems: IRepositoryItem[],
    setRepositoryItems: (repositoryItems: IRepositoryItems) => void,
}) {

    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const getDescriptorArray = (descriptorType: EDescriptorType) => {

        switch (descriptorType) {

            case EDescriptorType.KNOW_HOW:
                return customSkill?.descriptors?.filter(d => d.descriptorType === EDescriptorType.KNOW_HOW);

            case EDescriptorType.KNOWLEDGE:
                return customSkill?.descriptors?.filter(d => d.descriptorType === EDescriptorType.KNOWLEDGE);

            case EDescriptorType.APTITUDE:
                return customSkill?.descriptors?.filter(d => d.descriptorType === EDescriptorType.APTITUDE);

            default:
                return [];
        }
    };

    const descriptorChecked = (descriptorId: string) => {
        return !!repositoryItems.find(i => i.id === customSkill.id)?.descriptors?.some(id => id === descriptorId);
    };

    const onCheckDescriptor = (descriptor: IDescriptor) => {
        let updatedItems = [...repositoryItems];
        let index = updatedItems.findIndex(i => i.id === customSkill.id);
        let updatedDescriptors = updatedItems[index]?.descriptors || [];

        let indexDescriptor = updatedDescriptors.findIndex(id => id === descriptor.id);
        if (indexDescriptor === -1) {
            updatedDescriptors.push(descriptor.id);
        }
        else {
            updatedDescriptors.splice(indexDescriptor, 1);
        }

        updatedItems[index].descriptors = updatedDescriptors;
        setRepositoryItems({ customSkills: updatedItems });
    };

    const linkItemChecked = (linkItemId: string) => {
        return !!repositoryItems.find(i => i.id === customSkill.id)?.linkItems?.some(id => id === linkItemId);
    };

    const onCheckLinkItem = (linkItem: ILinkItem) => {
        let updatedItems = [...repositoryItems];
        let index = updatedItems.findIndex(i => i.id === customSkill.id);
        let updatedLinkItems = updatedItems[index]?.linkItems || [];

        let indexDescriptor = updatedLinkItems.findIndex(id => id === linkItem.id);
        if (indexDescriptor === -1) {
            updatedLinkItems.push(linkItem.id);
        }
        else {
            updatedLinkItems.splice(indexDescriptor, 1);
        }

        updatedItems[index].linkItems = updatedLinkItems;
        setRepositoryItems({ customSkills: updatedItems });
    };

    const atypicalRepositories = useMemo(() => {
        let atypicalRepositories: IAtypicalRepository[] = [];

        customSkill.linkItems.forEach(li => {
            let atypicalRepository = li.atypicalItem?.atypicalRepository;
            if (atypicalRepository && ! atypicalRepositories.some(ar => ar.id === atypicalRepository.id)) {
                atypicalRepositories.push(atypicalRepository);
            }
        })

        return atypicalRepositories;
    }, [customSkill.linkItems]);

    return (
        <Dialog
            open
            onClose={onClose}
            fullWidth
            fullScreen={!mediaMD}
        >
            <DialogContent>
                <div className="dialog-title">
                    {customSkill?.customBlock?.position}.{customSkill?.skill?.position} {customSkill?.skill?.name}
                </div>
                {actionType === 'SELECT' && (
                    <div>
                        {atypicalRepositories.length > 0 ? t('specify_items_to_work_on') : t('specify_descriptors_to_work_on')} :
                    </div>
                )}
                {Object.values(EDescriptorType).map(value => {
                    let array = getDescriptorArray(value);
                    return (
                        <Fragment key={value}>
                            {(array && array.length > 0) && (
                                <div
                                    className="framed-block"
                                    style={{ borderColor: primaryMain, padding: 0, marginTop: 24 }}
                                >
                                    <div style={{ padding: '12px 12px 0px 12px' }}>
                                        <Title style={{ color: primaryMain }}>
                                            {t(value.toLowerCase())}
                                        </Title>
                                    </div>
                                    <List style={{ alignItems: 'start' }}>
                                        {array.map(descriptor => (
                                            actionType === 'READ' ? (
                                                <ListItem
                                                    key={descriptor.id}
                                                >
                                                    <span>-</span>
                                                    <span style={{ marginLeft: 8 }}>
                                                        {descriptor.name}
                                                    </span>
                                                </ListItem>
                                            ) : (
                                                <ListItemButton
                                                    key={descriptor.id}
                                                    onClick={() => onCheckDescriptor(descriptor)}
                                                >
                                                    <Checkbox
                                                        checked={descriptorChecked(descriptor.id)}
                                                        style={{ padding: 0 }}
                                                    />
                                                    <span style={{ marginLeft: 8 }}>
                                                        {descriptor.name}
                                                    </span>
                                                </ListItemButton>   
                                            )
                                        ))}
                                    </List>
                                </div>
                            )}
                        </Fragment>
                    );
                })}
                {atypicalRepositories.length > 0 && (
                    atypicalRepositories.map(ar => {
                        return (
                            <Fragment key={'ar' + ar.id}>
                                <div
                                    className="framed-block"
                                    style={{ borderColor: primaryMain, padding: 0, marginTop: 24 }}
                                >
                                    <div style={{ padding: '12px 12px 0px 12px' }}>
                                        <Title style={{ color: primaryMain }}>
                                            {ar.name.toUpperCase()}
                                        </Title>
                                    </div>
                                    <List style={{ alignItems: 'start' }}>
                                        {customSkill.linkItems.filter(li => li.atypicalItem?.atypicalRepository?.id === ar.id)
                                            .map(linkItem => (
                                                actionType === 'READ' ? (
                                                    <ListItem
                                                        key={'li' + linkItem.id}
                                                    >
                                                        <span>-</span>
                                                        <span style={{ marginLeft: 8 }}>
                                                            {linkItem.atypicalItem?.parentItem && (
                                                                <>
                                                                    <span className="subtitle">
                                                                        {linkItem.atypicalItem.parentItem.name}
                                                                    </span>
                                                                    <br/>
                                                                </>
                                                            )}
                                                            {linkItem.atypicalItem?.name}
                                                        </span>
                                                    </ListItem>
                                                ) : (
                                                    <ListItemButton
                                                        key={'li' + linkItem.id}
                                                        onClick={() => onCheckLinkItem(linkItem)}
                                                    >
                                                        <Checkbox
                                                            checked={linkItemChecked(linkItem.id)}
                                                            style={{ padding: 0 }}
                                                        />
                                                        <span style={{ marginLeft: 8 }}>
                                                            {linkItem.atypicalItem?.parentItem && (
                                                                <>
                                                                    <span className="subtitle">
                                                                        {linkItem.atypicalItem.parentItem.name}
                                                                    </span>
                                                                    <br/>
                                                                </>
                                                            )}
                                                            {linkItem.atypicalItem?.name}
                                                        </span>
                                                    </ListItemButton>
                                                )
                                        ))}
                                    </List>
                                </div>
                            </Fragment>
                        );
                    })
                )}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
