import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Grid, Divider, Fab, Dialog, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import { Edit as SignIcon } from '@mui/icons-material';

// Apollo
import { useMutation } from '@apollo/client';
import { UPLOAD_TRAINER_WORK_SITUATION_SIGNATURE } from './gql-upload-trainer-signature';

// Services
import { IWorkSituation, ITrainerWorkSituation } from 'services/interfaces';
import { getErrorExtensions } from 'services/utils';

// Components
import Title from 'components/title';
import SignCard from 'components/sign-card';
import PelletCard from 'components/pellet-card';
import { isCanvasBlank } from 'components/sign-card/utils';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ UPLOAD TRAINER SIGNATURES ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const UploadTrainerSignatures = ({
    workSituation,
    refetch,
} : {
    workSituation: IWorkSituation | undefined,
    refetch: any,
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [trainerWS, setTrainerWS] = useState<ITrainerWorkSituation | null>(null);

    return (
        <>
            <PelletCard
                paddingTop={0}
                startIcon={<SignIcon color="secondary" />}
                summary={(
                    <Grid item xs>
                        {t('trainer_signatures')}
                    </Grid>
                )}
            >
                <table>
                    <thead>
                        <tr>
                            <td className="table-td">
                                <Title>
                                    {t('name')}
                                </Title>
                            </td>
                            <td className="table-td">
                                <Title>
                                    {t('status')}
                                </Title>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {(
                            workSituation?.trainerWorkSituations
                            && workSituation.trainerWorkSituations.length > 0
                        ) && (
                            workSituation.trainerWorkSituations.map(tws => (
                                <tr key={tws.id}>
                                    <td className="table-td">
                                        {tws.trainer?.fullName}
                                    </td>
                                    <td className="table-td">
                                        {tws.signatureUrl ? t('signed') : (workSituation?.signOffSheetUrl ? t('see_sign_off_sheet') : t('waiting_signature'))}
                                    </td>
                                    <td className="table-td">
                                        <Fab
                                            onClick={() => { setTrainerWS(tws); setOpen(true); }}
                                            color="secondary"
                                        >
                                            <SignIcon />
                                        </Fab>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </PelletCard>
            {(open && trainerWS) && (
                <SignatureDialog
                    trainerWorkSituation={trainerWS}
                    onClose={() => setOpen(false)}
                    refetch={() => { setOpen(false); refetch(); }}
                />
            )}
        </>
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SIGNATURE DIALOG ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const SignatureDialog = ({
    trainerWorkSituation,
    onClose,
    refetch,
} : {
    trainerWorkSituation: ITrainerWorkSituation,
    onClose: () => void,
    refetch: any,
}) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [uploadTrainerSignature, { loading, error, data }] = useMutation(UPLOAD_TRAINER_WORK_SITUATION_SIGNATURE);

    const onUploadTrainerSignature = () => {
        if (isCanvasBlank()) {
            enqueueSnackbar(t('missing_signature'), { variant: 'warning' });
        }
        else {
            let canvas = document.getElementById('signCanvas') as HTMLCanvasElement;
            canvas.toBlob(blob => {
                uploadTrainerSignature({
                    variables: {
                        id: trainerWorkSituation.id,
                        file: blob,
                    },
                });
            });
        }
    };

    useEffect(() => {
        if (data?.uploadTrainerWorkSituationSignature) {
            enqueueSnackbar(t('added_successfully'), { variant: 'success' });
            refetch();
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <Dialog
            id="dialog"
            open
            onClose={onClose}
            fullScreen
        >
            <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="dialog-title">
                    {t('signature')} {t('of.1')} {trainerWorkSituation?.trainer?.fullName}
                </div>
                <SignCard />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                >
                    {t('close')}
                </Button>
                <Button
                    onClick={onUploadTrainerSignature}
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                    color="secondary"
                >
                    {t('validate')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UploadTrainerSignatures;
