import { forwardRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneInput, PhoneInputProps, defaultCountries, ParsedCountry } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';

// CSS
import styles from './ReactInternationalPhone.module.css';

const phoneUtil = PhoneNumberUtil.getInstance();

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- REACT INTERNATIONAL PHONE -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IReactInternationalPhoneProps extends Omit<PhoneInputProps, 'onChange'> {
    onChange?: (phoneNumber: string, isInvalid: boolean) => void;
    defaultPhoneNumberPrefix?: string;
    error?: boolean;
}

const ReactInternationalPhone = forwardRef(function ReactInternationalPhone(props: IReactInternationalPhoneProps, ref: any) {

    const { t } = useTranslation();
    const [isInvalid, setIsInvalid] = useState(false);

    const defaultCountry = useMemo(() => {
        if (
            props.defaultPhoneNumberPrefix
            && props.defaultPhoneNumberPrefix !== '33'
        ) {
            let country = defaultCountries.find(c => c[2] === props.defaultPhoneNumberPrefix);
            if (country) {
                return country[1];
            }
        }
        return 'fr';
    }, [props.defaultPhoneNumberPrefix]);

    const onChangePhone = (phone: string, meta: { country: ParsedCountry, inputValue: string }) => {
        if (
            meta.inputValue.split(`+${meta.country.dialCode}`)[1] !== ''
            && meta.inputValue.split(`+${meta.country.dialCode}`)[1] !== ' '
            && !isValidPhoneNumber(phone)
        ) {
            setIsInvalid(true);
            return props.onChange ? props.onChange(phone, true) : undefined;
        }
        else {
            setIsInvalid(false);
            return props.onChange ? props.onChange(phone, false) : undefined;
        }
    };

    return (
        <>
            <PhoneInput
                {...props}
                ref={ref}
                onChange={(phone, meta) => onChangePhone(phone, meta)}
                defaultCountry={defaultCountry}
                forceDialCode
                className={isInvalid ? 'react-international-phone-error' : undefined}
            />
            {isInvalid && (
                <p className={styles.helperText}>
                    {t('invalid_phone_number')}
                </p>
            )}
        </>
    );
});

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- IS VALID PHONE NUMBER --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const isValidPhoneNumber = (phoneNumber: string | undefined) => {
    try {
        return phoneNumber ? phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber)) : false;
    }
    catch (error) {
        return false;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- GET PHONE NUMBER ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const getPhoneNumber = (phoneNumber: string | undefined) => {
    try {
        return phoneNumber ? phoneUtil.getNationalSignificantNumber(phoneUtil.parseAndKeepRawInput(phoneNumber)) : '';
    }
    catch (error) {
        return '';
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ GET PHONE NUMBER PREFIX --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getPhoneNumberPrefix = (phoneNumber: string) => {
    try {
        let regionCode = phoneUtil.getRegionCodeForNumber(phoneUtil.parseAndKeepRawInput(phoneNumber))
        return regionCode ? phoneUtil.getCountryCodeForRegion(regionCode).toString() : '';
    }
    catch (error) {
        return '';
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- GET FORMATTED PHONE NUMBER ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getFormattedPhoneNumber = (phoneNumber: string, phoneNumberPrefix: string) => {
    try {
        let phone = `+${phoneNumberPrefix}${phoneNumber}`;
        return phoneUtil.formatInOriginalFormat(phoneUtil.parseAndKeepRawInput(phone));
    }
    catch {
        return phoneNumber;
    }
};

export default ReactInternationalPhone;
