import { gql } from '@apollo/client';

export const REFLECTION_PHASE = gql`
    query ReflectionPhase($id: ID!) {
        reflectionPhase(id: $id) {
            id
            trainingDate
            startTime
            endTime
            result
            personalResult
            weather
            learnerSignatureUrl
            signOffSheetUrl
            isSigned
            proofItems {
                id
                name
                fileUrl
                fileSize
                externalLink
                mastery
                comment
                createdAt
                updatedAt
                creator {
                    id
                    fullName
                }
                lastModifier {
                    id
                    fullName
                }
            }
            reflexivityAxis {
                id
                name
            }
            reflexivityQuestions {
                id
                position
                description
                pivot {
                    answer
                }
            }
            reflectionPhaseTrainers {
                id
                signatureUrl
                weather
                trainer {
                    id
                    fullName
                }
            }
            learnerWorkSituation {
                id
                learner {
                    id
                    fullName
                }
                roadmap {
                    id
                    name
                    endDate
                    learner {
                        id
                    }
                    company {
                        id
                        name
                    }
                    customRepository {
                        id
                        name
                    }
                }
                workSituation {
                    id
                    trainingDate
                    signatureDisabled
                    customRepository {
                        id
                        name
                    }
                    company {
                        id
                        name
                        footer
                    }
                    descriptors {
                        id
                        name
                        descriptorType
                        skill {
                            name
                        }
                    }
                    tasks {
                        id
                        name
                        position
                        activity {
                            id
                            position
                        }
                        observableElements {
                            id
                            name
                        }
                        successIndicators {
                            id
                            name
                        }
                    }
                    atypicalItems {
                        id
                        name
                        parentItem {
                            id
                            name
                        }
                        atypicalRepository {
                            id
                            name
                        }
                    }
                    linkItems {
                        id
                        skillName
                        atypicalItem {
                            id
                            name
                            parentItem {
                                id
                                name
                            }
                            atypicalRepository {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`
