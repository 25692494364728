import { useEffect, useLayoutEffect, useRef, useState } from 'react';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { REFLEXIVITY_AXIS } from 'views/authenticated/management/reflexivity-axes/reflexivity-axis/gql-reflexivity-axis';

// Services
import { IReflexivityAxis, IReflexivityQuestion, IReflexivityQuestionItem, ISelectOption } from 'services/interfaces';
import { EStepType } from 'services/enums';

// Views
import ReflexivityAxis from './01-reflexivity-axis';
import ReflexivityQuestions from './02-reflexivity-questions';
import ReflexivityAnswers from './03-reflexivity-answers';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ REFLEXIVITY QUESTIONS FORM ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

interface IReflexivityQuestionsForm {
    startPosition: number;
    reflexivityAxis: ISelectOption | null;
    setReflexivityAxis: (reflexivityAxis: ISelectOption | null) => void;
    reflexivityQuestionItems: IReflexivityQuestionItem[];
    setReflexivityQuestionItems: (reflexivityQuestionItems: IReflexivityQuestionItem[]) => void;
    stepType?: EStepType;
    withAnswers?: boolean;
}

export default function ReflexivityQuestionsForm(props: IReflexivityQuestionsForm) {

    const preselectQuestions = useRef(false);
    const [reflexivityQuestions, setReflexivityQuestions] = useState<IReflexivityQuestion[]>([]);
    const [reflexivityAxis, { loading, data }] = useLazyQuery<{ reflexivityAxis: IReflexivityAxis }>(REFLEXIVITY_AXIS);

    useEffect(() => {
        if (props.reflexivityAxis?.value) {
            reflexivityAxis({ variables: { id: props.reflexivityAxis.value } });
        }
        if (props.reflexivityAxis?.value && data?.reflexivityAxis) {
            setReflexivityQuestions(data.reflexivityAxis.reflexivityQuestions);
        }
        if (props.reflexivityAxis == null) {
            setReflexivityQuestions([]);
        }
        // eslint-disable-next-line
    }, [props.reflexivityAxis, data]);

    useLayoutEffect(() => {
        if (
            reflexivityQuestions.length > 0
            && preselectQuestions.current
        ) {
            props.setReflexivityQuestionItems(reflexivityQuestions.map(rq => props.withAnswers
                ? ({ id: rq.id, answer: '' })
                : ({ id: rq.id })
            ));
        }
        // eslint-disable-next-line
    }, [reflexivityQuestions]);

    return (
        <>
            {/* -------------------- 01 REFLEXIVITY AXIS -------------------- */}
            <ReflexivityAxis
                loading={loading}
                position={props.startPosition}
                stepType={props.stepType}
                reflexivityAxis={props.reflexivityAxis}
                setReflexivityAxis={value => { if (!preselectQuestions.current) { preselectQuestions.current = true; } props.setReflexivityAxis(value); }}
            />
            {/* -------------------- 02 REFLEXIVITY QUESTIONS -------------------- */}
            {(
                ! props.withAnswers
                && reflexivityQuestions.length > 0
            ) && (
                <ReflexivityQuestions
                    position={props.startPosition + 1}
                    reflexivityQuestions={reflexivityQuestions}
                    reflexivityQuestionItems={props.reflexivityQuestionItems}
                    setReflexivityQuestionItems={props.setReflexivityQuestionItems}
                />
            )}
            {/* -------------------- 03 REFLEXIVITY ANSWERS -------------------- */}
            {(
                props.withAnswers
                && reflexivityQuestions.length > 0
            ) && (
                <ReflexivityAnswers
                    position={props.startPosition + 1}
                    reflexivityQuestions={reflexivityQuestions}
                    reflexivityQuestionItems={props.reflexivityQuestionItems}
                    setReflexivityQuestionItems={props.setReflexivityQuestionItems}
                />
            )}
        </>
    );
}
