import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Tooltip } from '@mui/material';
import { PhotoCamera as CameraIcon } from '@mui/icons-material';

// Apollo
import { useMutation } from '@apollo/client';
import { UPLOAD_USER_PHOTO } from './gql-upload-user-photo';

// Services
import { getStoredUser } from 'services/storage';
import { getErrorExtensions } from 'services/utils';

// Components
import FileInput from 'components/file-input';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- UPLOAD USER PHOTO ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function UploadUserPhoto({
    userId,
    refetch,
    style,
} : {
    userId?: string,
    refetch: any,
    style?: React.CSSProperties,
}) {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [uploadUserPhoto, { loading, error, data }] = useMutation(UPLOAD_USER_PHOTO);

    const onChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            uploadUserPhoto({
                variables: {
                    id: userId,
                    file: files[0],
                },
            });
        }
    };

    useEffect(() => {
        if (data?.uploadUserPhoto) {

            let storedUser = getStoredUser();
            if (userId === storedUser?.id) {
    
                let updatedUser = Object.assign({}, storedUser, {
                    photoUrl: data.uploadUserPhoto?.photoUrl,
                });

                if (localStorage.getItem('stayConnected') === '1') {
                    localStorage.setItem('user', JSON.stringify(updatedUser));
                }
                else {
                    sessionStorage.setItem('user', JSON.stringify(updatedUser));
                }
    
                const event = new Event('updateAvatar');
                document.dispatchEvent(event);
            }

            enqueueSnackbar(t('updated_successfully'), { variant: 'success' });
            refetch();
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <Tooltip title={t('change_profile_picture')}>
            <div>
                <FileInput
                    onChange={onChange}
                    inputId="userPhoto"
                    accept="image/*"
                    icon={<CameraIcon />}
                    loading={loading}
                    disabledLabel
                    containerProps={style}
                />
            </div>
        </Tooltip>
    );
}
