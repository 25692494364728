import { useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InputActionMeta, ActionMeta } from 'react-select';

// Lodash
import { debounce } from 'lodash';

// Apollo
import { useQuery } from '@apollo/client';
import { USERS } from 'views/authenticated/users/gql-users';

// Services
import { IUser } from 'services/interfaces';

// Components
import Select, { ISelectProps } from 'components/react-select';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SELECT USER ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ISelectUserProps extends ISelectProps {
    onChange: (newValue: any) => void;
    placeholder?: string;
    companyId?: string;
    roleIds?: string[];
    filterIds?: string[];
}

export default function SelectUser(props: ISelectUserProps) {

    const { companyId, roleIds, filterIds } = props;
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');

    const { loading, data } = useQuery<{ users: { data: IUser[] }}>(USERS, {
        variables: {
            page: 1,
            first: 10,
            search: '%' + searchValue + '%',
            companyId: companyId,
            roles: roleIds,
        },
    });

    const delayedOnSearch = useRef(debounce(value => setSearchValue(value), 500)).current;

    const handleDelayedOnSearch = (value: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change') {
            delayedOnSearch(value);
        }
    };

    const handleOnChange = (value: any, actionMeta: ActionMeta<any>) => {
        if (actionMeta.action === 'clear') {
            setSearchValue('');
        }
        props.onChange(value);
    };

    const userOptions = useMemo(() => {
        if(data?.users?.data) {

            let users = data.users.data.map(u => ({
                value: u.id,
                label: u.fullName,
                filter: `${u.firstName} ${u.lastName}`,
            }));

            if (filterIds) {
                users = users.filter(u => filterIds.every(id => id !== u.value));
            }

            return users;
        }
        return [];
    }, [data?.users, filterIds]);

    return (
        <Select
            {...props}
            placeholder={props.placeholder || t('search_user')}
            onChange={(value, actionMeta) => handleOnChange(value, actionMeta)}
            onInputChange={(value, actionMeta) => handleDelayedOnSearch(value, actionMeta)}
            options={userOptions}
            isLoading={loading}
            hasFilterOption
        />
    );
}
