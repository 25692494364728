import React from 'react';

// Services
import { IState } from '../create-reflection-phase';

// Views
import ReflectionPhaseDetails from './01-details';
import SelectedItems from './02-selected-items';
import ReflectionPhaseQuestions from './03-reflexivity-questions';
import ValidateReflectionPhase from './05-validate';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- REFLECTION PHASE FORM --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const ReflectionPhaseForm = ({
    state,
    setState,
    onSubmit,
    loading,
} : {
    state: IState,
    setState: React.Dispatch<React.SetStateAction<IState>>,
    onSubmit: () => void,
    loading: boolean,
}) => {
    return (
        <>
            {/* -------------------- 01 DETAILS -------------------- */}
            <ReflectionPhaseDetails
                state={state}
                setState={setState}
            />
            {/* -------------------- 02 SELECTED ITEMS -------------------- */}
            <SelectedItems
                state={state}
            />
            {/* -------------------- 03 REFLEXIVITY QUESTIONS -------------------- */}
            <ReflectionPhaseQuestions
                state={state}
                setState={setState}
            />
            {/* -------------------- 05 VALIDATE -------------------- */}
            <ValidateReflectionPhase
                state={state}
                onSubmit={onSubmit}
                loading={loading}
            />
        </>
    );
};

export default ReflectionPhaseForm;
