import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Dayjs
import dayjs from 'dayjs';

// Apollo
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_REFLECTION_PHASE } from './gql-update-reflection-phase';
import { REFLECTION_PHASE } from '../reflection-phase/gql-reflection-phase';

// Services
import { getErrorExtensions, transformTimeToDate, connectOrCreateUser, getBackUrl, getBreadcrumbItems } from 'services/utils';
import { IBreadcrumbItem, IReflectionPhase } from 'services/interfaces';
import { IState } from '../create-reflection-phase';

// Views
import ReflectionPhaseForm from '../reflection-phase-form';

// Components
import Title from 'components/title';
import Spinner from 'components/spinner';
import Error from 'components/error';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ FETCH ROADMAP ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const FetchReflectionPhase = ({
    source,
    defaultBreadcrumbItems,
    setBreadcrumbItems,
} : {
    source: 'USER' | 'ROADMAP' | 'COMPANY',
    defaultBreadcrumbItems: IBreadcrumbItem[],
    setBreadcrumbItems: React.Dispatch<React.SetStateAction<IBreadcrumbItem[]>>,
}) => {

    const { t } = useTranslation();
    const { id, roadmapId, reflectionPhaseId } = useParams();

    const { loading, error, data } = useQuery<{ reflectionPhase: IReflectionPhase }>(REFLECTION_PHASE, {
        variables: { id: reflectionPhaseId },
    });

    useEffect(() => {
        if (data?.reflectionPhase) {

            let workSituation = data?.reflectionPhase.learnerWorkSituation?.workSituation;

            const { items } = getBreadcrumbItems({
                mainId: id,
                workSituation,
                source,
                items: defaultBreadcrumbItems,
                roadmapId,
                reflectionPhaseId,
                action: t('edit'),
            });

            setBreadcrumbItems(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <UpdateReflectionPhase
            reflectionPhase={data?.reflectionPhase}
            roadmapId={roadmapId}
        />
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- UPDATE REFLECTION PHASE -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const UpdateReflectionPhase = ({
    reflectionPhase,
    roadmapId,
} : {
    reflectionPhase: IReflectionPhase | undefined,
    roadmapId: string | undefined,
}) => {

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const [updateReflectionPhase, { loading, error, data }] = useMutation(UPDATE_REFLECTION_PHASE);

    const defaultTrainers = useMemo(() => {
        if (reflectionPhase?.reflectionPhaseTrainers) {
            return reflectionPhase.reflectionPhaseTrainers.map(p => ({
                value: p.trainer?.id,
                label: p.trainer?.fullName,
            }));
        }
        return [];
    }, [reflectionPhase]);

    const [state, setState] = useState<IState>({
        trainingDate: reflectionPhase?.trainingDate ? dayjs(reflectionPhase?.trainingDate) : null,
        startTime: transformTimeToDate(reflectionPhase?.startTime),
        endTime: transformTimeToDate(reflectionPhase?.endTime),
        result: reflectionPhase?.result || '',
        trainers: defaultTrainers,
        learnerWorkSituationDate: reflectionPhase?.learnerWorkSituation?.workSituation?.trainingDate || '',
        roadmapEndDate: reflectionPhase?.learnerWorkSituation?.roadmap?.endDate || null,
        descriptors: reflectionPhase?.learnerWorkSituation?.workSituation?.descriptors || [],
        tasks: reflectionPhase?.learnerWorkSituation?.workSituation?.tasks || [],
        atypicalItems: reflectionPhase?.learnerWorkSituation?.workSituation?.atypicalItems || [],
        linkItems: reflectionPhase?.learnerWorkSituation?.workSituation?.linkItems || [],
        reflexivityAxis: reflectionPhase?.reflexivityAxis ? ({ value: reflectionPhase.reflexivityAxis?.id, label: reflectionPhase.reflexivityAxis?.name }) : null,
        reflexivityQuestionItems: reflectionPhase?.reflexivityQuestions ? reflectionPhase.reflexivityQuestions.map(q => ({ id: q.id, answer: q.pivot?.answer })) : [],
    });

    const getTrainersToCreate = () => {
        return state.trainers
            .filter(t => defaultTrainers.every(trainer => trainer?.value !== t.value))
            .map(t => ({ trainer: connectOrCreateUser(t) }));
    };

    const getTrainersToDelete = () => {
        let reflectionPhaseTrainers = reflectionPhase?.reflectionPhaseTrainers;
        if (reflectionPhaseTrainers && reflectionPhaseTrainers.length > 0) {
            return reflectionPhaseTrainers
            .filter(p => state.trainers.every(t => t?.value !== p?.trainer?.id))
            .map(p => p.id);
        }
        return [];
    };

    const onUpdateReflectionPhase = () => {
        updateReflectionPhase({
            variables: {
                id: reflectionPhase?.id,
                trainingDate: dayjs(state.trainingDate).format('YYYY-MM-DD'),
                startTime: dayjs(state.startTime).format('HH:mm:00'),
                endTime: dayjs(state.endTime).format('HH:mm:00'),
                result: state.reflexivityAxis ? '' : state.result,
                reflectionPhaseTrainers: {
                    create: getTrainersToCreate(),
                    delete: getTrainersToDelete(),
                },
                reflexivityAxis: state.reflexivityAxis ? { connect: state.reflexivityAxis.value } : reflectionPhase?.reflexivityAxis ? { disconnect: true } : null,
                reflexivityQuestions: { sync: state.reflexivityQuestionItems },
            },
        });
    };

    useEffect(() => {
        if (data?.updateReflectionPhase) {
            
            enqueueSnackbar(t('updated_successfully'), { variant: 'success' });

            if (roadmapId) {
                navigate('/app/users/' + reflectionPhase?.learnerWorkSituation.learner.id + '/roadmaps/' + roadmapId);
            }
            else {
                navigate(getBackUrl(pathname));
            }
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <>
            <div className="container-padding">
                <Title style={{ fontSize: 18 }}>
                    {t('edit_reflection_phase')}
                </Title>
            </div>
            <ReflectionPhaseForm
                state={state}
                setState={setState}
                onSubmit={onUpdateReflectionPhase}
                loading={loading}
            />
        </>
    );
};

export default FetchReflectionPhase;
