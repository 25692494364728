import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Badge, Fab, IconButton, Tooltip } from '@mui/material';
import { Add as AddIcon, Info as InfoIcon, HelpCenter as QuestionInfo } from '@mui/icons-material';

// Services
import { ICustomRepository, ICustomSkill, ISelfPlacementResult } from 'services/interfaces';
import { EMastery, ERepositoryItemType, EStepType } from 'services/enums';

// Views
import DescriptorsNotMastered from './descriptors-not-mastered';
import { getSelfResultIcon, RatingButton, RatingSelfMasteryButton, ResponsiveTable, RatingGlobalButton, SelfResultInfos } from '../utils';
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../index';

interface IState {
    openSelfResultInfos: boolean;
    openDescriptors: boolean;
    actionType: 'READ' | 'SELECT';
    selectedCustomSkill: ICustomSkill | undefined;
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- RATING CUSTOM SKILLS --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function RatingCustomSkills({
    customRepository,
    selfPlacementResults,
} : {
    customRepository: ICustomRepository | undefined,
    selfPlacementResults: ISelfPlacementResult[],
}) {

    const { t } = useTranslation();

    const {
        setRepositoryItems,
        stepType,
        customSkills: repositoryItems,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const [state, setState] = useState<IState>({
        openSelfResultInfos: false,
        openDescriptors: false,
        actionType: 'READ',
        selectedCustomSkill: undefined,
    });

    const onOpenDescriptors = (actionType: 'READ' | 'SELECT', customSkill: ICustomSkill | undefined) => {
        setState(prevState => ({
            ...prevState,
            openDescriptors: true,
            actionType: actionType,
            selectedCustomSkill: customSkill,
        }));
    };

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <td />
                        {(
                            stepType !== EStepType.SELF_PLACEMENT
                            && repositoryItems.length > 1
                        ) ? (
                            <td className="table-td">
                                <RatingGlobalButton
                                    repositoryItemType={ERepositoryItemType.CUSTOM_SKILL}
                                    repositoryItems={repositoryItems}
                                    setRepositoryItems={setRepositoryItems}
                                />
                            </td>
                        ) : (
                            <td />
                        )}
                        {selfPlacementResults.length > 0 && (
                            <td
                                className="table-td"
                                style={{ textAlign: 'center' }}
                            >
                                <Tooltip title={t('self_placement_results')}>
                                    <IconButton onClick={() => setState(prevState => ({ ...prevState, openSelfResultInfos: true }))}>
                                        <QuestionInfo fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                {state.openSelfResultInfos && (
                                    <SelfResultInfos
                                        title={t('self_placement_results')}
                                        onClose={() => setState(prevState => ({ ...prevState, openSelfResultInfos: false }))}
                                    />
                                )}
                            </td>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {repositoryItems.map((repositoryItem, index) => {

                        let customSkill = customRepository?.customBlocks
                            ?.find(cb => cb.customSkills.some(cs => cs.id === repositoryItem.id))?.customSkills
                            ?.find(cs => cs.id === repositoryItem.id);

                        return (
                            <ResponsiveTable
                                key={index}
                                index={index}
                                children1={(
                                    <div className="inline-flex">
                                        <span>
                                            {customSkill?.customBlock?.position}.{customSkill?.skill?.position} {customSkill?.skill?.name}
                                        </span>
                                        {(
                                            customSkill?.descriptors
                                            && customSkill.descriptors.length > 0
                                        ) && (
                                            <Tooltip title={t('descriptors')}>   
                                                <IconButton
                                                    onClick={() => onOpenDescriptors('READ', customSkill)}
                                                    style={{ padding: 6 }}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </div>
                                )}
                                children2={(
                                    (stepType === EStepType.SELF_PLACEMENT) ? (
                                        <RatingSelfMasteryButton
                                            itemId={repositoryItem.id}
                                            selfMastery={repositoryItem.selfMastery}
                                            repositoryItemType={ERepositoryItemType.CUSTOM_SKILL}
                                            repositoryItems={repositoryItems}
                                            setRepositoryItems={setRepositoryItems}
                                        />
                                    ) : (
                                        <RatingButton
                                            itemId={repositoryItem.id}
                                            mastery={repositoryItem.mastery}
                                            repositoryItemType={ERepositoryItemType.CUSTOM_SKILL}
                                            repositoryItems={repositoryItems}
                                            setRepositoryItems={setRepositoryItems}
                                        />
                                    )
                                )}
                                children3={(
                                    selfPlacementResults.length > 0
                                    ? getSelfResultIcon(repositoryItem.id, ERepositoryItemType.CUSTOM_SKILL, selfPlacementResults)
                                    : undefined
                                )}
                                children4={(
                                    repositoryItem.mastery === EMastery.NOT_VALIDATED
                                    ? (
                                        <Tooltip title={t('specify_descriptors_to_work_on')}>
                                            <Badge
                                                badgeContent={(repositoryItem.descriptors?.length ?? 0) + (repositoryItem.linkItems?.length ?? 0)}
                                                invisible={(repositoryItem.descriptors == null || repositoryItem.descriptors.length === 0) && (repositoryItem.linkItems == null || repositoryItem.linkItems.length === 0)}
                                                color="secondary"
                                                overlap="circular"
                                            >
                                                <Fab
                                                    onClick={() => onOpenDescriptors('SELECT', customSkill)}
                                                    style={{ boxShadow: 'none' }}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Badge>
                                        </Tooltip>
                                    )
                                    : undefined
                                )}
                            />
                        );
                    })}
                </tbody>
            </table>
            {/* -------------------- SPECIFY DESCRIPTORS NOT MASTERED -------------------- */}
            {(state.openDescriptors && state.selectedCustomSkill) && (
                <DescriptorsNotMastered
                    customSkill={state.selectedCustomSkill}
                    actionType={state.actionType}
                    onClose={() => setState(prevState => ({ ...prevState, openDescriptors: false }))}
                    repositoryItems={repositoryItems}
                    setRepositoryItems={setRepositoryItems}
                />
            )}
        </>
    );
}
