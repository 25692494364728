import { gql } from '@apollo/client';

export const STEP_MODELS = gql`
    query StepModels(
        $first: Int
        $page: Int
        $name: String
        $stepType: StepType
        $customRepositoryId: ID
        $companyId: ID
    ) {
        stepModels(
            first: $first
            page: $page
            name: $name
            stepType: $stepType
            customRepositoryId: $customRepositoryId
            companyId: $companyId
        ) {
            paginatorInfo {
                total
            }
            data {
                id
                name
                duration
                supportType
                modality
                stepTypes
                context
                reflexivityAxis {
                    id
                    name
                }
                reflexivityQuestions {
                    id
                }
                customRepository {
                    id
                    name
                }
                customSkills {
                    id
                }
                descriptors {
                    id
                }
                activities {
                    id
                }
                tasks {
                    id
                }
                atypicalItems {
                    id
                    atypicalRepository {
                        id
                    }
                }
            }
        }
    }
`
