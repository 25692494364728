import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Apollo
import { useQuery } from '@apollo/client';
import { REFLECTION_PHASE } from './gql-sign-reflection-phase';

// Services
import { IBreadcrumbItem, IReflectionPhase } from 'services/interfaces';
import { getBreadcrumbItems } from 'services/utils';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import SignReflectionPhaseCard from './01-sign-reflection-phase-card';
import UploadSignOffSheet from './02-upload-sign-off-sheet';
import UploadTrainerSignatures from './03-upload-trainer-signatures';
import UploadLearnerSignature from './04-upload-learner-signature';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------- FETCH REFLECTION PHASE TRAINER ---------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const FetchReflectionPhase = ({
    source,
    defaultBreadcrumbItems,
    setBreadcrumbItems,
} : {
    source: 'USER' | 'ROADMAP' | 'COMPANY',
    defaultBreadcrumbItems: IBreadcrumbItem[],
    setBreadcrumbItems: React.Dispatch<React.SetStateAction<IBreadcrumbItem[]>>,
}) => {
    
    const { t } = useTranslation();
    const { id, roadmapId, reflectionPhaseId } = useParams();

    const { loading, error, data, refetch } = useQuery<{ reflectionPhase: IReflectionPhase }>(REFLECTION_PHASE, {
        variables: { id: reflectionPhaseId },
    });

    useEffect(() => {
        if (data?.reflectionPhase) {
            
            let workSituation = data.reflectionPhase?.learnerWorkSituation?.workSituation;

            const { items } = getBreadcrumbItems({
                mainId: id,
                workSituation,
                source,
                items: defaultBreadcrumbItems,
                reflectionPhaseId,
                roadmapId,
                action: t('signature'),
            });

            setBreadcrumbItems(items);
        }
        // eslint-disable-next-line
    }, [data]);

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <SignReflectionPhase
            reflectionPhase={data?.reflectionPhase}
            refetch={refetch}
        />
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- SIGN REFLECTION PHASE TRAINER ---------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const SignReflectionPhase = ({
    reflectionPhase,
    refetch,
} : {
    reflectionPhase: IReflectionPhase | undefined,
    refetch: any,
}) => {
    return (
        <>
            {/* -------------------- 01 SIGN REFLECTION PHASE CARD -------------------- */}
            <SignReflectionPhaseCard
                reflectionPhase={reflectionPhase}
            />
            {/* -------------------- 02 UPLOAD SIGN OFF SHEET -------------------- */}
            <UploadSignOffSheet
                reflectionPhase={reflectionPhase}
                refetch={refetch}
            />
            {/* -------------------- 03 UPLOAD TRAINER SIGNATURES -------------------- */}
            <UploadTrainerSignatures
                reflectionPhase={reflectionPhase}
                refetch={refetch}
            />
            {/* -------------------- 04 UPLOAD LEARNER SIGNATURES -------------------- */}
            <UploadLearnerSignature
                reflectionPhase={reflectionPhase}
                refetch={refetch}
            />
        </>
    );
};

export default FetchReflectionPhase;
