import { ISelectOption, IRole, ICompany, IStepModel, ISelectOptionWithStepModel } from 'services/interfaces';
import { getRoleLabel } from 'services/utils';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- DEFAULT ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getSelectedOption = (item: any) => {
    return ({
        value: item.id,
        label: item.name,
    }) as ISelectOption;
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- ROLE ------------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getSelectedOptionRole = (role: IRole) => {
    return ({
        value: role.id,
        label: getRoleLabel(role.id),
    }) as ISelectOption;
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- COMPANY ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getSelectedOptionCompany = (company: ICompany) => {
    if (company.postalCode && company.city) {
        return ({
            value: company.id,
            label: `${company.name} (${company.postalCode} ${company.city})`,
        }) as ISelectOption;
    }
    return ({
        value: company.id,
        label: company.name,
    }) as ISelectOption;
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- STEP MODEL -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getSelectedOptionStepModel = (stepModel: IStepModel) => {
    return ({
        value: stepModel.id,
        label: stepModel.name,
        stepTypes: stepModel.stepTypes,
    }) as ISelectOptionWithStepModel;
};
