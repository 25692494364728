import { useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Button, Fab, Tooltip } from '@mui/material';
import { Edit as SignIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

// Services
import { IWorkSituation } from 'services/interfaces';
import { EWorkSituationType } from 'services/enums';
import { yellowMain } from 'services/constants';
import { getWorkSituationTitle } from 'services/utils';

// Permissions
import { canUpdateWorkSituation, canDeleteWorkSituation, canSignWorkSituation } from 'permissions';

// Components
import Title from 'components/title';
import PelletCard from 'components/pellet-card';

// Icons
import StepTypeIcon from 'icons/step-type-icon';

// Views
import DeleteWorkSituation from '../../delete-work-situation';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- 01 WORK SITUATION CARD --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function WorkSituationCard({
    workSituation,
    source,
} : {
    workSituation: IWorkSituation | undefined,
    source: 'USER' | 'COMPANY' | 'ROADMAP',
}) {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams();
    const { pathname } = useLocation();
    const [openDelete, setOpenDelete] = useState(false);

    return (
        <PelletCard
            startIcon={(
                <StepTypeIcon
                    stepType={workSituation?.workSituationType}
                    width={40}
                    height={40}
                    color={yellowMain}
                />
            )}
            summary={(
                <>
                    <Grid item xs>
                        <Title>
                            {getWorkSituationTitle(workSituation?.workSituationType)}
                        </Title>
                        <br />
                        <span>
                            {workSituation?.customRepository?.name || ''} {t('at.1')} {workSituation?.company?.name}
                        </span>
                    </Grid>
                    {/* -------------------- UPDATE -------------------- */}
                    {canUpdateWorkSituation({ workSituation }) && (
                        <Grid item xs="auto">
                            <Tooltip title={t('edit')}>
                                <Fab
                                    onClick={() => navigate(pathname + '/update')}
                                    color="yellow"
                                >
                                    <EditIcon />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    )}
                    {/* -------------------- DELETE -------------------- */}
                    {canDeleteWorkSituation({ workSituation }) && (
                        <Grid item xs="auto">
                            <Tooltip title={t('delete')}>
                                <Fab
                                    onClick={() => setOpenDelete(true)}
                                    color="yellow"
                                >
                                    <DeleteIcon />
                                </Fab>
                            </Tooltip>
                            {openDelete && (
                                <DeleteWorkSituation
                                    workSituation={workSituation}
                                    onClose={() => setOpenDelete(false)}
                                    refetch={() => navigate('/app/' + (source === 'USER' ? 'users' : 'companies') + '/' + id)}
                                />
                            )}
                        </Grid>
                    )}
                </>
            )}
        >
            {(
                ! workSituation?.signatureDisabled
                && canSignWorkSituation(workSituation)
            ) && (
                <Button
                    onClick={() => navigate(pathname + '/signatures')}
                    endIcon={<SignIcon />}
                    color="yellow"
                >
                    {workSituation?.workSituationType === EWorkSituationType.PREPARATORY_SEQUENCE
                        ? t('sign_preparatory_sequence')
                        : t('sign_work_situation')
                    }
                </Button>
            )}
        </PelletCard>
    );
}
