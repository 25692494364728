import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { IActivity, IActivityRepository, ISimpleResult } from 'services/interfaces';
import { getStepTypeActionLabel } from 'services/utils';
import { yellowMain } from 'services/constants';

// Components
import { Block, BlockContainer, BlockIconButton, EmptyBlock } from 'components/block';

// Icons
import { SelectedRepositoryItemIcon } from 'icons';
import ResultTypeIcon, { ResultTypeBadge, getResultTypeLabel, isResultTypeIcon } from 'components/result-type';

// Components
import PelletCard from 'components/pellet-card';

// Repository Items
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../../index';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECTABLE ACTIVITIES --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SelectableActivities({
    position,
    activityRepository,
    activityResults,
} : {
    position: number,
    activityRepository: IActivityRepository | undefined,
    activityResults?: ISimpleResult[],
}) {

    const { t } = useTranslation();

    const {
        setRepositoryItems,
        stepType,
        activities,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const onCheckActivity = (activity: IActivity) => {
        let index = activities.findIndex(i => i.id === activity.id);
        if (index === -1) {
            activities.push({ id: activity.id });
        }
        else {
            activities.splice(index, 1);
        }
        setRepositoryItems({ activities });
    };

    return (
        <PelletCard
            paddingTop={0}
            pelletColor={yellowMain}
            pelletContent={position}
            summary={(
                <Grid item xs>
                    {t('select_activities')} {getStepTypeActionLabel(stepType)} ({activities.length})
                </Grid>
            )}
        >
            <Grid
                container
                spacing={3}
            >
                {(
                    activityRepository?.activities
                    && activityRepository.activities.length > 0
                ) ? (
                    activityRepository.activities.map(activity => {

                        let selected = activities.some(a => a.id === activity.id);
                        let resultType = activityResults?.find(r => r.simpleResultId === activity.id)?.resultType;

                        return (
                            <BlockContainer key={activity.id}>
                                <Block
                                    onClick={() => onCheckActivity(activity)}
                                    selected={selected}
                                    color="yellow"
                                    badge={<ResultTypeBadge resultType={resultType} />}
                                    icons={(
                                        <>
                                            {isResultTypeIcon(resultType) && (
                                                <BlockIconButton tooltip={getResultTypeLabel(resultType)}>
                                                    <ResultTypeIcon resultType={resultType} />
                                                </BlockIconButton>
                                            )}
                                            {selected && (
                                                <BlockIconButton tooltip={t('selected_item')}>
                                                    <SelectedRepositoryItemIcon />
                                                </BlockIconButton>
                                            )}
                                        </>
                                    )}
                                >
                                    {activity.position}. {activity.name}
                                </Block>
                            </BlockContainer>
                        );
                    })
                ) : (
                    <BlockContainer>
                        <EmptyBlock color={yellowMain}>
                            {t('none.2')}
                        </EmptyBlock>
                    </BlockContainer>
                )}
            </Grid>
        </PelletCard>
    );
}
