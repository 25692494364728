import { useTranslation } from 'react-i18next';

// MUI
import { Dialog, DialogContent, DialogActions, Divider, Button, List, ListItemButton, Checkbox } from '@mui/material';

// Services
import { IObservableElement, ITask, IRepositoryItem, IRepositoryItems } from 'services/interfaces';
import { useMediaQueries } from 'services/hooks';
import { primaryMain } from 'services/constants';

// Components
import Title from 'components/title';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- OBSERVABLE ELEMENTS ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const ObservableElements = ({
    task,
    onClose,
    repositoryItems,
    setRepositoryItems,
} : {
    task: ITask,
    onClose: VoidFunction,
    repositoryItems: IRepositoryItem[],
    setRepositoryItems: (repositoryItems: IRepositoryItems) => void,
}) => {

    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const observableElementChecked = (observableElementId: string) => {
        let observableElements = repositoryItems.find(i => i.id === task.id)?.observableElements;
        if (observableElements?.includes(observableElementId)) {
            return true;
        }
        return false;
    };

    const onCheckObservableElement = (observableElement: IObservableElement) => {
        let updatedItems = [...repositoryItems];
        let index = updatedItems.findIndex(i => i.id === task.id);
        let updatedObservableElements = updatedItems[index]?.observableElements || [];

        let indexOE = updatedObservableElements.findIndex(id => id === observableElement.id);
        if (indexOE === -1) {
            updatedObservableElements.push(observableElement.id);
        }
        else {
            updatedObservableElements.splice(indexOE, 1);
        }

        updatedItems[index].observableElements = updatedObservableElements;
        setRepositoryItems({ tasks: updatedItems });
    };

    return (
        <Dialog
            open
            onClose={onClose}
            fullWidth
            fullScreen={!mediaMD}
        >
            <DialogContent>
                <div className="dialog-title">
                    {task.activity?.position}.{task.position} {task.name}
                </div>
                <div>
                    {t('specify_observable_elements_to_work_on')} :
                </div>
                <div
                    className="framed-block"
                    style={{
                        padding: 0,
                        marginTop: 24,
                        borderColor: primaryMain,
                    }}
                >
                    <div style={{ padding: '12px 12px 0px 12px' }}>
                        <Title style={{ color: primaryMain }}>
                            {t('observable_elements')}
                        </Title>
                    </div>
                    <List>
                        {task.observableElements.map(observableElement => (
                            <ListItemButton
                                key={observableElement.id}
                                onClick={() => onCheckObservableElement(observableElement)}
                            >
                                <Checkbox
                                    checked={observableElementChecked(observableElement.id)}
                                    style={{ padding: 0, marginRight: 6 }}
                                />
                                <span>
                                    {observableElement.name}
                                </span>
                            </ListItemButton>
                        ))}
                    </List>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ObservableElements;
