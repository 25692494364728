import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { ICustomBlock, ICustomRepositoryResults } from 'services/interfaces';
import { yellowMain } from 'services/constants';

// Components
import PelletCard from 'components/pellet-card';
import { Block, BlockContainer, BlockIconButton, EmptyBlock } from 'components/block';
import ResultTypeIcon, { ResultTypeBadge, isResultTypeIcon } from 'components/result-type';

// Repository Items
import CustomSkills from './custom-skills';
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../index';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ CUSTOM BLOCKS ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function CustomBlocks({
    position,
    customBlocks,
    customRepositoryResults,
} : {
    position: number,
    customBlocks: ICustomBlock[] | undefined,
    customRepositoryResults: ICustomRepositoryResults | undefined,
}) {

    const { t } = useTranslation();
    const [selectedBlock, setSelectedBlock] = useState<ICustomBlock | null>(null);

    const {
        customSkills,
        descriptors,
        enabledSkillRepositoryItems,
        linkItems,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const getItemCount = (customBlock: ICustomBlock) => {
        let length = 0;

        if (customSkills && customSkills.length > 0) {
            length = customBlock.customSkills.filter(cs => customSkills.some(i => i.id === cs.id))?.length || 0;
        }

        if (descriptors && descriptors.length > 0) {

            customBlock.customSkills.forEach(cs => {
                cs.descriptors.forEach(descriptor => {
                    if (descriptors.some(i => i.id === descriptor.id)) {
                        length += 1;
                    }
                });
            });

            if (enabledSkillRepositoryItems) {
                customBlock.missingSkills.forEach(ms => {
                    ms.descriptors.forEach(descriptor => {
                        if (descriptors.some(i => i.id === descriptor.id)) {
                            length += 1;
                        }
                    });
                });
            }
        }

        if (linkItems && linkItems.length > 0) {
            customBlock.customSkills.forEach(cs => {
                cs.linkItems.forEach(linkItem => {
                    if (linkItems.some(i => i.id === linkItem.id)) {
                        length += 1;
                    }
                })
            });
        }

        return length;
    };

    const onChangeCustomBlock = (customBlock: ICustomBlock) => {
        if (customBlock.id === selectedBlock?.id) {
            setSelectedBlock(null);
        }
        else {
            setSelectedBlock(customBlock);
        }
    };

    return (
        <>
            <PelletCard
                paddingTop={0}
                pelletColor={yellowMain}
                pelletContent={position}
                summary={(
                    <Grid item xs>
                        {t('select_custom_block')}
                    </Grid>
                )}
            >
                <Grid
                    container
                    spacing={3}
                >
                    {(
                        customBlocks
                        && customBlocks.length > 0
                    ) ? (
                        customBlocks.map(customBlock => {

                            let itemCount = getItemCount(customBlock);
                            let resultType = customRepositoryResults?.customBlockResults?.find(r => r.simpleResultId === customBlock.id)?.resultType;

                            return (
                                <BlockContainer key={customBlock.id}>
                                    <Block
                                        onClick={() => onChangeCustomBlock(customBlock)}
                                        selected={selectedBlock?.id === customBlock.id}
                                        deselected={selectedBlock != null && selectedBlock.id !== customBlock?.id}
                                        color="yellow"
                                        badge={<ResultTypeBadge resultType={resultType} />}
                                        icons={(
                                            <>
                                                {isResultTypeIcon(resultType) && (
                                                    <BlockIconButton>
                                                        <ResultTypeIcon resultType={resultType} />
                                                    </BlockIconButton>
                                                )}
                                                <BlockIconButton>
                                                    {itemCount}
                                                </BlockIconButton>
                                            </>
                                        )}
                                    >
                                        {customBlock.position}. {customBlock.skillBlock?.name}
                                    </Block>
                                </BlockContainer>
                            )
                        })
                    ) : (
                        <BlockContainer>
                            <EmptyBlock color={yellowMain}>
                                {t('none.1')}
                            </EmptyBlock>
                        </BlockContainer>
                    )}
                </Grid>
            </PelletCard>
            {/* -------------------- CUSTOM SKILLS -------------------- */}
            {selectedBlock && (
                <CustomSkills
                    position={position + 1}
                    customBlock={selectedBlock}
                    customRepositoryResults={customRepositoryResults}
                />
            )}
        </>
    );
}
