import { useContext } from 'react';

// Services
import { ICustomRepositoryResults, IActivityRepository } from 'services/interfaces';
import { ERepositoryItemType } from 'services/enums';

// Repository Items
import SelectableActivities from './selectable-activities';
import ActivitiesWithTasks from './activities-with-tasks';
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../index';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ACTIVITIES --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Activities({
    position,
    activityRepository,
    customRepositoryResults,
} : {
    position: number,
    activityRepository: IActivityRepository | undefined,
    customRepositoryResults: ICustomRepositoryResults | undefined,
}) {

    const {
        repositoryItemType,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    return (
        <>
            {repositoryItemType === ERepositoryItemType.ACTIVITY && (
                <SelectableActivities
                    position={position}
                    activityRepository={activityRepository}
                    activityResults={customRepositoryResults?.activityResults}
                />
            )}
            {repositoryItemType === ERepositoryItemType.TASK && (
                <ActivitiesWithTasks
                    position={position}
                    activityRepository={activityRepository}
                    customRepositoryResults={customRepositoryResults}
                />
            )}
        </>
    );
}
