import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// Notistack
import { SnackbarProvider } from 'notistack';

// i18next
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// Apollo Client
import { ApolloProvider } from '@apollo/client';
import client from 'services/apollo-client';

// MUI
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

// Roboto Font
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// React International Phone CSS
import 'react-international-phone/style.css';

// Dayjs Locale
import 'dayjs/locale/fr';
import 'dayjs/locale/de';

// Services
import {
    yellowLight, yellowMain, yellowDark,
    magentaLight, magentaMain, magentaDark,
    navyBlueLight, navyBlueMain, navyBlueDark,
} from 'services/constants';

// Main CSS
import './index.css';

// Main Component
import App from './app';

// Translation files
import translationFR from 'assets/translations/fr.translations.json';
import translationFR_FALC from 'assets/translations/fr-falc.translations.json';
import translationEN from 'assets/translations/en.translations.json';
import translationDE from 'assets/translations/de.translations.json';
import translationSL from 'assets/translations/sl.translations.json';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- I18NEXT ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

i18next.use(initReactI18next).init({
    resources: {
        "fr": { translation: translationFR },
        "fr-FALC": { translation: translationFR_FALC },
        "en": { translation: translationEN },
        "de": { translation: translationDE },
        "sl": { translation: translationSL },
    },
    lng: 'fr',
    fallbackLng: {
        'fr-FALC': ['fr'],
        default: ['en'],
    },
});

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- MUI COMPONENTS ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/ButtonGroup' {
    interface ButtonGroupPropsColorOverrides {
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/Checkbox' {
    interface CheckboxPropsColorOverrides {
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/Fab' {
    interface FabPropsColorOverrides {
        magenta: true;
        yellow: true;
        navyBlue: true;
    }
}

declare module '@mui/material/Switch' {
    interface SwitchPropsColorOverrides {
        magenta: true;
        yellow: true;
        navyBlue: true;
    }
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- MUI PALETTE -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

declare module '@mui/material/styles' {
    interface Palette {
        magenta: Palette['primary'];
        yellow: Palette['primary'];
        navyBlue: Palette['primary'];
    }
    interface PaletteOptions {
        magenta: PaletteOptions['primary'];
        yellow: PaletteOptions['primary'];
        navyBlue: Palette['primary'];
    }
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- MUI THEME --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const theme = createTheme({
    palette: {
        magenta: {
            light: magentaLight,
            main: magentaMain,
            dark: magentaDark,
            contrastText: '#fff',
        },
        yellow: {
            light: yellowLight,
            main: yellowMain,
            dark: yellowDark,
            contrastText: '#fff',
        },
        navyBlue: {
            light: navyBlueLight,
            main: navyBlueMain,
            dark: navyBlueDark,
            contrastText: '#fff',
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'contained',
            },
            styleOverrides: {
                root: {
                    minWidth: 0,
                },
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    zIndex: 0,
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                enterDelay: 400,
                leaveDelay: 200,
                placement: 'top',
            },
            styleOverrides: {
                tooltip: {
                    fontSize: 14,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: '#DDDDDD',
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                size: 'small',
                variant: 'outlined',
                InputProps: {
                    style: {
                        backgroundColor: 'white',
                    },
                },
            },
        },
    },
});

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- ROOT ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
                <HelmetProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </HelmetProvider>
            </SnackbarProvider>
        </ThemeProvider>
    </ApolloProvider>
);
