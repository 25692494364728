import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Dialog, DialogContent, DialogActions, Divider, Button, List, ListItem, Grid } from '@mui/material';

// Services
import { IActivity, ITask } from 'services/interfaces';
import { useMediaQueries } from 'services/hooks';
import { primaryMain } from 'services/constants';

// Components
import Title from 'components/title';
import { Block } from 'components/block';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- TASKS ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const Tasks = ({
    activity,
    onClose,
} : {
    activity: IActivity,
    onClose: VoidFunction,
}) => {

    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const [selectedTask, setSelectedTask] = useState<ITask | null>(activity.tasks[0]);

    return (
        <Dialog
            open
            onClose={onClose}
            fullWidth
            maxWidth="md"
            fullScreen={!mediaMD}
        >
            <DialogContent style={{ padding: 0 }}>
                <div 
                    className="dialog-title"
                    style={{ padding: 24, margin: 0 }}
                >
                    {activity.position}. {activity.name}
                </div>
                <Divider />
                <Grid container>
                    <Grid
                        item xs={12} md={6}
                        style={{
                            padding: 24,
                            borderRight: mediaMD ? '1px solid rgba(0, 0, 0, 0.12)' : undefined,
                            borderBottom: mediaMD ? undefined : '1px solid rgba(0, 0, 0, 0.12)',
                        }}
                    >
                        <Grid container flexDirection="column">
                            {activity.tasks.map((task, index) => (
                                <Block
                                    key={task.id}
                                    onClick={() => setSelectedTask(task)}
                                    selected={selectedTask?.id === task.id}
                                    deselected={!!(selectedTask && selectedTask.id !== task.id)}
                                    color="magenta"
                                    gridProps={{ marginTop: index > 0 ? 3 : undefined }} 
                                >
                                    {activity.position}.{task.position} {task.name}
                                </Block>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid
                        item xs={12} md={6}
                        padding={3}
                    >
                        <div
                            className="framed-block"
                            style={{ padding: 0, borderColor: primaryMain }}
                        >
                            <div style={{ padding: 12 }}>
                                <Title style={{ color: primaryMain }}>
                                    {t('success_indicators')}
                                </Title>
                            </div>
                            <List style={{ paddingTop: 0 }}>
                                {selectedTask?.successIndicators.map(successIndicator => (
                                    <ListItem key={successIndicator.id}>
                                        <span>-</span>
                                        <span style={{ marginLeft: 8 }}>
                                            {successIndicator.position}. {successIndicator.name}
                                        </span>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Tasks;
