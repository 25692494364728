
// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- IS CANVAS BLANK ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const isCanvasBlank = () => {
    
    let canvas = document.getElementById('signCanvas') as HTMLCanvasElement;
    const context = canvas.getContext('2d');

    if (context) {
        const pixelBuffer = new Uint32Array(
            context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
        );
        return !pixelBuffer.some(color => color !== 0);
    }
    return true;
};
