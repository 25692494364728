import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

// Dayjs
import dayjs from 'dayjs';

// MUI
import { List, ListItemButton, Divider, Grid } from '@mui/material';
import {
    Logout as LogoutIcon, AutoStories as UserGuideIcon, Settings as SettingsIcon,
    People as UsersIcon, LocalActivity as CertificationsIcon, MenuBook as RepositoriesIcon,
    Dashboard as DashboardIcon,
} from '@mui/icons-material';

// Services
import { getStoredUser } from 'services/storage';
import { EDestinationApp } from 'services/enums';
import { getVersionName } from 'services/utils';
import PACKAGE_JSON from '../../../../package.json';

// Permissions
import { canViewAnyUser, canViewAnyCompany, canViewAnyCertification, canViewAnyPedagogicalTool } from 'permissions';

// Icons
import { CompanyIcon, ModelIcon, PedagogicalToolIcon } from 'icons';

// Assets
import HappincoIcon from 'assets/images/happinco/happinco-icon.png';

// Views
import LoginCode from '../login-code';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- MENU LIST --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const MenuList = ({
    onClose,
    onOpenLogout,
} : {
    onClose: VoidFunction,
    onOpenLogout: () => void,
}) => {

    const storedUser = getStoredUser();
    const { t } = useTranslation();

    const [state, setState] = useState({
        loginCodeDestination: EDestinationApp.HAPPINCO,
        loginCodeUrl: '',
        openLoginCode: false,
    });

    return (
        <>
            <div className="SideMenu-list">
                <List>
                    {/* -------------------- DASHBOARD -------------------- */}
                    <NavLink
                        to="/app"
                        end
                        className={({ isActive }) => isActive ? 'NavLink NavLink-selected' : 'NavLink'}
                    >
                        <ListItemButton onClick={onClose}>
                            <DashboardIcon
                                color="primary"
                                sx={{ fontSize: 26 }}
                            />
                            <span className="NavLink-title">
                                {t('dashboard')}
                            </span>
                        </ListItemButton>
                    </NavLink>
                    {/* -------------------- REPOSITORIES -------------------- */}
                    <NavLink
                        to="/app/repositories"
                        className={({ isActive }) => isActive ? 'NavLink NavLink-selected' : 'NavLink'}
                    >
                        <ListItemButton onClick={onClose}>
                            <RepositoriesIcon
                                color="primary"
                                sx={{ fontSize: 26 }}
                            />
                            <span className="NavLink-title">
                                {t('repositories')}
                            </span>
                        </ListItemButton>
                    </NavLink>
                    {/* -------------------- CERTIFICATIONS -------------------- */}
                    {canViewAnyCertification() && (
                        <NavLink
                            to="/app/certifications"
                            className={({ isActive }) => isActive ? 'NavLink NavLink-selected' : 'NavLink'}
                        >
                            <ListItemButton onClick={onClose}>
                                <CertificationsIcon
                                    color="primary"
                                    sx={{ fontSize: 26 }}
                                />
                                <span className="NavLink-title">
                                    {t('certifications')}
                                </span>
                            </ListItemButton>
                        </NavLink>
                    )}
                    {/* -------------------- USERS -------------------- */}
                    {canViewAnyUser() && (
                        <NavLink
                            to="/app/users"
                            className={({ isActive }) => isActive ? 'NavLink NavLink-selected' : 'NavLink'}
                        >
                            <ListItemButton onClick={onClose}>
                                <UsersIcon
                                    color="primary"
                                    sx={{ fontSize: 26 }}
                                />
                                <span className="NavLink-title">
                                    {t('users')}
                                </span>
                            </ListItemButton>
                        </NavLink>
                    )}
                    {/* -------------------- COMPANIES -------------------- */}
                    {canViewAnyCompany() && (
                        <NavLink
                            to="/app/companies"
                            className={({ isActive }) => isActive ? 'NavLink NavLink-selected' : 'NavLink'}
                        >
                            <ListItemButton onClick={onClose}>
                                <CompanyIcon
                                    color="primary"
                                    sx={{ fontSize: 26 }}
                                />
                                <span className="NavLink-title">
                                    {t('companies')}
                                </span>
                            </ListItemButton>
                        </NavLink>
                    )}
                    {/* -------------------- PEDAGOGICAL TOOLS -------------------- */}
                    {canViewAnyPedagogicalTool() && (
                        <NavLink
                            to="/app/pedagogical-tools"
                            className={({ isActive }) => isActive ? 'NavLink NavLink-selected' : 'NavLink'}
                        >
                            <ListItemButton onClick={onClose}>
                                <PedagogicalToolIcon
                                    color="primary"
                                    sx={{ fontSize: 26 }}
                                />
                                <span className="NavLink-title">
                                    {t('pedagogical_tools')}
                                </span>
                            </ListItemButton>
                        </NavLink>
                    )}
                    {/* -------------------- MODELS -------------------- */}
                    <NavLink
                        to="/app/models"
                        className={({ isActive }) => isActive ? 'NavLink NavLink-selected' : 'NavLink'}
                    >
                        <ListItemButton onClick={onClose}>
                            <ModelIcon
                                color="primary"
                                sx={{ fontSize: 26 }}
                            />
                            <span className="NavLink-title">
                                {t('models')}
                            </span>
                        </ListItemButton>
                    </NavLink>
                    {/* -------------------- MANAGEMENT -------------------- */}
                    {(
                        storedUser?.role?.id
                        && Number(storedUser.role.id) === 1
                    ) && (
                        <NavLink
                            to="/app/management"
                            className={({ isActive }) => isActive ? 'NavLink NavLink-selected' : 'NavLink'}
                        >
                            <ListItemButton onClick={onClose}>
                                <SettingsIcon
                                    color="primary"
                                    sx={{ fontSize: 26 }}
                                />
                                <span className="NavLink-title">
                                    {t('management')}
                                </span>
                            </ListItemButton>
                        </NavLink>
                    )}
                    {/* -------------------- LOGOUT -------------------- */}
                    <ListItemButton onClick={onOpenLogout}>
                        <LogoutIcon
                            color="primary"
                            sx={{ fontSize: 26 }}
                        />
                        <span className="NavLink-title">
                            {t('logout')}
                        </span>
                    </ListItemButton>
                </List>
                <Divider />
                {/* -------------------- USER GUIDE -------------------- */}
                <List>
                    <ListItemButton
                        onClick={() => setState(prevState => ({
                            ...prevState,
                            openLoginCode: true,
                            loginCodeDestination: EDestinationApp.USER_GUIDE,
                            loginCodeUrl: '&applicationId=1',
                        }))}
                    >
                        <UserGuideIcon
                            sx={{ fontSize: 26, color: 'gray' }}
                        />
                        <span className="NavLink-title">
                            {t('user_guide')}
                        </span>
                    </ListItemButton>
                </List>
                <Divider />
                {/* -------------------- HAPPINCO -------------------- */}
                {(
                    storedUser?.company?.applications
                    && storedUser.company.applications.some(a =>
                        a.name === 'happinco'
                        && (a.expiration == null || dayjs().format('YYYY-MM-DD') <= a.expiration)
                    )
                ) && (
                    <>
                        <List>
                            <ListItemButton
                                onClick={() => setState(prevState => ({
                                    ...prevState,
                                    openLoginCode: true,
                                    loginCodeDestination: EDestinationApp.HAPPINCO,
                                    loginCodeUrl: '',
                                }))}
                            >
                                <img
                                    alt="Icone de Happ'In Co"
                                    src={HappincoIcon}
                                    width={26}
                                    height={26}
                                    loading="eager"
                                />
                                <span className="NavLink-title">
                                    Happ'In Co
                                </span>
                            </ListItemButton>
                        </List>
                        <Divider />
                    </>
                )}
                <Grid flex={1} />
                {/* -------------------- APP VERSION -------------------- */}
                <Grid
                    padding={1}
                    className="subtitle"
                >
                    v{PACKAGE_JSON.version} {getVersionName()}
                </Grid>
            </div>
            {/* -------------------- LOGIN WITH CODE -------------------- */}
            {state.openLoginCode && (
                <LoginCode
                    url={state.loginCodeUrl}
                    to={state.loginCodeDestination}
                    onClose={() => setState(prevState => ({ ...prevState, openLoginCode: false }))}
                />
            )}
        </>
    );
};

export default MenuList;
