import React from 'react';

// Services
import { magentaMain } from 'services/constants';

// CSS
import styles from './Title.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- TITLE ------------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Title({
    children,
    required = false,
    style,
} : {
    children: React.ReactNode,
    required?: boolean,
    style?: React.CSSProperties,
}) {
    return (
        <span
            className={styles.title}
            style={style}
        >
            {children}
            {required && (
                <span
                    className={styles.required}
                    style={{ color: magentaMain }}
                >
                    *
                </span>
            )}
        </span>
    );
}
