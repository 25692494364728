import React, { PropsWithChildren } from 'react';

// MUI
import { Grid, Button, Tooltip, GridProps, ButtonProps, ButtonOwnProps } from '@mui/material';

// CSS
import styles from './Block.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- BLOCK CONTAINER ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const BlockContainer = (props: PropsWithChildren) => {
    return (
        <Grid item xs={12} sm={6} md={4} xl={3}>
            {props.children}
        </Grid>
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- BLOCK ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IBlockProps extends PropsWithChildren {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    color?: ButtonOwnProps["color"];
    disabled?: boolean;
    selected?: boolean;
    deselected?: boolean;
    sorted?: boolean;
    icons?: React.ReactNode;
    badge?: React.ReactNode;
    gridProps?: GridProps;
    buttonProps?: ButtonProps;
}

export const Block = (props: IBlockProps) => {
    return (
        <Grid
            {...props.gridProps}
            position="relative"
            zIndex={0}
            style={{ ...props.gridProps?.style, cursor: props.sorted ? 'grab' : undefined }}
        >
            <Button
                {...props.buttonProps}
                onClick={props.onClick}
                color={props.color}
                disabled={props.disabled}
                fullWidth
                className={props.selected ? styles.selected : props.deselected ? styles.deselected : props.sorted ? styles.sorted : undefined}
                sx={{
                    ...props.buttonProps?.sx,
                    minHeight: 108,
                    padding: 3,
                    borderRadius: 2,
                    lineHeight: 1.25,
                    fontSize: 16,
                    fontWeight: 400,
                    flexDirection: 'column',
                    textAlign: 'center',
                    textTransform: 'none',
                }}
            >
                {props.children}
            </Button>
            {!(props.disabled || props.sorted) && (
                <>
                    {props.badge && (
                        <div className={styles.badgeContainer}>
                            {props.badge}
                        </div>
                    )}
                    {props.icons && (
                        <Grid className={styles.iconContainer}>
                            <Grid
                                container
                                alignItems="center"
                                spacing={1}
                            >
                                {props.icons}
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- BLOCK ICON BUTTON ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IBlockIconButtonProps extends PropsWithChildren {
    onClick?: VoidFunction;
    tooltip?: React.ReactNode;
}

export const BlockIconButton = (props: IBlockIconButtonProps) => {

    if (typeof props.children === 'number' && props.children === 0) return;

    return (
        <Grid item xs="auto">
            <Tooltip title={props.tooltip}>
                <div
                    className={styles.iconButton}
                    onClick={props.onClick}
                    style={{ cursor: props.onClick ? 'pointer' : 'default' }}
                >
                    {props.children}
                </div>
            </Tooltip>
        </Grid>
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- EMPTY BLOCK -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IEmptyBlockProps extends PropsWithChildren {
    color: string;
}

export const EmptyBlock = (props: IEmptyBlockProps) => {
    return (
        <div
            className={styles.emptyBlock}
            style={{ color: props.color, borderColor: props.color }}
        >
            {props.children}
        </div>
    );
};
