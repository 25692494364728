import { Button, Dialog, DialogActions, DialogContent, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { IAtypicalItem, IAtypicalRepository } from 'services/interfaces';
import { useMediaQueries } from 'services/hooks';

// Components
import { Block } from 'components/block';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- SUB ITEMS --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ISubItemsProps {
    atypicalRepository: IAtypicalRepository;
    subItems: IAtypicalItem[];
    onClose: VoidFunction;
}

export default function SubItems(props: ISubItemsProps) {

    const subItemIds = props.subItems.map(ai => ai.id);

    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const atypicalLevel = props.atypicalRepository.atypicalLevels.find(al =>
        al.atypicalItems.some(ai => subItemIds.includes(ai.id))
    );

    const atypicalItems = props.atypicalRepository.atypicalItems.filter(ai =>
        subItemIds.includes(ai.id)
    );

    return (
        <Dialog
            open
            onClose={props.onClose}
            fullWidth
            fullScreen={!mediaMD}
            maxWidth="lg"
        >
            <DialogContent>
                <Grid
                    borderRadius={2}
                    border="1px solid black"
                    width="100%"
                >
                    <Grid padding={1}>
                        {atypicalLevel?.level}. {atypicalLevel?.name}
                    </Grid>
                    <Divider />
                    <Grid padding={3}>
                        <Grid
                            container
                            spacing={3}
                        >
                            {atypicalItems.map(atypicalItem => (
                                <Grid
                                    key={atypicalItem.id}
                                    item xs={12} md={6}
                                >
                                    <Block
                                        color="magenta"
                                        buttonProps={{ style: { pointerEvents: 'none' } }}
                                    >
                                        {atypicalItem.name}
                                    </Block>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={props.onClose}
                    color="inherit"
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
