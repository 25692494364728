import { ERepositoryItemType } from 'services/enums';

// Services
import { magentaMain, yellowMain } from 'services/constants';

// Icons
import { ActivityIcon, DescriptorIcon, SkillIcon, TaskIcon, AtypicalItemIcon } from '../index';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- REPOSITORY ITEM ICON ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function RepositoryItemIcon({ repositoryItemType } : { repositoryItemType: ERepositoryItemType | undefined }) {

    switch (repositoryItemType) {

        case ERepositoryItemType.CUSTOM_SKILL:
            return <SkillIcon style={{ color: magentaMain }} />;

        case ERepositoryItemType.DESCRIPTOR:
            return <DescriptorIcon color="primary" />;

        case ERepositoryItemType.ACTIVITY:
            return <ActivityIcon style={{ color: yellowMain }} />;

        case ERepositoryItemType.TASK:
            return <TaskIcon style={{ color: magentaMain }} />;

        case ERepositoryItemType.ATYPICAL_ITEM:
            return <AtypicalItemIcon />;

        default:
            return <></>;
    }
}
