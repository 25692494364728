import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { ICustomBlock, ICustomRepositoryResults } from 'services/interfaces';
import { ERepositoryItemType } from 'services/enums';
import { getStepTypeActionLabel } from 'services/utils';
import { magentaMain } from 'services/constants';

// Components
import PelletCard from 'components/pellet-card';

// Repository Items
import SelectableSkills from './selectable-skills';
import SkillsWithDescriptors from './skills-with-descriptors';
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../../index';

// CSS
import styles from './CustomSkills.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ CUSTOM SKILLS ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function CustomSkills({
    position,
    customBlock,
    customRepositoryResults,
} : {
    position: number,
    customBlock: ICustomBlock,
    customRepositoryResults: ICustomRepositoryResults | undefined,
}) {

    const { t } = useTranslation();

    const {
        stepType,
        repositoryItemType,
        customSkills,
        descriptors,
        enabledSkillRepositoryItems,
        linkItems,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const selectedItemsCount = descriptors.length + linkItems.length;

    return (
        <>
            <PelletCard
                paddingTop={0}
                paddingContent={repositoryItemType === ERepositoryItemType.CUSTOM_SKILL ? undefined : 0}
                pelletColor={magentaMain}
                pelletContent={position}
                summary={(
                    <Grid item xs>
                        <span>
                            {repositoryItemType === ERepositoryItemType.CUSTOM_SKILL
                                ? `${t('select_skills')} ${getStepTypeActionLabel(stepType)} (${customSkills.length})`
                                : `${t('select_items')} ${getStepTypeActionLabel(stepType)} (${selectedItemsCount})`
                            }
                        </span>
                        {enabledSkillRepositoryItems && (
                            <>
                                <br />
                                <Grid
                                    container
                                    alignItems="center"
                                    className="subtitle"
                                >
                                    <span
                                        className={styles.dot}
                                        style={{ backgroundColor: magentaMain, opacity: 0.5 }}
                                    />
                                    <span
                                        className={styles.dot}
                                        style={{ backgroundColor: 'lightslategray', marginLeft: 4 }}
                                    />
                                    <span style={{ marginLeft: 4 }}>
                                        {t('items_not_covered_by_activity')}
                                    </span>
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
            >
                {repositoryItemType === ERepositoryItemType.CUSTOM_SKILL ? (
                    <SelectableSkills
                        customBlock={customBlock}
                        customSkillResults={customRepositoryResults?.customSkillResults}
                    />
                ) : (
                    <SkillsWithDescriptors
                        customBlock={customBlock}
                        customSkillResults={customRepositoryResults?.customSkillResults}
                        descriptorResults={customRepositoryResults?.descriptorResults}
                        linkItemResults={customRepositoryResults?.linkItemResults}
                    />
                )}
            </PelletCard>
        </>
    );
}
