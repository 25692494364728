import { gql } from '@apollo/client';

export const UPLOAD_LEARNER_WORK_SITUATION_SIGNATURE = gql`
    mutation UploadLearnerWorkSituationSignature(
        $id: ID!
        $file: Upload!
    ) {
        uploadLearnerWorkSituationSignature(
            id: $id,
            file: $file,
        ) {
            id
        }
    }
`
