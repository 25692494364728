import React, { useEffect, useState } from 'react';
import { useParams, Outlet, useOutletContext, useMatch, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';
import { Edit as EditIcon, Remove as RemoveIcon } from '@mui/icons-material';

// Apollo
import { useQuery, ApolloError } from '@apollo/client';
import { USER } from './gql-user';

// Services
import { IUser, IBreadcrumbItem } from 'services/interfaces';
import { useDocumentTitle } from 'services/hooks';

// Permissions
import { canUpdateUser, canRemoveUserFromCompany } from 'permissions';

// Components
import Breadcrumb from 'components/breadcrumb';
import Spinner from 'components/spinner';
import Error from 'components/error';
import ResponsiveButton from 'components/responsive-button';

// Views
import UserCard from './user-card';
import RemoveUserFromCompany from './remove-user-from-company';

type ContextType = {
    user: IUser,
    defaultBreadcrumbItems: IBreadcrumbItem[],
    setBreadcrumbItems: React.Dispatch<React.SetStateAction<IBreadcrumbItem[]>>
};

export function useBreadcrumbFromUser() {
    return useOutletContext<ContextType>();
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- FETCH USER -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const FetchUser = () => {

    const match = useMatch('/app/users/:id');
    const { t } = useTranslation();
    const { id } = useParams();
    const { pathname } = useLocation();
    const [openRemove, setOpenRemove] = useState<boolean>(false);
    
    const { loading, error, data, refetch } = useQuery<{ user: IUser }>(USER, {
        variables: { id: id },
    });

    useDocumentTitle(data?.user?.fullName || `#${id}`);

    const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumbItem[]>([
        {
            label: t('users'),
            route: '/app/users',
        },
        {
            label: '...',
        },
    ]);

    const onRefetch = () => {
        setOpenRemove(false);
        refetch();
    };

    useEffect(() => {
        if (match) {
            setBreadcrumbItems([
                {
                    label: t('users'),
                    route: '/app/users',
                },
                {
                    label: data?.user?.fullName || `${t('user')} #${id}`,
                },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, pathname]);

    return (
        <>
            <Breadcrumb breadcrumbItems={breadcrumbItems}>
                {match && (data?.user && canUpdateUser(data.user)) && (
                    <Grid item xs="auto">
                        <ResponsiveButton
                            component={Link}
                            to={'/app/users/' + id + '/update'}
                            icon={<EditIcon />}
                            placement="end"
                        >
                            {t('edit_user')}
                        </ResponsiveButton>
                    </Grid>
                )}
                {match && canRemoveUserFromCompany(data?.user) && (
                    <>
                        <Grid item xs="auto">
                            <ResponsiveButton
                                onClick={() => setOpenRemove(true)}
                                icon={<RemoveIcon />}
                                placement="end"
                            >
                                {t('remove_from_company')}
                            </ResponsiveButton>
                        </Grid>
                        <RemoveUserFromCompany
                            user={data?.user}
                            open={openRemove}
                            onClose={() => setOpenRemove(false)}
                            onRefetch={onRefetch}
                        />
                    </>
                )}
            </Breadcrumb>
            <User
                loading={loading}
                error={error}
                data={data}
                refetch={refetch}
                setBreadcrumbItems={setBreadcrumbItems}
            />
        </>
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- USER ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const User = ({
    loading,
    error,
    data,
    refetch,
    setBreadcrumbItems,
} : {
    loading: boolean,
    error: ApolloError | undefined,
    data: { user: IUser } | undefined,
    refetch: any,
    setBreadcrumbItems: React.Dispatch<React.SetStateAction<IBreadcrumbItem[]>>,
}) => {

    const { t } = useTranslation();

    if (loading) return <Spinner />;
    if (error) return <Error />;
    if ((data || error) && !data?.user && !error) return <Error error={`${t('user')} ${t('not_found').toLowerCase()}`} />

    return (
        <>
            <UserCard
                user={data?.user}
                refetch={refetch}
            />
            <Outlet
                context={{
                    user: data?.user,
                    defaultBreadcrumbItems: [
                        {
                            label: t('users'),
                            route: '/app/users',
                        },
                        {
                            label: data?.user?.fullName,
                            route: '/app/users/' + data?.user?.id,
                        },
                    ],
                    setBreadcrumbItems,
                }}
            />
        </>
    );
};

export default FetchUser;
