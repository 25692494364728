import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// Apollo
import { useQuery } from '@apollo/client';
import { ROLES } from './gql-select-role';

// Services
import { IRole } from 'services/interfaces';
import { getRoleLabel } from 'services/utils';

// Components
import Select, { ISelectProps } from 'components/react-select';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ SELECT ROLE --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ISelectRoleProps extends ISelectProps {
    onlyAccessible?: boolean;
}

const SelectRole = forwardRef(function SelectRole(props: ISelectRoleProps, ref: any) {

    const { t } = useTranslation();

    const { loading, data } = useQuery<{ roles: IRole[] }>(ROLES, {
        variables: { onlyAccessible: props.onlyAccessible },
        fetchPolicy: 'cache-first',
    });

    return (
        <Select
            {...props}
            ref={ref}
            placeholder={props.placeholder || t('roles')}
            options={data?.roles ? data.roles.map(r => ({ value: r.id, label: getRoleLabel(r.id) })) : []}
            isLoading={loading}
        />
    );
});

export default SelectRole;
