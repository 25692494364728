import { gql } from '@apollo/client';

export const SIMPLIFIED_STEP_MODEL = gql`
    query StepModel($id: ID!) {
        stepModel(id: $id) {
            id
            name
        }
    }
`

export const STEP_MODEL = gql`
    query StepModel($id: ID!) {
        stepModel(id: $id) {
            id
            name
            duration
            supportType
            modality
            context
            stepTypes
            creator {
                id
                fullName
            }
            lastModifier {
                id
                fullName
            }
            roadmapModels {
                id
                name
            }
            customRepository {
                id
                name
                company {
                    id
                    name
                    footer
                }
            }
            customSkills {
                id
                skill {
                    id
                    name
                    position
                }
                customBlock {
                    id
                    position
                    skillBlock {
                        id
                        name
                    }
                }
            }
            descriptors {
                id
                name
                descriptorType
            }
            activities {
                id
                name
                position
            }
            tasks {
                id
                name
                position
                activity {
                    id
                    name
                    position
                }
            } 
            atypicalItems {
                id
                name
                parentItem {
                    id
                    name
                    atypicalLevel {
                        id
                        name
                        level
                    }
                }
                atypicalRepository {
                    id
                    name
                }
                atypicalLevel {
                    id
                    name
                    level
                }
            }
            linkItems {
                id
                skillName
                atypicalItem {
                    id
                    name
                    parentItem {
                        id
                        name
                    }
                    atypicalRepository {
                        id
                        name
                    }
                }
            }
            reflexivityAxis {
                id
                name
            }
            reflexivityQuestions {
                id
                name
                position
                description
            }
        }
    }
`
