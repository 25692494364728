
// Services
import { IRepositoryItems } from 'services/interfaces';
import { EStepType } from 'services/enums';

// Components
import RepositoryItemsForm from 'repository-items/repository-items-form';

// Views
import { IState } from '../../create-work-situation-from-roadmap';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------- 02 WORK SITUATION REPOSITORY ITEMS -------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function WorkSituationRepositoryItems({
    state,
    setState,
} : {
    state: IState,
    setState: React.Dispatch<React.SetStateAction<IState>>,
}) {

    const onSetRepositoryItems = (repositoryItems: IRepositoryItems) => {
        setState(prevState => ({
            ...prevState,
            descriptors: repositoryItems.descriptors || prevState.descriptors,
            tasks: repositoryItems.tasks || prevState.tasks,
            atypicalItems: repositoryItems.atypicalItems || prevState.atypicalItems,
            linkItems: repositoryItems.linkItems || prevState.linkItems,
        }));
    };

    return (
        <>
            {state.customRepository && (
                <RepositoryItemsForm
                    customRepositoryId={state.customRepository?.value}
                    stepType={state.workSituationType as EStepType}
                    setRepositoryItems={repositoryItems => onSetRepositoryItems(repositoryItems)}
                    descriptors={state.descriptors}
                    tasks={state.tasks}
                    atypicalItems={state.atypicalItems}
                    linkItems={state.linkItems}
                    roadmap={state.roadmap ? ({ id: state.roadmap.id, learnerId: state.roadmap.learner?.id }) : undefined}
                    startPosition={2}
                    defaultRepositoryType={state.defaultRepositoryType}
                    defaultAtypicalRepositoryId={state.defaultAtypicalRepositoryId}
                />
            )}
        </>
    );
}
