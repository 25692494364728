import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { CircularProgress, Fab, Grid } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';

// Apollo
import { useMutation } from '@apollo/client';
import { SEND_CONFIRM_EMAIL } from './gql-send-confirm-email';

// Services
import { getErrorExtensions } from 'services/utils';
import { magentaMain } from 'services/constants';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- SEND CONFIRM EMAIL ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

const SendConfirmEmail = ({ userId } : { userId: string | undefined }) => {

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [sendConfirmEmail, { loading, error, data }] = useMutation(SEND_CONFIRM_EMAIL);

    useEffect(() => {
        if (data?.sendConfirmEmail) {
            enqueueSnackbar(`${t('link_sent')} ${t('successfully')}`, { variant: 'success' });
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <Grid
            marginTop={1}
            display="flex"
            alignItems="center"
        >
            <Fab
                onClick={() => sendConfirmEmail({ variables: { id: userId }})}
                disabled={loading}
                color="magenta"
            >
                {loading ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
            </Fab>
            <Grid
                marginLeft={1}
                maxWidth={128}
                color={magentaMain}
            >
                {t('resend_confirmation_link')}
            </Grid>
        </Grid>
    );
};

export default SendConfirmEmail;
