import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { ESelfMastery } from 'services/enums';

// Icons
import SelfMasteryIcon from 'icons/self-mastery-icon';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SELF MASTER LEGEND ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SelfMasteryLegend({ containerProps } : { containerProps?: React.CSSProperties }) {
    
    const { t } = useTranslation();

    return (
        <div style={containerProps}>
            {/* -------------------- I KNOW -------------------- */}
            <Grid
                container
                alignItems="center"
            >
                <SelfMasteryIcon
                    selfMastery={ESelfMastery.KNOW}
                />
                <Grid
                    item xs
                    marginLeft={1}
                >
                    {t('I_know')}
                </Grid>
            </Grid>
            {/* -------------------- I KNOW PARTIALLY -------------------- */}
            <Grid
                container
                alignItems="center"
                marginTop={1}
            >
                <SelfMasteryIcon
                    selfMastery={ESelfMastery.KNOW_PARTIALLY}
                />
                <Grid
                    item xs
                    marginLeft={1}
                >
                    {t('I_know_partially')}
                </Grid>
            </Grid>
            {/* -------------------- DO NOT KNOW -------------------- */}
            <Grid
                container
                alignItems="center"
                marginTop={1}
            >
                <SelfMasteryIcon
                    selfMastery={ESelfMastery.DO_NOT_KNOW}
                />
                <Grid
                    item xs
                    marginLeft={1}
                >
                    {t('I_do_not_know')}
                </Grid>
            </Grid>
            {/* -------------------- DO NOT UNDERSTAND -------------------- */}
            <Grid
                container
                alignItems="center"
                marginTop={1}
            >
                <SelfMasteryIcon
                    selfMastery={ESelfMastery.DO_NOT_UNDERSTAND}
                />
                <Grid
                    item xs
                    marginLeft={1}
                >
                    {t('I_do_not_understand')}
                </Grid>
            </Grid>
        </div>
    );
}
