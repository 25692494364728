import { gql } from '@apollo/client';

export const SIMPLIFIED_CUSTOM_REPOSITORY = gql`
    query CustomRepository($id: ID!) {
        customRepository(id: $id) {
            id
            name
            customBlocks {
                id
                skillBlock {
                    id
                    name
                    pivot {
                        position
                    }
                    skills {
                        id
                        name
                        position
                        descriptors {
                            id
                            name
                            descriptorType
                        }
                    }
                }
                customSkills {
                    id
                    skill {
                        id
                    }
                    descriptors {
                        id
                    }
                }
            }
        }
    }
`

export const CUSTOM_REPOSITORY = gql`
    query CustomRepository($id: ID!) {
        customRepository(id: $id) {
            id
            name
            jobsConcerned
            prerequisite
            status
            creator {
                id
                fullName
            }
            lastModifier {
                id
                fullName
            }
            company {
                id
                name
                postalCode
                city
            }
            customBlocks {
                id
                position
                skillBlock {
                    id
                    name
                    skillRepositories {
                        id
                        name
                        activitySector {
                            id
                            name
                            iconUrl
                        }
                    }
                }
                customSkills {
                    id
                    customBlock {
                        id
                        position
                    }
                    skill {
                        id
                        name
                        position
                    }
                    descriptors {
                        id
                        name
                        descriptorType
                    }
                    linkItems {
                        id
                        atypicalItem {
                            id
                            name
                            parentItem {
                                id
                                name
                            }
                            atypicalRepository {
                                id
                            }
                        }
                    }
                }
            }
            activityRepository {
                id
                activities {
                    id
                    name
                    position
                    tasks {
                        id
                        name
                        position
                        activity {
                            id
                            position
                        }
                        observableElements {
                            id
                            name
                            position
                        }
                        successIndicators {
                            id
                            name
                            position
                        }
                    }
                }
            }
            repositoryLinks {
                id
                atypicalRepository {
                    id
                    name
                    logoUrl
                    atypicalLevels {
                        id
                        name
                        level
                        isBlock
                        isLinkable
                        isEvaluable
                        isTrainable
                        parentLevel {
                            id
                        }
                        childLevel {
                            id
                        }
                        atypicalItems {
                            id
                            name
                            parentItem {
                                id
                            }
                            subItems {
                                id
                            }
                        }
                    }
                    atypicalItems {
                        id
                        name
                    }
                }
                linkItems {
                    id
                    linkable_id
                    linkableType
                    atypicalItem {
                        id
                        name
                        parentItem {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`

export const CUSTOM_REPOSITORY_RESULTS = gql`
    query CustomRepositoryResults(
        $learnerId: ID!
        $customRepositoryId: ID!
        $roadmapId: ID
    ) {
        customRepositoryResults(
            learnerId: $learnerId
            customRepositoryId: $customRepositoryId
            roadmapId: $roadmapId
        ) {
            customBlockResults {
                simpleResultId
                resultType
                nextStep
            }
            customSkillResults {
                simpleResultId
                resultType
                nextStep
            }
            descriptorResults {
                simpleResultId
                resultType
                nextStep
            }
            activityResults {
                simpleResultId
                resultType
                nextStep
            }
            taskResults {
                simpleResultId
                resultType
                nextStep
            }
            linkItemResults {
                simpleResultId
                resultType
                nextStep 
            }
        }
    }
`;
