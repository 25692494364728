import React from 'react';

// MUI
import { QuestionMark as DefaultIcon } from '@mui/icons-material';

// Services
import { ESelfMastery } from 'services/enums';

/* * *
* Assets
*/

import { ReactComponent as KnowIcon } from 'assets/images/smileys/smiley-know.svg';
import { ReactComponent as KnowPartiallyIcon } from 'assets/images/smileys/smiley-know-partially.svg';
import { ReactComponent as DoNotKnowIcon } from 'assets/images/smileys/smiley-do-not-know.svg';
import { ReactComponent as DoNotUnderstandIcon } from 'assets/images/smileys/smiley-do-not-understand.svg';

import { ReactComponent as UnselectedKnowIcon } from 'assets/images/unselected-smileys/unselected-smiley-know.svg';
import { ReactComponent as UnselectedKnowPartiallyIcon } from 'assets/images/unselected-smileys/unselected-smiley-know-partially.svg';
import { ReactComponent as UnselectedDoNotKnowIcon } from 'assets/images/unselected-smileys/unselected-smiley-do-not-know.svg';
import { ReactComponent as UnselectedDoNotUnderstandIcon } from 'assets/images/unselected-smileys/unselected-smiley-do-not-understand.svg';

/* * *
* CSS
*/

const iconProps : React.CSSProperties = {
    width: 48,
    height: 48,
    verticalAlign: 'middle',
};

const divProps : React.CSSProperties = {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- SELF MASTERY ICON ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SelfMasteryIcon({
    selfMastery,
    unselected = false,
} : {
    selfMastery : ESelfMastery | undefined,
    unselected?: boolean,
}) {

    switch (selfMastery) {

        case ESelfMastery.KNOW:
            return unselected
                ? <UnselectedKnowIcon style={iconProps} />
                : <KnowIcon style={iconProps} />;

        case ESelfMastery.KNOW_PARTIALLY:
            return unselected
                ? <UnselectedKnowPartiallyIcon style={iconProps} />
                : <KnowPartiallyIcon style={iconProps} />;

        case ESelfMastery.DO_NOT_KNOW:
            return unselected
                ? <UnselectedDoNotKnowIcon style={iconProps} />
                : <DoNotKnowIcon style={iconProps} />;

        case ESelfMastery.DO_NOT_UNDERSTAND:
            return unselected
                ? <UnselectedDoNotUnderstandIcon style={iconProps} />
                : <DoNotUnderstandIcon style={iconProps} />;

        default:
            return (
                <div style={divProps}>
                    <DefaultIcon htmlColor="gray" />
                </div>
            );
    }
}
