import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, IconButton, Tooltip } from '@mui/material';
import { Info as InfoIcon, HelpCenter as QuestionInfo } from '@mui/icons-material';

// Services
import { IAtypicalItem, ISelfPlacementResult } from 'services/interfaces';
import { ERepositoryItemType, EStepType } from 'services/enums';

// Views
import SubItems from './sub-items';
import { getSelfResultIcon, RatingButton, RatingSelfMasteryButton, ResponsiveTable, RatingGlobalButton, SelfResultInfos } from '../utils';
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../index';
import { getRepositoryItemLabel } from '../../../utils';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- RATING ACTIVITIES ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function RatingAtypicalItems({ selfPlacementResults } : { selfPlacementResults: ISelfPlacementResult[] }) {

    const {
        setRepositoryItems,
        stepType,
        atypicalRepository,
        atypicalItems: repositoryItems,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const { t } = useTranslation();
    const [openSelfResultInfos, setOpenSelfResultInfos] = useState(false);
    const [openSubItems, setOpenSubItems] = useState(false);
    const [subItems, setSubItems] = useState<IAtypicalItem[]>([]);

    return (
        <table>
            <thead>
                <tr>
                    <td />
                    {(
                        stepType !== EStepType.SELF_PLACEMENT
                        && stepType !== EStepType.SELF_EVALUATION
                        && repositoryItems.length > 1
                    ) ? (
                        <td className="table-td">
                            <RatingGlobalButton
                                repositoryItemType={ERepositoryItemType.ATYPICAL_ITEM}
                                repositoryItems={repositoryItems}
                                setRepositoryItems={setRepositoryItems}
                            />
                        </td>  
                    ) : (
                        <td />
                    )}
                    {selfPlacementResults.length > 0 && (
                        <td
                            className="table-td"
                            style={{ textAlign: 'center' }}
                        >
                            <Tooltip title={`${t('self_placement_results')} ${t('or_auto_evaluation')}`}>
                                <IconButton onClick={() => setOpenSelfResultInfos(true)}>
                                    <QuestionInfo fontSize="large" />
                                </IconButton>
                            </Tooltip>
                            {openSelfResultInfos && (
                                <SelfResultInfos
                                    title={`${t('self_placement_results')} ${t('or_auto_evaluation')}`}
                                    onClose={() => setOpenSelfResultInfos(false)}
                                />
                            )}
                        </td>
                    )}
                </tr>
            </thead>
            <tbody>
                {repositoryItems.map((repositoryItem, index) => {

                    let atypicalItem = atypicalRepository?.atypicalItems?.find(ai => ai.id === repositoryItem.id);

                    return (
                        <ResponsiveTable
                            key={index}
                            index={index}
                            children1={(
                                <Grid
                                    container
                                    alignItems="center"
                                >
                                    <Grid item xs>
                                        {getRepositoryItemLabel(ERepositoryItemType.ATYPICAL_ITEM, atypicalItem)}
                                    </Grid>
                                    {(
                                        atypicalItem?.subItems
                                        && atypicalItem.subItems.length > 0
                                    ) && (
                                        <Grid item xs="auto">
                                            <Tooltip title={t('sub_items')}> 
                                                <IconButton
                                                    onClick={() => { if (atypicalItem) setSubItems(atypicalItem.subItems); setOpenSubItems(true); }}
                                                    style={{ padding: 6 }}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                            children2={(
                                (stepType === EStepType.SELF_PLACEMENT || stepType === EStepType.SELF_EVALUATION) ? (
                                    <RatingSelfMasteryButton
                                        itemId={repositoryItem.id}
                                        selfMastery={repositoryItem.selfMastery}
                                        repositoryItemType={ERepositoryItemType.ATYPICAL_ITEM}
                                        repositoryItems={repositoryItems}
                                        setRepositoryItems={setRepositoryItems}
                                    />
                                ) : (
                                    <RatingButton
                                        itemId={repositoryItem.id}
                                        mastery={repositoryItem.mastery}
                                        repositoryItemType={ERepositoryItemType.ATYPICAL_ITEM}
                                        repositoryItems={repositoryItems}
                                        setRepositoryItems={setRepositoryItems}
                                    />
                                )
                            )}
                            children3={(
                                selfPlacementResults.length > 0
                                ? getSelfResultIcon(repositoryItem.id, ERepositoryItemType.ATYPICAL_ITEM, selfPlacementResults)
                                : undefined
                            )}
                        />
                    )
                })}
                {/* -------------------- SUB ITEMS -------------------- */}
                {(openSubItems && atypicalRepository) && (
                    <SubItems
                        atypicalRepository={atypicalRepository}
                        subItems={subItems}
                        onClose={() => setOpenSubItems(false)}
                    />
                )}
            </tbody>
        </table>
    );
}
