import { lazy } from 'react';
import { Routes, Route, Navigate, useMatch } from 'react-router-dom';

// Services
import { getToken, clearStorage } from 'services/storage';
import client from 'services/apollo-client';

// Components
import PageNotFound from 'components/page-not-found';

// Views
import UnauthenticatedLayout from 'views/unauthenticated';

/* * *
* Lazy Imports
*/

const Login = lazy(() => import('../../views/unauthenticated/login'));
const LoginWithCode = lazy(() => import('../../views/unauthenticated/login-with-code'));
const ForgotPassword = lazy(() => import('../../views/unauthenticated/forgot-password'));
const SetNewPassword = lazy(() => import('../../views/unauthenticated/set-new-password'));
const ApiResponse = lazy(() => import('../../views/unauthenticated/api-response'));
const LegalNotice = lazy(() => import('../../views/unauthenticated/legal-notice'));

/* * *
* Redirect to App
*/

const RedirectToApp = ({ children } : { children: JSX.Element }) => {

    const token = getToken();
    const wantLoginWithCode = useMatch('login-with-code');

    if (wantLoginWithCode) {

        clearStorage();
        client.clearStore();

        return children;
    }
    if (token) {
        return <Navigate replace to="/app" />;
    }
    return children;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- PUBLIC ROUTES ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function PublicRoutes() {
    return (
        <Routes>
            <Route element={<RedirectToApp><UnauthenticatedLayout /></RedirectToApp>}>
                <Route index element={<Login />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="set-new-password" element={<SetNewPassword />} />
                <Route path="login-with-code" element={<LoginWithCode />} />
                <Route path="api-response" element={<ApiResponse />} />
                <Route path="legal-notice" element={<LegalNotice />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>
    );
}
