import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// MUI
import { Chip } from '@mui/material';

// Services
import { ICompany, ICreatorAndLastModifier } from 'services/interfaces';
import { getApplicationName, getFormattedDate, getFullAddress, truncateLabel, getCreatorAndModifierNames, getFileSize } from 'services/utils';
import { getStoredUser } from 'services/storage';

// Permissions
import { canUpdateCompany } from 'permissions';

// Icons
import { CompanyIcon } from 'icons';

// Components
import Subheader, { SubheaderTitleIcons } from 'components/subheader';
import SubheaderItem from 'components/subheader/subheader-item';

// Views
import UploadCompanyLogo from '../../upload-company-logo';
import SetCompanyStorageLimit from './set-company-storage-limit';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ COMPANY CARD -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const CompanyCard = ({ company, refetch } : { company: ICompany | undefined, refetch: any }) => {

    const storedUser = getStoredUser();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [openStorageLimit, setOpenStorageLimit] = useState(false);

    return (
        <Subheader
            title={company?.name}
            titleIcons={<SubheaderTitleIcons tooltip={getCreatorAndModifierNames(company as ICreatorAndLastModifier)} icon="info" />}
            icon={<CompanyIcon />}
            description={company?.description}
            imageUrl={company?.logoUrl}
            imageUpload={(
                canUpdateCompany() && (
                    <UploadCompanyLogo
                        companyId={company?.id}
                        refetch={refetch}
                    />
                )
            )}
        >
            {/* -------------------- PARENT -------------------- */}
            {company?.parentCompany && (
                <SubheaderItem
                    onClick={() => navigate('/app/companies/' + company.parentCompany.id)}
                    title={t('is_part_of')}
                    icon="business"
                    tooltip={company.parentCompany.name}
                >
                    {truncateLabel(company.parentCompany.name, 30)}
                </SubheaderItem>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            <SubheaderItem
                title={t('address')}
                icon="place"
            >
                {getFullAddress(company)}
            </SubheaderItem>
            {/* -------------------- STORAGE -------------------- */}
            {(
                company?.storageSize != null
                && company?.storageLimit != null
                && storedUser?.role?.id
                && Number(storedUser.role.id) <= 3
            ) && (
                <>
                    <SubheaderItem
                        onClick={(storedUser?.role?.id && Number(storedUser.role.id) === 1) ? () => setOpenStorageLimit(true) : undefined}
                        title={t('storage')}
                        icon="storage"
                    >
                        {getFileSize(company.storageSize)} / {company?.storageLimit} Go
                    </SubheaderItem>
                    {openStorageLimit && (
                        <SetCompanyStorageLimit
                            storageLimit={company.storageLimit.toString()}
                            onClose={() => setOpenStorageLimit(false)}
                            onRefetch={() => { setOpenStorageLimit(false); refetch(); }}
                        />
                    )}
                </>
            )}
            {/* -------------------- ACCESSIBLE APPLICATIONS -------------------- */}
            {(
                storedUser?.role?.id
                && Number(storedUser.role.id) <= 3
            ) && (
                <SubheaderItem
                    title={t('accessible_applications')}
                    icon="verified_user"
                >
                    <>
                        {(
                            company?.applications
                            && company.applications.length > 0
                        ) ? (
                            company.applications.map(a => (
                                <Chip
                                    key={a.id}
                                    label={`${getApplicationName(a.name)}${a.pivot?.expiration ? ' : ' + getFormattedDate(a.pivot.expiration) : ''}`}
                                    style={{ marginTop: 6, marginLeft: 2 }}
                                />
                            ))
                        ) : (
                            <span>{t('none.2')}</span>
                        )}
                    </>
                </SubheaderItem>
            )}
            {/* -------------------- LEADER -------------------- */}
            {company?.leader && (
                <SubheaderItem
                    title={t('leader')}
                    icon="person"
                >
                    {company.leader}
                </SubheaderItem>
            )}
        </Subheader>
    );
};

export default CompanyCard;
