import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InputActionMeta, ActionMeta } from 'react-select';

// Lodash
import { debounce } from 'lodash';

// Apollo
import { useQuery } from '@apollo/client';
import { SIMPLIFIED_STEP_MODEL } from 'views/authenticated/models/step-models/step-model/gql-step-model';
import { STEP_MODELS } from 'views/authenticated/models/step-models/gql-step-models';

// Services
import { IStepModel } from 'services/interfaces';
import { EStepType } from 'services/enums';

// Components
import Select, { ISelectProps } from 'components/react-select';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SELECT STEP MODEL ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ISelectStepModelProps extends ISelectProps {
    onChange: (newValue: any) => void;
    stepType?: EStepType;
    customRepositoryId?: string;
    defaultValue?: string;
}

export default function SelectStepModel(props: ISelectStepModelProps) {

    const { t } = useTranslation();
    const [options, setOptions] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState('');

    const { loading, data } = useQuery<{ stepModels: { data: IStepModel[] }}>(STEP_MODELS, {
        variables: {
            page: 1,
            first: 10,
            name: '%' + searchValue + '%',
            customRepositoryId: props.customRepositoryId,
            stepType: props.stepType,
        },
    });

    const { data: defaultData } = useQuery<{ stepModel: IStepModel }>(SIMPLIFIED_STEP_MODEL, {
        variables: { id: props.defaultValue },
        skip: props.defaultValue == null,
    });

    const delayedOnSearch = useRef(debounce(value => setSearchValue(value), 500)).current;

    const handleDelayedOnSearch = (value: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change') {
            delayedOnSearch(value);
        }
    };

    const handleOnChange = (value: any, actionMeta: ActionMeta<any>) => {
        if (actionMeta.action === 'clear') {
            setSearchValue('');
        }
        props.onChange(value);
    };

    useEffect(() => {
        if (data?.stepModels) {
            setOptions(data.stepModels.data.map(m => ({
                value: m.id,
                label: m.name,
                duration: m.duration,
                supportType: m.supportType,
                modality: m.modality,
                context: m.context,
                stepTypes: m.stepTypes,
                reflexivityAxis: m.reflexivityAxis,
                reflexivityQuestions: m.reflexivityQuestions,
                customSkills: m.customSkills,
                descriptors: m.descriptors,
                activities: m.activities,
                tasks: m.tasks,
                atypicalItems: m.atypicalItems,
            })));
        }
        if (defaultData?.stepModel) {
            props.onChange({ value: defaultData.stepModel?.id, label: defaultData.stepModel?.name });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, defaultData]);

    return (
        <Select
            {...props}
            placeholder={t('search_model')}
            onChange={(value, actionMeta) => handleOnChange(value, actionMeta)}
            onInputChange={(value, actionMeta) => handleDelayedOnSearch(value, actionMeta)}
            options={options}
            isLoading={loading}
        />
    );
}
