import { Grid } from '@mui/material';

// Services
import { IStepObject, IWorkSituation } from 'services/interfaces';

// Components
import PelletCard from 'components/pellet-card';

// Repository Items
import RepositoryItemsTable from 'repository-items/repository-items-display/repository-items-table';
import { getRepositoryItemTypeLabelFromStepObject, getRepositoryItemTypeFromStepObject } from 'repository-items/utils';

// Icons
import RepositoryItemIcon from 'icons/repository-item-icon';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------- WORK SITUATION REPOSITORY ITEMS ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function WorkSituationRepositoryItems({ workSituation } : { workSituation: IWorkSituation | undefined }) {

    const repositoryItemType = getRepositoryItemTypeFromStepObject(workSituation as IStepObject);

    return (
        <PelletCard
            paddingTop={0}
            startIcon={<RepositoryItemIcon repositoryItemType={repositoryItemType} />}
            summary={(
                <Grid item xs>
                    {getRepositoryItemTypeLabelFromStepObject(workSituation as IStepObject)}
                </Grid>
            )}
        >
            <RepositoryItemsTable stepObject={workSituation as IStepObject} />
        </PelletCard>
    );
}
