import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';
import { Error as ErrorIcon } from '@mui/icons-material';

// Services
import { magentaMain } from 'services/constants';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- ERROR ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Error({ error } : { error?: string }) {

    const { t } = useTranslation();

    return (
        <Grid
            padding={3}
            container
            flex={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
        >
            <ErrorIcon
                fontSize="large"
                style={{ color: magentaMain }}
            />
            <span style={{ color: magentaMain }}>
                {error || t('something_wrong_happened')}
            </span>
        </Grid>
    );
}
