import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Icon } from '@mui/material';

// Services
import { EArticleStatus, ERepositoryStatus, ERoadmapStatus, EVisibility, EMastery } from 'services/enums';
import { errorMain, magentaMain, successMain, warningMain } from 'services/constants';

// CSS
import styles from './FramedStatus.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- FRAMED STATUS ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

const FramedStatus = ({
    status,
    disabledLabel = false,
    style,
} : {
    status: 'SIGNED' | 'WAITING' | ERoadmapStatus | ERepositoryStatus | EVisibility | EArticleStatus | EMastery | undefined,
    disabledLabel?: boolean,
    style?: React.CSSProperties,
}) => {

    const { t } = useTranslation();

    const getAttributes = () => {

        switch (status) {

            case 'PUBLISHED':
                return ({
                    label: t('published'),
                    icon: 'check_circle',
                    color: successMain,
                });
            
            case 'VALIDATED':
                return ({
                    label: t('validated'),
                    icon: 'check_circle',
                    color: successMain,
                });

            case 'AVAILABLE':
                return ({
                    label: t('available'),
                    icon: 'check_circle',
                    color: successMain,
                });

            case 'SIGNED':
                return ({
                    label: t('signed'),
                    icon: 'check_circle',
                    color: successMain,
                });

            case 'ARCHIVED':
                return ({
                    label: t('archived'),
                    icon: 'archive',
                    color: magentaMain,
                });

            case 'DRAFT':
                return ({
                    label: t('draft'),
                    icon: 'error',
                    color: warningMain,
                });

            case 'WAITING':
                return ({
                    label: t('waiting'),
                    icon: 'pending',
                    color: warningMain,
                });

            case 'IN_PROGRESS':
                return ({
                    label: t('in_progress'),
                    icon: 'build_circle',
                    color: warningMain,
                });

            case 'NOT_OBSERVABLE':
                return ({
                    label: t('not_observable.1'),
                    icon: 'visibility_off',
                    color: warningMain,
                    rounded: true,
                });

            case 'NOT_VALIDATED':
                return ({
                    label: t('not_validated'),
                    icon: 'cancel',
                    color: errorMain,
                });

            case 'ENDED':
                return ({
                    label: t('ended'),
                    icon: 'flag_circle',
                    color: successMain,
                });

            case 'PUBLIC':
                return ({
                    label: t('public.2'),
                    icon: 'public',
                    color: 'gray'
                });

            case 'LIMITED':
                return ({
                    label: t('limited.2'),
                    icon: 'security',
                    color: 'gray'
                });

            case 'PRIVATE':
                return ({
                    label: t('private.2'),
                    icon: 'shield',
                    color: 'gray'
                });

            default:
                return ({
                    label: t('undefined'),
                    icon: 'help',
                    color: 'gray',
                });
        }
    };
    
    const attributes = getAttributes();

    if (disabledLabel && attributes.rounded) {
        return (
            <div
                className={styles.roundedIcon}
                style={{ backgroundColor: attributes.color }}
            >
                <Icon sx={{ color: 'white', fontSize: 18 }}>
                    {attributes.icon}
                </Icon>
            </div>
        );
    }

    if (disabledLabel) {
        return (
            <Icon style={{ ...style, color: attributes.color }}>
                {attributes.icon}
            </Icon>
        );
    }

    return (
        <div
            className={styles.container}
            style={{ ...style, backgroundColor: attributes.color }}
        >
            <span style={{ marginRight: 8 }}>
                {attributes.label}
            </span>
            <Icon fontSize="small">
                {attributes.icon}
            </Icon>
        </div>
    );
};

export default FramedStatus;
