import { gql } from '@apollo/client';

export const USER = gql`
    query User($id: ID) {
        user(id: $id) {
            id
            fullName
            lastName
            firstName
            email
            hasVerifiedEmail
            phoneNumber
            hasVerifiedPhoneNumber
            phoneNumberPrefix
            birthdate
            gender
            notificationType
            photoUrl
            locale
            permissions
            favoriteStepTypes
            ownDashboardSettings
            creator {
                id
                fullName
            }
            lastModifier {
                id
                fullName
            }
            role {
                id
                name
            }
            company {
                id
                name
                postalCode
                city
                footer
                leader
                logoUrl
                applications {
                    id
                    name
                    pivot {
                        expiration
                    }
                }
            }
            companies {
                id
                name
            }
        }
    }
`;
