import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';
import { Link as LinkIcon, DynamicFeed as ContainIcon } from '@mui/icons-material';

// Services
import { IAtypicalItem, IAtypicalLevel } from 'services/interfaces';
import { magentaMain } from 'services/constants';
import { EResultType } from 'services/enums';

// Components
import { Block, BlockContainer, BlockIconButton, EmptyBlock } from 'components/block';
import ResultTypeIcon, { ResultTypeBadge, getResultTypeLabel, isResultTypeIcon } from 'components/result-type';

// Repository Items
import { getAtypicalLevelTypeFromStepType } from 'repository-items/utils';

// Icons
import { SelectedRepositoryItemIcon } from 'icons';

// Repository Items
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../../index';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- ATYPICAL ITEMS ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function AtypicalItems({
    atypicalLevel,
    atypicalItems,
    selectedItem,
    setSelectedItem,
} : {
    atypicalLevel: IAtypicalLevel | undefined,
    atypicalItems: IAtypicalItem[] | undefined,
    selectedItem: IAtypicalItem | null,
    setSelectedItem: (item: IAtypicalItem | null) => void,
}) {

    const { t } = useTranslation();

    const {
        setRepositoryItems,
        stepType,
        atypicalRepository,
        atypicalRepositorySelectOption,
        atypicalRepositoryResults,
        atypicalItems: selectedItems,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const levelType = useMemo(() => {
        return getAtypicalLevelTypeFromStepType(stepType);
    }, [stepType]);

    const getSelectedItemCount = (item: IAtypicalItem) => {

        let count = 0;

        function recursiveCount(atypicalItem: IAtypicalItem) {

            atypicalItem.subItems.forEach(subItem => {

                if (selectedItems.some(i => i.id === subItem.id)) {
                    count += 1;
                }
    
                let correspondingAtypicalItem = atypicalRepository?.atypicalItems.find(ai => ai.id === subItem.id);
                if (
                    correspondingAtypicalItem?.subItems
                    && correspondingAtypicalItem.subItems.length > 0
                ) {
                    recursiveCount(correspondingAtypicalItem);
                }
            });
        }

        recursiveCount(item);
        return count;
    };

    const onSearchLinkItems = (item: IAtypicalItem) => {
        let hasLinkItems = false;

        function searchLinkItems(atypicalItem: IAtypicalItem) {

            atypicalItem.subItems.forEach(subItem => {

                if (atypicalRepositorySelectOption?.linkItems?.some(i => i.atypicalItem?.id === subItem.id)) {
                    hasLinkItems = true;
                }

                let correspondingAtypicalItem = atypicalRepository?.atypicalItems.find(ai => ai.id === subItem.id);
                if (
                    correspondingAtypicalItem?.subItems
                    && correspondingAtypicalItem.subItems.length > 0
                ) {
                    searchLinkItems(correspondingAtypicalItem);
                }
            });
        }

        searchLinkItems(item);
        return hasLinkItems;
    };

    const onSelectAtypicalItem = (atypicalItem: IAtypicalItem) => {
        if (atypicalItem.id === selectedItem?.id) {
            setSelectedItem(null);
        }
        else {
            setSelectedItem(atypicalItem);
        }
    };

    const onCheckAtypicalItem = (atypicalItemId: string) => {
        let index = selectedItems.findIndex(i => i.id === atypicalItemId);
        if (index === -1) {
            selectedItems.push({ id: atypicalItemId });
        }
        else {
            selectedItems.splice(index, 1);
        }
        setRepositoryItems({ atypicalItems: selectedItems });
    };

    return (
        <Grid
            container
            spacing={3}
        >
            {(
                atypicalItems
                && atypicalItems.length > 0
            ) ? (
                atypicalItems.map(atypicalItem => {

                    let itemCount = getSelectedItemCount(atypicalItem);
                    let hasLinkItems = onSearchLinkItems(atypicalItem);
                    let resultType = atypicalRepositoryResults.find(r => r.simpleResultId === atypicalItem.id)?.resultType;

                    return (
                        <BlockContainer key={atypicalItem.id}>
                            <Block
                                onClick={(atypicalLevel && atypicalLevel[levelType])
                                    ? () => onCheckAtypicalItem(atypicalItem.id)
                                    : () => onSelectAtypicalItem(atypicalItem)
                                }
                                selected={selectedItem?.id === atypicalItem.id || selectedItems?.some(i => i.id === atypicalItem.id)}
                                deselected={(selectedItem && selectedItem.id !== atypicalItem.id) ? true : false}
                                color="magenta"
                                badge={<ResultTypeBadge resultType={resultType} />}
                                icons={(
                                    <>
                                        {(
                                            atypicalRepositorySelectOption?.linkItems
                                            && atypicalRepositorySelectOption.linkItems.some(i => i.atypicalItem?.id === atypicalItem.id)
                                        ) && (
                                            <BlockIconButton tooltip={t('atypical_item_linked_to_custom_repository')}>
                                                <LinkIcon />
                                            </BlockIconButton>
                                        )}
                                        {(hasLinkItems && resultType !== EResultType.CONTAINS) && (
                                            <BlockIconButton tooltip={t('contains_items_linked_to_custom_repository')}>
                                                <ContainIcon />
                                            </BlockIconButton>
                                        )}
                                        {isResultTypeIcon(resultType) && (
                                            <BlockIconButton tooltip={getResultTypeLabel(resultType)}>
                                                <ResultTypeIcon resultType={resultType} />
                                            </BlockIconButton>
                                        )}
                                        <BlockIconButton>
                                            {itemCount}
                                        </BlockIconButton>
                                        {selectedItems?.some(i => i.id === atypicalItem.id) && (
                                            <BlockIconButton tooltip={t('selected_item')}>
                                                <SelectedRepositoryItemIcon />
                                            </BlockIconButton>
                                        )}
                                    </>
                                )}
                            >
                                {atypicalItem.name}
                            </Block>
                        </BlockContainer>
                    );
                })
            ) : (
                <BlockContainer>
                    <EmptyBlock color={magentaMain}>
                        {t('none.1')}
                    </EmptyBlock>
                </BlockContainer>
            )}
        </Grid>
    );
}
