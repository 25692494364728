import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { PhotoCamera as CameraIcon } from '@mui/icons-material';

// Apollo
import { useMutation } from '@apollo/client';
import { UPLOAD_COMPANY_LOGO } from './gql-upload-company-logo';

// Services
import { getErrorExtensions } from 'services/utils';

// Components
import FileInput from 'components/file-input';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- UPLOAD COMPANY LOGO --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function UploadCompanyLogo({
    companyId,
    refetch,
    style,
} : {
    companyId: string | undefined,
    refetch?: any,
    style?: React.CSSProperties,
}) {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [uploadCompanyLogo, { loading, error, data }] = useMutation(UPLOAD_COMPANY_LOGO);

    const onChangeFile = (files: FileList | null) => {
        if (files && files.length > 0) {
            uploadCompanyLogo({
                variables: {
                    id: companyId,
                    file: files[0],
                },
            });
        }
    };

    useEffect(() => {
        if (data?.uploadCompanyLogo) {
            enqueueSnackbar(t('updated_successfully'), { variant: 'success' });
            refetch();
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <FileInput
            onChange={onChangeFile}
            inputId="companyLogo"
            accept="image/*"
            icon={<CameraIcon />}
            loading={loading}
            disabledLabel
            containerProps={style}
        />
    );
}
