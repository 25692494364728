import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { magentaMain } from 'services/constants';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- FIELDS REQUIRED ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function FieldsRequired({ label, style } : { label?: string, style?: React.CSSProperties }) {

    const { t } = useTranslation();

    return (
        <Grid
            container
            justifyContent="center"
            textAlign="center"
            fontWeight={500}
            color={magentaMain}
            style={style}
        >
            {label || t('fields_required')}
        </Grid>
    );
}
