import { gql } from '@apollo/client';

export const COMPANIES = gql`
    query Companies(
        $search: String
        $isRoot: Boolean
        $zerobarrierAccess: ApplicationAccess
        $happincoAccess: ApplicationAccess
        $mealsAndBenefitsAccess: ApplicationAccess
        $first: Int
        $page: Int
    ) {
        companies(
            search: $search
            isRoot: $isRoot
            zerobarrierAccess: $zerobarrierAccess
            happincoAccess: $happincoAccess
            mealsAndBenefitsAccess: $mealsAndBenefitsAccess
            first: $first
            page: $page
            orderBy: [{ column: NAME, order: ASC }]
        ) {
            paginatorInfo {
                currentPage
                hasMorePages
                total
            }
            data {
                id
                name
                address
                postalCode
                city
                logoUrl
                leader
                parentCompany {
                    id
                    name
                }
                country {
                    id
                    name
                }
                applications {
                    name
                    pivot {
                        expiration
                    }
                }
            }
        }
    }
`;
