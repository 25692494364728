import { Backdrop, CircularProgress } from '@mui/material';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- BACKDROP --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function CustomizeBackdrop() {
    return (
        <Backdrop
            open
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
