import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Fab, Tooltip } from '@mui/material';
import { Description as SheetIcon, Download as DownloadIcon, TaskAlt as ValidIcon } from '@mui/icons-material';

// Services
import { successMain } from 'services/constants';

// Components
import FileInput from 'components/file-input';
import PelletCard from 'components/pellet-card';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ UPLOAD SIGN OFF SHEET CARD ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function UploadSignOffSheetCard({
    onSaveSignOffSheet,
    onChangeFile,
    signOffSheetUrl,
    loading,
} : {
    onSaveSignOffSheet: () => void,
    onChangeFile: (files: FileList | null) => void,
    signOffSheetUrl: string | undefined,
    loading: boolean,
}) {

    const { t } = useTranslation();

    return (
        <PelletCard
            paddingTop={0}
            startIcon={<SheetIcon color="primary" />}
            summary={(
                <Grid item xs>
                    {t('sign_off_sheet')}
                </Grid>
            )}
        >
            {/* -------------------- DOWNLOAD -------------------- */}
            {signOffSheetUrl && (
                <Grid
                    container
                    marginBottom={3}
                >
                    <div
                        className="framed-block inline-flex"
                        style={{ borderColor: successMain, color: successMain }}
                    >
                        <Grid
                            container
                            alignItems="center"
                        >
                            <Grid item xs="auto">
                                <ValidIcon />
                            </Grid>
                            <Grid
                                item xs
                                marginLeft={1}
                            >
                                {t('sign_off_sheet_has_been_stored')}
                            </Grid>
                            <Grid
                                item xs="auto"
                                marginLeft={3}
                            >
                                <Tooltip title={t('download')}>
                                    <Fab
                                        onClick={onSaveSignOffSheet}
                                        color="success"
                                    >
                                        <DownloadIcon />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            )}
            {/* -------------------- FILE INPUT -------------------- */}
            <FileInput
                onChange={onChangeFile}
                label={signOffSheetUrl ? t('replace_sign_off_sheet') : t('add_sign_off_sheet')}
                inputId="signOffSheet"
                accept="image/*, .pdf, .doc"
                loading={loading}
            />
        </PelletCard>
    );
}
