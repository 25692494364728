import { Grid } from '@mui/material';

// Services
import { IAtypicalRepository, IReflectionPhase, IStepObject } from 'services/interfaces';

// Components
import PelletCard from 'components/pellet-card';

// Repository Items
import RepositoryItemsTable from 'repository-items/repository-items-display/repository-items-table';
import { getRepositoryItemTypeLabelFromStepObject, getRepositoryItemTypeFromStepObject } from 'repository-items/utils';

// Icons
import RepositoryItemIcon from 'icons/repository-item-icon';
import { useQuery } from "@apollo/client";
import {
    ATYPICAL_ITEMS_RELATED_TO_ACTIVITY
} from '../../../repositories/activity-repositories/activity-repository/activities/gql-activities';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------- REFLECTION PHASE REPOSITORY ITEMS --------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ReflectionPhaseRepositoryItems({ reflectionPhase } : { reflectionPhase: IReflectionPhase | undefined }) {

    const repositoryItemType = getRepositoryItemTypeFromStepObject(reflectionPhase?.learnerWorkSituation?.workSituation as IStepObject);

    const { data } = useQuery<{atypicalItemsRelatedToActivity: IAtypicalRepository[]}>(ATYPICAL_ITEMS_RELATED_TO_ACTIVITY, {
        variables: { taskIds: reflectionPhase?.learnerWorkSituation?.workSituation?.tasks.map(task => task.id) || [] },
    });

    return (
        <PelletCard
            paddingTop={0}
            startIcon={<RepositoryItemIcon repositoryItemType={repositoryItemType} />}
            summary={(
                <Grid item xs>
                    {getRepositoryItemTypeLabelFromStepObject(reflectionPhase?.learnerWorkSituation?.workSituation as IStepObject)}
                </Grid>
            )}
        >
            <RepositoryItemsTable
                stepObject={reflectionPhase?.learnerWorkSituation?.workSituation as IStepObject}
                atypicalItemsRelatedToActivity={data?.atypicalItemsRelatedToActivity}
            />
        </PelletCard>
    );
}
