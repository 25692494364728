import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Fab, CircularProgress } from '@mui/material';
import { Upload as UploadIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

// Services
import { acceptedExtensions } from 'services/constants';

const extensions = Object.values(acceptedExtensions).flatMap(value => value.map(item => `.${item}`)).join(', ');

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- FILE INPUT -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function FileInput({
    onChange,
    inputId,
    label,
    disabledLabel = false,
    icon,
    accept,
    loading = false,
    fabColor = 'primary',
    containerProps,
} : {
    onChange: (files: FileList | null) => void,
    inputId: string,
    label?: string,
    disabledLabel?: boolean,
    icon?: JSX.Element,
    accept?: string,
    loading?: boolean,
    fabColor?: 'primary' | 'yellow' | 'warning',
    containerProps?: React.CSSProperties,
}) {

    const { t } = useTranslation();
    const [fileName, setFileName] = useState<string | null>(null);

    const Input = styled('input')({
        display: 'none',
    });

    const onChangeFileList = (fileList: FileList | null) => {
        if (fileList && fileList.length > 0) {
            setFileName(fileList[0].name);
        }
        onChange(fileList);
    };

    return (
        <Grid
            container
            alignItems="center"
            style={containerProps}
        >
            <label htmlFor={`upload-${inputId}`}>
                <Input
                    id={`upload-${inputId}`}
                    type="file"
                    onChange={e => onChangeFileList(e.target.files)}
                    accept={accept || extensions}
                />
                <Fab
                    disabled={loading}
                    color={fabColor}
                    aria-label="upload"
                    component="span"
                >
                    {loading ? (
                        <CircularProgress size={20} color="inherit" />
                    ) : (
                        icon || <UploadIcon />
                    )}
                </Fab>
            </label>
            {!disabledLabel && (
                <Grid
                    item xs
                    marginLeft={1}
                >
                    <span>
                        {label || t('choose_file')}
                    </span>
                    {fileName && (
                        <>
                            <br />
                            <span className="subtitle">
                                {fileName}
                            </span>
                        </>
                    )}
                </Grid>
            )}
        </Grid>
    )
}
