import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { primaryMain } from 'services/constants';
import { IReflexivityQuestion, IReflexivityQuestionItem } from 'services/interfaces';

// Icons
import { SelectedRepositoryItemIcon } from 'icons';

// Components
import PelletCard from 'components/pellet-card';
import { Block, BlockContainer, BlockIconButton } from 'components/block';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- 02 REFLEXIVITY QUESTIONS ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

interface IReflexivityQuestionsProps {
    position: number;
    reflexivityQuestions: IReflexivityQuestion[];
    reflexivityQuestionItems: IReflexivityQuestionItem[];
    setReflexivityQuestionItems: (reflexivityQuestionItems: IReflexivityQuestionItem[]) => void;
}

export default function ReflexivityQuestions(props: IReflexivityQuestionsProps) {

    const { t } = useTranslation();

    const onClick = (questionId: string) => {

        let items = [...props.reflexivityQuestionItems];
        let index = items.findIndex(q => q.id === questionId);

        if (index === -1) {
            items.push({ id: questionId, answer: '' });
        }
        else {
            items.splice(index, 1);
        }

        props.setReflexivityQuestionItems(items);
    };

    return (
        <PelletCard
            paddingTop={0}
            pelletColor={primaryMain}
            pelletContent={props.position}
            summary={(
                <Grid item xs>
                    {t('select_questions')} ({props.reflexivityQuestionItems.length})
                </Grid>
            )}
        >
            <Grid
                container
                spacing={3}
            >
                {props.reflexivityQuestions.map(rq => {

                    let selected = props.reflexivityQuestionItems.some(i => i.id === rq.id);

                    return (
                        <BlockContainer key={rq.id}>
                            <Block
                                onClick={() => onClick(rq.id)}
                                selected={selected}
                                icons={(
                                    selected && (
                                        <BlockIconButton tooltip={t('selected_item')}>
                                            <SelectedRepositoryItemIcon />
                                        </BlockIconButton>
                                    )
                                )}
                                buttonProps={{ style: { whiteSpace: 'pre-wrap' } }}
                            >
                                {rq.position}. {rq.description}
                            </Block>
                        </BlockContainer>  
                    );
                })}
            </Grid>
        </PelletCard>
    );
}
