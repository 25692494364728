import { gql } from '@apollo/client';

export const USERS = gql`
    query Users(
        $search: String
        $first: Int
        $page: Int
        $roles: [ID]
        $companyId: ID
        $hasValidatedAccount: Boolean
        $isLinkedToCompany: Boolean
    ) {
        users(
            search: $search
            first: $first
            page: $page
            roles: $roles
            companyId: $companyId
            hasValidatedAccount: $hasValidatedAccount
            isLinkedToCompany: $isLinkedToCompany
            orderBy: [{ column: LAST_NAME, order: ASC }, { column: FIRST_NAME, order: ASC }]
        ) {
            paginatorInfo {
                currentPage
                hasMorePages
                total
            }
            data {
                id
                firstName
                lastName
                fullName
                photoUrl
                birthdate
                email
                phoneNumber
                phoneNumberPrefix
                gender
                company {
                    id
                    name
                }
                role {
                    id
                }
            }
        }
    }
`;
