import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { IReflexivityQuestion, IReflexivityQuestionItem } from 'services/interfaces';
import { primaryMain } from 'services/constants';
import { useMediaQueries } from 'services/hooks';

// Icons
import { SelectedRepositoryItemIcon } from 'icons';

// Components
import PelletCard from 'components/pellet-card';
import MobileTextField from 'components/mobile-text-field';
import { Block, BlockContainer, BlockIconButton } from 'components/block';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- 03 REFLEXIVITY ANSWERS -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IAnswersProps {
    position: number;
    reflexivityQuestions: IReflexivityQuestion[];
    reflexivityQuestionItems: IReflexivityQuestionItem[];
    setReflexivityQuestionItems: (reflexivityQuestionItems: IReflexivityQuestionItem[]) => void;
}

export default function ReflexivityAnswers(props: IAnswersProps) {

    const { t } = useTranslation();
    const { mediaSM } = useMediaQueries();

    const onClick = (questionId: string) => {

        let items = [...props.reflexivityQuestionItems];
        let index = items.findIndex(q => q.id === questionId);

        if (index === -1) {
            items.push({ id: questionId, answer: '' });
        }
        else {
            items.splice(index, 1);
        }

        props.setReflexivityQuestionItems(items);
    };

    const onChangeAnswer = (questionId: string, answer: string) => {

        let items = [...props.reflexivityQuestionItems];
        let index = items.findIndex(i => i.id === questionId);

        items[index].answer = answer;
        props.setReflexivityQuestionItems(items);
    };

    return (
        <PelletCard
            paddingTop={0}
            pelletColor={primaryMain}
            pelletContent={props.position}
            summary={(
                <Grid item xs>
                    {t('answers')}
                </Grid>
            )}
        >
            {props.reflexivityQuestions.map((rq, index) => {

                let item = props.reflexivityQuestionItems.find(i => i.id === rq.id);

                return (
                    <Grid
                        key={rq.id}
                        container
                        alignItems="center"
                        marginTop={index > 0 ? 3 : undefined}
                    >
                        <BlockContainer>
                            <Block
                                onClick={() => onClick(rq.id)}
                                selected={!!(item)}
                                icons={(
                                    item && (
                                        <BlockIconButton tooltip={t('selected_item')}>
                                            <SelectedRepositoryItemIcon />
                                        </BlockIconButton>
                                    )
                                )}
                                buttonProps={{ style: { whiteSpace: 'pre-wrap' } }}
                            >
                                {rq.position}. {rq.description}
                            </Block>
                        </BlockContainer>
                        {item && (
                            <Grid
                                item xs
                                marginLeft={mediaSM ? 3 : undefined}
                                marginTop={mediaSM ? undefined : 3}
                            >
                                <MobileTextField
                                    name={`answer_${rq.id}`}
                                    label={t('answer')}
                                    value={item.answer as string}
                                    onChange={e => onChangeAnswer(rq.id, e.target.value)}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        )}
                    </Grid>
                );
            })}
        </PelletCard>
    );
}
