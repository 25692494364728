import { gql } from '@apollo/client';

export const UPLOAD_USER_PHOTO = gql`
    mutation UploadUserPhoto($id: ID!, $file: Upload!) {
        uploadUserPhoto(id: $id, file: $file) {
            id
            photoUrl
        }
    }
`;
