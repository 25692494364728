import dayjs, { Dayjs } from 'dayjs';

// i18next
import i18next from 'i18next';

// MUI
import { Description as DocIcon, Image as ImageIcon, Movie as VideoIcon, MusicNote as AudioIcon } from '@mui/icons-material';
import { frFR, deDE, enUS } from '@mui/x-date-pickers';
import { SvgIconProps } from '@mui/material';

// Services
import {
    ISelectOptionWithCompanyAttributes, ISelectOptionWithStepModel, ISelectOptionWithUserAttributes,
    IStepModel, IUser, IRoadmap, ICompany, ISignatureGroup, IWorkSituation, IRoadmapStep, IApplication,
    IAtypicalRepository, IAtypicalItem, IBreadcrumbItem, ICreatorAndLastModifier, IInternship, IModelTranslation,
    IProofItem,
    ILearnerWorkSituation,
    IEvaluation,
} from './interfaces';
import {
    ECertificationType, EStepType, EEvaluationType, EApplicationAccess, ENotificationType, EMastery,
    EPostInternshipResult, EDurationUnit, EWorkSituationType, EActionRequired, EArticleType, ECardType,
} from './enums';
import { alphabetChars, acceptedExtensions, originLabel } from './constants';

// Components
import { isValidPhoneNumber } from 'components/react-international-phone';

/*
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*                                                 <LABELS>                                              *
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*/

export const getVersionName = () => {

    switch (window.origin) {

        case 'http://localhost:3000':
        case 'http://localhost:3001':
            return 'dev';

        case 'https://beta.v2.zerobarrier.eu':
            return 'beta';

        case 'https://demo.zerobarrier.app':
            return 'demo';
            
        default:
            return '';
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- GET CONJUGATE LABEL -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const conjugateLabel = (count: number, key: string) => {
    if (count > 1) {
        return i18next.t(key + 's');
    }
    return i18next.t(key);
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- TRUNCATE LABEL ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const truncateLabel = (label: string, index: number) => {
    if (label.length > index) {
        return `${label.substring(0, index).trimEnd()}...`;
    }
    return label;
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- GET ROLE LABEL ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRoleLabel = (roleId: string | undefined) => {

    switch (roleId) {

        case '1':
            return i18next.t('super_admin');

        case '2':
            return i18next.t('admin');

        case '3':
            return i18next.t('manager');

        case '4':
            return i18next.t('trainer');

        case '5':
            return i18next.t('learner');

        default:
            return i18next.t('none.1');
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ GET NOTIFICATION TYPE LABEL ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getNotificationTypeLabel = (notificationType: ENotificationType) => {

    switch (notificationType) {

        case ENotificationType.ALL:
            return i18next.t('all.4');

        case ENotificationType.NONE:
            return i18next.t('none.2');

        default:
            return i18next.t(notificationType.toLowerCase());
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- GET ARTICLE TYPE LABEL ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const getArticleTypeLabel = (articleType: EArticleType | undefined) => {

    switch (articleType) {

        case EArticleType.EVENT:
            return i18next.t('event');

        case EArticleType.FUNCTIONALITY:
            return i18next.t('functionality');

        case EArticleType.NEWS:
            return i18next.t('news.3');

        default:
            return i18next.t('undefined');
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- GET CARD TYPE LABEL -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getCardTypeLabel = (cardType: ECardType) => {

    switch (cardType) {

        case ECardType.ARTICLES:
            return i18next.t('news.2');

        default:
            return i18next.t(cardType.toLowerCase());
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- GET CERTIFICATION TYPE LABEL --------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getCertificationTypeLabel = (certificationType: ECertificationType | undefined) => {

    switch (certificationType) {

        case ECertificationType.CQP:
            return 'CQP';

        case ECertificationType.DIPLOMA:
            return i18next.t('diploma');

        case ECertificationType.EUROPEAN:
            return i18next.t('european_skill_repository');

        case ECertificationType.TITLE:
            return i18next.t('title');

        default:
            return i18next.t('undefined');
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------- GET POST INTERNSHIP RESULT LABEL -------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getPostInternshipResultLabel = (result: EPostInternshipResult) => {

    switch (result) {

        case EPostInternshipResult.NONE:
            return i18next.t('internship_result_none');

        case EPostInternshipResult.POSITIVE:
            return i18next.t('internship_result_positive');

        case EPostInternshipResult.TRANSITION:
            return i18next.t('internship_result_transition');

        case EPostInternshipResult.SUSTAINABLE:
            return i18next.t('internship_result_sustainable');

        case EPostInternshipResult.SUSTAINABLE_NO_END:
            return i18next.t('internship_result_sustainable_no_end');

        case EPostInternshipResult.PROFESSIONAL_PROJECT_VALIDATED:
            return i18next.t('professional_project_validated');

        default:
            return i18next.t('undefined');
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- GET CERTIFICATION LEVEL LABEL -------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getCertificationLevelLabel = (certificationLevel: number) => {
    return i18next.t('certification_level_' + certificationLevel);
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- GET DURATION LABEL ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getDurationLabelFromDurationUnit = (duration: number, durationUnit: EDurationUnit) => {

    switch (durationUnit) {

        case EDurationUnit.HOURS:
            return i18next.t('hour_abbreviation');

        case EDurationUnit.DAYS:
            return duration > 1 ? i18next.t('days') : i18next.t('day.1');

        case EDurationUnit.MONTHS:
            return duration > 1 ? i18next.t('months') : i18next.t('month');

        case EDurationUnit.MINUTES:
            return i18next.t('min_abbreviation');

        default:
            return '';
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- GET SIGNATURE GROUP LABEL ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getSignatureGroupLabel = (signatureGroup: ISignatureGroup, user?: IUser, company?: ICompany) => {
    if (user?.role && user.role?.id === '5') {
        return signatureGroup.company?.name;
    }
    if (company) {
        return signatureGroup.learner?.fullName;
    }
    return `${signatureGroup.learner?.fullName} ${i18next.t('for')} ${signatureGroup.company?.name}`
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- GET WORK SITUATION LABEL ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getWorkSituationLabel = (workSituation: IWorkSituation, workSituationType: EWorkSituationType, company?: ICompany) => {
    if (company) {
        return workSituation.customRepository?.name || `${getWorkSituationTitle(workSituationType)} #${workSituation.id}`;
    }
    return `${workSituation.customRepository?.name || `${getWorkSituationTitle(workSituationType)} #${workSituation.id}`} ${i18next.t('at.1')} ${workSituation.company?.name}`;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- GET ROADMAP NAME --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRoadmapName = (roadmap: IRoadmap | undefined) => {
    if (roadmap?.name) {
        return roadmap.name;
    }
    if (roadmap?.customRepository) {
        return roadmap.customRepository?.name;
    }
    return `${i18next.t('roadmap')} ${i18next.t('at.1')} ${roadmap?.company?.name}`;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ GET ROADMAP LABEL --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRoadmapLabel = (roadmap: IRoadmap | undefined, user?: IUser, company?: ICompany) => {
    if (
        (user?.role?.id && Number(user.role.id) === 5)
        || user?.id === roadmap?.learner?.id
    ) {
        return `${getRoadmapName(roadmap)} ${i18next.t('at.1')} ${roadmap?.company?.name}`;
    }
    if (company) {
        return `${getRoadmapName(roadmap)} ${i18next.t('of.1')} ${roadmap?.learner?.fullName}`;
    }
    return `${getRoadmapName(roadmap)} ${i18next.t('of.1')} ${roadmap?.learner?.fullName} ${i18next.t('at.1')} ${roadmap?.company?.name}`;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- GET MASTERY PROOF ITEM LABEL --------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getMasteryProofItemLabel = (mastery: EMastery) => {
    
    switch (mastery) {

        case EMastery.VALIDATED:
            return i18next.t('validated');

        case EMastery.NOT_VALIDATED:
            return i18next.t('not_validated');

        case EMastery.NOT_OBSERVABLE:
            return i18next.t('not_observable.1');

        default:
            return i18next.t('waiting');
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- GET APPLICATION NAME ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getApplicationName = (name: string, initial?: boolean) => {

    switch (name) {

        case 'zerobarrier':
            return initial ? 'ZB' : 'Zero Barrier';

        case 'happinco':
            return initial ? 'HP' : 'Happ\'In Co';

        case 'mealsAndBenefits':
            return initial ? 'RB' : i18next.t('meals_and_benefits');

        default:
            return name;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- GET ROADMAP STEP NAME ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRoadmapStepName = (roadmapSteps: IRoadmapStep[], roadmapStep: IRoadmapStep) => {

    let name = roadmapStep.name;

    if (roadmapStep.linkedRoadmapStepId) {

        let linkedRoadmapStep = roadmapSteps?.find(s => s.id === roadmapStep.linkedRoadmapStepId);

        switch (linkedRoadmapStep?.stepType) {

            case EStepType.PREPARATORY_SEQUENCE:
                name += `. ${i18next.t('linked_to_preparatory_sequence')}`;
                break;

            case EStepType.WORK_SITUATION:
                name += `. ${i18next.t('linked_to_work_situation')}`;
                break;

            case EStepType.INTERNSHIP:
                name += `. ${i18next.t('linked_to_internship')}`;
                break;

            default:
                break;
        }

        name += ` #${Number(roadmapStep.linkedRoadmapStepId).toLocaleString('fr', { minimumIntegerDigits: 2 })}`;
    }

    return name;
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------- GET ROADMAP STEP ACTIONS REQUIRED ------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRoadmapStepActionsRequired = (actionsRequired: EActionRequired[]) => {
    
    let array : string[] = [];

    actionsRequired.forEach(actionRequired => {

        switch (actionRequired) {

            case EActionRequired.SIGNATURE:
                array.push(`${i18next.t('signature')} ${i18next.t('required.2')}.`);
                break;

            case EActionRequired.WEATHER:
                array.push(`${i18next.t('weather')} ${i18next.t('required.2')}.`);
                break;

            case EActionRequired.REFLECTION_PHASE:
                array.push(`${i18next.t('missing_reflection_phase')}.`);
                break;

            case EActionRequired.VALIDATION:
                array.push(`${i18next.t('validation')} ${i18next.t('required.2')}.`);
                break;

            case EActionRequired.PROOF_ITEMS:
                array.push(`${i18next.t('proof_items_awaiting_validation')}.`);
                break;
        }
    });

    return array.join(' ');
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- WORK SITUATION TITLE ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getWorkSituationTitle = (workSituationType: EWorkSituationType | EStepType | undefined, actionType?: 'CREATE' | 'UPDATE') => {

    let title = '';

    switch (actionType) {

        case 'CREATE':
            title += 'add_';
            break;

        case 'UPDATE':
            title += 'edit_';
            break;
    }

    switch (workSituationType) {
        
        case EWorkSituationType.PREPARATORY_SEQUENCE:
            title += 'preparatory_sequence';
            break;

        default:
            title += 'work_situation';
            break;
    }

    return i18next.t(title);
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- INTERNSHIP LABEL --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getInternshipLabel = (internship: IInternship | undefined) => {
    return `${internship?.internshipType?.name} ${i18next.t('from.1').toLowerCase()} ${getFormattedDate(internship?.startDate)} ${i18next.t('to.1')} ${getFormattedDate(internship?.endDate)}`;
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- CREATOR AND LAST MODIFIER ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function getDateTime(datetime: string | undefined) {
    if (datetime) {
        return ` ${i18next.t('on.2')} ${dayjs(datetime).format('DD/MM/YYYY')} ${i18next.t('at.3')} ${dayjs(datetime).format('HH:mm')}`;
    }
}

export const getCreatorAndModifierNames = (props: ICreatorAndLastModifier) => {

    if (props.creator && props.lastModifier) {
        return (
            <>
                {i18next.t('created_by')} {props.creator.fullName}{getDateTime(props.createdAt)}
                <br />
                {i18next.t('edited_by')} {props.lastModifier.fullName}{getDateTime(props.updatedAt)}
            </>
        );
    }

    if (props.creator) {
        return <>{i18next.t('created_by')} {props.creator.fullName}{getDateTime(props.createdAt)}</>;
    }

    if (props.lastModifier) {
        return <>{i18next.t('edited_by')} {props.lastModifier.fullName}{getDateTime(props.updatedAt)}</>;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ GET PROOFABLE TYPE LABEL -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function isLearnWorkSituation(object: any): object is ILearnerWorkSituation {
    return 'workSituation' in object;
}

function isEvaluation(object: any): object is IEvaluation {
    return 'evaluationType' in object;
}

export const getProofableTypeLabel = (proofItem: IProofItem) => {
    
    let type = proofItem.proofableType.split('App\\Models\\');

    if (isLearnWorkSituation(proofItem.proofable)) {
        return proofItem.proofable?.workSituation?.workSituationType === EWorkSituationType.PREPARATORY_SEQUENCE
            ? i18next.t('preparatory_sequence')
            : i18next.t('work_situation');
    }

    if (isEvaluation(proofItem.proofable)) {

        const evaluationType = proofItem.proofable.evaluationType;

        return evaluationType === EEvaluationType.PLACEMENT
            ? i18next.t('placement') : evaluationType === EEvaluationType.FINAL_EVALUATION
            ? i18next.t('final_evaluation')
            : i18next.t('evaluation');
    }

    if (type.length >= 1) {

        switch (type[1]) {

            case 'SelfPlacement':
                return i18next.t('self_placement');

            case 'SelfEvaluation':
                return i18next.t('self_evaluation');
                
            case 'ReflectionPhase':
                return i18next.t('reflection_phase');

            case 'OtherTraining':
                return i18next.t('other_training');

            case 'Support':
                return i18next.t('support');

            case 'ReflexiveResult':
                return i18next.t('reflexive_result');

            case 'Internship':
                return i18next.t('internship');

            case 'ProfessionalPractice':
                return i18next.t('professional_practice');

            default:
                return i18next.t('undefined');
        }
    }

    return i18next.t('undefined');
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ MODEL TRANSLATIONS -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getInitialTranslations = (translations: IModelTranslation[]) : IModelTranslation[] => {
    return getLocales().map(l => {
        let t = translations.find(t => t.locale === l.value);
        if (t) {
            return ({ id: t.translationId, locale: l.value, name: t.name || '', description: t.description || '' });
        }
        return ({ id: undefined, locale: l.value, name: '', description: '' });
    });
};

/*
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*                                                </LABELS>                                              *
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*/

/*
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*                                                 <APOLLO>                                              *
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*/

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- CONNECT OR CREATE USER -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const connectOrCreateUser = (user: ISelectOptionWithUserAttributes) => {
    if (user?.value == null) {
        return ({
            create: {
                lastName: user?.lastName,
                firstName: user?.firstName,
                gender: user?.gender,
                birthdate: user?.birthdate,
                email: user?.email || null,
                phoneNumberPrefix: user?.phoneNumberPrefix,
                phoneNumber: user?.phoneNumber,
            }
        });
    }
    return ({ connect: user.value });
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- CONNECT OR CREATE COMPANY ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const connectOrCreateCompany = (company: ISelectOptionWithCompanyAttributes) => {
    if (company?.value == null) {
        return ({
            create: {
                name: company?.name,
                address: company?.address,
                postalCode: company?.postalCode,
                city: company?.city,
                description: company?.description,
                countryId: company?.countryId,
            }
        });
    }
    return ({ connect: company.value });
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------- CONNECT OR DISCONNECT STEP MODEL -------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const connectOrDisconnectStepModel = (stepModel: ISelectOptionWithStepModel | null, prevStepModel?: IStepModel) => {
    if (stepModel) {
        return ({ connect: stepModel.value });
    }
    if (!stepModel && prevStepModel) {
        return ({ disconnect: prevStepModel.id });
    }
    return null;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ERROR EXTENSIONS  --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getErrorExtensions = (error: any) => {
    
    let hasErrorExtension = false;
    let message = '';

    for (let err of error.graphQLErrors) {
        let item = '';

        if (err?.extensions?.validation && Object.values(err?.extensions?.validation)?.length > 0) {
            hasErrorExtension = true;

            Object.values(err.extensions.validation).forEach((value: any) => {
                value.forEach((v: any) => item += v);
            });
        }
        message += item;
    }

    if (hasErrorExtension) {
        return message;
    }
    return error?.message;
};

/*
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*                                                </APOLLO>                                              *
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*/

/*
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*                                              <STEP TYPES>                                             *
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*/

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- GET STEP TYPE COLOR -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getStepTypeColor = (stepType: EStepType, mui?: boolean) => {

    switch (stepType) {

        case EStepType.SELF_PLACEMENT:
        case EStepType.PLACEMENT:
        case EStepType.FINAL_EVALUATION:
            return mui ? 'navyBlue' : '#3f51b5';

        case EStepType.PREPARATORY_SEQUENCE:
        case EStepType.WORK_SITUATION:
        case EStepType.REFLECTION_PHASE:
        case EStepType.EVALUATION:
            return mui ? 'yellow' : '#f5b200';

        case EStepType.SELF_EVALUATION:
        case EStepType.SUPPORT:
        case EStepType.OTHER_TRAINING:
        case EStepType.PROFESSIONAL_PRACTICE:
        case EStepType.REFLEXIVE_RESULT:
            return mui ? 'success' : '#2e7d32';

        case EStepType.INTERNSHIP:
            return mui ? 'magenta' : '#ac0066';

        default:
            return mui ? 'default' : '#9c9c9c';
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- GET STEP TYPE ACTION LABEL ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getStepTypeActionLabel = (stepType: EStepType | EEvaluationType | undefined) => {

    switch (stepType) {

        case EStepType.WORK_SITUATION:
        case EStepType.OTHER_TRAINING:
        case EStepType.SUPPORT:
        case EStepType.PROFESSIONAL_PRACTICE:
            return i18next.t('to_train');

        case EStepType.PLACEMENT:
            return i18next.t('to_place');

        default:
            return i18next.t('to_evaluate');
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- GET FILTERED STEP TYPES ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getFilteredStepTypes = (stepType?: EStepType) => {

    let filteredTypes: EStepType[];

    switch (stepType) {

        case EStepType.SELF_PLACEMENT:
        case EStepType.PLACEMENT:
        case EStepType.FINAL_EVALUATION:
            filteredTypes = Object.values(EStepType).filter(value =>
                [EStepType.SELF_PLACEMENT, EStepType.PLACEMENT, EStepType.FINAL_EVALUATION]
                    .some(v => v === value)
            );
            break;

        case EStepType.SELF_EVALUATION:
        case EStepType.PREPARATORY_SEQUENCE:
        case EStepType.WORK_SITUATION:
        case EStepType.REFLECTION_PHASE:
        case EStepType.EVALUATION:
            filteredTypes = Object.values(EStepType).filter(value =>
                [EStepType.SELF_EVALUATION, EStepType.PREPARATORY_SEQUENCE, EStepType.WORK_SITUATION, EStepType.REFLECTION_PHASE, EStepType.EVALUATION]
                    .some(v => v === value)
            );
            break;

        case EStepType.SUPPORT:
        case EStepType.OTHER_TRAINING:
        case EStepType.PROFESSIONAL_PRACTICE:
        case EStepType.REFLEXIVE_RESULT:
        case EStepType.INTERNSHIP:
            filteredTypes = [];
            break;

        default:
            filteredTypes = Object.values(EStepType);
            break;
    }

    return filteredTypes;
};

/*
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*                                             </STEP TYPES>                                             *
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*/

/*
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*                                               <HELPERS>                                               *
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*/

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- LOCALES --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getLocales = () => [
    {
        value: 'fr',
        label: i18next.t('locale.fr'),
        notTranslated: 'Français',
    },
    {
        value: 'fr-FALC',
        label: i18next.t('locale.fr-falc'),
        notTranslated: 'Français - Facile à Lire et à Comprendre (FALC)',
    },
    {
        value: 'en',
        label: i18next.t('locale.en'),
        notTranslated: 'English',
    },
    {
        value: 'de',
        label: i18next.t('locale.de'),
        notTranslated: 'Deutsch',
    },
    {
        value: 'sl',
        label: i18next.t('locale.sl'),
        notTranslated: 'Slovenščina',
    },
];

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- HANDLE LOCALE CHANGE --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const handleLocaleChange = (locale: string | undefined) => {
    if (locale) {
        localStorage.setItem('locale', locale);
        i18next.changeLanguage(locale);
        dayjs.locale(locale);
        document.documentElement.setAttribute('lang', locale.substring(0, 2));
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- GET ADAPTER LOCALE ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getAdapterLocale = () => {

    switch (i18next.language.substring(0, 2)) {

        case 'fr':
            return 'fr';

        case 'de':
            return 'de';

        default:
            return 'en';
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- GET LOCALE TEXT ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const getLocaleText = () => {

    switch (i18next.language.substring(0, 2)) {

        case 'fr':
            return frFR.components.MuiLocalizationProvider.defaultProps.localeText;

        case 'de':
            return deDE.components.MuiLocalizationProvider.defaultProps.localeText;

        default:
            return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- GET BACK URL ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const getBackUrl = (url: string, depth: number = 1): string => {
    let index = url.lastIndexOf('/');
    if (index !== -1) {
        if (depth > 1) {
            return getBackUrl(url.slice(0, index), depth - 1);
        }
        return url.slice(0, index);
    }
    return url;
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- GET FILE EXTENSION ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getFileExtension = (fileName: string | undefined) => {
    if (fileName) {
        return `${fileName.split('.').pop()}`;
    }
    return '';
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- IS VALID EMAIL ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const isValidEmail = (email: string) => {
    // eslint-disable-next-line
    let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g;
    return regex.test(email);
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ EMAIL HELPER TEXT --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getEmailHelperText = (email: string, phoneNumber: string) => {
    if (email !== '' && !isValidEmail(email)) {
        return i18next.t('invalid_email');
    }
    if (email === '' && !isValidPhoneNumber(phoneNumber)) {
        return i18next.t('please_enter_mail_or_phone');
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- ARRAY CHANGE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const arrayChange = (array: string[], id: string) => {
    let index = array.findIndex(itemId => itemId === id);

    if (index === - 1) {
        array.push(id);
    }
    else {
        array.splice(index, 1);
    }
    return array;
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- TRANSFORM TIME TO DATE -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const transformTimeToDate = (time: string | undefined, addHours: number = 0) => {
    if (time && time.length >= 5) {
        return dayjs(new Date(0, 0, 0, Number(time.substring(0, 2)) + addHours, Number(time.substring(3, 5))));
    }
    return null;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- TRANSFORM MINUTE TO TIME ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const transformMinuteToTime = (minute: number) => {
    let hour = Math.floor(minute / 60);
    let min = minute % 60;

    if (hour > 0 && min > 0) return `${hour}h${(min).toLocaleString('fr', { minimumIntegerDigits: 2 })}`;
    if (min > 0) return `${min}min`;
    return `${hour}h`;
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- GET FORMATTED DATE --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getFormattedDate = (date: string | undefined) => {
    return date ? dayjs(date).format('DD/MM/YYYY') : '';
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- GET FORMATTED TIME ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getFormattedTime = (time?: string) => {
    if (time) {
        return time.substring(0, 5).replace(':', 'h');
    }
    return '';
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- COMPARE DATES ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const compareDates = (date1: Dayjs | null, date2: Dayjs | null, operator: '>' | '<' | '=') => {

    if (date1 && date2) {

        switch (operator) {

            case '>':
                return dayjs(date1).format('YYYY-MM-DD') > dayjs(date2).format('YYYY-MM-DD');

            case '<':
                return dayjs(date1).format('YYYY-MM-DD') < dayjs(date2).format('YYYY-MM-DD');

            case '=':
                return dayjs(date1).format('YYYY-MM-DD') === dayjs(date2).format('YYYY-MM-DD');
        }
    }
    return false;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- COMPARE TIMES ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const compareTimes = (date1: Dayjs | null, date2: Dayjs | null, operator: '>' | '<' | '=') => {

    if (date1 && date2) {

        switch (operator) {
            
            case '>':
                return dayjs(date1).format('HH:mm') > dayjs(date2).format('HH:mm');

            case '<':
                return dayjs(date1).format('HH:mm') < dayjs(date2).format('HH:mm');

            case '=':
                return dayjs(date1).format('HH:mm') === dayjs(date2).format('HH:mm');
        }
    }
    return false;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ARRAY EQUALITY ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const arrayEquality = (array1: any[], array2: any[]) => {
    if (array1.length === array2.length) {
        array1.forEach(a => {
            if (array2.every(b => a !== b)) {
                return false;
            }
        });
        return true;
    }
    return false;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- GET BIGGEST ARRAY LENGTH ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getBiggestArrayLength = (array1: any[], array2: any[], array3: any[]) => {
    if (array1.length >= array2.length && array1.length >= array3.length) {
        return array1.length;
    }
    if (array2.length >= array1.length && array2.length >= array3.length) {
        return array2.length;
    }
    return array3.length;
};

export const getLongestArrayLength = (arrays: any[]) => {
    
    if (arrays.length === 0)
        return 0;

    let largest = 0;

    for (let i = 0; i < arrays.length; i++) {
        if (arrays[i].length > largest)
            largest = arrays[i].length;
    }

    return largest;
}

export const getNextKey = (key: string): any => {
    if (key === 'Z' || key === 'z') {
        return String.fromCharCode(key.charCodeAt(0) - 25) + String.fromCharCode(key.charCodeAt(0) - 25); // AA or aa
    }

    let lastChar = key.slice(-1);
    let sub = key.slice(0, -1);

    if (lastChar === 'Z' || lastChar === 'z') {
        // If a string of length > 1 ends in Z/z,
        // increment the string (excluding the last Z/z) recursively,
        // and append A/a (depending on casing) to it
        return getNextKey(sub) + String.fromCharCode(lastChar.charCodeAt(0) - 25);
    }

    return sub + String.fromCharCode(lastChar.charCodeAt(0) + 1);
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- CAPITALIZE FIRST LETTER ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- ON SEARCH ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const onSearch = (setState: any, filters: any) => {
    setState((prevState: any) => ({ ...prevState, isResearch: true, page: 1, filters }));
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------- GET ROADMAP MODEL TOTAL DURATION --------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRoadmapModelTotalDuration = (stepModels: any, pivot: boolean) => {
    let total = 0;
    let lastTime = 0;

    if (stepModels && stepModels.length > 0) {
        stepModels.forEach((stepModel: any) => {

            let time = pivot ? stepModel.pivot?.time : stepModel.time;
            let separation = pivot ? stepModel.pivot?.separation : stepModel.separation;

            if (separation === 0) {
                if (time > lastTime) {
                    total += (time - lastTime);
                }
            }
            else {
                total += (time + separation);
            }
            lastTime = time;
        });
    }

    return total;
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------- GET ROADMAP MODEL ESTIMATED HOURS NUMBER ----------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRoadmapModelEstimatedHoursNumber = (stepModels: IStepModel[]) => {
    let estimated_hours = 0;
    stepModels.forEach(stepModel => {
        if (stepModel.duration) {
            estimated_hours += stepModel.duration;
        }
    });
    return estimated_hours;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- GET FULL ADDRESS --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getFullAddress = (item: any) => {
    let array = [];
    if (item?.address) {
        array.push(item.address);
    }
    if (item?.postalCode) {
        array.push(item.postalCode);
    }
    if (item?.city) {
        array.push(item.city);
    }
    if (item?.country) {
        array.push(item.country?.name);
    }
    return array.join(' ');
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------- GET ATYPICAL ITEMS FROM ATYPICAL REPOSITORY -------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getAtypicalItemsFromAtypicalRepository = (atypicalRepository: IAtypicalRepository) => {
    let atypicalItems : IAtypicalItem[] = [];

    if (
        atypicalRepository.atypicalLevels
        && atypicalRepository.atypicalLevels.length > 0
    ) {
        atypicalRepository.atypicalLevels.forEach(atypicalLevel => {
            atypicalItems = atypicalItems.concat(atypicalLevel.atypicalItems);
        });
    }

    return atypicalItems;
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- GET BASE URI ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const getDataUri = (url: string, callback: (dataUri: string, imgWidth: number, imgHeight: number) => void) => {

    return new Promise((resolve, reject) => {

        let image = new Image();
        image.crossOrigin = 'Anonymous';
        image.src = url;

        image.onload = function() {
        
            let canvas = document.createElement('canvas');
            canvas.width = image.naturalWidth;
            canvas.height = image.naturalHeight;
    
            let ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.fillStyle = '#FFFFFF';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                resolve(callback(canvas.toDataURL('image/png'), canvas.width, canvas.height));
            }
        };

        image.onerror = function(e) {
            reject(e);
        };
    });
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- CALCULATE ASPECT RATIO FIT ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const calculateAspectRatioFit = (srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) => {
    let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- EXCEL COLUMN FROM INDEX ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const getExcelColumnFromIndex = (index: number) : string => {
    let value = index / 26;

    if (value >= 1) {
        return getExcelColumnFromIndex(Math.floor(value - 1)) + alphabetChars[index % 26];
    }
    return alphabetChars[index % 26];
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ REFLECTION PHASE BREADCRUMB ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getBreadcrumbItems = ({
    mainId,
    workSituation,
    source,
    items,
    reflectionPhaseId,
    roadmapId,
    action,
} : {
    mainId?: string
    workSituation?: IWorkSituation,
    source: 'USER' | 'COMPANY' | 'ROADMAP',
    items: IBreadcrumbItem[],
    reflectionPhaseId?: string,
    roadmapId?: string,
    action?: string
}) : { items: IBreadcrumbItem[], workSituationLink: string } => {

    let title = getWorkSituationTitle(workSituation?.workSituationType);
    let workSituationLink = '';

    switch (source) {

        case 'COMPANY':
            workSituationLink = '/app/companies/' + mainId + '/work-situations/' + workSituation?.id;
            items.push({
                label: title + ' #' + workSituation?.id,
                route: workSituationLink,
            });
            break;

        case 'USER':
            workSituationLink = '/app/users/' + mainId + '/work-situations/' + workSituation?.id;
            items.push({
                label: title + ' #' + workSituation?.id,
                route: workSituationLink,
            });
            break;

        case 'ROADMAP':
            workSituationLink = '/app/users/' + mainId + '/roadmaps/' + roadmapId + '/work-situations/' + workSituation?.id;
            items.push({
                label: i18next.t('roadmap') + ' #' + roadmapId,
                route: '/app/users/' + mainId + '/roadmaps/' + roadmapId,
            }, {
                label: title + ' #' + workSituation?.id,
                route: workSituationLink,
            });
            break;
    }

    if (reflectionPhaseId) {

        switch (source) {
            
            case 'COMPANY':
                items.push({
                    label: i18next.t('reflection_phase') + ' #' + reflectionPhaseId,
                    route: '/app/companies/' + mainId + '/work-situations/' + workSituation?.id + '/reflection-phase/' + reflectionPhaseId,
                });
                break;

            case 'USER':
                items.push({
                    label: i18next.t('reflection_phase') + ' #' + reflectionPhaseId,
                    route: '/app/users/' + mainId + '/work-situations/' + workSituation?.id + '/reflection-phase/' + reflectionPhaseId,
                },);
                break;

            case 'ROADMAP':
                items.push({
                    label: i18next.t('reflection_phase') + ' #' + reflectionPhaseId,
                    route: '/app/users/' + mainId + '/roadmaps/' + roadmapId + '/work-situations/' + workSituation?.id + '/reflection-phase/' + reflectionPhaseId,
                });
                break;
        }
    }

    if (action) {
        items.push({
            label: action
        });
    }

    return { items, workSituationLink };
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------- GET APPLICATION STATUS EXPIRATION ------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getApplicationAccess = (app: IApplication) : EApplicationAccess => {
    if (app.pivot?.expiration) {
        if (app.pivot.expiration < dayjs().format('YYYY-MM-DD')) {
            return EApplicationAccess.ACCESS_EXPIRED;
        }
        if (app.pivot.expiration <= dayjs().add(1, 'month').format('YYYY-MM-DD')) {
            return EApplicationAccess.ACCESS_EXPIRES_SOON;
        }
        if (app.pivot.expiration > dayjs().add(1, 'month').format('YYYY-MM-DD')) {
            return EApplicationAccess.HAS_ACCESS;
        }
    }
    return EApplicationAccess.HAS_ACCESS;
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SET DOCUMENT TITLE --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const setDocumentTitle = (title: string) => {
    document.title = `${truncateLabel(title, 48)} | ${originLabel}`;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ GET FILE SIZE ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getFileSize = (fileSize: number) => {
    if (fileSize >= 1073741824) {
        return `${(fileSize / 1073741824).toFixed(2)} Go`;
    }
    if (fileSize >= 1048576) {
        return `${(fileSize / 1048576).toFixed(2)} Mo`;
    }
    return `${(fileSize / 1024).toFixed(2)} Ko`;
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- GET FILE ATTRIBUTES ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getFileAttributes = (fileExtention: string, iconProps?: SvgIconProps) => {

    if (acceptedExtensions.document.includes(fileExtention)) {
        return ({ title: i18next.t('document'), icon: <DocIcon {...iconProps} /> });
    }
    if (acceptedExtensions.image.includes(fileExtention)) {
        return ({ title: i18next.t('image'), icon: <ImageIcon {...iconProps} /> });
    }
    if (acceptedExtensions.audio.includes(fileExtention)) {
        return ({ title: i18next.t('audio'), icon: <AudioIcon {...iconProps} /> });
    }
    if (acceptedExtensions.video.includes(fileExtention)) {
        return ({ title: i18next.t('video'), icon: <VideoIcon {...iconProps} /> });
    }
    return ({ title: i18next.t('file'), icon: <DocIcon {...iconProps} /> });
}

/*
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*                                              </HELPERS>                                               *
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*/
