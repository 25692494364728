import { gql } from '@apollo/client';

export const ATYPICAL_REPOSITORY = gql`
    query AtypicalRepository($id: ID!) {
        atypicalRepository(id: $id) {
            id
            name
            description
            logoUrl
            status
            creator {
                id
                fullName
            }
            lastModifier {
                id
                fullName
            }
            companies {
                id
                name
                postalCode
                city
            }
            countries {
                id
                name
            }
            translations {
                translationId
                name
                description
                locale
            }
            atypicalLevels {
                id
                name
                level
                isLinkable
                isBlock
                isTrainable
                isEvaluable
                isCertifiable
                translations {
                    translationId
                    name
                    locale
                }
                parentLevel {
                    id
                }
                childLevel {
                    id
                }
                atypicalItems {
                    id
                    name
                    description
                    translations {
                        translationId
                        name
                        description
                        locale
                    }
                    parentItem {
                        id
                        name
                    }
                    subItems {
                        id
                    }
                }
            }
        }
    }
`

export const ATYPICAL_REPOSITORY_RESULTS = gql`
    query AtypicalRepositoryResults(
        $learnerId: ID!
        $atypicalRepositoryId: ID!
        $customRepositoryId: ID
        $roadmapId: ID
    ) {
        atypicalRepositoryResults(
            learnerId: $learnerId
            atypicalRepositoryId: $atypicalRepositoryId
            customRepositoryId: $customRepositoryId
            roadmapId: $roadmapId
        ) {
            simpleResultId
            resultType
            nextStep
        }
    }
`;
