import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Dialog, DialogContent, DialogActions, Button, CircularProgress, Divider } from '@mui/material';
import { WarningRounded as WarningIcon } from '@mui/icons-material'; 

// Apollo
import { useMutation } from '@apollo/client';
import { DELETE_WORK_SITUATION } from './gql-delete-work-situation';

// Services
import { IWorkSituation } from 'services/interfaces';
import { getErrorExtensions } from 'services/utils';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- DELETE WORK SITUATION -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const DeleteWorkSituation = ({
    workSituation,
    onClose,
    refetch,
} : {
    workSituation: IWorkSituation | undefined,
    onClose: VoidFunction,
    refetch: any,
}) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [deleteWorkSituation, { loading, error, data }] = useMutation(DELETE_WORK_SITUATION);

    useEffect(() => {
        if (data?.deleteWorkSituation) {
            enqueueSnackbar(t('deleted_successfully'), { variant: 'success' });
            refetch();
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <Dialog
            open
            onClose={loading ? undefined : onClose}
        >
            <DialogContent>
                {t(`confirm_delete_${workSituation?.workSituationType.toLowerCase()}`)}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    disabled={loading}
                    color="inherit"
                >
                    {t('no')}
                </Button>
                <Button
                    onClick={() => deleteWorkSituation({ variables: { id: workSituation?.id }})}
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <WarningIcon fontSize="small" />}
                    color="warning"
                >
                    {t('yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteWorkSituation;
