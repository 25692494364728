import { gql } from '@apollo/client';

export const ROADMAPS = gql`
    query Roadmaps(
        $first: Int
        $page: Int
        $search: String
        $companyId: ID
        $learnerId: ID
        $trainerId: ID
    ) {
        roadmaps(
            first: $first
            page: $page
            search: $search
            companyId: $companyId
            learnerId: $learnerId
            trainerId: $trainerId
            orderBy: [{ column: START_DATE, order: DESC }]
        ) {
            paginatorInfo {
                total
            }
            data {
                id
                name
                status
                startDate
                endDate
                company {
                    id
                    name
                    postalCode
                    city
                }
                learner {
                    id
                    fullName
                }
                customRepository {
                    id
                    name
                }
            }
        }
    }
`
