import { gql } from '@apollo/client';

export const SET_COMPANY_STORAGE_LIMIT = gql`
    mutation SetCompanyStorageLimit(
        $id: ID!
        $storageLimit: Int
    ) {
        setCompanyStorageLimit(
            id: $id
            storageLimit: $storageLimit
        ) {
            id
        }
    }
`;
