import { useTranslation } from 'react-i18next';

// MUI
import { Divider, Dialog, DialogContent, DialogActions, Button } from '@mui/material';

// Services
import { API_URL } from 'services/apollo-client';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ SIGNATURE DIALOG ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const SignatureDialog = ({
    username,
    signatureUrl,
    onClose,
} : {
    username: string,
    signatureUrl: string,
    onClose: VoidFunction,
}) => {

    const { t } = useTranslation();

    return (
        <Dialog
            open
            onClose={onClose}
        >
            <DialogContent>
                <div className="dialog-title">
                    {t('signature_of_user', { user: username })}
                </div>
                <img
                    alt="signature"
                    src={API_URL + signatureUrl}
                    style={{ height: 'auto', maxHeight: 300, width: 'auto', maxWidth: 300 }}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                >
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SignatureDialog;
