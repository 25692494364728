import { useEffect, Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// Apollo
import { useQuery } from '@apollo/client';
import { USER } from 'views/authenticated/users/user/gql-user';

// Services
import { getStoredUser, setStoredUser, setPermissions } from 'services/storage';
import { IUser } from 'services/interfaces';
import { handleLocaleChange } from 'services/utils';

// Components
import AppBar from './app-bar';
import Backdrop from 'components/backdrop';
import Spinner from 'components/spinner';

// CSS
import './AuthenticatedLayout.css';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- AUTHENTICATED LAYOUT --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const AuthenticatedLayout = () => {
    
    const storedUser = getStoredUser();
    const location = useLocation();
    const locationState : any = location.state;

    const { loading, data } = useQuery<{ user: IUser }>(USER, {
        variables: {
            id: storedUser?.id,
        },
        skip: locationState?.skipFetchUser,
    });

    useEffect(() => {
        if (data?.user) {
            
            let user : any = {...data.user};

            handleLocaleChange(user.locale);
            setPermissions(user.permissions);

            delete user.permissions;
            setStoredUser(user);
        }
    }, [data]);

    if (loading) return <Backdrop />;

    return (
        <>
            <AppBar />
            <div
                id="AuthenticatedLayout"
                className="AuthenticatedLayout"
            >
                <Suspense fallback={<Spinner />}>
                    <Outlet />
                </Suspense>
            </div>
        </>
    );
};

export default AuthenticatedLayout;
