import { Grid, CircularProgress } from '@mui/material';
import { darkGrayMain } from 'services/constants';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- SPINNER ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Spinner() {
    return (
        <Grid
            padding={3}
            flex={1}
            container
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            <CircularProgress size={40} style={{ color: darkGrayMain }} />
        </Grid>
    );
}
