import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

// Services
import PACKAGE_JSON from '../package.json';

// Components
import Cookies from 'components/cookies';

// Views
import PublicRoutes from 'routes/public-routes';
import PrivateRoutes from 'routes/private-routes';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------------ APP ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const App = () => {

    useEffect(() => {

        localStorage.setItem('currentVersion', PACKAGE_JSON.version);

        const onCheckVersion = () => {

            if ('caches' in window) {
                caches.keys().then(keys => {
                    keys.forEach(key => {
                        caches.delete(key);
                    });
                });
            }

            window.location.reload();
        };
        document.addEventListener('updateVersion', onCheckVersion);

        return () => {
            document.removeEventListener('updateVersion', onCheckVersion);
        };
    }, []);

    return (
        <>
            <Cookies />
            <Routes>
                <Route path="/app/*" element={<PrivateRoutes />} />
                <Route path="/*" element={<PublicRoutes />} />
            </Routes>
        </>
    );
};

export default App;
