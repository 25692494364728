import { gql } from '@apollo/client';

export const UPDATE_LEARNER_WORK_SITUATION_WEATHER = gql`
    mutation UpdateLearnerWorkSituation(
        $id: ID!,
        $weather: Weather,
        $personalResult: String,
    ) {
        updateLearnerWorkSituation(input: {
            id: $id,
            weather: $weather,
            personalResult: $personalResult,
        }) {
            id
        }
    }
`

export const UPDATE_LEARNER_WORK_SITUATION_ROADMAP = gql`
    mutation UpdateLearnerWorkSituation(
        $id: ID!,
        $roadmap: ConnectRoadmapBelongsTo,
    ) {
        updateLearnerWorkSituation(input: {
            id: $id,
            roadmap: $roadmap,
        }) {
            id
        }
    }
`
