import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Services
import { IActivity, ITask, IActivityRepository, ICustomRepositoryResults } from 'services/interfaces';
import { yellowMain } from 'services/constants';

// Components
import PelletCard from 'components/pellet-card';
import { BlockContainer, Block, EmptyBlock, BlockIconButton } from 'components/block';
import ResultTypeIcon, { ResultTypeBadge, isResultTypeIcon } from 'components/result-type';

// Repository Items
import Tasks from './tasks';
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../../index';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ACTIVITIES --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ActivitiesWithTasks({
    position,
    activityRepository,
    customRepositoryResults,
} : {
    position: number,
    activityRepository: IActivityRepository | undefined,
    customRepositoryResults: ICustomRepositoryResults | undefined,
}) {

    const { t } = useTranslation();
    const [selectedActivity, setSelectedActivity] = useState<IActivity | null>(null);
    const { tasks } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const getTaskCount = (taskList: ITask[]) => {
        return taskList.filter(t => tasks.some(i => i.id === t.id)).length;
    };

    const onSelectActivity = (activity: IActivity) => {
        if (activity.id === selectedActivity?.id) {
            setSelectedActivity(null);
        }
        else {
            setSelectedActivity(activity);
        }
    };

    return (
        <>
            <PelletCard
                paddingTop={0}
                pelletColor={yellowMain}
                pelletContent={position}
                summary={(
                    <Grid item xs>
                        {t('select_activity')}
                    </Grid>
                )}
            >
                <Grid
                    container
                    spacing={3}
                >
                    {(
                        activityRepository?.activities
                        && activityRepository.activities.length > 0
                    ) ? (
                        activityRepository.activities.map(activity => {

                            let taskCount = getTaskCount(activity.tasks);
                            let resultType = customRepositoryResults?.activityResults?.find(r => r.simpleResultId === activity.id)?.resultType;
                            
                            return (
                                <BlockContainer key={activity.id}>
                                    <Block
                                        onClick={() => onSelectActivity(activity)}
                                        selected={selectedActivity?.id === activity.id}
                                        deselected={(selectedActivity != null && selectedActivity.id !== activity.id)}
                                        color="yellow"
                                        badge={<ResultTypeBadge resultType={resultType} />}
                                        icons={(
                                            <>
                                                {isResultTypeIcon(resultType) && (
                                                    <BlockIconButton>
                                                        <ResultTypeIcon resultType={resultType} />
                                                    </BlockIconButton>
                                                )}
                                                <BlockIconButton>
                                                    {taskCount}
                                                </BlockIconButton>
                                            </>
                                        )}
                                    >
                                        {activity.position}. {activity.name}
                                    </Block>
                                </BlockContainer>
                            )
                        })
                    ) : (
                        <BlockContainer>
                            <EmptyBlock color={yellowMain}>
                                {t('none.2')}
                            </EmptyBlock>
                        </BlockContainer>
                    )}
                </Grid>
            </PelletCard>
            {/* ------------------ TASKS ------------------ */}
            {selectedActivity && (
                <Tasks
                    position={position + 1}
                    activity={selectedActivity}
                    taskResults={customRepositoryResults?.taskResults}
                />
            )}
        </>
    );
}
