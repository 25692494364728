import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// File Saver
import { saveAs } from 'file-saver';

// MUI
import { Grid, Button } from '@mui/material';
import { Assignment as DetailIcon, ArrowRightAlt as ArrowIcon } from '@mui/icons-material';

// Services
import { IReflectionPhase } from 'services/interfaces';
import { getFormattedDate, getFormattedTime, getFileExtension } from 'services/utils';
import { API_URL } from 'services/apollo-client';

// Components
import Title from 'components/title';
import FramedStatus from 'components/framed-status';
import PelletCard from 'components/pellet-card';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- 02 REFLECTION PHASE DETAILS ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ReflectionPhaseDetails({
    reflectionPhase,
    workSituationLink,
} : {
    reflectionPhase: IReflectionPhase | undefined,
    workSituationLink: string,
}) {
    
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onSaveSignOffSheet = () => {
        let fileName = t('work_situation').toUpperCase() + '-' + getFormattedDate(reflectionPhase?.trainingDate);
        saveAs(
            API_URL + reflectionPhase?.signOffSheetUrl,
            fileName + '.' + getFileExtension(reflectionPhase?.signOffSheetUrl),
        );
    };

    return (
        <PelletCard
            paddingTop={0}
            startIcon={<DetailIcon color="success" />}
            summary={(
                <Grid item xs>
                    {t('reflection_phase_details')}
                </Grid>
            )}
        >
            <Grid
                container
                spacing={3}
            >
                {/* ------------------ STATUS ------------------ */}
                {! reflectionPhase?.learnerWorkSituation?.workSituation?.signatureDisabled && (
                    <Grid item xs={12}>
                        <FramedStatus
                            status={reflectionPhase?.isSigned ? 'SIGNED' : 'WAITING'}
                        />
                    </Grid>
                )}
                {/* ------------------ WORK SITUATION ------------------ */}
                <Grid item xs={12} sm="auto">
                    <Title>
                        {t('work_situation')}
                    </Title>
                    <div>
                        <Button
                            onClick={() => navigate(workSituationLink)}
                            variant="text"
                            style={{ padding: 0 }}
                        >
                            {t('view')}
                        </Button>
                    </div>
                </Grid>
                {/* ------------------ IS SIGNED ------------------ */}
                {!reflectionPhase?.isSigned && (
                    <Grid item xs={12} sm="auto">
                        <Title>
                            {t('status')}
                        </Title>
                        <br />
                        <span>
                            {reflectionPhase?.learnerWorkSituation?.workSituation?.signatureDisabled ? (
                                t('signatures_disabled')
                            ) : (
                                t('waiting_signature')
                            )}
                        </span>
                    </Grid>
                )}
                {/* ------------------ TRAINING DATE ------------------ */}
                <Grid item xs={12} sm="auto">
                    <Title>
                        {t('training_date')}
                    </Title>
                    <br />
                    <span>
                        {getFormattedDate(reflectionPhase?.trainingDate)}
                    </span>
                </Grid>
                {/* -------------------- START & END TIMES -------------------- */}
                <Grid item xs={12} sm="auto">
                    <Title>
                        {t('schedule.1')}
                    </Title>
                    <div className="inline-flex">
                        <span>
                            {getFormattedTime(reflectionPhase?.startTime)}
                        </span>
                        <ArrowIcon />
                        <span>
                            {getFormattedTime(reflectionPhase?.endTime)}
                        </span>
                    </div>
                </Grid>
                {/* ------------------ SIGN OFF SHEET ------------------ */}
                {reflectionPhase?.signOffSheetUrl && (
                    <Grid item xs={12} sm="auto">
                        <Title>
                            {t('sign_off_sheet')}
                        </Title>
                        <div>
                            <Button
                                onClick={onSaveSignOffSheet}
                                variant="text"
                                style={{ padding: 0 }}
                            >
                                {t('download')}
                            </Button>
                        </div>
                    </Grid>
                )}
            </Grid>
        </PelletCard>
    );
}
