import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Apollo
import { useQuery } from '@apollo/client';
import { WORK_SITUATION } from './gql-sign-work-situation';

// Services
import { IWorkSituation, IBreadcrumbItem } from 'services/interfaces';
import { getBreadcrumbItems } from 'services/utils';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import SignWorkSituationCard from './01-sign-work-situation-card';
import UploadSignOffSheet from './02-upload-sign-off-sheet';
import UploadTrainerSignatures from './03-upload-trainer-signatures';
import UploadLearnerSignatures from './04-upload-learner-signatures';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- FETCH TRAINER WORK SITUATION ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const FetchWorkSituation = ({
    source,
    defaultBreadcrumbItems,
    setBreadcrumbItems,
} : {
    source: 'USER' | 'COMPANY' | 'ROADMAP',
    defaultBreadcrumbItems: IBreadcrumbItem[],
    setBreadcrumbItems: (breadcrumbItems: IBreadcrumbItem[]) => void,
}) => {

    const { t } = useTranslation();
    const { id, roadmapId, workSituationId } = useParams();

    const { loading, error, data, refetch } = useQuery<{ workSituation: IWorkSituation }>(WORK_SITUATION, {
        variables: { id: workSituationId },
    });

    useEffect(() => {
        if (data?.workSituation) {

            const { items } = getBreadcrumbItems({
                mainId: id,
                workSituation: data.workSituation,
                source,
                items: defaultBreadcrumbItems,
                roadmapId,
                action: t('signatures'),
            });

            setBreadcrumbItems(items);
        }
        // eslint-disable-next-line
    }, [data]);

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <SignWorkSituation
            workSituation={data?.workSituation}
            refetch={refetch}
        />
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SIGN WORK SITUATION --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const SignWorkSituation = ({
    workSituation,
    refetch,
} : {
    workSituation: IWorkSituation | undefined,
    refetch: any,
}) => {
    return (
        <>
            {/* -------------------- 01 CARD -------------------- */}
            <SignWorkSituationCard
                workSituation={workSituation}
            />
            {/* -------------------- 02 UPLOAD SIGN OFF SHEET -------------------- */}
            <UploadSignOffSheet
                workSituation={workSituation}
                refetch={refetch}
            />
            {/* -------------------- 03 UPLOAD TRAINER SIGNATURES -------------------- */}
            <UploadTrainerSignatures
                workSituation={workSituation}
                refetch={refetch}
            />
            {/* -------------------- 04 UPLOAD LEARNER SIGNATURES -------------------- */}
            <UploadLearnerSignatures
                workSituation={workSituation}
                refetch={refetch}
            />
        </>
    );
};

export default FetchWorkSituation;
