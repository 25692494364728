import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Dayjs
import dayjs, { Dayjs } from 'dayjs';

// Apollo
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_REFLECTION_PHASE, LEARNER_WORK_SITUATION } from './gql-create-reflection-phase';

// Services
import {
    ILearnerWorkSituation, ISelectOptionWithUserAttributes, IDescriptor, ITask, IAtypicalItem,
    IBreadcrumbItem, ILinkItem, ISelectOption, IReflexivityQuestionItem, IReflexivityAxis, IReflexivityQuestion,
} from 'services/interfaces';
import { getErrorExtensions, connectOrCreateUser, transformTimeToDate, getBreadcrumbItems } from 'services/utils';

// Views
import ReflectionPhaseForm from '../reflection-phase-form';

// Components
import Title from 'components/title';
import Spinner from 'components/spinner';
import Error from 'components/error';

export interface IState {
    trainingDate: Dayjs | null;
    startTime: Dayjs | null;
    endTime: Dayjs | null;
    result: string;
    trainers: ISelectOptionWithUserAttributes[];
    learnerWorkSituationDate: string;
    roadmapEndDate: string | null;
    descriptors: IDescriptor[];
    tasks: ITask[];
    atypicalItems: IAtypicalItem[];
    linkItems: ILinkItem[];
    reflexivityAxis: ISelectOption | null;
    reflexivityQuestionItems: IReflexivityQuestionItem[];
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- FETCH LEARNER WORK SITUATION ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const FetchLearnerWorkSituation = (
{
    source,
    defaultBreadcrumbItems,
    setBreadcrumbItems,
} : {
    source: 'USER' | 'ROADMAP' | 'COMPANY',
    defaultBreadcrumbItems: IBreadcrumbItem[],
    setBreadcrumbItems: React.Dispatch<React.SetStateAction<IBreadcrumbItem[]>>,
}) => {
    
    const { t } = useTranslation();
    const { id, roadmapId, learnerWorkSituationId } = useParams();

    const { loading, error, data } = useQuery<{ learnerWorkSituation: ILearnerWorkSituation }>(LEARNER_WORK_SITUATION, {
        variables: { id: learnerWorkSituationId },
    });

    const [workSituationLink, setWorkSituationLink] = useState('');

    useEffect(() => {
        if (data?.learnerWorkSituation) {

            let workSituation = data?.learnerWorkSituation?.workSituation;
            const { items, workSituationLink } = getBreadcrumbItems({mainId: id, workSituation, source, items: defaultBreadcrumbItems, roadmapId, action: t('add_reflection_phase')});

            setBreadcrumbItems(items);
            setWorkSituationLink(workSituationLink);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <CreateReflectionPhase
            learnerWorkSituation={data?.learnerWorkSituation}
            workSituationLink={workSituationLink}
        />
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- CREATE REFLECTION PHASE -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const CreateReflectionPhase = ({
    learnerWorkSituation,
    workSituationLink,
} : {
    learnerWorkSituation: ILearnerWorkSituation | undefined,
    workSituationLink: string,
}) => {
    
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { id, roadmapId, learnerWorkSituationId } = useParams();
    const [createReflectionPhase, { loading, error, data }] = useMutation(CREATE_REFLECTION_PHASE);

    const defaultTrainers = useMemo(() => {
        let trainerWorkSituations = learnerWorkSituation?.workSituation?.trainerWorkSituations;
        if (trainerWorkSituations) {
            return trainerWorkSituations.map(s => ({ value: s.trainer?.id, label: s.trainer?.fullName }));
        }
        return [];
        // eslint-disable-next-line
    }, [learnerWorkSituation]);

    const getInitialReflexivityAxis = (reflexivityAxis: IReflexivityAxis | undefined) => {
        if (reflexivityAxis) {
            return ({ value: reflexivityAxis.id, label: reflexivityAxis.name });
        }
        return null;
    };

    const getInitialReflixivityQuestionItems = (reflexivityQuestions: IReflexivityQuestion[] | undefined) => {
        if (reflexivityQuestions && reflexivityQuestions.length > 0) {
            return reflexivityQuestions.map(rq => ({ id: rq.id, answer: '' }));
        }
        return [];
    };

    const getDefaultTime = (addHours: number = 0) => {
        let endWorkSituation = learnerWorkSituation?.workSituation?.endAfternoon ?? learnerWorkSituation?.workSituation?.endMorning;
        return endWorkSituation ? transformTimeToDate(endWorkSituation, addHours) : null;
    };

    const [state, setState] = useState<IState>({
        trainingDate: learnerWorkSituation?.workSituation?.trainingDate ? dayjs(learnerWorkSituation.workSituation.trainingDate) : dayjs(),
        startTime: getDefaultTime(),
        endTime: getDefaultTime(1),
        result: '',
        trainers: defaultTrainers,
        learnerWorkSituationDate: learnerWorkSituation?.workSituation?.trainingDate || '',
        roadmapEndDate: learnerWorkSituation?.roadmap?.endDate || null,
        descriptors: learnerWorkSituation?.workSituation?.descriptors || [],
        tasks: learnerWorkSituation?.workSituation?.tasks || [],
        atypicalItems: learnerWorkSituation?.workSituation?.atypicalItems || [],
        linkItems: learnerWorkSituation?.workSituation?.linkItems || [],
        reflexivityAxis: getInitialReflexivityAxis(learnerWorkSituation?.workSituation?.stepModel?.reflexivityAxis),
        reflexivityQuestionItems: getInitialReflixivityQuestionItems(learnerWorkSituation?.workSituation?.stepModel?.reflexivityQuestions),
    });

    const onCreateReflectionPhase = () => {
        createReflectionPhase({
            variables: {
                trainingDate: dayjs(state.trainingDate).format('YYYY-MM-DD'),
                startTime: dayjs(state.startTime).format('HH:mm:00'),
                endTime: dayjs(state.endTime).format('HH:mm:00'),
                result: state.reflexivityAxis ? '' : state.result,
                learnerWorkSituation: { connect: learnerWorkSituationId },
                reflectionPhaseTrainers: { create: state.trainers.map(t => ({ trainer: connectOrCreateUser(t) })) },
                reflexivityAxis: state.reflexivityAxis ? { connect: state.reflexivityAxis.value } : null,
                reflexivityQuestions: { sync: state.reflexivityQuestionItems }
            },
        });
    };

    useEffect(() => {
        if (data?.createReflectionPhase) {
            enqueueSnackbar(t('created_successfully'), { variant: 'success' });
            if (roadmapId) {
                navigate('/app/users/' + id + '/roadmaps/' + roadmapId);
            }
            else {
                navigate(workSituationLink + '/reflection-phase/' + data.createReflectionPhase.id);
            }
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <>
            <div className="container-padding">
                <Title style={{ fontSize: 18 }}>
                    {t('add_reflection_phase')}
                </Title>
            </div>
            <ReflectionPhaseForm
                state={state}
                setState={setState}
                onSubmit={onCreateReflectionPhase}
                loading={loading}
            />
        </>
    );
};

export default FetchLearnerWorkSituation;
