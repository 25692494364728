import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// File Saver
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Apollo
import { useMutation } from '@apollo/client';
import { UPLOAD_SIGN_OFF_SHEET } from './gql-upload-sign-off-sheet';

// Services
import { IReflectionPhase } from 'services/interfaces';
import { getErrorExtensions, getFileExtension, getFormattedDate } from 'services/utils';
import { API_URL } from 'services/apollo-client';

// Components
import UploadSignOffSheetCard from 'components/upload-sign-off-sheet-card';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SIGN OFF SHEET URL ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SignOffSheetUrl({
    reflectionPhase,
    refetch,
} : {
    reflectionPhase: IReflectionPhase | undefined,
    refetch: any,
}) {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [uploadSignOffSheet, { loading, error, data }] = useMutation(UPLOAD_SIGN_OFF_SHEET);

    const onChangeFile = (files: FileList | null) => {
        if (files && files.length > 0) {
            uploadSignOffSheet({
                variables: {
                    id: reflectionPhase?.id,
                    file: files[0],
                },
            });
        }
    };

    const onSaveSignOffSheet = () => {
        let fileName = t('reflection_phase').toUpperCase() + '-' + getFormattedDate(reflectionPhase?.trainingDate);
        saveAs(
            API_URL + reflectionPhase?.signOffSheetUrl,
            fileName + '.' + getFileExtension(reflectionPhase?.signOffSheetUrl),
        );
    };

    useEffect(() => {
        if (data?.uploadReflectionPhaseSignOffSheet) {
            enqueueSnackbar(t('added_successfully'), { variant: 'success' });
            refetch();
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <UploadSignOffSheetCard
            onSaveSignOffSheet={onSaveSignOffSheet}
            onChangeFile={onChangeFile}
            signOffSheetUrl={reflectionPhase?.signOffSheetUrl}
            loading={loading}
        />
    );
}
