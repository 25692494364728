import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Button, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

// Services
import { ICustomRepository, ISimpleResult } from 'services/interfaces';
import { ERepositoryType, EStepType } from 'services/enums';
import { primaryMain } from 'services/constants';
import { useMediaQueries } from 'services/hooks';

// Components
import PelletCard from 'components/pellet-card';
import Title from 'components/title';
import { ResultTypeLegend } from 'components/result-type';

// Views
import SelectAtypicalRepository from './select-atypical-repository';
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../index';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- SELECT REPOSITORY TYPE --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SelectRepositoryType({
    position,
    customRepository,
    activityResults,
} : {
    position: number,
    customRepository: ICustomRepository | undefined,
    activityResults: ISimpleResult[] | undefined,
}) {

    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const [openLegend, setOpenLegend] = useState(false);

    const {
        setContext,
        setRepositoryItems,
        stepType,
        repositoryType,
        enabledActivityRepository,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const onChangeRepositoryType = (value: ERepositoryType) => {
        setRepositoryItems({ customSkills: [], descriptors: [], activities: [], tasks: [], atypicalItems: [], linkItems: [] });
        setContext(prevContext => ({ ...prevContext, repositoryType: value }));
    };

    return (
        <PelletCard
            paddingTop={0}
            pelletColor={primaryMain}
            pelletContent={position}
            summary={(
                <Grid item xs>
                    <span className="fw500">
                        {customRepository?.name}
                    </span>
                    <br />
                    <span>
                        {t('select_repository_type')}
                    </span>
                </Grid>
            )}
        >
            {/* -------------------- LEGEND -------------------- */}
            <Grid
                container
                alignItems="center"
            >
                <Title>
                    {t('legend')}
                </Title>
                <IconButton onClick={() => setOpenLegend(true)}>
                    <InfoIcon />
                </IconButton>
                {openLegend && (
                    <Dialog
                        open
                        onClose={() => setOpenLegend(false)}
                    >
                        <DialogContent>
                            <div className="dialog-title">
                                {t('legend')}
                            </div>
                            <ResultTypeLegend />
                        </DialogContent>
                        <Divider />
                        <DialogActions>
                            <Button
                                onClick={() => setOpenLegend(false)}
                                color="inherit"
                            >
                                {t('close')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Grid>
            {/* -------------------- TOGGLE REPOSITORY TYPE -------------------- */}
            {stepType !== EStepType.SUPPORT && (
                <Grid
                    container
                    marginTop={2}
                    marginBottom={repositoryType === ERepositoryType.ATYPICAL ? 3 : 0}
                >
                    <ToggleButtonGroup
                        value={repositoryType}
                        onChange={(e, value) => value ? onChangeRepositoryType(value) : undefined}
                        orientation={mediaMD ? 'horizontal' : 'vertical'}
                        exclusive
                        color="primary"
                    >
                        <ToggleButton value={ERepositoryType.CUSTOM}>
                            {t('custom_repository')}
                        </ToggleButton>
                        {(
                            enabledActivityRepository
                            && customRepository?.activityRepository
                        ) && (
                            <ToggleButton value={ERepositoryType.ACTIVITY}>
                                {t('activity_repository')}
                            </ToggleButton>
                        )}
                        {(
                            customRepository?.repositoryLinks
                            && customRepository.repositoryLinks.length > 0
                        ) && (
                            <ToggleButton value={ERepositoryType.ATYPICAL}>
                                {t('atypical_repository')}
                            </ToggleButton>
                        )}
                    </ToggleButtonGroup>
                </Grid>
            )}
            {/* ---------------------- SELECT ATYPICAL REPOSITORY ---------------------- */}
            {repositoryType === ERepositoryType.ATYPICAL && (
                <SelectAtypicalRepository
                    customRepository={customRepository}
                    activityResults={activityResults}
                />
            )}
        </PelletCard>
    );
}
