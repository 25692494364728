import { gql } from '@apollo/client';

export const REFLEXIVITY_AXIS = gql`
    query ReflexivityAxis($id: ID!) {
        reflexivityAxis(id: $id) {
            id
            name
            description
            stepType
            companies {
                id
                name
                postalCode
                city
            }
            translations {
                translationId
                locale
                name
                description
            }
            reflexivityQuestions {
                id
                name
                description
                position
                translations {
                    translationId
                    locale
                    name
                    description
                }
            }
        }
    }
`;

export const REORDER_REFLEXIVITY_AXIS = gql`
    mutation ReorderReflexivityAxis(
        $id: ID!
        $reflexivityQuestions: UpdateReflexivityQuestionsHasMany
    ) {
        updateReflexivityAxis(input: {
            id: $id
            reflexivityQuestions: $reflexivityQuestions
        }) {
            id
        }
    }
`;
