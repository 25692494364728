import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Button, CircularProgress } from '@mui/material';

// Services
import { compareTimes } from 'services/utils';
import { successMain } from 'services/constants';

// Components
import PelletCard, { PelletCardMissingFields } from 'components/pellet-card';

// Views
import { IState } from '../../create-reflection-phase';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- 03 VALIDATE REFLECTION PHASE ----------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ValidateReflectionPhase({
    state,
    onSubmit,
    loading,
} : {
    state: IState,
    onSubmit: VoidFunction,
    loading: boolean,
}) {

    const { t } = useTranslation();

    const submitDisabled = (
        state.trainingDate == null
        || state.startTime == null
        || state.endTime == null
        || compareTimes(state.startTime, state.endTime, '>')
    );

    const missingFields = () => {
        let array = [];
        
        if (state.trainingDate == null) {
            array.push(t('training_date'));
        }
        if (state.startTime == null || state.endTime == null || compareTimes(state.startTime, state.endTime, '>')) {
            array.push(t('schedule.1'));
        }

        return `${t('you_have_not_completed_following_fields')} : ${array.join(', ')}.`;
    };

    return (
        <PelletCard
            paddingTop={0}
            pelletColor={successMain}
            pelletContent={5}
            summary={(
                <>
                    <Grid item xs>
                        {t('validate_reflection_phase')}
                    </Grid>
                    <Grid item xs="auto">
                        <Button
                            onClick={onSubmit}
                            disabled={loading || submitDisabled}
                            endIcon={loading ? <CircularProgress color="inherit" size={20} /> : <></>}
                            color="success"
                        >
                            {t('validate')}
                        </Button>
                    </Grid>
                </>
            )}
        >
            {submitDisabled && (
                <PelletCardMissingFields
                    fields={missingFields()}
                />
            )}
        </PelletCard>
    );
}
