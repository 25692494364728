import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

// Permissions
import { canCreatePedagogicalTool, canUpdatePedagogicalTool, canViewAnyPedagogicalTool, canViewPedagogicalTool } from 'permissions';
import { RequirePerm } from '..';

/* * *
* Lazy Imports
*/

const PedagogicalTools = lazy(() => import('../../../views/authenticated/pedagogical-tools'));
const PedagogicalTool = lazy(() => import('../../../views/authenticated/pedagogical-tools/pedagogical-tool'));
const CreatePedagogicalTool = lazy(() => import('../../../views/authenticated/pedagogical-tools/create-pedagogical-tool'));
const UpdatePedagogicalTool = lazy(() => import('../../../views/authenticated/pedagogical-tools/update-pedagogical-tool'));

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ PEDAGOGICAL TOOL ROUTES --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function PedagogicalToolRoutes() {
    return (
        <Routes>
            <Route index element={<RequirePerm perm={canViewAnyPedagogicalTool()}><PedagogicalTools /></RequirePerm>} />
            <Route path="create" element={<RequirePerm perm={canCreatePedagogicalTool()}><CreatePedagogicalTool /></RequirePerm>} />
            <Route path=":pedagogicalToolId">
                <Route index element={<RequirePerm perm={canViewPedagogicalTool()}><PedagogicalTool /></RequirePerm>} />
                <Route path="update" element={<RequirePerm perm={canUpdatePedagogicalTool()}><UpdatePedagogicalTool /></RequirePerm>} />
            </Route>
        </Routes>
    );
}
